import React, { useState } from 'react';
import StartStream from '../components/Streaming/StartStream';
import ActiveStreams from '../components/Streaming/ActiveStreams';
import WatchStream from '../components/Streaming/WatchStream';
import { useSelector } from "react-redux";
import Sidebar from '../components/Layout/Sidebar';
import Header from '../components/Layout/Header';

const StreamPage = () => {
  const [selectedStreamId, setSelectedStreamId] = useState(null);
  const currentProfile = useSelector((state) => state.user.currentProfile);

  const handleSelectStream = (streamId) => {
    setSelectedStreamId(streamId);
  };

  const handleCloseStream = () => {
    setSelectedStreamId(null);
  };

  return (
    <div className="stream-page-container">
        <Header />
        <Sidebar />
      <div className="stream-management">
        <StartStream />
      </div>
      <div className="active-streams-section">
        <ActiveStreams onSelectStream={handleSelectStream} /> 
      </div>

      {selectedStreamId && (
        <div className="watch-stream-modal">
          <div className="watch-stream-content">
            <button className="close-stream-button" onClick={handleCloseStream}>
              &times;
            </button>
            <WatchStream streamId={selectedStreamId} currentUser={currentProfile} /> 
          </div>
        </div>
      )}
    </div>
  );
};

export default StreamPage;
