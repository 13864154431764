import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ecommerceServer } from '../../server';

const carrierDisplayNames = {
  'stamps_com': 'Stamps.com',
  'ups': 'UPS',
  'fedex': 'FedEx',
  'dhl_express_mydhl_api': 'DHL',
  'usps': 'USPS'
};

const carrierLogos = {
  UPS: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/48f4c6bc-8f27-4a35-e0c3-f220dd276b00/public',
  FedEx: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/59d69ef9-0e9d-4c0d-2025-990266929f00/public',
  DHL: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/665e5e0b-948a-425a-6b66-e3154ca82400/public',
  USPS: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/38263d2d-8074-45b6-2c65-6b094b542a00/public',
  'Stamps.com': 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/38263d2d-8074-45b6-2c65-6b094b542a00/public'
};

const CarriersOnboardingCheckbox = () => {
  const [availableCarriers, setAvailableCarriers] = useState([]);
  const [selectedCarriers, setSelectedCarriers] = useState([]);
  const [error, setError] = useState(null);

  const { seller } = useSelector((state) => state.seller);
  const currentProfileName = sessionStorage.getItem('currentProfile');

  // Initialize selectedCarriers from seller data if available
  useEffect(() => {
    if (seller?.shop?.carriers) {
      // Assuming these are already IDs like "se-1292992", etc.
      setSelectedCarriers(seller.shop.carriers);
    }
  }, [seller]);

  // Fetch available carriers from backend
  useEffect(() => {
    const fetchAvailableCarriers = async () => {
      try {
        const res = await axios.get(`${ecommerceServer}/shipping/available`, {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile'
          },
          withCredentials: true
        });
        if (res.data && res.data.carriers) {
          console.log('Carriers available:', res.data.carriers);
          // res.data.carriers should be an array of { id: string, code: string }
          setAvailableCarriers(res.data.carriers);
        }
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
    };
    fetchAvailableCarriers();
  }, [currentProfileName]);

  const handleCheckboxChange = (carrierId) => {
    setSelectedCarriers((prev) =>
      prev.includes(carrierId)
        ? prev.filter((c) => c !== carrierId)
        : [...prev, carrierId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedCarriers.length === 0) {
      setError('Please select at least one carrier.');
      return;
    }
    try {
      const response = await axios.put(
        `${ecommerceServer}/shipping/update-carriers`,
        { carriers: selectedCarriers },
        {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile'
          },
          withCredentials: true
        }
      );
      console.log('Carriers updated:', response.data);
      alert('Carriers updated successfully!');
    } catch (err) {
      setError(err.response?.data?.message || err.message || 'Failed to update carriers');
    }
  };

  return (
    <div className="carriers-onboarding-container">
      <h1 className="carriers-onboarding-title">Choose Your Carriers</h1>
      {error && <p className="carriers-onboarding-error">{error}</p>}

      <form onSubmit={handleSubmit} className="carriers-onboarding-form">
        <fieldset className="carriers-onboarding-fieldset">
          <legend className="carriers-onboarding-legend">Select one or more carriers</legend>
          <div className="carriers-onboarding-checkbox-group">
            {availableCarriers.map((carrier) => {
              const displayName = carrierDisplayNames[carrier.code];
              const logo = carrierLogos[displayName] || 'https://via.placeholder.com/40';
              const isChecked = selectedCarriers.includes(carrier.id);

              return (
                <label key={carrier.id} className="carriers-onboarding-checkbox-option">
                  <input
                    type="checkbox"
                    name="carriers"
                    value={carrier.id}
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(carrier.id)}
                    className="carriers-onboarding-checkbox-input"
                  />
                  <img
                    src={logo}
                    alt={`${displayName} logo`}
                  />
                  <span className="carriers-onboarding-checkbox-label">{displayName}</span>
                </label>
              );
            })}
          </div>
        </fieldset>

        <button type="submit" className="carriers-onboarding-save-button">Save Carriers</button>
      </form>
    </div>
  );
};

export default CarriersOnboardingCheckbox;


