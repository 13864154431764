import React from 'react'
import Header from "../components/Layout/Header"
import Sidebar from '../components/Layout/Sidebar';
import BuyerProtectionReturnPolicy from '../components/Admin/BuyerProtectionReturnPolicy';
import Footer from '../components/Layout/Footer';

const BuyerProtectionPage = () => {
  
  return (
    <div>
        <Header />
        <Sidebar />
        <div>
        <BuyerProtectionReturnPolicy />
        </div>
        <Footer />
    </div>
  )
}

export default BuyerProtectionPage;