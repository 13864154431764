import React from 'react';
import PropTypes from 'prop-types';

const CategoryScroller = ({ categoriesData, onCategorySelect, currentCategory }) => {
  return (
    <div className="category-scroller-container">
      <div className="category-scroller">
        {categoriesData.map((category) => (
          <div
            key={category.id}
            className="category-item"
            onClick={() => onCategorySelect(category)}
          >
            <div
              className={`category-image-wrapper ${
                currentCategory === category.title ? 'active-category-image-wrapper' : ''
              }`}
            >
              <img
                src={category.imageUrl}
                alt={category.title}
                className="category-image"
              />
            </div>
            <p className="category-name">{category.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

CategoryScroller.propTypes = {
  categoriesData: PropTypes.array.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
  currentCategory: PropTypes.string,
};

export default CategoryScroller;



