import React from 'react';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Footer from '../Layout/Footer';


const VendorTermsAndConditions = () => {
  return (
    <>
    <Header />
    <Sidebar />
    <div className="legal-docs-container">
      <h1 className="legal-docs-title">Vendor Terms & Conditions</h1>

      <section>
        <h2 className="legal-docs-subtitle">Payment</h2>
        <ul className="legal-docs-list">
          <li>BuzzVibe charges a 15% commission on the sale price of all orders.</li>
          <li>
            Payouts are made every Friday for orders with confirmed delivery, based on tracking
            information provided by the vendor. Vendors will receive a weekly invoice via email.
          </li>
          <li>Payouts must be processed through Stripe.</li>
          <li>Only orders with confirmed deliveries (with tracking) will be paid out.</li>
        </ul>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Refunds, Returns, and Fees</h2>
        <ul className="legal-docs-list">
          <li>
            BuzzVibe is a platform for independent sellers, each managing their own shop and policies
            for refunds, exchanges, and returns. Policies can vary by shop.
          </li>
          <li>
            If you're a seller outside the EU, you must set a return policy when creating or editing
            a listing for a physical item, even if it's to state you do not accept returns or
            exchanges.
          </li>
          <li>
            If you agree to a return or refund with a buyer through BuzzVibe Messages or the case
            system, you must honor the agreement. This may include:
            <ul className="legal-docs-list">
              <li>Refunding the returned item(s).</li>
              <li>Providing proof of shipping for a replacement item.</li>
            </ul>
          </li>
          <li>
            If there's an issue, contact the buyer directly via BuzzVibe Messages. Buyers are also
            encouraged to reach out to you first. If a case is opened, refer to the Help article for
            next steps. See BuzzVibe’s <a href="/terms-of-service" className="legal-docs-link">Terms of Service</a> for more details.
          </li>
          <li>
            Vendors will incur a $5 fee for case resolution. Vendors will be charged a $25 fee for
            refunds caused by vendor mistakes (e.g., incorrect inventory or shipping errors). Vendors
            must keep their inventory up to date.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Chargebacks</h2>
        <ul className="legal-docs-list">
          <li>Vendors will receive a $15 credit for any chargeback filed against their order.</li>
          <li>If the chargeback is unsuccessful, the vendor will pay the full amount of the chargeback.</li>
        </ul>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Shipping</h2>
        <ul className="legal-docs-list">
          <li>Vendors must provide tracking information for every order.</li>
        </ul>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Size Swaps</h2>
        <ul className="legal-docs-list">
          <li>Vendors must handle size exchanges if:</li>
          <ul className="legal-docs-list">
            <li>The customer requests the swap within 10 days of delivery.</li>
            <li>The requested size or product is available.</li>
          </ul>
          <li>
            Vendors must ship the replacement within their fulfillment timeframe after receiving the
            returned item.
          </li>
          <li>
            Vendors are not responsible for exchanges if the returned product is worn or damaged.
          </li>
          <li>BuzzVibe will reimburse shipping costs for size swaps, with proof of payment.</li>
        </ul>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Future Updates</h2>
        <ul className="legal-docs-list">
          <li>BuzzVibe may update these terms and conditions. See the date below for the last update.</li>
        </ul>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Termination</h2>
        <ul className="legal-docs-list">
          <li>Vendors can cancel their account anytime and request their products be removed from the platform.</li>
          <li>BuzzVibe may remove vendors who repeatedly violate these terms.</li>
        </ul>
      </section>

      <p className="legal-docs-text"><strong>Last Updated: Nov 29, 2024</strong></p>
    </div>
    <Footer />
    </>
  );
};

export default VendorTermsAndConditions;
