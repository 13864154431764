import React from 'react';
import cn from 'classnames';
import './Button.scss';

/**
 * @typedef {Object} Props
 * @property {boolean} [active]
 * @property {string} [className]
 * @property {React.ReactNode} [children]
 * @property {React.ButtonHTMLAttributes<HTMLButtonElement>} [props]
 */

const Button = (/** @type {Props} */ { className, active, children, ...props }) => {
    return (
        <button className={cn('image-editor-button', active && 'image-editor-button--active', className)} {...props}>
            {children}
        </button>
    );
};

export default Button;
