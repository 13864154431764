import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ecommerceServer } from '../../server';

const carrierDisplayNames = {
  'stamps_com': 'Stamps.com',
  'ups': 'UPS',
  'fedex': 'FedEx',
  'dhl': 'DHL',
  'usps': 'USPS'
};

const carrierLogos = {
    UPS: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/48f4c6bc-8f27-4a35-e0c3-f220dd276b00/public',
    FedEx: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/59d69ef9-0e9d-4c0d-2025-990266929f00/public',
    DHL: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/665e5e0b-948a-425a-6b66-e3154ca82400/public',
    USPS: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/38263d2d-8074-45b6-2c65-6b094b542a00/public',
    'Stamps.com': 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/38263d2d-8074-45b6-2c65-6b094b542a00/public',
   
  };

const CarrierSelection = () => {
  const [availableCarriers, setAvailableCarriers] = useState([]);
  const [selectedCarriers, setSelectedCarriers] = useState([]);
  const [error, setError] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { seller } = useSelector((state) => state.seller);
  const currentProfileName = sessionStorage.getItem('currentProfile');

  // Update selected carriers when seller data changes
  useEffect(() => {
    if (seller?.shop?.carriers) {
      setSelectedCarriers(seller.shop.carriers);
    }
  }, [seller]);

  // Fetch available carriers from backend
  useEffect(() => {
    const fetchAvailableCarriers = async () => {
      try {
        const res = await axios.get(`${ecommerceServer}/shipping/available`, {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile'
          },
          withCredentials: true
        });
        if (res.data && res.data.carriers) {
          setAvailableCarriers(res.data.carriers);
        }
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
    };

    fetchAvailableCarriers();
  }, [currentProfileName]);

  const handleCheckboxChange = (carrier) => {
    setSelectedCarriers((prev) =>
      prev.includes(carrier) ? prev.filter((c) => c !== carrier) : [...prev, carrier]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${ecommerceServer}/shipping/update-carriers`,
        { carriers: selectedCarriers }, 
        {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile'
          },
          withCredentials: true
        }
      );
      console.log('Carriers updated:', response.data);
      alert('Carriers updated successfully!');
    } catch (err) {
      setError(err.response?.data?.message || err.message || 'Failed to update carriers');
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="carrier-selection-container">
      <h1 className="carrier-selection-title">Choose Your Carriers</h1>
      {error && <p className="carrier-selection-error">{error}</p>}

      <form onSubmit={handleSubmit} className="carrier-selection-form">
        <div className="carrier-dropdown" ref={dropdownRef}>
          <button type="button" className="carrier-dropdown-toggle" onClick={toggleDropdown}>
            Select Carriers
          </button>
          {dropdownOpen && (
            <div className="carrier-dropdown-menu">
              {availableCarriers.length === 0 ? (
                <p>Loading carriers...</p>
              ) : (
                availableCarriers.map((carrierCode, index) => {
                  const displayName = carrierDisplayNames[carrierCode] || carrierCode.toUpperCase();
                  const logo = carrierLogos[carrierCode] || 'https://via.placeholder.com/40';

                  return (
                    <div key={index} className="carrier-option">
                      <label className="carrier-label">
                        <input
                          type="checkbox"
                          checked={selectedCarriers.includes(carrierCode)}
                          onChange={() => handleCheckboxChange(carrierCode)}
                        />
                        <img
                          src={logo}
                          alt={`${displayName} logo`}
                          className="carrier-logo"
                        />
                        <span className="carrier-name">{displayName}</span>
                      </label>
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>

        <button type="submit" className="carrier-save-button">Save Carriers</button>
      </form>
    </div>
  );
};

export default CarrierSelection;


