import React from "react";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";


const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);

  return (
    <div className="shop-dashboard-header-container">
      <div>
        <Link to={`/dashboard/${seller?.handle}`}>
          <img
            src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724797193/BuzzVibe-TM-Gradient-outline_jfxvg6.png"
            alt=""
            className="shop-dashboard-header-logo"
          />
        </Link>
      </div>
      <div className="shop-dashboard-header-links">
        <div className="shop-dashboard-header-icons">
          <Link to="/dashboard-coupons" className="shop-dashboard-header-icon-link">
            <AiOutlineGift className="shop-dashboard-header-icon" />
          </Link>
          <Link to="/dashboard-events" className="shop-dashboard-header-icon-link">
            <MdOutlineLocalOffer className="shop-dashboard-header-icon" />
          </Link>
          <Link to="/dashboard-products" className="shop-dashboard-header-icon-link">
            <FiShoppingBag className="shop-dashboard-header-icon" />
          </Link>
          <Link to="/dashboard-orders" className="shop-dashboard-header-icon-link">
            <FiPackage className="shop-dashboard-header-icon" />
          </Link>
          <Link to="/dashboard-messages" className="shop-dashboard-header-icon-link">
            <BiMessageSquareDetail className="shop-dashboard-header-icon" />
          </Link>
          <Link to={`/shop/preview/${seller?.handle}`}>
            <img
              src={`${seller?.avatar?.url}`}
              alt=""
              className="shop-dashboard-header-avatar"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;

