import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { loadSellers, setSellerOnLogin } from "../../redux/actions/user";

const ShopLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [identifier, setIdentifier] = useState(""); // Renamed from shopHandle
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const { isSeller, seller, sellers: sellerProfiles } = useSelector((state) => state.seller);

  useEffect(() => {
    if (isSeller && seller) {
      if (seller.isOnboardingComplete === false) {
        navigate("/onboarding");
      } else {
        navigate(`/dashboard/${seller.handle}`);
      }
    }
  }, [isSeller, seller, navigate]);

  useEffect(() => {
    dispatch(loadSellers());
  }, [dispatch]);

  useEffect(() => {
    const currentProfileName = sessionStorage.getItem('currentProfile');
    if (currentProfileName) {
      const foundSeller = sellerProfiles.find(seller => seller.name === currentProfileName);
      if (foundSeller) {
        setIdentifier(foundSeller.handle);
      }
    }
  }, [sellerProfiles]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!identifier || !password) {
      toast.error("Please provide your email or shop handle and password");
      return;
    }

    try {
      const response = await axios.post(
        `${server}/shop/login-shop`,
        { identifier, password },
        { withCredentials: true }
      );

      toast.success("Login Successful!");
      const { shop } = response.data;
      dispatch(setSellerOnLogin(shop));
      sessionStorage.setItem('currentProfile', shop.name);
    } catch (err) {
      console.error('Error during login:', err.response?.data);
      toast.error(err.response?.data?.message || "Login failed");
    }
  };

  if (isSeller && seller) {
    return null;
  }

  return (
    <div className="shop-login-component-container">
      <div className="shop-login-component-header">
        <h2>Login to your shop</h2>
      </div>
      <div className="shop-login-component-form-container">
        <div className="shop-login-component-form">
          <form className="shop-login-component-form-elements" onSubmit={handleSubmit}>
            <div className="shop-login-component-form-group">
              <label htmlFor="identifier">Email or Shop Handle</label>
              <input
                type="text"
                name="identifier"
                required
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                className="shop-login-component-input"
              />
            </div>
            <div className="shop-login-component-form-group shop-login-component-password-container">
              <label htmlFor="password">Password</label>
              <input
                type={visible ? "text" : "password"}
                name="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="shop-login-component-input"
              />
              {visible ? (
                <AiOutlineEye
                  className="shop-login-component-eye-icon"
                  onClick={() => setVisible(false)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className="shop-login-component-eye-icon"
                  onClick={() => setVisible(true)}
                />
              )}
            </div>
            <div>
              <button type="submit" className="shop-login-component-button">
                Login
              </button>
            </div>
            <div className="shop-login-component-footer">
              <h4>Don’t have an account?</h4>
              <Link to="/shop-create">Sign Up</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopLogin;

