import React, { useState } from "react";
import {
  AiOutlineLogin,
  AiOutlineMessage,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineReceiptRefund, HiOutlineShoppingBag } from "react-icons/hi";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineTrackChanges,
} from "react-icons/md";
import { TbAddressBook } from "react-icons/tb";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";

const ProfileSidebar = ({ setActive, active }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { seller } = useSelector((state) => state.seller);

  const logoutHandler = () => {
    axios
      .post(`${server}/user/logout`, {}, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/login");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleConfirmLogout = () => {
    logoutHandler();
    setShowLogoutModal(false);
  };

  const handleCancelLogout = () => {
    setShowLogoutModal(false);
  };

  return (
    <div className="profile-sidebar-component-container">
      <div className="profile-sidebar-component-list">
      <div
        className={`profile-sidebar-component-item ${active === 1 ? "active" : ""}`}
        onClick={() => setActive(1)}
      >
        <RxPerson size={20} />
        <span className={`profile-sidebar-component-text ${active === 1 ? "active" : ""}`}>
          Profile
        </span>
      </div>
      <div
        className={`profile-sidebar-component-item ${active === 2 ? "active" : ""}`}
        onClick={() => setActive(2)}
      >
        <HiOutlineShoppingBag size={20} />
        <span className={`profile-sidebar-component-text ${active === 2 ? "active" : ""}`}>
          Orders
        </span>
      </div>
      <div
        className={`profile-sidebar-component-item ${active === 3 ? "active" : ""}`}
        onClick={() => setActive(3)}
      >
        <HiOutlineReceiptRefund size={20} />
        <span className={`profile-sidebar-component-text ${active === 3 ? "active" : ""}`}>
          Refunds
        </span>
      </div>
      <div
        className={`profile-sidebar-component-item ${active === 4 ? "active" : ""}`}
        onClick={() => setActive(4) || navigate("/inbox")}
      >
        <AiOutlineMessage size={20} />
        <span className={`profile-sidebar-component-text ${active === 4 ? "active" : ""}`}>
          Inbox
        </span>
      </div>
      <div
        className={`profile-sidebar-component-item ${active === 5 ? "active" : ""}`}
        onClick={() => setActive(5)}
      >
        <MdOutlineTrackChanges size={20} />
        <span className={`profile-sidebar-component-text ${active === 5 ? "active" : ""}`}>
          Track Order
        </span>
      </div>
      <div
        className={`profile-sidebar-component-item ${active === 6 ? "active" : ""}`}
        onClick={() => setActive(6)}
      >
        <RiLockPasswordLine size={20} />
        <span className={`profile-sidebar-component-text ${active === 6 ? "active" : ""}`}>
          Change Password
        </span>
      </div>
      <div
        className={`profile-sidebar-component-item ${active === 7 ? "active" : ""}`}
        onClick={() => setActive(7)}
      >
        <TbAddressBook size={20} />
        <span className={`profile-sidebar-component-text ${active === 7 ? "active" : ""}`}>
          Address
        </span>
      </div>
      {user && user?.role === "Admin" && (
        <Link to="/admin/dashboard">
          <div
            className={`profile-sidebar-component-item ${active === 8 ? "active" : ""}`}
            onClick={() => setActive(8)}
          >
            <MdOutlineAdminPanelSettings size={20} />
            <span className={`profile-sidebar-component-text ${active === 8 ? "active" : ""}`}>
              Admin Dashboard
            </span>
          </div>
        </Link>
      )}
      <div
        className={`profile-sidebar-component-item ${active === 9 ? "active" : ""}`}
        onClick={() => setActive(9)}
      >
        <TbAddressBook size={20} />
        <span className={`profile-sidebar-component-text ${active === 9 ? "active" : ""}`}>
          Subscriptions
        </span>
      </div>
      <div
        className={`profile-sidebar-component-item ${active === 10 ? "active" : ""}`}
        onClick={handleLogoutClick}
      >
        <AiOutlineLogin size={20} />
        <span className={`profile-sidebar-component-text ${active === 10 ? "active" : ""}`}>
          Log out
        </span>
      </div>
      </div>

      {/* Logout Confirmation Modal */}
      {showLogoutModal && (
        <div className="logout-modal">
          <div className="logout-modal-content">
            <h2>Are you sure you want to log out?</h2>
            <div className="logout-modal-buttons">
              <button
                className="logout-modal-button-yes"
                onClick={handleConfirmLogout}
              >
                Yes
              </button>
              <button
                className="logout-modal-button-no"
                onClick={handleCancelLogout}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSidebar;

