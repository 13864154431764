import React, { useState } from 'react';
import axios from 'axios';
import { ecommerceServer } from "../../server";
import { useSelector } from 'react-redux';
import { AiOutlineCamera } from "react-icons/ai";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { categoriesData } from "../../static/data";
import TagSelector from '../Tags/TagSelector';
import TaxCodeSelector from '../Shop/TaxCodeSelector';

const BrandCatalogItemCreate = ({ brandId }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    SKU: '',
    tags: [],
    productType: [],
    unitOfMeasurement: '',
    priceFloor: '',
    isEdible: false,
    brand: brandId,
    taxCode: '',
  });
  const [images, setImages] = useState([]);
  const [productType, setProductType] = useState(""); 
  const [imagePreviews, setImagePreviews] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const { seller } = useSelector(state => state.seller); 
  const [nutritionInfoImages, setNutritionInfoImages] = useState([]);
  const [nutritionInfoImagePreviews, setNutritionInfoImagePreviews] = useState([]);
  const [testResultsImages, setTestResultsImages] = useState([]);
  const [testResultsImagePreviews, setTestResultsImagePreviews] = useState([]);
  const [tags, setTags] = useState([]);
  const [taxCode, setTaxCode] = useState("");

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleProductTypeChange = (e) => {
    const value = e.target.value;
    setProductType((prevTypes) => {
      if (prevTypes.includes(value)) {
        return prevTypes.filter((type) => type !== value);
      } else {
        return [...prevTypes, value];
      }
    });
  };

  const handleImageChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setImages([...images, ...newFiles]);
    const newPreviews = newFiles.map(file => URL.createObjectURL(file));
    setImagePreviews([...imagePreviews, ...newPreviews]);
  };

  const handleNutritionImagesChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setNutritionInfoImages([...nutritionInfoImages, ...newFiles]);
    const newPreviews = newFiles.map(file => URL.createObjectURL(file));
    setNutritionInfoImagePreviews([...nutritionInfoImagePreviews, ...newPreviews]);
  };

  const handleTestResultsImagesChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setTestResultsImages([...testResultsImages, ...newFiles]);
    const newPreviews = newFiles.map(file => URL.createObjectURL(file));
    setTestResultsImagePreviews([...testResultsImagePreviews, ...newPreviews]);
  };

  const handleVideosChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setVideos([...videos, ...newFiles]);
    const newPreviews = newFiles.map(file => URL.createObjectURL(file));
    setVideoPreviews([...videoPreviews, ...newPreviews]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const data = new FormData();
        Object.keys(formData).forEach(key => {
            if (key !== 'tags' && key !== 'productType' && key !== 'taxCode') {
                data.append(key, formData[key]);
            }
        });

        data.append('tags', JSON.stringify(tags.map(tag => ({ _id: tag._id, name: tag.name }))));
        data.append('taxCode', taxCode.id); 
        data.append('productType', productType.join(",")); 

        images.forEach(image => data.append('images', image));
        videos.forEach(video => data.append('videos', video));
        nutritionInfoImages.forEach(image => data.append('nutritionInfoImages', image));
        testResultsImages.forEach(image => data.append('testResultsImages', image));

        const response = await axios.post(`${ecommerceServer}/brand/create-brand-catalog-item`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'current-profile': seller.name || 'defaultProfile',
            },
            withCredentials: true,
        });

        toast.success("Brand catalog item created successfully");
        // Reset form data
        setFormData({
            name: '',
            description: '',
            category: '',
            SKU: '',
            tags: [],
            productType: [],
            unitOfMeasurement: '',
            priceFloor: '',
            isEdible: false,
            brand: brandId,
            taxCode: '',
        });
        setImages([]);
        setVideos([]);
        setImagePreviews([]);
        setVideoPreviews([]);
        setNutritionInfoImages([]);
        setTestResultsImages([]);
        setNutritionInfoImagePreviews([]);
        setTestResultsImagePreviews([]);
    } catch (error) {
        console.error("Submission error:", error);
        toast.error("Error creating catalog item: " + error.message);
    }
};

  
  

  const updateTags = (newTags) => {
    setTags(newTags);
  };
  
  const updateTaxCode = (newTaxCode) => {
    console.log("Updating tax code to:", newTaxCode);  
    setTaxCode(newTaxCode);
  };

  const handleRemovePreview = (index, previews, setPreviews, files, setFiles) => {
    const newPreviews = previews.filter((_, i) => i !== index);
    const newFiles = files.filter((_, i) => i !== index);
    setPreviews(newPreviews);
    setFiles(newFiles);
  };

  
  return (
    <form onSubmit={handleSubmit} className="brand-catalog-item-create-form">
      <div className="brand-catalog-item-create-section">
        <h2 className="brand-catalog-item-create-title">Create Brand Catalog Item</h2>
        <div>
          <label htmlFor="name" className="brand-catalog-item-create-label">Product Name</label>
          <input
            id="name"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            className="brand-catalog-item-create-input"
            placeholder="Product Name"
          />
        </div>
        <div>
          <label htmlFor="description" className="brand-catalog-item-create-label">Description</label>
          <textarea
            id="description"
            name="description"
            rows={4}
            value={formData.description}
            onChange={handleChange}
            className="brand-catalog-item-create-textarea"
            placeholder="Product Description"
          ></textarea>
        </div>
        <div>
          <label className="brand-catalog-item-create-label">Product Images</label>
          <div className="brand-catalog-item-create-file-upload">
            <AiOutlineCamera className="brand-catalog-item-create-icon" />
            <label htmlFor="file-upload" className="brand-catalog-item-create-file-upload-label">
              <span>Upload a file</span>
              <input id="file-upload" name="file-upload" type="file" multiple onChange={handleImageChange} />
            </label>
            <p className="brand-catalog-item-create-file-upload-text">or drag and drop</p>
            <p className="brand-catalog-item-create-file-upload-text">PNG, JPG, GIF up to 10MB</p>
          </div>
          <div className="brand-catalog-item-create-preview-container">
            {imagePreviews.map((imageUrl, index) => (
              <div key={index} className="brand-catalog-item-create-preview-item">
                <img src={imageUrl} alt={`Preview ${index}`} />
                <button
                  onClick={() => handleRemovePreview(index, imagePreviews, setImagePreviews, images, setImages)}
                  className="brand-catalog-item-create-remove-button"
                  aria-label="Remove image"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="brand-catalog-item-create-label">Videos</label>
          <div className="brand-catalog-item-create-file-upload">
            <AiOutlineCamera className="brand-catalog-item-create-icon" />
            <label htmlFor="video-upload" className="brand-catalog-item-create-file-upload-label">
              <span>Upload a file</span>
              <input id="video-upload" name="video-upload" type="file" multiple onChange={handleVideosChange} />
            </label>
            <p className="brand-catalog-item-create-file-upload-text">or drag and drop</p>
            <p className="brand-catalog-item-create-file-upload-text">MP4, MOV up to 100MB</p>
          </div>
          <div className="brand-catalog-item-create-preview-container">
            {videoPreviews.map((videoUrl, index) => (
              <div key={index} className="brand-catalog-item-create-preview-item">
                <video src={videoUrl} alt={`Video Preview ${index}`} controls />
                <button
                  onClick={() => handleRemovePreview(index, videoPreviews, setVideoPreviews, videos, setVideos)}
                  className="brand-catalog-item-create-remove-button"
                  aria-label="Remove video"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="brand-catalog-item-create-label">Nutrition Info Images</label>
          <div className="brand-catalog-item-create-file-upload">
            <AiOutlineCamera className="brand-catalog-item-create-icon" />
            <label htmlFor="nutritionInfoImage" className="brand-catalog-item-create-file-upload-label">
              <span>Upload a file</span>
              <input id="nutritionInfoImage" name="nutritionInfoImage" type="file" multiple onChange={handleNutritionImagesChange} />
            </label>
            <p className="brand-catalog-item-create-file-upload-text">or drag and drop</p>
            <p className="brand-catalog-item-create-file-upload-text">PNG, JPG, GIF up to 10MB</p>
          </div>
          <div className="brand-catalog-item-create-preview-container">
            {nutritionInfoImagePreviews.map((imageUrl, index) => (
              <div key={index} className="brand-catalog-item-create-preview-item">
                <img src={imageUrl} alt={`Nutrition Info ${index}`} />
                <button
                  onClick={() => handleRemovePreview(index, nutritionInfoImagePreviews, setNutritionInfoImagePreviews, nutritionInfoImages, setNutritionInfoImages)}
                  className="brand-catalog-item-create-remove-button"
                  aria-label="Remove nutrition info"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="brand-catalog-item-create-label">Test Results Images</label>
          <div className="brand-catalog-item-create-file-upload">
            <AiOutlineCamera className="brand-catalog-item-create-icon" />
            <label htmlFor="testResults" className="brand-catalog-item-create-file-upload-label">
              <span>Upload a file</span>
              <input id="testResults" name="testResults" type="file" multiple onChange={handleTestResultsImagesChange} />
            </label>
            <p className="brand-catalog-item-create-file-upload-text">or drag and drop</p>
            <p className="brand-catalog-item-create-file-upload-text">PNG, JPG, GIF up to 10MB</p>
          </div>
          <div className="brand-catalog-item-create-preview-container">
            {testResultsImagePreviews.map((imageUrl, index) => (
              <div key={index} className="brand-catalog-item-create-preview-item">
                <img src={imageUrl} alt={`Test Result ${index}`} />
                <button
                  onClick={() => handleRemovePreview(index, testResultsImagePreviews, setTestResultsImagePreviews, testResultsImages, setTestResultsImages)}
                  className="brand-catalog-item-create-remove-button"
                  aria-label="Remove test result"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label htmlFor="category" className="brand-catalog-item-create-label">Category</label>
          <select
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="brand-catalog-item-create-select"
            required
          >
            <option value="" disabled>Select a category</option>
            {categoriesData.map((cat, index) => (
              <option key={index} value={cat.title}>{cat.title}</option>
            ))}
          </select>
        </div>

        <div className="brand-catalog-item-create-grid">
          <div>
            <label htmlFor="priceFloor" className="brand-catalog-item-create-label">Price Floor</label>
            <input
              id="priceFloor"
              name="priceFloor"
              type="number"
              min="0"
              step="0.01"
              value={formData.priceFloor}
              onChange={handleChange}
              className="brand-catalog-item-create-input"
              placeholder="Minimum selling price"
              required
            />
          </div>
          <div className="brand-catalog-item-create-checkbox-group">
            <label className="brand-catalog-item-create-label">
              <input
                type="checkbox"
                value="retail"
                checked={productType.includes("retail")}
                onChange={handleProductTypeChange}
              />
              Retail
            </label>
            <label className="brand-catalog-item-create-label">
              <input
                type="checkbox"
                value="wholesale"
                checked={productType.includes("wholesale")}
                onChange={handleProductTypeChange}
              />
              Wholesale
            </label>
          </div>

          <div>
            <label htmlFor="SKU" className="brand-catalog-item-create-label">SKU</label>
            <input
              id="SKU"
              name="SKU"
              type="text"
              value={formData.SKU}
              onChange={handleChange}
              className="brand-catalog-item-create-input"
              placeholder="SKU"
            />
          </div>
        </div>

        <div className="brand-catalog-item-create-tags">
          <TagSelector selectedTags={tags} updateTags={updateTags} />
        </div>

        <div className="brand-catalog-item-create-tax-code">
          <TaxCodeSelector selectedTaxCode={taxCode} updateTaxCode={updateTaxCode} />
        </div>

        <div>
          <label htmlFor="unitOfMeasurement" className="brand-catalog-item-create-label">Unit of Measurement</label>
          <select
            name="unitOfMeasurement"
            onChange={handleChange}
            value={formData.unitOfMeasurement}
            className="brand-catalog-item-create-select"
            required
          >
            <option value="" disabled>Select unit</option>
            <option value="g">Grams (g)</option>
            <option value="lbs">Pounds (lbs)</option>
            <option value="kg">Kilograms (kg)</option>
          </select>
        </div>

        <div className="brand-catalog-item-create-actions">
          <button
            type="button"
            className="brand-catalog-item-create-button cancel"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="brand-catalog-item-create-button submit"
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
};
export default BrandCatalogItemCreate;

