import React, { useState } from 'react';
import ImportShopifyProducts from './ImportShopifyProducts';

const ImportShopifyProductsModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  }

  return (
    <div>
      <button className="import-shopify-modal-open-button" onClick={openModal}>
        Upload Products from Shopify
      </button>

      {showModal && (
        <div className="import-shopify-modal-overlay" onClick={closeModal}>
          <div className="import-shopify-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="import-shopify-modal-close-button" onClick={closeModal}>×</button>
            <ImportShopifyProducts onClose={closeModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportShopifyProductsModal;

