import React, { useState, useEffect, useRef } from 'react';
import { FaMapMarkerAlt, FaClock, FaCalendarAlt, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { server } from "../../server";
import GoogleCalendarButton from './GoogleCalendarButton';
import OutlookCalendarButton from './OutlookCalendarButton';
import DownloadICSButton from './DownloadICSButton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EventCard = ({ event, onRSVP, onCancelRSVP }) => {
    console.log('Event prop:', event);
    const [isGoing, setIsGoing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null); 
    const currentProfileType = useSelector((state) => state.user.currentProfile);
    const navigate = useNavigate();
    const modalRef = useRef(null);

    useEffect(() => {
        checkIfGoing();
    }, []);

    const checkIfGoing = async () => {
        try {
            const data = {
                currentProfile: currentProfileType,
            };
            const response = await axios.get(`${server}/event/events/my`, data,{ withCredentials: true });
            const myEvents = response.data;
            const goingToEvent = myEvents.some((myEvent) => myEvent._id === event._id);
            setIsGoing(goingToEvent);
        } catch (error) {
            console.error('Error checking if going to event:', error);
        }
    };

    const goToEventDetail = () => {
        navigate(`/events/${event._id}`);
    };

    const handleRSVP = async () => {
        try {
            const data = {
                currentProfile: currentProfileType,
            };
            await axios.post(`${server}/event/rsvp/${event._id}`, data, {
                withCredentials: true,
            });
            setIsGoing(true);
            onRSVP(event._id);
            setModalType('RSVP'); // Set modal type to RSVP
            setIsModalOpen(true); // Open modal after successful RSVP
        } catch (error) {
            console.error('Error RSVPing to event:', error);
            alert('Failed to RSVP: ' + (error.response?.data?.message || 'Error occurred'));
        }
    };

    const handleCancelRSVP = async () => {
        try {
            await axios.delete(`${server}/event/rsvp/${event._id}`, { withCredentials: true });
            setIsGoing(false);
            onCancelRSVP(event._id);
            closeModal();
        } catch (error) {
            console.error('Failed to cancel RSVP:', error);
            alert('Failed to cancel RSVP: ' + (error.response?.data?.message || 'Error occurred'));
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalType(null);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('keydown', handleKeyDown);
            if (modalRef.current) {
                modalRef.current.focus();
            }
            document.body.style.overflow = 'hidden';
        } else {
            document.removeEventListener('keydown', handleKeyDown);
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    const eventDate = new Date(event.date);
    const formattedDate = eventDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });    
    const formattedTime = eventDate.toLocaleTimeString();

    return (
        <>
            <div className="event-card-component-card" onClick={goToEventDetail}>
                <img src={event.image} alt={event.title} className="event-card-component-image" />
                <div className="event-card-component-info">
                <div className="event-card-component-owner-info">
            {event.owner && (
                <div className="event-card-owner-details">
                    {event.owner.avatar && event.owner.avatar.url && (
                        <img 
                            src={event.owner.avatar.url} 
                            alt={event.owner.name} 
                            className="event-card-component-owner-avatar"
                        />
                    )}
                    <span className="event-card-component-owner-name">
                        {event.owner.name}
                    </span>
                </div>
            )}
        </div>
                    <h3 className="event-card-component-title">{event.title}</h3>
                    <p className="event-card-component-description">{event.description}</p>
                    <div className="event-card-component-datetime">
                        <p className="event-card-component-date">
                            <FaCalendarAlt className="event-card-icon" /> {formattedDate}
                        </p>
                        <p className="event-card-component-time">
                            <FaClock className="event-card-icon" /> {formattedTime}
                        </p>
                    </div>
                    <p className="event-card-component-location">
                        <FaMapMarkerAlt className="event-card-icon" /> {event.location.address}
                    </p>
                    <div className="event-card-component-buttons">
                        <button
                            className="event-card-component-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (isGoing) {
                                    setModalType('Cancel'); // Set modal type to Cancel
                                    setIsModalOpen(true); // Open modal to confirm cancellation
                                } else {
                                    handleRSVP();
                                }
                            }}
                        >
                            {isGoing ? 'Going' : 'Attend'}
                        </button>
                    </div>
                </div>
            </div>

            {/* Modal Structure */}
            {isModalOpen && (
                <div className="event-card-modal-overlay" onClick={handleOverlayClick}>
                    <div
                        className="event-card-modal-content"
                        onClick={(e) => e.stopPropagation()}
                        tabIndex="-1"
                        ref={modalRef}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="event-card-modal-title"
                    >
                        <button className="event-card-modal-close-button" onClick={closeModal} aria-label="Close Modal">
                            <FaTimes />
                        </button>

                        {modalType === 'RSVP' && (
                            <>
                                <h2 id="event-card-modal-title">Add to Your Calendar</h2>
                                <div className="event-card-modal-buttons">
                                    <GoogleCalendarButton event={event} />
                                    <OutlookCalendarButton event={event} />
                                    <DownloadICSButton event={event} />
                                </div>
                            </>
                        )}

                        {modalType === 'Cancel' && (
                            <>
                                <h2 id="event-card-modal-title">Can't make it?</h2>
                                <p>We're sorry to hear that you can't make it to the event.</p>
                                <button
                                    className="event-card-modal-cancel-button"
                                    onClick={handleCancelRSVP}
                                >
                                    Cancel RSVP
                                </button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default EventCard;







