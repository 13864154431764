import axios from "axios";
import { ecommerceServer } from "../../server";


export const createProduct = (newForm, config) => async (dispatch) => {
  try {
      dispatch({ type: "productCreateRequest" });

      const response = await axios.post(
          `${ecommerceServer}/product/create-product`,
          newForm,
          config
      );

      dispatch({
          type: "productCreateSuccess",
          payload: response.data.product,
      });
  } catch (error) {
      dispatch({
          type: "productCreateFail",
          payload: error.response?.data?.message || "An error occurred",
      });
  }
};



// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axios.get(
      `${ecommerceServer}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteProductRequest",
    });

    const { data } = await axios.delete(
      `${ecommerceServer}/product/delete-shop-product/${id}`,
      {
        headers: {
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};

// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });

    const { data } = await axios.get(`${ecommerceServer}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};
