// LazyLoadComponent.jsx
import React, { Suspense } from 'react';
import useIntersectionObserver from './useIntersectionObserver'; // Import your custom hook
import ItemLoader from '../components/Marketplace/ItemLoader';


const LazyLoadComponent = ({ children }) => {
  const [ref, isIntersecting] = useIntersectionObserver({
    rootMargin: '200px', // Adjust as needed
    threshold: 0.1,      // Adjust as needed
  });

  return (
    <div ref={ref}>
      {isIntersecting ? (
        <Suspense fallback={<ItemLoader />}>
          {children}
        </Suspense>
      ) : (
        <ItemLoader />
      )}
    </div>
  );
};

export default LazyLoadComponent;
