export const addTocart = (data) => (dispatch, getState) => {
  const profileType = sessionStorage.getItem('currentProfile') || 'User';
  const currentCart = getState().cart.carts[profileType] || [];

  const itemIndex = currentCart.findIndex(item => 
    item._id === data._id && (!data.variant?._id || (item.variant?._id === data.variant?._id))
  );

  let updatedCart = [];
  if (itemIndex !== -1) {
    // Update the quantity with the provided qty in data
    updatedCart = currentCart.map((item, index) =>
      index === itemIndex
        ? { ...item, qty: data.qty } // Update with the explicit qty
        : item
    );
  } else {
    updatedCart = [...currentCart, { ...data, qty: data.qty || 1 }];
  }

  dispatch({
    type: "addTocart",
    profileType: profileType,
    payload: updatedCart
  });

  localStorage.setItem("cartItems_" + profileType, JSON.stringify(updatedCart));
};



// Remove from cart action modified to handle profile names
export const removeFromCart = (data) => async (dispatch, getState) => {
  const profileType = sessionStorage.getItem('currentProfile') || 'User';
  const currentCart = getState().cart.carts[profileType] || [];

  console.log("Profile Type:", profileType);
  console.log("Current Cart before removal:", currentCart);
  console.log("Item to be removed:", data);

  // Filter out the item that matches both the product ID and variant ID (if applicable)
  const updatedCart = currentCart.filter(item => {
    const isSameProduct = item._id === data._id;
    const isSameVariant = !data.variant || (item.variant?._id === data.variant?._id);

    // Log each item check
    console.log("Checking item:", item);
    console.log("Is Same Product:", isSameProduct);
    console.log("Is Same Variant:", isSameVariant);
    
    // Keep the item if it doesn't match both product ID and variant ID (i.e., we're not removing it)
    return !(isSameProduct && isSameVariant);
  });

  console.log("Updated Cart after removal:", updatedCart);

  // Dispatch the action to update the cart
  dispatch({
    type: "removeFromCart", 
    profileType: profileType,
    payload: updatedCart,
  });

  // Update local storage with the updated cart
  localStorage.setItem("cartItems_" + profileType, JSON.stringify(updatedCart));
  console.log("Local Storage updated for profile:", profileType, updatedCart);
};


export const clearCart = (profileType) => (dispatch) => {
  dispatch({
    type: "clearCart",
    profileType: profileType
  });

  // Clear cart in localStorage as well
  localStorage.setItem("cartItems_" + profileType, JSON.stringify([]));
};

