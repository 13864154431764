import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";


const ShopOwnerProtectedRoute = ({ children }) => {
  const { handle } = useParams();
  const navigate = useNavigate();
  const { isLoading, seller } = useSelector((state) => state.seller);

  useEffect(() => {
    
      if (!seller || seller.handle !== handle) {
        navigate(`/shop/${handle}`, { replace: true });
      }
    
  }, [seller, handle, navigate]);

 

  // Avoid rendering children until after navigation check
  if (!seller || seller.handle !== handle) {
    return null; // Or a placeholder component
  }

  return children;
};

export default ShopOwnerProtectedRoute;
