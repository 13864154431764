import { createReducer } from "@reduxjs/toolkit";
import {
  LOAD_SELLERS_SUCCESS,
  SET_ACTIVE_SELLER,
  LOAD_ALL_SELLERS_REQUEST,
  LOAD_ALL_SELLERS_SUCCESS,
  LOAD_ALL_SELLERS_FAIL,
  SET_ACTIVE_SELLER_PROFILE,
  FETCH_SHOP_AND_SUBSCRIPTIONS_REQUEST,
  FETCH_SHOP_AND_SUBSCRIPTIONS_SUCCESS,
  FETCH_SHOP_AND_SUBSCRIPTIONS_FAILURE,
  SET_SELLER_ON_LOGIN
} from "../actions/user";

const initialState = {
  isLoading: true,
  isSeller: false,
  seller: null,
  activeSeller: null,
  sellers: [], 
  allSellers: [],
  activeSellerIndex: 0,
  shopDetails: null,
  shopSubscriptions: [],
};

export const sellerReducer = createReducer(initialState, {
  LoadSellerRequest: (state) => {
    state.isLoading = true;
  },
  LoadSellerSuccess: (state, action) => {
    state.isSeller = true;
    state.isLoading = false;
    state.seller = action.payload;
  },
  LoadSellerFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isSeller = false;
  },
  [SET_SELLER_ON_LOGIN]: (state, action) => {
    state.isSeller = true;
    state.seller = action.payload; 
  },

  [LOAD_ALL_SELLERS_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [LOAD_ALL_SELLERS_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.allSellers = action.payload;
    state.error = null;
  },
  [LOAD_ALL_SELLERS_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  [SET_ACTIVE_SELLER_PROFILE]: (state, action) => {
    state.activeSellerIndex = action.payload;
   
  },

  [LOAD_SELLERS_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.isSeller = true;
    state.sellers = action.payload; // Set the array of sellers
  },

  [SET_ACTIVE_SELLER]: (state, action) => {
    console.log('Updating state with new active seller:', action.payload);
    state.isSeller = true;
    state.activeSeller = action.payload; // Set the selected seller as the active seller
  },

  [FETCH_SHOP_AND_SUBSCRIPTIONS_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [FETCH_SHOP_AND_SUBSCRIPTIONS_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.seller = action.payload.shop;
    state.subscriptions = action.payload.subscriptions;
  },
  [FETCH_SHOP_AND_SUBSCRIPTIONS_FAILURE]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all sellers ---admin
  getAllSellersRequest: (state) => {
    state.isLoading = true;
  },
  getAllSellersSuccess: (state, action) => {
    state.isLoading = false;
    state.sellers = action.payload;
  },
  getAllSellerFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});
