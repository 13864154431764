// src/components/WHEPClient.js

export default class WHEPClient {
    constructor(url, videoElement) {
      this.url = url;
      this.videoElement = videoElement;
      this.pc = new RTCPeerConnection({
        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
      });
      this.init();
    }
  
    async init() {
      try {
        // Create an offer
        const offer = await this.pc.createOffer();
        await this.pc.setLocalDescription(offer);
  
        // Send the offer to WHEP server
        const answer = await this.sendOffer(offer);
  
        // Set remote description with the answer
        if (answer && answer.sdp) {
          await this.pc.setRemoteDescription(new RTCSessionDescription(answer));
        }
  
        // Handle incoming tracks
        this.pc.ontrack = (event) => {
          if (this.videoElement) {
            this.videoElement.srcObject = event.streams[0];
          }
        };
  
        // Handle ICE candidates
        this.pc.onicecandidate = (event) => {
          if (event.candidate) {
            this.sendIceCandidate(event.candidate);
          }
        };
      } catch (error) {
        console.error('Error initializing WHEPClient:', error);
      }
    }
  
    async sendOffer(offer) {
      try {
        const response = await fetch(this.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/sdp',
            'X-Stream-ID': this.url.split('/').slice(-2, -1)[0], // Extract UID from URL
          },
          body: offer.sdp,
        });
  
        if (response.ok) {
          const answerSdp = await response.text();
          return { type: 'answer', sdp: answerSdp };
        } else {
          const errorData = await response.text();
          console.error('WHEP offer failed:', errorData);
        }
      } catch (error) {
        console.error('Error sending WHEP offer:', error);
      }
    }
  
    async sendIceCandidate(candidate) {
      try {
        // Implement ICE candidate sending if required by WHEP server
        // This might involve sending via WebSockets or another protocol
      } catch (error) {
        console.error('Error sending ICE candidate:', error);
      }
    }
  
    close() {
      if (this.pc) {
        this.pc.close();
      }
    }
  }
  