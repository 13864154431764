import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SingleLargeProductGrid = ({ products }) => {
  const [currentLargeProduct, setCurrentLargeProduct] = useState(0);
  const [currentSmallProductSlide, setCurrentSmallProductSlide] = useState(0);
  const [smallProductsSlides, setSmallProductsSlides] = useState([]);

  // Reference to track currently displayed small products
  const currentSmallProductsRef = useRef([]);

  // Helper function to shuffle an array
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // State to hold shuffled products
  const [shuffledProducts, setShuffledProducts] = useState([]);

  // Shuffle products when component mounts or products change
  useEffect(() => {
    if (Array.isArray(products) && products.length > 0) {
      const shuffled = shuffleArray(products);
      setShuffledProducts(shuffled);
    } else {
      setShuffledProducts([]);
    }
  }, [products]);

  const totalProducts = shuffledProducts.length;

  const itemsPerSlide = 2; // Number of small products per slide

  // Helper function to group array into chunks
  const groupIntoChunks = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Update smallProductsSlides whenever currentLargeProduct or products change
  useEffect(() => {
    if (totalProducts === 0) {
      setSmallProductsSlides([]);
      setCurrentSmallProductSlide(0);
      return;
    }

    const largeProduct = shuffledProducts[currentLargeProduct];

    // Exclude all instances of the large product from small products
    const smallProducts = shuffledProducts.filter(
      (product) => product._id !== largeProduct._id
    );

    // Deduplicate smallProducts based on _id
    const uniqueSmallProducts = Array.from(
      new Map(smallProducts.map((product) => [product._id, product])).values()
    );

    // Group small products into slides
    const groupedSmallProducts = groupIntoChunks(uniqueSmallProducts, itemsPerSlide);

    setSmallProductsSlides(groupedSmallProducts);

    // Reset small product slide index if current slide is out of bounds
    if (currentSmallProductSlide >= groupedSmallProducts.length) {
      setCurrentSmallProductSlide(0);
    }
  }, [
    currentLargeProduct,
    shuffledProducts,
    totalProducts,
    itemsPerSlide,
    currentSmallProductSlide,
  ]);

  // Update the currentSmallProductsRef whenever smallProductsSlides or currentSmallProductSlide changes
  useEffect(() => {
    if (smallProductsSlides.length > 0) {
      currentSmallProductsRef.current = smallProductsSlides[currentSmallProductSlide];
    } else {
      currentSmallProductsRef.current = [];
    }
  }, [smallProductsSlides, currentSmallProductSlide]);

  const nextLargeProduct = () => {
    if (totalProducts === 0) return;
    let nextIndex = (currentLargeProduct + 1) % totalProducts;
    let attempts = 0;

    // Loop to find the next large product not in current small products
    while (
      currentSmallProductsRef.current.some(
        (product) => product._id === shuffledProducts[nextIndex]._id
      ) &&
      attempts < totalProducts
    ) {
      nextIndex = (nextIndex + 1) % totalProducts;
      attempts += 1;
    }

    // If all products are in small grid, do not change the large product
    if (attempts < totalProducts) {
      setCurrentLargeProduct(nextIndex);
    } else {
      console.warn('All products are currently displayed in the small carousel.');
    }
  };

  const prevLargeProduct = () => {
    if (totalProducts === 0) return;
    let prevIndex = (currentLargeProduct - 1 + totalProducts) % totalProducts;
    let attempts = 0;

    // Loop to find the previous large product not in current small products
    while (
      currentSmallProductsRef.current.some(
        (product) => product._id === shuffledProducts[prevIndex]._id
      ) &&
      attempts < totalProducts
    ) {
      prevIndex = (prevIndex - 1 + totalProducts) % totalProducts;
      attempts += 1;
    }

    // If all products are in small grid, do not change the large product
    if (attempts < totalProducts) {
      setCurrentLargeProduct(prevIndex);
    } else {
      console.warn('All products are currently displayed in the small carousel.');
    }
  };

  const nextSmallProductSlide = () => {
    if (smallProductsSlides.length === 0) return;
    setCurrentSmallProductSlide(
      (prev) => (prev + 1) % smallProductsSlides.length
    );
  };

  const prevSmallProductSlide = () => {
    if (smallProductsSlides.length === 0) return;
    setCurrentSmallProductSlide(
      (prev) => (prev - 1 + smallProductsSlides.length) % smallProductsSlides.length
    );
  };

  if (!shuffledProducts.length) {
    return <div className="product-large-grid-error">No products to display.</div>;
  }

  // Get current small products to display
  const currentSmallProducts =
    smallProductsSlides.length > 0
      ? smallProductsSlides[currentSmallProductSlide]
      : [];

  return (
    <div className="product-large-grid-bg-black">
      <div className="product-large-grid-container">
        <div className="product-large-grid-header-container">
          <h2 className="product-large-grid-heading">Our Featured Products</h2>
          <Link to="/products" className="product-large-grid-browse-link">
            Browse all products
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>

        <div className="product-large-grid-carousel">
          <div className="product-large-grid-grid">
            {/* Large Product Tile */}
            {totalProducts > 0 && (
              <div className="product-large-grid-item large">
                <Link to={`/product/${shuffledProducts[currentLargeProduct]._id}`}>
                  {shuffledProducts[currentLargeProduct].images[0]?.url ? (
                    <img
                      src={shuffledProducts[currentLargeProduct].images[0].url}
                      alt={
                        shuffledProducts[currentLargeProduct].name ||
                        'Featured Product'
                      }
                      className="product-large-grid-large-image"
                      loading="lazy"
                    />
                  ) : (
                    <div className="product-large-grid-placeholder">
                      No Image Available
                    </div>
                  )}
                  <div className="product-large-grid-overlay" />
                  <div className="product-large-grid-text">
                    <h3>{shuffledProducts[currentLargeProduct].name}</h3>
                    <p>Shop now</p>
                  </div>
                </Link>
                {/* Large Product Carousel Controls */}
                {totalProducts > 1 && (
                  <>
                    <button
                      className="product-large-grid-carousel-button prev"
                      onClick={prevLargeProduct}
                      aria-label="Previous Product"
                    >
                      &#10094;
                    </button>
                    <button
                      className="product-large-grid-carousel-button next"
                      onClick={nextLargeProduct}
                      aria-label="Next Product"
                    >
                      &#10095;
                    </button>
                  </>
                )}
              </div>
            )}

            {/* Small Products Container */}
            <div className="product-large-grid-small-tiles-container">
              {currentSmallProducts.map((product) => (
                <div className="product-large-grid-item small" key={product._id}>
                  <Link to={`/product/${product._id}`}>
                    {product.images[0]?.url ? (
                      <img
                        src={product.images[0].url}
                        alt={product.name}
                        className="product-large-grid-image"
                        loading="lazy"
                      />
                    ) : (
                      <div className="product-large-grid-placeholder">
                        No Image Available
                      </div>
                    )}
                    <div className="product-large-grid-overlay" />
                    <div className="product-large-grid-text">
                      <h3>{product.name}</h3>
                      <p>Shop now</p>
                    </div>
                  </Link>
                </div>
              ))}
              {/* Small Products Carousel Controls */}
              {smallProductsSlides.length > 1 && (
                <>
                  <button
                    className="product-large-grid-carousel-button product-prev"
                    onClick={prevSmallProductSlide}
                    aria-label="Previous Products"
                  >
                    &#10094;
                  </button>
                  <button
                    className="product-large-grid-carousel-button product-next"
                    onClick={nextSmallProductSlide}
                    aria-label="Next Products"
                  >
                    &#10095;
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SingleLargeProductGrid.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default SingleLargeProductGrid;





