import React, { useState, useEffect } from 'react';
import ImageCropperModal from '../Image/ImageCropperModal';
import { Switch } from '@headlessui/react';
import ReactDOM from 'react-dom';

const Variants = ({
  variants,
  setVariants,
  isVariableProduct,
  productWeight,
  productDimensions,
  weightUnitOfMeasurement,
  dimensionUnitOfMeasurement,
}) => {
  const [selectedVariantTypes, setSelectedVariantTypes] = useState([]);
  const [variantOptions, setVariantOptions] = useState({
    colors: [],
    size: '',
    custom: { title: '', values: '' },
  });
  const [variantsGenerated, setVariantsGenerated] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState([]);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [selectedImageForCrop, setSelectedImageForCrop] = useState(null);
  const [currentVariantIndex, setCurrentVariantIndex] = useState(null);
  const [useCustomWeightAndDimensions, setUseCustomWeightAndDimensions] = useState([]);

  const preventScrollChange = (event) => {
    event.target.blur();
  };

  // New useEffect to initialize state based on existing variants
  useEffect(() => {
    if (variants && variants.length > 0) {
      const types = [];
      const colorsSet = new Set();
      const sizesSet = new Set();
      const customValuesSet = new Set();
      let customTitle = '';

      variants.forEach((variant) => {
        if (variant.color) {
          if (!types.includes('color')) {
            types.push('color');
          }
          colorsSet.add(JSON.stringify({ name: variant.color, hex: variant.colorSwatch }));
        }
        if (variant.size) {
          if (!types.includes('size')) {
            types.push('size');
          }
          sizesSet.add(variant.size);
        }
        if (variant.customTitle && variant.customValue) {
          if (!types.includes('custom')) {
            types.push('custom');
          }
          customTitle = variant.customTitle;
          customValuesSet.add(variant.customValue);
        }
      });

      setSelectedVariantTypes(types);

      setVariantOptions({
        colors: Array.from(colorsSet).map((item) => JSON.parse(item)),
        size: Array.from(sizesSet).join(','),
        custom: { title: customTitle, values: Array.from(customValuesSet).join(',') },
      });

      setVariantsGenerated(true);

      // Initialize isAccordionOpen state
      setIsAccordionOpen(variants.map(() => false));

      // Initialize useCustomWeightAndDimensions
      setUseCustomWeightAndDimensions(
        variants.map((variant) => {
          return (
            variant.weight !== '' ||
            variant.dimensions.length !== '' ||
            variant.dimensions.width !== '' ||
            variant.dimensions.height !== ''
          );
        })
      );
    }
  }, [variants.length]);

  const handleColorNameChange = (index, colorName) => {
    const updatedColors = [...variantOptions.colors];
    updatedColors[index] = { ...updatedColors[index], name: colorName };
    setVariantOptions((prev) => ({ ...prev, colors: updatedColors }));
  };

  const handleColorHexChange = (index, hexValue) => {
    const updatedColors = [...variantOptions.colors];
    updatedColors[index] = { ...updatedColors[index], hex: hexValue };
    setVariantOptions((prev) => ({ ...prev, colors: updatedColors }));
  };

  const addColorOption = () => {
    setVariantOptions((prev) => ({
      ...prev,
      colors: [...prev.colors, { name: '', hex: '#ffffff' }],
    }));
  };

  const handleCustomWeightDimensionsToggle = (index) => {
    const updatedCustomUsage = [...useCustomWeightAndDimensions];
    updatedCustomUsage[index] = !updatedCustomUsage[index];
    setUseCustomWeightAndDimensions(updatedCustomUsage);
  };

  const handleVariantTypeToggle = (value) => {
    setSelectedVariantTypes((prev) =>
      prev.includes(value) ? prev.filter((type) => type !== value) : [...prev, value]
    );
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];

    if (field.includes('.')) {
      // Handle nested fields like 'dimensions.length'
      const [parentField, childField] = field.split('.');
      updatedVariants[index][parentField][childField] = value;
    } else {
      updatedVariants[index][field] = value;
    }

    setVariants(updatedVariants);
  };

  const handleImageChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImageForCrop(file);
      setCurrentVariantIndex(index);
      setShowImageCropper(true);
    }
  };

  const handleImageCropCompletion = (croppedImage) => {
    const updatedVariants = [...variants];
    updatedVariants[currentVariantIndex].image = {
      file: croppedImage,
      url: URL.createObjectURL(croppedImage),
    };
    setVariants(updatedVariants);
    setShowImageCropper(false);
    setSelectedImageForCrop(null);
    setCurrentVariantIndex(null);
  };

  const handleRemoveVariant = (index) => {
    const updatedVariants = variants.filter((_, i) => i !== index);
    setVariants(updatedVariants);
  };

  const handleOptionsChange = (e, optionType) => {
    if (optionType === 'customTitle') {
      setVariantOptions({
        ...variantOptions,
        custom: { ...variantOptions.custom, title: e.target.value },
      });
    } else if (optionType === 'customValues') {
      setVariantOptions({
        ...variantOptions,
        custom: { ...variantOptions.custom, values: e.target.value.split(',') },
      });
    } else {
      setVariantOptions({
        ...variantOptions,
        [optionType]: e.target.value.split(','), // Comma-separated values
      });
    }
  };

  const generateVariants = () => {
    if (variants && variants.length > 0) {
      // Ask for confirmation before regenerating variants
      if (
        !window.confirm(
          'This will overwrite existing variants. Do you want to continue?'
        )
      ) {
        return;
      }
    }

    const { colors, size, custom } = variantOptions;
    let generatedVariants = [];

    const combinations = (a, b) => a.flatMap((d) => b.map((e) => [d, e]));

    const validColors = Array.isArray(colors) ? colors : [];
    const validSizes = Array.isArray(size) ? size : [];

    if (selectedVariantTypes.includes('color') && selectedVariantTypes.includes('size')) {
      generatedVariants = combinations(validColors, validSizes);
    } else if (selectedVariantTypes.includes('color')) {
      generatedVariants = validColors.map((c) => [c]);
    } else if (selectedVariantTypes.includes('size')) {
      generatedVariants = validSizes.map((s) => [s]);
    }

    if (
      selectedVariantTypes.includes('custom') &&
      custom.title &&
      Array.isArray(custom.values) &&
      custom.values.length
    ) {
      const customValues = custom.values.map((v) => v.trim());
      if (generatedVariants.length > 0) {
        generatedVariants = combinations(generatedVariants, customValues).map((arr) =>
          arr.flat()
        );
      } else {
        generatedVariants = customValues.map((val) => [val]);
      }
    }

    const finalVariants = generatedVariants.map((combination) => {
      let variantObj = {
        sku: '',
        price: '',
        stock: '',
        weight: '',
        weightUnitOfMeasurement: '',
        dimensionUnitOfMeasurement: '',
        dimensions: { length: '', width: '', height: '' },
        image: null,
        description: '',
      };

      if (selectedVariantTypes.includes('color')) {
        const color = combination.find((val) =>
          validColors.some((c) => c.name === val.name)
        );
        variantObj.color = color?.name;
        variantObj.colorSwatch = color?.hex || '#ffffff';
      }
      if (selectedVariantTypes.includes('size')) {
        variantObj.size = combination.find((val) => validSizes.includes(val));
      }
      if (selectedVariantTypes.includes('custom') && custom.title) {
        variantObj.customTitle = custom.title.toLowerCase();
        variantObj.customValue = combination.find((val) => custom.values.includes(val));
      }

      return variantObj;
    });

    setVariants(finalVariants);
    setVariantsGenerated(true);
  };

  const handleAccordionToggle = (index) => {
    setIsAccordionOpen((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = !updatedState[index];
      return updatedState;
    });
  };

  const renderToggleSwitch = (label, value) => (
    <div
      className={`create-product-component-switch-group ${
        selectedVariantTypes.includes(value)
          ? 'create-product-component-switch-group-active'
          : ''
      }`}
    >
      <label className="create-product-component-switch-label">{label}</label>
      <Switch
        checked={selectedVariantTypes.includes(value)}
        onChange={() => handleVariantTypeToggle(value)}
        className={`create-product-component-switch ${
          selectedVariantTypes.includes(value)
            ? 'create-product-component-switch-active'
            : 'create-product-component-switch-inactive'
        }`}
      >
        <span
          className={`create-product-component-switch-toggle ${
            selectedVariantTypes.includes(value)
              ? 'create-product-component-switch-toggle-active'
              : 'create-product-component-switch-toggle-inactive'
          }`}
        />
      </Switch>
    </div>
  );

  return (
    <>
      {isVariableProduct && (
        <div className="create-product-component-variants-section">
          <h6 className="create-product-component-variants-title">Product Variants</h6>

          {/* Variant Type Selection */}
          <div className="create-product-variant-form-group">
            <label htmlFor="variant-type" className="create-product-variant-form-label">
              Select Variant Types
            </label>
            <div className="create-product-variant-dropdown-menu">
              {renderToggleSwitch('Color', 'color')}
              {renderToggleSwitch('Size', 'size')}
              {renderToggleSwitch('Custom', 'custom')}
            </div>
          </div>

          {/* Conditionally render fields based on selected variant types */}
          {selectedVariantTypes.includes('color') && (
            <div className="create-product-component-form-group">
              <label htmlFor="variant-colors" className="create-product-component-form-label">
                Colors
              </label>

              {variantOptions.colors.map((color, index) => (
                <div key={index} className="color-swatch-picker">
                  <input
                    type="text"
                    value={color.name}
                    onChange={(e) => handleColorNameChange(index, e.target.value)}
                    className="create-product-component-form-input"
                    placeholder="Color name e.g., red"
                  />
                  <input
                    type="color"
                    value={color.hex}
                    onChange={(e) => handleColorHexChange(index, e.target.value)}
                    style={{ backgroundColor: color.hex }}
                  />
                </div>
              ))}

              <button
                type="button"
                onClick={addColorOption}
                className="color-swatch-picker-button"
              >
                Add Color Option
              </button>
            </div>
          )}

          {selectedVariantTypes.includes('size') && (
            <div className="create-product-component-form-group">
              <label htmlFor="variant-sizes" className="create-product-component-form-label">
                Sizes (comma separated)
              </label>
              <input
                type="text"
                id="variant-sizes"
                value={variantOptions.size}
                onChange={(e) => handleOptionsChange(e, 'size')}
                className="create-product-component-form-input"
                placeholder="e.g., small, medium, large"
              />
            </div>
          )}

          {selectedVariantTypes.includes('custom') && (
            <>
              <div className="create-product-component-form-group">
                <label htmlFor="custom-variant-title" className="create-product-component-form-label">
                  Custom Variant Title
                </label>
                <input
                  type="text"
                  id="custom-variant-title"
                  value={variantOptions.custom.title}
                  onChange={(e) => handleOptionsChange(e, 'customTitle')}
                  className="create-product-component-form-input"
                  placeholder="e.g., Edition, Material"
                />
              </div>
              <div className="create-product-component-form-group">
                <label
                  htmlFor="custom-variant-values"
                  className="create-product-component-form-label"
                >
                  Custom Values (comma separated)
                </label>
                <input
                  type="text"
                  id="custom-variant-values"
                  value={variantOptions.custom.values}
                  onChange={(e) => handleOptionsChange(e, 'customValues')}
                  className="create-product-component-form-input"
                  placeholder="e.g., First Edition, Second Edition"
                />
              </div>
            </>
          )}

          <button
            type="button"
            onClick={generateVariants}
            className="create-product-component-add-variant-button"
          >
            Generate Variants
          </button>

          {/* Display existing or generated variants */}
          {variantsGenerated && (
            <>
              {variants.map((variant, index) => (
                <div key={index} className="create-product-component-variant-accordion">
                  <div
                    className={`create-product-component-accordion-header ${
                      isAccordionOpen[index] ? 'open' : ''
                    }`}
                    onClick={() => handleAccordionToggle(index)}
                  >
                    <div className="create-product-component-accordion-title">
                      <h6>
                        {`Variant ${index + 1} - `}
                        {variant.size && `Size: ${variant.size} `}
                        {variant.customTitle && variant.customValue && (
                          `${variant.customTitle}: ${variant.customValue} `
                        )}
                        {variant.color && `Color: ${variant.color} `}
                      </h6>
                      {variant.color && (
                        <div
                          className="color-swatch-preview"
                          style={{
                            backgroundColor: variant.colorSwatch,
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            border: '1px solid #000',
                            display: 'inline-block',
                            marginLeft: '10px',
                          }}
                        />
                      )}
                    </div>

                    <button type="button" className="create-product-component-accordion-toggle">
                      {isAccordionOpen[index] ? 'Collapse' : 'Expand'}
                    </button>
                  </div>

                  {isAccordionOpen[index] && (
                    <div className="create-product-component-accordion-content">
                      {/* SKU Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-sku-${index}`}
                          className="create-product-component-form-label"
                        >
                          SKU <span className="create-product-component-form-required">*</span>
                        </label>
                        <input
                          type="text"
                          id={`variant-sku-${index}`}
                          value={variant.sku}
                          onChange={(e) => handleVariantChange(index, 'sku', e.target.value)}
                          className="create-product-component-form-input"
                          placeholder="Enter variant SKU..."
                        />
                      </div>

                      {/* Price Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-price-${index}`}
                          className="create-product-component-form-label"
                        >
                          Price
                        </label>
                        <input
                          type="number"
                          id={`variant-price-${index}`}
                          value={variant.price}
                          onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
                          onWheel={preventScrollChange}
                          className="create-product-component-form-input"
                          placeholder="Enter variant price..."
                        />
                      </div>

                      {/* Stock Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-stock-${index}`}
                          className="create-product-component-form-label"
                        >
                          Stock <span className="create-product-component-form-required">*</span>
                        </label>
                        <input
                          type="number"
                          id={`variant-stock-${index}`}
                          value={variant.stock}
                          onChange={(e) => handleVariantChange(index, 'stock', e.target.value)}
                          onWheel={preventScrollChange}
                          className="create-product-component-form-input"
                          placeholder="Enter variant stock..."
                        />
                      </div>

                      {useCustomWeightAndDimensions[index] ? (
                        <>
                          {/* Weight Unit of Measurement */}
                          <div className="create-product-component-form-section">
                            <label
                              htmlFor={`variant-weight-unit-of-measurement-${index}`}
                              className="create-product-component-form-label"
                            >
                              Weight Unit of Measurement
                            </label>
                            <select
                              id={`variant-weight-unit-of-measurement-${index}`}
                              value={variant.weightUnitOfMeasurement}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  'weightUnitOfMeasurement',
                                  e.target.value
                                )
                              }
                              className="create-product-component-select"
                            >
                              <option value="">Select Weight Unit</option>
                              <option value="g">Grams (g)</option>
                              <option value="lbs">Pounds (lbs)</option>
                              <option value="kg">Kilograms (kg)</option>
                            </select>
                          </div>

                          {/* Weight Field */}
                          <div className="create-product-component-form-section">
                            <label
                              htmlFor={`variant-weight-${index}`}
                              className="create-product-component-form-label"
                            >
                              Weight <span className="create-product-component-form-required">*</span>
                            </label>
                            <input
                              type="number"
                              id={`variant-weight-${index}`}
                              value={variant.weight}
                              onChange={(e) =>
                                handleVariantChange(index, 'weight', e.target.value)
                              }
                              onWheel={preventScrollChange}
                              className="create-product-component-form-input"
                              placeholder="Enter variant weight..."
                            />
                          </div>

                          {/* Dimension Unit of Measurement */}
                          <div className="create-product-component-form-section">
                            <label
                              htmlFor={`variant-dimension-unit-of-measurement-${index}`}
                              className="create-product-component-form-label"
                            >
                              Dimension Unit of Measurement
                            </label>
                            <select
                              id={`variant-dimension-unit-of-measurement-${index}`}
                              value={variant.dimensionUnitOfMeasurement}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  'dimensionUnitOfMeasurement',
                                  e.target.value
                                )
                              }
                              className="create-product-component-select"
                            >
                              <option value="">Select Dimension Unit</option>
                              <option value="cm">Centimeters (cm)</option>
                              <option value="in">Inches (in)</option>
                              <option value="m">Meters (m)</option>
                            </select>
                          </div>

                          {/* Dimensions Fields */}
                          <div className="create-product-component-measurement-section">
                            <div className="create-product-component-form-section">
                              <label
                                htmlFor={`variant-length-${index}`}
                                className="create-product-component-form-label"
                              >
                                Length
                              </label>
                              <input
                                type="number"
                                id={`variant-length-${index}`}
                                value={variant.dimensions.length}
                                onChange={(e) =>
                                  handleVariantChange(
                                    index,
                                    'dimensions.length',
                                    e.target.value
                                  )
                                }
                                onWheel={preventScrollChange}
                                className="create-product-component-form-input"
                                placeholder="Enter variant length..."
                              />
                            </div>
                            <div className="create-product-component-form-section">
                              <label
                                htmlFor={`variant-width-${index}`}
                                className="create-product-component-form-label"
                              >
                                Width
                              </label>
                              <input
                                type="number"
                                id={`variant-width-${index}`}
                                value={variant.dimensions.width}
                                onChange={(e) =>
                                  handleVariantChange(
                                    index,
                                    'dimensions.width',
                                    e.target.value
                                  )
                                }
                                onWheel={preventScrollChange}
                                className="create-product-component-form-input"
                                placeholder="Enter variant width..."
                              />
                            </div>
                            <div className="create-product-component-form-section">
                              <label
                                htmlFor={`variant-height-${index}`}
                                className="create-product-component-form-label"
                              >
                                Height
                              </label>
                              <input
                                type="number"
                                id={`variant-height-${index}`}
                                value={variant.dimensions.height}
                                onChange={(e) =>
                                  handleVariantChange(
                                    index,
                                    'dimensions.height',
                                    e.target.value
                                  )
                                }
                                onWheel={preventScrollChange}
                                className="create-product-component-form-input"
                                placeholder="Enter variant height..."
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <p>
                            Weight: {productWeight} {weightUnitOfMeasurement}
                          </p>
                          <p>
                            Dimensions: {productDimensions.length} x {productDimensions.width} x{' '}
                            {productDimensions.height} {dimensionUnitOfMeasurement}
                          </p>
                        </div>
                      )}

                      <div className="create-product-component-form-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={useCustomWeightAndDimensions[index]}
                            onChange={() => handleCustomWeightDimensionsToggle(index)}
                          />
                          Use custom weight and dimensions for this variant
                        </label>
                      </div>

                      {/* Description Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-description-${index}`}
                          className="create-product-component-form-label"
                        >
                          Description
                        </label>
                        <textarea
                          id={`variant-description-${index}`}
                          value={variant.description}
                          onChange={(e) =>
                            handleVariantChange(index, 'description', e.target.value)
                          }
                          className="create-product-component-form-textarea"
                          placeholder="Enter variant description..."
                        />
                      </div>

                      {/* Image Upload Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-image-${index}`}
                          className="create-product-component-form-label"
                        >
                          Image
                        </label>
                        <input
                          type="file"
                          id={`variant-image-${index}`}
                          onChange={(e) => handleImageChange(index, e)}
                          className="create-product-component-form-input"
                          accept="image/*"
                        />
                        {variant.image && (
                          <div className="create-product-component-variant-image-preview">
                            <img
                              src={
                                variant.image.url
                                  ? variant.image.url
                                  : URL.createObjectURL(variant.image.file)
                              }
                              alt="Variant"
                              className="create-product-component-variant-image"
                            />
                            <button
                              type="button"
                              onClick={() => handleVariantChange(index, 'image', null)}
                              className="create-product-component-remove-variant-image"
                            >
                              Remove Image
                            </button>
                          </div>
                        )}
                      </div>

                      <button
                        type="button"
                        onClick={() => handleRemoveVariant(index)}
                        className="create-product-component-remove-variant"
                      >
                        Remove Variant
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {showImageCropper && selectedImageForCrop && ReactDOM.createPortal(
            <ImageCropperModal
              updateAvatar={handleImageCropCompletion}
              closeModal={() => {
                setShowImageCropper(false);
                setSelectedImageForCrop(null);
                setCurrentVariantIndex(null);
              }}
              initialImage={selectedImageForCrop}
              aspectRatio={1}
              minWidth={400}
              isAvatar={false}
              isPost={false}
            /> ,
            document.body
          )}
        </div>
      )}
    </>
  );
};

export default Variants;


