import React from "react";
import PropTypes from "prop-types";

const HeroAllProducts = ({ category, subCategory }) => {
  let displayTitle = "All Products";
  if (category === "tag" && subCategory) {
    displayTitle = `Products tagged with "${subCategory}"`;
  } else if (category && subCategory) {
    displayTitle = `${category} - ${subCategory}`;
  } else if (category) {
    displayTitle = category;
  }

  return (
    <div
      className="Hero-all-products-container"
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/dzlopmfj8/image/upload/v1716384099/buzzvibe2_zxqrw9.jpg)",
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="Hero-all-products-section">
        <h1 className="Hero-all-products-title">{displayTitle}</h1>
        {category === "tag" && subCategory && (
          <h2 className="Hero-all-products-subtitle">Explore our tagged products</h2>
        )}
        {/* Optionally, display subCategory as a separate subtitle */}
        {category !== "tag" && category && subCategory && (
          <h2 className="Hero-all-products-subtitle">{subCategory}</h2>
        )}
      </div>
    </div>
  );
};

HeroAllProducts.propTypes = {
  category: PropTypes.string,
  subCategory: PropTypes.string,
};

HeroAllProducts.defaultProps = {
  category: "",
  subCategory: "",
};

export default HeroAllProducts;
