import React, { useState, useEffect } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
} from "react-icons/ai";
import { MdTrackChanges } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Country, State } from "country-state-city";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import UserSubscriptions from "../Subscription/UserSubscriptions";
import UserAllOrders from "./UserAllOrders";
import AllRefundOrders from "./AllRefundOrders";
import ChangePassword from "./ChangePassword";
import UserAddress from "./UserAddress";
import UserProfileForm from "./UserProfileForm";


const ProfileContent = ({ active }) => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  return (
    <div className="profile-content-component-container">
      {active === 1 && <UserProfileForm />}
      {active === 2 && <UserAllOrders />}
      {active === 3 && <AllRefundOrders/>}
      {active === 5 && <TrackOrder />}
      {active === 6 && <ChangePassword />}
      {active === 7 && <UserAddress />}
      {active === 9 && <UserSubscriptions />}
    </div>
  );
};



const TrackOrder = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  return (
    <div className="profile-content-component-orders">
      <table className="profile-content-component-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Items Qty</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td className={order.status === "Delivered" ? "greenColor" : "redColor"}>
                  {order.status}
                </td>
                <td>{order.cart.length}</td>
                <td>{`US$ ${order.totalPrice}`}</td>
                <td>
                  <Link to={`/user/track/order/${order._id}`}>
                    <button className="profile-content-component-view-btn">
                      <MdTrackChanges />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};


export default ProfileContent;

