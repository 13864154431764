import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid'; 

const CreateProductPrompt = () => {
  return (
    <div className="create-product-prompt">
      <svg
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="create-product-prompt__icon"
      >
        <path
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          strokeWidth={2}
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <h3 className="create-product-prompt__title">No products</h3>
      <p className="create-product-prompt__text">Get started by creating a new product</p>
      <div className="create-product-prompt__button-container">
        <a href="/dashboard-create-product">
          <button
            type="button"
            className="create-product-prompt__button"
          >
            <PlusIcon aria-hidden="true" className="create-product-prompt__button-icon" />
            Create a product
          </button>
        </a>
      </div>
    </div>
  );
};

export default CreateProductPrompt;
