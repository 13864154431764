import React from 'react';
import OwnedBrand from '../components/Brands/OwnedBrand';
import BrandCatalog from '../components/Brands/BrandCatalog';
import DashboardHeader from '../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../components/Shop/Layout/DashboardSideBar';

const BrandManagementPage = () => {
  return (
    <div className="brand-management-container">
      <DashboardHeader />
      <div className="brand-management-content">
        <div className="brand-management-sidebar">
          <DashboardSideBar active={13} />
        </div>
        <div className="brand-management-main-content">
          <OwnedBrand />
          <BrandCatalog />
        </div>
      </div>
    </div>
  );
};

export default BrandManagementPage;
