import React from 'react';

const TaxCodeGuide = () => {
  return (
    <div className="doc-container">
      <h1 className="doc-title">Guide to Using the Tax Code Selector</h1>
      <p className="doc-intro">
        This guide will walk you through the process of using the Tax Code Selector to assign appropriate tax codes to your products based on category, subcategory, and other relevant information.
      </p>

      <h2 className="doc-step h2">Step-by-Step Guide to Using the Tax Code Selector</h2>
      <ol className="doc-steps">
        <li className="doc-step">
          <h2>Enter Product Information</h2>
          <p>To help the system suggest the most appropriate tax code, you can enter relevant product information:</p>
          <ul>
            <li><strong>Product Category and Subcategory</strong>: Provide the category and subcategory of your product to narrow down suitable tax codes.</li>
            <li><strong>Product Name and Description</strong>: Enter the product name and a brief description to improve tax code suggestions.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Search for Tax Codes</h2>
          <p>Follow these steps to search for and select a tax code:</p>
          <ul>
            <li><strong>Focus on the Input Field</strong>: Click on the input field labeled <strong>Search tax codes...</strong> to activate the dropdown and see available tax codes.</li>
            <li><strong>Automatic Suggestions</strong>: The system will suggest tax codes based on the product information provided. You can also type keywords to filter the available tax codes.</li>
            <li><strong>Select a Tax Code</strong>: Click on a tax code from the dropdown list to select it. The selected tax code will be displayed in the input field.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Manage Selected Tax Code</h2>
          <p>Once a tax code is selected, you can manage it as follows:</p>
          <ul>
            <li><strong>View Selected Tax Code</strong>: The selected tax code, along with its simplified description, will be displayed in the input field.</li>
            <li><strong>Change Tax Code</strong>: To change the selected tax code, click on the input field and choose a different code from the dropdown list.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Submit Product with Tax Code</h2>
          <p>After selecting the appropriate tax code, it will be included when submitting the product details:</p>
          <ul>
            <li><strong>Review Tax Code</strong>: Ensure the selected tax code is accurate to comply with tax regulations.</li>
            <li><strong>Submit Product Details</strong>: The tax code will be submitted along with the rest of your product information.</li>
          </ul>
        </li>
      </ol>

      <div className="doc-additional">
        <h2>Additional Tips</h2>
        <ul>
          <li><strong>Provide Detailed Product Information</strong>: The more detailed the product information, the better the system can suggest appropriate tax codes.</li>
          <li><strong>Use Relevant Keywords</strong>: When searching for tax codes, use keywords that accurately describe your product to find the best match.</li>
          <li><strong>Check for Updates</strong>: Tax codes may be updated periodically. Make sure to use the most recent tax code applicable to your product.</li>
        </ul>
      </div>
    </div>
  );
};

export default TaxCodeGuide;
