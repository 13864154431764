import React, { useState } from 'react';
import CSVUploadComponent from './CSVUploadComponent';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ecommerceServer } from "../../server";


const BulkProductUpload = () => {
  const handleProductsFromCSV = async (products) => {
    try {
      const response = await axios.post(`${ecommerceServer}/product/create-products-csv`, {
        products: products
      });

      if (response.status === 201) {
        toast.success("Products created successfully!");
        
      }
    } catch (error) {
      console.error("Error occurred during products creation:", error);
      toast.error("Failed to create products.");
      
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <button onClick={handleOpen} className="bulk-product-upload-button">
        Bulk Upload via CSV
      </button>

      {open && (
        <div className="bulk-product-upload-modal-overlay" onClick={handleClose}>
          <div className="bulk-product-upload-modal" onClick={(e) => e.stopPropagation()}>
            <h2 id="bulk-upload-modal-title" className="bulk-product-upload-title">
              Bulk Product Upload
            </h2>
            <CSVUploadComponent onProductsParsed={handleProductsFromCSV} />
            <button onClick={handleClose} className="bulk-product-upload-close-button">
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BulkProductUpload;

