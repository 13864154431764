import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';
import BrandCreationGuide from '../../components/Documentation/BrandCreationGuide';

const BrandCreateDocPage = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <BrandCreationGuide />
    </div>
  );
};

export default BrandCreateDocPage;