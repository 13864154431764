import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ecommerceServer } from '../../server';
import { Switch } from '@headlessui/react';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ImageCropperModal from '../Image/ImageCropperModal';
import TagSelector from '../Tags/TagSelector';
import TaxCodeSelector from '../Shop/TaxCodeSelector';
import Variants from '../Shop/Variants';
import { toast } from "react-toastify";
import ReactDOM from 'react-dom';

const EditProduct = ({ productId, onClose }) => {
  const [productData, setProductData] = useState({
    name: '',
    description: '',
    category: '',
    tags: [],
    originalPrice: '',
    discountPrice: '',
    productType: ['retail'],
    stock: '',
    availableForPickup: false,
    availableForDelivery: true,
    handle: '',
    brand: '',
    sold_out: 0,
    SKU: '',
    lowInventoryThreshold: '',
    weightUnitOfMeasurement: '',
    dimensionUnitOfMeasurement: '',
    isEdible: false,
    barcodeString: '',
    isOnSale: false,
    taxCode: '',
    weight: '',
    length: '',
    width: '',
    height: '',
  });
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [barcodeImage, setBarcodeImage] = useState([]);
  const [barcodePreviews, setBarcodePreviews] = useState([]);
  const [nutritionInfoImage, setNutritionInfoImage] = useState([]);
  const [nutritionInfoImagePreviews, setNutritionInfoImagePreviews] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [selectedImageForCrop, setSelectedImageForCrop] = useState(null);
  const [remainingImagesToCrop, setRemainingImagesToCrop] = useState([]);
  const [fieldNameBeingCropped, setFieldNameBeingCropped] = useState('');
  const [removedImageIds, setRemovedImageIds] = useState([]);
  const currentProfile = sessionStorage.getItem('currentProfile');
  const [variants, setVariants] = useState([]);  // State for handling variants
  const [isVariableProduct, setIsVariableProduct] = useState(false);


  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If the item is dropped outside the list
    if (!destination) {
      return;
    }

    // If the item is dropped back in the same position
    if (source.index === destination.index) {
      return;
    }

    // Reorder images
    const updatedImages = Array.from(images);
    const [movedImage] = updatedImages.splice(source.index, 1);
    updatedImages.splice(destination.index, 0, movedImage);

    // Reorder image previews
    const updatedImagePreviews = Array.from(imagePreviews);
    const [movedPreview] = updatedImagePreviews.splice(source.index, 1);
    updatedImagePreviews.splice(destination.index, 0, movedPreview);

    // Update the state
    setImages(updatedImages);
    setImagePreviews(updatedImagePreviews);
  };

  useEffect(() => {
    console.log('isVariableProduct updated:', isVariableProduct);
  }, [isVariableProduct]);

  useEffect(() => {
    axios
      .get(`${ecommerceServer}/product/get-product/${productId}`)
      .then((response) => {
        const product = response.data.product;
  
        

        setProductData({
          ...product,
          length: product.dimensions?.length ?? '',
          width: product.dimensions?.width ?? '',
          height: product.dimensions?.height ?? '',
          tags: product.tags,
          availableForDelivery:
            product.availableForDelivery !== undefined
              ? product.availableForDelivery
              : false,
          // Similarly, ensure other boolean fields are properly set
          availableForPickup:
            product.availableForPickup !== undefined
              ? product.availableForPickup
              : false,
          isOnSale:
            product.isOnSale !== undefined
              ? product.isOnSale
              : false,
          isEdible:
            product.isEdible !== undefined
              ? product.isEdible
              : false,
        });

        console.log('Updated product data:', product);
  
        // Set images and their previews
        setImages(product.images || []);
        setImagePreviews(product.images.map((img) => img.url) || []);
  
        // Handle variants
        const updatedVariants = product.variants.map((variant) => ({
          ...variant,
          dimensions: variant.dimensions || { length: '', width: '', height: '' },
          weight: variant.weight || '',
          image: variant.image || null,
        }));
  
        setVariants(updatedVariants);
        console.log('updatedVariants.length:', updatedVariants.length);
        setIsVariableProduct(updatedVariants.length > 0);
  
        // Set barcode images and their previews
        setBarcodeImage(product.barcode || []);
        setBarcodePreviews(product.barcode.map((barcode) => barcode.url) || []);
  
        // Set nutrition info images and their previews
        setNutritionInfoImage(product.nutritionInfoImages || []);
        setNutritionInfoImagePreviews(
          product.nutritionInfoImages.map((img) => img.url) || []
        );
  
        setVideos(product.videos || []);
        setVideoPreviews(product.videos?.map((video) => video.url) || []);
  
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching product data:', error);
        setLoading(false);
      });
  }, [productId]);
  
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProductData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setProductData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleProductTypeChange = (type) => {
    setProductData((prevState) => {
      const updatedProductType = prevState.productType.includes(type)
        ? prevState.productType.filter((t) => t !== type)
        : [...prevState.productType, type];
      return { ...prevState, productType: updatedProductType };
    });
  };

  const handleImageChange = (e, fieldName) => {
    const newFiles = Array.from(e.target.files);
    if (newFiles.length > 0) {
      setRemainingImagesToCrop(newFiles);
      setSelectedImageForCrop(newFiles[0]);
      setFieldNameBeingCropped(fieldName);
      setShowImageCropper(true);
    }
  };

  const handleImageCropCompletion = (croppedImage) => {
    let updatedFiles = [];
    let updatedPreviews = [];
  
    switch (fieldNameBeingCropped) {
      case 'images':
        updatedFiles = [...images, croppedImage];
        updatedPreviews = updatedFiles.map((file) => {
          if (file instanceof File || file instanceof Blob) {
            return URL.createObjectURL(file);
          } else if (typeof file === 'object' && file.url) {
            return file.url;
          } else {
            return file;
          }
        });
        setImages(updatedFiles);
        setImagePreviews(updatedPreviews);
        break;
  
      case 'barcode':
        updatedFiles = [...barcodeImage, croppedImage];
        updatedPreviews = updatedFiles.map((file) => {
          if (file instanceof File || file instanceof Blob) {
            return URL.createObjectURL(file);
          } else if (typeof file === 'object' && file.url) {
            return file.url;
          } else {
            return file;
          }
        });
        setBarcodeImage(updatedFiles);
        setBarcodePreviews(updatedPreviews);
        break;
  
      case 'nutritionInfoImage':
        updatedFiles = [...nutritionInfoImage, croppedImage];
        updatedPreviews = updatedFiles.map((file) => {
          if (file instanceof File || file instanceof Blob) {
            return URL.createObjectURL(file);
          } else if (typeof file === 'object' && file.url) {
            return file.url;
          } else {
            return file;
          }
        });
        setNutritionInfoImage(updatedFiles);
        setNutritionInfoImagePreviews(updatedPreviews);
        break;
  
      default:
        return;
    }
  
    // Move to the next image in the queue
    const newQueue = [...remainingImagesToCrop];
    newQueue.shift();
  
    if (newQueue.length > 0) {
      setRemainingImagesToCrop(newQueue);
      setSelectedImageForCrop(newQueue[0]);
      setShowImageCropper(true);
    } else {
      setShowImageCropper(false);
      setSelectedImageForCrop(null);
      setFieldNameBeingCropped('');
    }
  };
  

  const handleRemoveImage = (index, files, setFiles, setPreviews) => {
    const removedFile = files[index];
  
    if (removedFile && removedFile._id) {
      setRemovedImageIds((prev) => [...prev, removedFile._id]);
    }
  
    const newFiles = files.filter((_, i) => i !== index);
  
    const newPreviews = newFiles.map((file) => {
      if (file instanceof File || file instanceof Blob) {
        return URL.createObjectURL(file);
      } else if (typeof file === 'object' && file.url) {
        return file.url;
      } else {
        return file;
      }
    });
  
    setFiles(newFiles);
    setPreviews(newPreviews);
  };
  
  

  const handleVideoChange = (newFiles) => {
    const updatedFiles = [...videos, ...newFiles];
    const updatedPreviews = updatedFiles.map((file) => URL.createObjectURL(file));
    setVideos(updatedFiles);
    setVideoPreviews(updatedPreviews);
  };

  const handleRemoveVideo = (index) => {
    const newVideos = videos.filter((_, i) => i !== index);
    const newPreviews = newVideos.map((file) => URL.createObjectURL(file));
    setVideos(newVideos);
    setVideoPreviews(newPreviews);
  };
  
  

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
  
    // Exclude 'length', 'width', 'height', and 'tags' from being appended individually
    for (const key in productData) {
      if (
        key !== 'reviews' &&
        key !== 'tags' &&
        key !== 'length' &&
        key !== 'width' &&
        key !== 'height' &&
        key !== 'dimensions' &&
        key !== 'variants'
      ) {
        if (Array.isArray(productData[key]) || typeof productData[key] === 'object') {
          formData.append(key, JSON.stringify(productData[key]));
        } else {
          formData.append(key, productData[key]);
        }
      }
    }
    
  
    // Append dimensions as a JSON string
    formData.append('dimensions', JSON.stringify({
      length: productData.length,
      width: productData.width,
      height: productData.height,
    }));
  
    // Map tags to IDs and append as JSON
    const tagIds = productData.tags.map(tag => tag._id || tag);
    formData.append('tags', JSON.stringify(tagIds));
  
    // Append images to formData
    images.forEach((image) => {
      if (image instanceof File) {
        formData.append('images', image);
      } else if (image.url) {
        formData.append('existingImageUrls[]', image.url);
      } else if (image._id) {
        formData.append('existingImageIds[]', image._id);
      }
    });
  
    // Append barcode images to formData
    barcodeImage.forEach((barcode) => {
      if (barcode instanceof File) {
        formData.append('barcode', barcode);
      } else if (barcode.url) {
        formData.append('existingBarcodeUrls[]', barcode.url);
      }
    });
  
    // Append nutrition info images to formData
    nutritionInfoImage.forEach((image) => {
      if (image instanceof File) {
        formData.append('nutritionInfoImage', image);
      } else if (image.url) {
        formData.append('existingNutritionInfoUrls[]', image.url);
      }
    });
  
    // Append videos to formData
    videos.forEach((video) => {
      if (video instanceof File) {
        formData.append('videos', video);
      } else if (video.url) {
        formData.append('existingVideoUrls[]', video.url);
      }
    });

    if (productData.inventory && Array.isArray(productData.inventory)) {
      productData.inventory.forEach(inv => formData.append('inventory[]', inv));
    }

    removedImageIds.forEach((id) => {
      formData.append('removedImageIds[]', id);
    });


    if (isVariableProduct) {
      // Assign identifiers to variants
      variants.forEach((variant) => {
        if (!variant._id && !variant.tempId) {
          variant.tempId = `temp_${Date.now()}_${Math.random()}`;
        }
      });
  
      // Prepare serializable variants
      const serializableVariants = variants.map((variant) => {
        const { image, tempId, ...rest } = variant;
  
        let imageData = null;
        if (image) {
          if (image._id) {
            imageData = { _id: image._id };
          } else if (image.url) {
            imageData = { url: image.url };
          }
        }
  
        return {
          ...rest,
          image: imageData,
          _id: variant._id || variant.tempId,
        };
      });
  
      formData.append('variants', JSON.stringify(serializableVariants));
  
      // Append variant images
      variants.forEach((variant) => {
        if (variant.image && variant.image.file) {
          const variantId = variant._id || variant.tempId;
          formData.append(`variant_image_${variantId}`, variant.image.file);
        }
      });
    }
    

    axios
      .put(`${ecommerceServer}/product/edit-product/${productId}`, formData, {
        withCredentials: true,
        headers: {
          'Current-Profile': currentProfile,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Product updated:', response.data);
        toast.success("Product updated successfully!");
        if (typeof onClose === 'function') {
          onClose();
        }
      })
      .catch((error) => {
        console.error('Error updating product:', error);
        toast.error("Failed to update product. Please try again.");
      });
  };

  const handleProductTypeSwitch = () => {
    setIsVariableProduct(!isVariableProduct);
  };
  
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!productData) {
    return <div>Product not found</div>;
  }

  return (
    <div className="create-product-component-wrapper">
      <h5 className="create-product-component-title">Edit Product</h5>
      <form onSubmit={handleSubmit} className="create-product-component-form">
        <div className="create-product-component-form-section">
          <div className="create-product-component-form-group">
            <label htmlFor="name" className="create-product-component-form-label">
              Name <span className="create-product-component-form-required">*</span>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={productData.name}
              required
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Enter your product name..."
            />
          </div>
        </div>

        <div className="create-product-component-form-section">
          <label htmlFor="description" className="create-product-component-form-label">
            Description <span className="create-product-component-form-required">*</span>
          </label>
          <textarea
            id="description"
            name="description"
            rows={4}
            required
            value={productData.description}
            onChange={handleInputChange}
            className="create-product-component-form-textarea"
            placeholder="Enter your product description..."
          />
        </div>

        <div className="create-product-component-upload-section">
          <label htmlFor="upload" className="create-product-component-upload-label">
            Upload Product Images
          </label>
          <div
            className={`create-product-component-dropzone`}
            onDrop={(e) => handleImageChange(e, 'images')}
          >
            <div className="create-product-component-dropzone-content">
              <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
              <label htmlFor="upload" className="create-product-component-dropzone-label">
                <span>Click to Change Image</span>
                <input
                  type="file"
                  id="upload"
                  multiple
                  onChange={(e) => handleImageChange(e, 'images')}
                  className="sr-only"
                />
              </label>
              <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppableImages" direction="horizontal">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="create-product-component-image-previews"
          >
            {imagePreviews.map((imageUrl, index) => (
              <Draggable key={index} draggableId={`image-${index}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`create-product-component-image-preview ${
                      snapshot.isDragging ? "create-product-component-image-preview-dragging" : ""
                    }`}
                  >
                    <img
                      src={imageUrl}
                      alt={`Uploaded ${index}`}
                      className="create-product-component-image-preview-img"
                    />
                    <div className="create-product-component-image-preview-badge">
                      {index + 1}
                    </div>
                    <button
                      onClick={() => handleRemoveImage(index, images, setImages, setImagePreviews)}
                      className="create-product-component-image-preview-remove"
                      aria-label="Remove image"
                    >
                      &times;
                    </button>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>

        <div className="create-product-component-upload-section">
          <label htmlFor="video-upload" className="create-product-component-upload-label">
            Upload Product Videos
          </label>
          <div 
            className="create-product-component-dropzone"
            onDrop={(e) => {
              e.preventDefault();
              const newFiles = Array.from(e.dataTransfer.files).filter(
                (file) => file.type.startsWith('video/')
              );
              handleVideoChange(newFiles);
            }}
          >
            <div className="create-product-component-dropzone-content">
              <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
              <label htmlFor="video-upload" className="create-product-component-dropzone-label">
                <span>Drag and drop your videos here or click to upload</span>
                <input 
                  type="file" 
                  id="video-upload" 
                  multiple 
                  accept="video/*"
                  onChange={(e) => handleVideoChange(Array.from(e.target.files))}
                  className="sr-only" 
                />
              </label>
              <p className="create-product-component-dropzone-note">MP4 up to 50MB</p>
            </div>
          </div>
        </div>

        <div className="create-product-component-video-previews">
          {videoPreviews.map((videoUrl, index) => (
            <div key={index} className="create-product-component-video-preview">
              <video 
                src={videoUrl} 
                controls 
                className="create-product-component-video-preview-video"
              />
              <button
                onClick={() => handleRemoveVideo(index)}
                className="create-product-component-video-preview-remove"
                aria-label="Remove video"
              >
                &times;
              </button>
            </div>
          ))}
        </div>



        {/* Retail and Wholesale Switches */}
          <div className="create-product-component-form-section">
            <label className="create-product-component-form-label">
              Product Type <span className="create-product-component-form-required">*</span>
            </label>
            <div className="create-product-component-product-type-section">
              {/* Retail Switch */}
              <Switch.Group as="div" className={`create-product-component-switch-group ${productData.productType.includes("retail") ? 'create-product-component-switch-group-active' : ''}`}>
                <span className="create-product-component-switch-label">Retail</span>
                <Switch
                  checked={productData.productType.includes("retail")}
                  onChange={() => handleProductTypeChange("retail")}
                  className={`create-product-component-switch ${productData.productType.includes("retail") ? 'create-product-component-switch-active' : 'create-product-component-switch-inactive'}`}
                >
                  <span
                    className={`create-product-component-switch-toggle ${productData.productType.includes("retail") ? 'create-product-component-switch-toggle-active' : 'create-product-component-switch-toggle-inactive'}`}
                  />
                </Switch>
              </Switch.Group>

              {/* Wholesale Switch */}
              <Switch.Group as="div" className={`create-product-component-switch-group ${productData.productType.includes("wholesale") ? 'create-product-component-switch-group-active' : ''}`}>
                <span className="create-product-component-switch-label">Wholesale</span>
                <Switch
                  checked={productData.productType.includes("wholesale")}
                  onChange={() => handleProductTypeChange("wholesale")}
                  className={`create-product-component-switch ${productData.productType.includes("wholesale") ? 'create-product-component-switch-active' : 'create-product-component-switch-inactive'}`}
                >
                  <span
                    className={`create-product-component-switch-toggle ${productData.productType.includes("wholesale") ? 'create-product-component-switch-toggle-active' : 'create-product-component-switch-toggle-inactive'}`}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>

          <div className="create-product-component-form-section create-product-component-form-group-row">
            <div className="create-product-component-form-group">
              <label htmlFor="sku" className="create-product-component-form-label">
                SKU <span className="create-product-component-form-required">*</span>
              </label>
              <input
                type="text"
                name="SKU"
                id="sku"
                value={productData.SKU}
                onChange={handleInputChange}
                className="create-product-component-form-input"
                placeholder="Enter SKU..."
              />
            </div>
            <div className="create-product-component-form-group">
              <label htmlFor="brand" className="create-product-component-form-label">
                Brand <span className="create-product-component-form-required">*</span>
              </label>
              <input
                type="text"
                name="brand"
                id="brand"
                value={productData.brand}
                onChange={handleInputChange}
                className="create-product-component-form-input"
                placeholder="Enter your product brand..."
              />
            </div>
          </div>

        <div className="create-product-component-form-section">
          <label htmlFor="category" className="create-product-component-form-label">
            Category <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="text"
            name="category"
            id="category"
            value={productData.category}
            onChange={handleInputChange}
            className="create-product-component-form-input"
            placeholder="Enter product category..."
          />
        </div>

        <div
            className={`create-product-component-switch-group ${
              productData.isOnSale ? 'create-product-component-switch-group-active' : ''
            }`}
          >
            <label className="create-product-component-switch-label">On Sale</label>
            <Switch
              checked={productData.isOnSale}
              onChange={(checked) =>
                setProductData((prev) => ({ ...prev, isOnSale: checked }))
              }
              className={`create-product-component-switch ${
                productData.isOnSale
                  ? 'create-product-component-switch-active'
                  : 'create-product-component-switch-inactive'
              }`}
            >
              <span
                className={`create-product-component-switch-toggle ${
                  productData.isOnSale
                    ? 'create-product-component-switch-toggle-active'
                    : 'create-product-component-switch-toggle-inactive'
                }`}
              />
            </Switch>
          </div>


        <div className="create-product-component-form-section create-product-component-form-group-row">
          <div className="create-product-component-form-group">
            <label htmlFor="original-price" className="create-product-component-form-label">
              {productData.isOnSale ? 'Original Price' : 'Price'} <span className="create-product-component-form-required">*</span>
            </label>
            <input
              type="number"
              name="originalPrice"
              id="original-price"
              value={productData.originalPrice}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder={productData.isOnSale ? 'Enter the original price...' : 'Enter the price...'}
            />
          </div>
          {productData.isOnSale && (
            <div className="create-product-component-form-group">
              <label htmlFor="discount-price" className="create-product-component-form-label">
                Discount Price
              </label>
              <input
                type="number"
                name="discountPrice"
                id="discount-price"
                value={productData.discountPrice}
                onChange={handleInputChange}
                className="create-product-component-form-input"
                placeholder="Enter the discounted price..."
              />
            </div>
          )}
        </div>
        <div className="create-product-component-form-section create-product-component-form-group-row">
          <div className="create-product-component-form-group">
            <label htmlFor="stock" className="create-product-component-form-label">
              Stock <span className="create-product-component-form-required">*</span>
            </label>
            <input
              type="number"
              name="stock"
              id="stock"
              value={productData.stock}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Enter the stock amount..."
            />
          </div>
          <div className="create-product-component-form-group">
            <label htmlFor="low-inventory-threshold" className="create-product-component-form-label">
              Low Inventory Threshold
            </label>
            <input
              type="number"
              name="lowInventoryThreshold"
              id="low-inventory-threshold"
              value={productData.lowInventoryThreshold}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Set the low inventory threshold..."
            />
          </div>
        </div>


        <div className="create-product-component-form-section">
          <TaxCodeSelector selectedTaxCode={productData.taxCode} updateTaxCode={(newTaxCode) => setProductData({ ...productData, taxCode: newTaxCode })}  returnOnlyId={true}/>
        </div>

        <div className="create-product-component-form-section">
          <label htmlFor="tagSelector" className="tag-selector-label">Tag Selector <span className="tax-code-selector-required">*</span></label>
          <TagSelector
            selectedTags={productData.tags}
            updateTags={(newTags) => {
              setProductData({ ...productData, tags: newTags }); // Keep full tag objects
            }}
          />

        </div>

        <div
          className={`create-product-component-switch-group ${
            productData.availableForPickup
              ? 'create-product-component-switch-group-active'
              : ''
          }`}
        >
          <label className="create-product-component-switch-label">
            Available for Pickup
          </label>
          <Switch
            checked={productData.availableForPickup}
            onChange={(checked) =>
              setProductData((prev) => ({
                ...prev,
                availableForPickup: checked,
              }))
            }
            className={`create-product-component-switch ${
              productData.availableForPickup
                ? 'create-product-component-switch-active'
                : 'create-product-component-switch-inactive'
            }`}
          >
            <span
              className={`create-product-component-switch-toggle ${
                productData.availableForPickup
                  ? 'create-product-component-switch-toggle-active'
                  : 'create-product-component-switch-toggle-inactive'
              }`}
            />
          </Switch>
        </div>



        <div
            className={`create-product-component-switch-group ${
              productData.availableForDelivery
                ? 'create-product-component-switch-group-active'
                : ''
            }`}
          >
            <label className="create-product-component-switch-label">
              Available for Delivery
            </label>
            <Switch
              checked={productData.availableForDelivery}
              onChange={(checked) =>
                setProductData((prev) => ({
                  ...prev,
                  availableForDelivery: checked,
                }))
              }
              className={`create-product-component-switch ${
                productData.availableForDelivery
                  ? 'create-product-component-switch-active'
                  : 'create-product-component-switch-inactive'
              }`}
            >
              <span
                className={`create-product-component-switch-toggle ${
                  productData.availableForDelivery
                    ? 'create-product-component-switch-toggle-active'
                    : 'create-product-component-switch-toggle-inactive'
                }`}
              />
            </Switch>
          </div>



        <div className="create-product-component-form-section">
          <label htmlFor="weight-unit-of-measurement" className="create-product-component-form-label">
            Weight Unit of Measurement
          </label>
          <select
            id="weight-unit-of-measurement"
            name="weight-unit-of-measurement"
            value={productData.weightUnitOfMeasurement}
            onChange={(e) => setProductData({ ...productData, weightUnitOfMeasurement: e.target.value })}
            className="create-product-component-select"
          >
            <option value="">Select Weight Unit</option>
            <option value="g">Grams (g)</option>
            <option value="lbs">Pounds (lbs)</option>
            <option value="kg">Kilograms (kg)</option>
          </select>
        </div>

        <div className="create-product-component-form-section">
          <label htmlFor="weight" className="create-product-component-form-label">
            Weight <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="number"
            name="weight"
            id="weight"
            value={productData.weight}
            onChange={handleInputChange}
            className="create-product-component-form-input"
            placeholder="Enter the product weight..."
            required
          />
        </div>

        <div className="create-product-component-form-section">
          <label htmlFor="dimension-unit-of-measurement" className="create-product-component-form-label">
            Dimension Unit of Measurement
          </label>
          <select
            id="dimension-unit-of-measurement"
            name="dimension-unit-of-measurement"
            value={productData.dimensionUnitOfMeasurement}
            onChange={(e) => setProductData({ ...productData, dimensionUnitOfMeasurement: e.target.value })}
            className="create-product-component-select"
          >
            <option value="">Select Dimension Unit</option>
            <option value="cm">Centimeters (cm)</option>
            <option value="in">Inches (in)</option>
            <option value="m">Meters (m)</option>
          </select>
        </div>

        <div className="create-product-component-measurement-section">
          <div className="create-product-component-form-section">
            <label htmlFor="length" className="create-product-component-form-label">
              Length <span className="create-product-component-form-required">*</span>
            </label>
            <input
              type="number"
              name="length"
              id="length"
              value={productData.length}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Enter the product length..."
              required
            />
          </div>
          <div className="create-product-component-form-section">
            <label htmlFor="width" className="create-product-component-form-label">
              Width <span className="create-product-component-form-required">*</span>
            </label>
            <input
              type="number"
              name="width"
              id="width"
              value={productData.width}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Enter the product width..."
              required
            />
          </div>
          <div className="create-product-component-form-section">
            <label htmlFor="height" className="create-product-component-form-label">
              Height <span className="create-product-component-form-required">*</span>
            </label>
            <input
              type="number"
              name="height"
              id="height"
              value={productData.height}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Enter the product height..."
              required
            />
          </div>
        </div>
        <div className="create-product-component-upload-section">
          <label htmlFor="barcode-upload" className="create-product-component-upload-label">
            Upload Barcode
          </label>
          <div 
            className="create-product-component-dropzone"
          >
            <div className="create-product-component-dropzone-content">
              <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
              <label htmlFor="barcode-upload" className="create-product-component-dropzone-label">
                <span>Drag and drop your barcodes here or click to upload</span>
                <input 
                  type="file" 
                  id="barcode-upload" 
                  multiple 
                  onChange={(e) => handleImageChange(e, 'barcode')} 
                  className="sr-only" 
                />
              </label>
              <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
          <div className="create-product-component-barcode-previews">
              {barcodePreviews.map((barcodeUrl, index) => (
                <div key={index} className="create-product-component-barcode-preview">
                  <img src={barcodeUrl} alt={`Barcode ${index}`} className="create-product-component-barcode-preview-img" />
                  <button  
                    onClick={() => handleRemoveImage(index, barcodeImage, setBarcodeImage, setBarcodePreviews)} 
                    className="create-product-component-barcode-preview-remove"
                    aria-label="Remove barcode"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>

        </div>
        <div className={`create-product-component-switch-group ${productData.isEdible ? 'create-product-component-switch-group-active' : ''}`}>
    <label className="create-product-component-switch-label">Is Edible</label>
    <Switch
      checked={productData.isEdible}
      onChange={(checked) => setProductData((prev) => ({ ...prev, isEdible: checked }))}
      className={`create-product-component-switch ${productData.isEdible ? 'create-product-component-switch-active' : 'create-product-component-switch-inactive'}`}
    >
      <span
        className={`create-product-component-switch-toggle ${productData.isEdible ? 'create-product-component-switch-toggle-active' : 'create-product-component-switch-toggle-inactive'}`}
      />
    </Switch>
  </div>

  {/* Nutrition Info Image Upload */}
  {productData.isEdible && (
    <div className="create-product-component-upload-section">
      <label htmlFor="nutrition-info-upload" className="create-product-component-upload-label">
        Upload Nutrition Info Image
      </label>
      <div
        className={`create-product-component-dropzone`}
        onDrop={(e) => handleImageChange(e, 'nutritionInfoImage')}
      >
        <div className="create-product-component-dropzone-content">
          <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
          <label htmlFor="nutrition-info-upload" className="create-product-component-dropzone-label">
            <span>Drag and drop your nutrition info images here or click to upload</span>
            <input
              type="file"
              id="nutrition-info-upload"
              multiple
              onChange={(e) => handleImageChange(e, 'nutritionInfoImage')}
              className="sr-only"
            />
          </label>
          <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
        </div>
      </div>
      <div className="create-product-component-image-previews">
        {nutritionInfoImagePreviews.map((imageUrl, index) => (
          <div key={index} className="create-product-component-image-preview">
            <img src={imageUrl} alt={`Nutrition Info ${index}`} className="create-product-component-image-preview-img" />
            <button
              onClick={() => handleRemoveImage(index, nutritionInfoImage, setNutritionInfoImage, setNutritionInfoImagePreviews)}
              className="create-product-component-image-preview-remove"
              aria-label="Remove image"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  )}
  {/* Variable Product Switch */}
  <Switch.Group as="div" className="edit-product-component-switch-group">
          <span className="edit-product-component-switch-label">Variable Product</span>
          <Switch
            checked={isVariableProduct}
            onChange={handleProductTypeSwitch}
            className={`edit-product-component-switch ${
              isVariableProduct ? 'edit-product-component-switch-active' : 'edit-product-component-switch-inactive'
            }`}
          >
            <span
              className={`edit-product-component-switch-toggle ${
                isVariableProduct
                  ? 'edit-product-component-switch-toggle-active'
                  : 'edit-product-component-switch-toggle-inactive'
              }`}
            />
          </Switch>
        </Switch.Group>

        {/* Variants Component */}
        {isVariableProduct && (
          <Variants
            variants={variants}
            setVariants={setVariants}
            isVariableProduct={isVariableProduct}
            productWeight={productData.weight}
            weightUnitOfMeasurement={productData.weightUnitOfMeasurement}
            productDimensions={{
              length: productData.length,
              width: productData.width,
              height: productData.height,
            }}
            dimensionUnitOfMeasurement={productData.dimensionUnitOfMeasurement}
          />
        )}


        <div className="create-product-component-submit">
          <button type="submit" className="create-product-component-submit-button">
            Update Product
          </button>
        </div>
      </form>
      {showImageCropper && selectedImageForCrop && ReactDOM.createPortal(
        <ImageCropperModal
          updateAvatar={handleImageCropCompletion}
          closeModal={() => {
            setShowImageCropper(false);
            setSelectedImageForCrop(null);
            setFieldNameBeingCropped('');
          }}
          initialImage={selectedImageForCrop}
          aspectRatio={1}
          minWidth={400}
          isAvatar={false}
          isPost={false}
        />,
        document.body
      )}
    </div>
  );
};

export default EditProduct;


