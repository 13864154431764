import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';
import InventoryManagementGuide from '../../components/Documentation/InventoryManagementGuide';

const InventoryManagementDocs = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <InventoryManagementGuide />
    </div>
  );
};

export default InventoryManagementDocs;
