import React, { forwardRef } from 'react';
import { getBackgroundStyle } from 'advanced-cropper';

import { CropperTransitions, CropperImage, CropperState } from 'react-advanced-cropper';
import AdjustableImage from './AdjustableImage';

/**
 * @typedef {Object} DesiredCropperRef
 * @property {() => CropperState} getState
 * @property {() => CropperTransitions} getTransitions
 * @property {() => CropperImage} getImage
 */

/**
 * @typedef {Object} Props
 * @property {string} [className]
 * @property {DesiredCropperRef} cropper
 * @property {'anonymous' | 'use-credentials' | boolean} [crossOrigin]
 * @property {number} [brightness]
 * @property {number} [saturation]
 * @property {number} [hue]
 * @property {number} [contrast]
 */

const AdjustableCropperBackground = forwardRef((/** @type {Props} */ props, ref) => {
    const {
        className,
        cropper,
        crossOrigin,
        brightness = 0,
        saturation = 0,
        hue = 0,
        contrast = 0,
    } = props;

    const state = cropper.getState();
    const transitions = cropper.getTransitions();
    const image = cropper.getImage();

    const style = image && state ? getBackgroundStyle(image, state, transitions) : {};

    return (
        <AdjustableImage
            src={image?.src}
            crossOrigin={crossOrigin}
            brightness={brightness}
            saturation={saturation}
            hue={hue}
            contrast={contrast}
            ref={ref}
            className={className}
            style={style}
        />
    );
});

export default AdjustableCropperBackground;


