import React from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import ShopSubscriptions from '../../components/Subscription/ShopSubscriptions';

const ShopSubscriptionsOverview = () => {
  return (
    <div className="shop-subscription-page">
        <DashboardHeader />
        <div className="shop-subscription-page__content">
          <div className="shop-subscription-page__sidebar">
            <DashboardSideBar active={15} />
          </div>
          <div className="shop-subscription-page__main">
            <ShopSubscriptions />
          </div>
        </div>
    </div>
  );
};

export default ShopSubscriptionsOverview;
