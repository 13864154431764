import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ecommerceServer  } from '../../server'; 

const AddEditShippingRuleForm = ({ rule, setEditingRule, fetchShippingRules }) => {
    const [formData, setFormData] = useState({
        name: '',
        weightRange: { min: 0, max: 0 },
        dimensions: { length: 0, width: 0, height: 0 },
        region: '',
        price: 0,
        transitionTime: 0,
        minOrderPrice: 0,
        maxOrderPrice: Infinity,
    });
    const [regions, setRegions] = useState([]);
    const currentProfileName = sessionStorage.getItem('currentProfile');
    const [showDimensionsWeight, setShowDimensionsWeight] = useState(false);
    const [showOrderPrice, setShowOrderPrice] = useState(false)

    useEffect(() => {
        if (rule._id) {
            setFormData(rule);
        }
        fetchRegions();
    }, [rule]);

    const fetchRegions = async () => {
        try {
            const { data } = await axios.get(`${ecommerceServer}/region/regions`);
            
            setRegions(data);
            if (data.length > 0 && !formData.region) {
                setFormData(formData => ({ ...formData, region: data[0]._id }));
            }
        } catch (error) {
            console.error('Error fetching regions:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (['minWeight', 'maxWeight'].includes(name)) {
            setFormData(prevState => ({
                ...prevState,
                weightRange: {
                    ...prevState.weightRange,
                    [name === 'minWeight' ? 'min' : 'max']: Number(value)
                }
            }));
        } else if (['length', 'width', 'height'].includes(name)) {
            setFormData(prevState => ({
                ...prevState,
                dimensions: {
                    ...prevState.dimensions,
                    [name]: Number(value)
                }
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: name === 'transitionTime' ? Number(value) : value
            }));
        }
    };
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const endpoint = formData._id ? `${ecommerceServer}/shippingrule/shipping-rules/${formData._id}` : `${ecommerceServer}/shippingrule/shipping-rules`;
            const method = formData._id ? 'patch' : 'post';
    
           
            const config = {
                withCredentials: true,
                headers: {
                    'current-profile': currentProfileName || 'defaultProfile',
                }
            };
    
            
            await axios[method](endpoint, formData, config);
    
            setEditingRule(null);
            fetchShippingRules();
        } catch (error) {
            console.error('Error saving shipping rule:', error);
        }
    };

    const preventScrollChange = (event) => {
        event.target.blur();
      };
    
    return (
        <form onSubmit={handleSubmit} className="shipping-form">
            <label>
                Name:
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
            </label>

            <label>
                Region:
                <select
                    name="region"
                    value={formData.region}
                    onChange={handleChange}
                    className="region-select-field"
                >
                    {regions.map(region => (
                        <option key={region._id} value={region._id}>
                            {region.name}
                        </option>
                    ))}
                </select>
            </label>
            <label>
                Price:
                <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    onWheel={preventScrollChange}
                    className="input-field"
                />
            </label>
            <label>
                Transition Time (in days):
                <input
                    type="number"
                    name="transitionTime"
                    value={formData.transitionTime}
                    onChange={handleChange}
                    onWheel={preventScrollChange}
                    className="input-field"
                />
            </label>
            <button type="button" onClick={() => setShowDimensionsWeight(!showDimensionsWeight)}>
                Based on Dimensions/Weight
            </button>
            {showDimensionsWeight && (
                <>
                    <label>
                        Weight Range Min:
                        <input
                            type="number"
                            name="minWeight"
                            value={formData.weightRange.min}
                            onChange={handleChange}
                            onWheel={preventScrollChange}
                        />
                    </label>
                    <label>
                        Weight Range Max:
                        <input
                            type="number"
                            name="maxWeight"
                            value={formData.weightRange.max}
                            onChange={handleChange}
                            onWheel={preventScrollChange}
                        />
                    </label>
                    <label>
                        Dimensions Length:
                        <input
                            type="number"
                            name="length"
                            value={formData.dimensions.length}
                            onChange={handleChange}
                            onWheel={preventScrollChange}
                        />
                    </label>
                    <label>
                        Dimensions Width:
                        <input
                            type="number"
                            name="width"
                            value={formData.dimensions.width}
                            onChange={handleChange}
                            onWheel={preventScrollChange}
                        />
                    </label>
                    <label>
                        Dimensions Height:
                        <input
                            type="number"
                            name="height"
                            value={formData.dimensions.height}
                            onChange={handleChange}
                            onWheel={preventScrollChange}
                        />
                    </label>
                </>
            )}

            <button type="button" onClick={() => setShowOrderPrice(!showOrderPrice)}>
             Based on Order Price
            </button>
            {showOrderPrice && (
                <>
                    <label>
                        Minimum Order Price:
                        <input
                            type="number"
                            name="minOrderPrice"
                            value={formData.minOrderPrice}
                            onChange={handleChange}
                            onWheel={preventScrollChange}
                            className="input-field"
                        />
                    </label>
                    <label>
                        Maximum Order Price:
                        <input
                            type="number"
                            name="maxOrderPrice"
                            value={isNaN(formData.maxOrderPrice) ? '' : formData.maxOrderPrice}
                            onChange={handleChange}
                            onWheel={preventScrollChange}
                            className="input-field"
                        />
                    </label>
                </>
            )}

            <div className="form-controls">
                <button type="submit" className="save-button">Save Rule</button>
                <button type="button" onClick={() => setEditingRule(null)} className="cancel-button">Cancel</button>
            </div>
        </form>
    );
};

export default AddEditShippingRuleForm;