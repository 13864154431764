import React from "react";


const ComingSoon = () => {
  return (
    <div className="coming-soon-overlay">
      <div className="coming-soon-content">
        <h1>Coming Soon...</h1>
      </div>
    </div>
  );
};

export default ComingSoon;
