import React, { useMemo } from "react";
import { useLottie } from "lottie-react";
import animationData from "../../Assests/animations/bolt trace.json";

const Loader = () => {
  const options = useMemo(() => ({
    animationData: animationData,
    loop: true,
  }), []);

  const { View } = useLottie(options);

  return (
    <div className="loader-component-overlay">
      {View}
    </div>
  );
};

export default Loader;
