import React, { useState, useEffect } from 'react';
import { DevicePhoneMobileIcon, ComputerDesktopIcon } from '@heroicons/react/24/outline';

const AdFormatIconRow = ({ formats, selectedFormat, onFormatSelect }) => {
    const [viewType, setViewType] = useState('desktop');

    const handleViewChange = (type) => {
        setViewType(type);

        // Automatically switch to the corresponding format for the new view type
        const relevantFormat = formats.find(
            format => format.viewType === type && 
            selectedFormat.includes(format.label.split(' ')[0]) // Match based on format label
        );
        if (relevantFormat) {
            onFormatSelect(relevantFormat.name);
        }
    };

    useEffect(() => {
        console.log("Current viewType:", viewType);
        console.log("Current selectedFormat:", selectedFormat);
    }, [viewType, selectedFormat]);

    return (
        <div>
            {/* View toggle buttons */}
            <div className="ad-format-view-toggle">
                <button
                    className={`view-toggle-btn ${viewType === 'desktop' ? 'active' : ''}`}
                    onClick={() => handleViewChange('desktop')}
                >
                    <ComputerDesktopIcon className="view-toggle-icon" />
                </button>
                <button
                    className={`view-toggle-btn ${viewType === 'mobile' ? 'active' : ''}`}
                    onClick={() => handleViewChange('mobile')}
                >
                    <DevicePhoneMobileIcon className="view-toggle-icon" />
                </button>
            </div>

            {/* Display ad format icons based on viewType */}
            <div className="ad-format-icon-row">
                {formats
                    .filter(format => {
                        // Show formats that are either for the current viewType or have no viewType (applicable for both views)
                        return !format.viewType || format.viewType === viewType || format.viewType === 'both';
                    })
                    .map(format => (
                        <button
                            key={format.name}
                            className={`ad-format-icon-item ${selectedFormat === format.name ? 'active' : ''}`}
                            onClick={() => onFormatSelect(format.name)}
                        >
                            <img src={format.icon} alt={format.label} className="ad-format-icon" />
                            <span className="ad-format-label">{format.label}</span>
                        </button>
                    ))}
            </div>
        </div>
    );
};

export default AdFormatIconRow;







