import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ecommerceServer } from '../../server';
import debounce from 'lodash.debounce';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const TaxCodeSelector = ({
  selectedTaxCode = {},
  updateTaxCode,
  returnOnlyId = false,
  productCategory = '',
  productSubCategory = '',
  productName = '',
  productDescription = '',
}) => {
  const [taxCodes, setTaxCodes] = useState([]);
  const [filteredTaxCodes, setFilteredTaxCodes] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cacheKey, setCacheKey] = useState('');

  const generateCacheKey = () => {
    return `${productCategory}-${productSubCategory}-${productName}-${productDescription}`;
  };

  const fetchTaxCodes = useCallback(
    debounce(async () => {
      const newCacheKey = generateCacheKey();
      if (cacheKey === newCacheKey && taxCodes.length > 0) {
        // Use cached tax codes
        setFilteredTaxCodes(taxCodes);
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get(`${ecommerceServer}/tax/tax-codes`, {
          params: {
            productCategory,
            productSubCategory,
            productName,
            productDescription,
          },
        });
        const fetchedTaxCodes = response.data.data;
        setTaxCodes(fetchedTaxCodes);
        setFilteredTaxCodes(fetchedTaxCodes);
        setCacheKey(newCacheKey);
      } catch (error) {
        console.error('Failed to fetch tax codes:', error);
      }
      setIsLoading(false);
    }, 500),
    [productCategory, productSubCategory, productName, productDescription, cacheKey]
  );

  // Fetch tax codes when product data changes
  useEffect(() => {
    fetchTaxCodes();
  }, [productCategory, productSubCategory, productName, productDescription, fetchTaxCodes]);

  const handleInputFocus = () => {
    setShowDropdown(true);
    if (taxCodes.length === 0) {
      fetchTaxCodes();
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setShowDropdown(true);
  };

  // Filter tax codes based on user input
  useEffect(() => {
    let codesToFilter = taxCodes;

    if (inputValue) {
      const inputValueLower = inputValue.toLowerCase();
      codesToFilter = codesToFilter.filter((code) => {
        const nameMatch = code.name
          ? code.name.toLowerCase().includes(inputValueLower)
          : false;
        const keywordsMatch = code.keywords
          ? code.keywords.toLowerCase().includes(inputValueLower)
          : false;
        return nameMatch || keywordsMatch;
      });
    }

    setFilteredTaxCodes(codesToFilter);
  }, [inputValue, taxCodes]);

  // Update input value when selectedTaxCode changes
  useEffect(() => {
    if (selectedTaxCode) {
      let selected;
      if (typeof selectedTaxCode === 'string') {
        // selectedTaxCode is the tax code ID
        selected = taxCodes.find((code) => code.id === selectedTaxCode);
      } else if (typeof selectedTaxCode === 'object' && selectedTaxCode.id) {
        // selectedTaxCode is an object with id
        selected = taxCodes.find((code) => code.id === selectedTaxCode.id);
      }
      if (selected) {
        setInputValue(selected.name || '');
      } else if (typeof selectedTaxCode === 'string') {
        setInputValue(selectedTaxCode);
      }
    }
  }, [selectedTaxCode, taxCodes]);

  const handleSelectCode = (code) => {
    if (returnOnlyId) {
      updateTaxCode(code.id); // Update taxCode as a string ID
    } else {
      updateTaxCode({
        id: code.id,
        name: code.name,
      });
    }
    setInputValue(code.name);
    setShowDropdown(false);
  };

  return (
    <div className="tax-code-selector-container">
      <label htmlFor="taxCode" className="tax-code-selector-label">
        Tax Code <span className="tax-code-selector-required">*</span>
        <a href="/docs/tax-codes" target="_blank" className="help-icon-link" aria-label="Help about Tax Codes">
          <QuestionMarkCircleIcon className="help-icon" />
          <span className="help-text">Help</span>
        </a>
      </label>
      <input
        type="text"
        placeholder="Select the tax code for your product..."
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={() => setTimeout(() => setShowDropdown(false), 300)}
        className="tax-code-selector-input"
      />
      {showDropdown && (
        <div className="tax-code-selector-dropdown">
          {isLoading ? (
            <div className="tax-code-selector-loading">Loading...</div>
          ) : (
            filteredTaxCodes.slice(0, 10).map((code) => (
              <div
                key={code.id}
                onClick={() => handleSelectCode(code)}
                className="tax-code-selector-item"
              >
                <span className="tax-code-selector-item-name">{code.name}</span>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default TaxCodeSelector;




