import React from 'react';

const BuyerProtectionReturnPolicy = () => {
  return (
    <div className="legal-docs-container">
      <h1 className="legal-docs-title">BuzzVibe Buyer Protection & Return Policy</h1>

      <p className="legal-docs-text">
        For any disputes, inquiries, or questions, please contact us at: <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a>
      </p>
      <p className="legal-docs-text">
        Our current response time is typically 24-48 hours.
      </p>

      <section>
        <h2 className="legal-docs-subtitle">Delayed Shipping (delayed dispatch)</h2>
        <p className="legal-docs-text">
          At BuzzVibe, we ensure customer satisfaction with our comprehensive Buyer Protection policy.
          If a product does not have an active tracking number by the stated "Ships Within" timeframe in the product description (plus an additional one-week grace period), you are entitled to request a full refund of the purchase price. Simply reach out to us, and we’ll assist promptly.
        </p>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Product Representation Issues</h2>
        <p className="legal-docs-text">
          If an item you receive differs significantly from the description or photos provided on our platform, you can claim a full refund of the purchase price.
          To qualify, photo evidence must be submitted, and a claim must be initiated within 10 days of the delivery date. Your satisfaction is our priority.
        </p>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Damaged or Lost Packages</h2>
        <ul className="legal-docs-list">
          <li>
            We’ve got you covered in the rare event that a package arrives damaged or gets lost in transit.
          </li>
          <li>
            <strong>Damaged Items:</strong> If your package arrives with damage, you are eligible for a full refund. Please provide photo evidence and open a claim within 10 days of delivery.
          </li>
          <li>
            <strong>Lost Items:</strong> If tracking information confirms that a package is lost (but not delivered), a dispute must be opened within 90 days of the original shipment date. Refunds will be processed if the package cannot be located within a reasonable period.
          </li>
          <li>
            Please note: BuzzVibe is not liable for packages marked as successfully delivered by the carrier but reported as missing or stolen.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Size Exchanges</h2>
        <ul className="legal-docs-list">
          <li>
            We understand that sizing can sometimes be tricky, so we offer size exchanges for eligible products as long as the desired size is available on BuzzVibe.
          </li>
          <li>
            The returned product must be in its original, unused condition with all tags intact.
          </li>
          <li>
            A carrier rate return shipping fee applies, and exchange requests must be made within 10 days of delivery.
          </li>
          <li>
            If the requested size or product is no longer available, the sale will be considered final.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">Final Sale Policy</h2>
        <ul className="legal-docs-list">
          <li>
            Orders that do not meet the criteria for refund or exchange outlined above are considered Final Sale.
          </li>
        </ul>
        <p className="legal-docs-text">
          Thank you for choosing BuzzVibe. We’re committed to making your shopping experience seamless and enjoyable!
        </p>
      </section>
    </div>
  );
};

export default BuyerProtectionReturnPolicy;
