import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';
import CreateProductDoc from '../../components/Documentation/CreateProductDoc';

const CreateProductDocPage = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <CreateProductDoc />
    </div>
  );
};

export default CreateProductDocPage;

