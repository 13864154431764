import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ecommerceServer } from '../../server';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import MiniLoader from '../Layout/MiniLoader'; // Ensure this path is correct

const OrderTracking = () => {
  const { id } = useParams(); // Assuming the order ID is in the URL
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTrackingInfo = async () => {
      try {
        const response = await axios.get(`${ecommerceServer}/shipping/${id}/tracking`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setTrackingInfo(response.data.trackingInfo);
      } catch (error) {
        console.error('Error fetching tracking info:', error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || 'Failed to fetch tracking information.');
      } finally {
        setLoading(false);
      }
    };

    fetchTrackingInfo();
  }, [id]);

  if (loading) {
    return <MiniLoader />;
  }

  if (!trackingInfo) {
    return <p className="shipping-tracker-no-info">No tracking information available for this order.</p>;
  }

  return (
    <div className="shipping-tracker-order-tracking-container">
      <h3>Package Tracking</h3>
      <p><strong>Carrier:</strong> {trackingInfo.carrier_code}</p>
      <p><strong>Tracking Number:</strong> {trackingInfo.tracking_number}</p>
      <p><strong>Status:</strong> {trackingInfo.status}</p>
      <div className="shipping-tracker-order-tracking-history">
        <h4>Tracking History:</h4>
        <ul>
          {trackingInfo.tracking_events.map((event, index) => (
            <li key={index}>
              <p><strong>Date:</strong> {new Date(event.timestamp).toLocaleString()}</p>
              <p><strong>Status:</strong> {event.status}</p>
              {event.description && <p><strong>Description:</strong> {event.description}</p>}
            </li>
          ))}
        </ul>
      </div>
      {trackingInfo.tracking_link && (
        <a
          href={trackingInfo.tracking_link}
          target="_blank"
          rel="noopener noreferrer"
          className="shipping-tracker-tracking-link"
        >
          View on Carrier's Site
        </a>
      )}
    </div>
  );
};

export default OrderTracking;
