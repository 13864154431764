import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { adsServer } from "../../server";

const SponsoredShopsCarouselPropsMobile = ({ ads }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const shopRefs = useRef(new Map()); 

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/click/${adId}`, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Click logged for shop:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };


    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Viewed impression logged for shop:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;

                        // Start a timer for 1 second to log viewed impression
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);

                        // Store the timer in the DOM element
                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer); 
                    }
                });
            },
            { threshold: 0.5 } 
        );

        // Observe the current shop ad in the carousel
        const currentShopElement = shopRefs.current.get(ads[currentIndex]?._id);
        if (currentShopElement) {
            observer.observe(currentShopElement);
        }

        return () => {
            observer.disconnect(); 
        };
    }, [currentIndex, ads]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % ads.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + ads.length) % ads.length);
    };

    if (ads.length === 0) {
        return <div className="sponsored-shops-carousel-loading"></div>;
    }

    return (
        <div className="sponsored-shops-carousel-container">
            <>
                <div className="sponsored-shops-carousel-wrapper">
                    {/* Only show the current shop ad */}
                    {ads[currentIndex] && (
                        <div
                            key={ads[currentIndex]._id}
                            className="sponsored-shops-carousel-paper-mobile"
                            style={{ backgroundImage: `url(${ads[currentIndex].shop.banner?.url})` }}
                            data-ad-id={ads[currentIndex]._id} // Track ad ID for IntersectionObserver
                            ref={(el) => el && shopRefs.current.set(ads[currentIndex]._id, el)} // Save ref for IntersectionObserver
                        >
                            <ShopItem shop={ads[currentIndex].shop} logAdClick={() => logAdClick(ads[currentIndex]._id)} />
                        </div>
                    )}
                </div>
                {ads.length > 1 && (
                    <>
                        <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>‹</button>
                        <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>›</button>
                    </>
                )}
            </>
        </div>
    );
};

const ShopItem = ({ shop, logAdClick }) => {
    return (
        <div className="sponsored-shops-carousel-content">
            <img src={shop.avatar?.url} alt={shop.name} className="sponsored-shops-carousel-avatar-mobile" />
            <h2 className="sponsored-shops-carousel-title">{shop.name}</h2>
            <Link
                to={`/shop/${shop.handle}`}
                onClick={logAdClick}
                className="sponsored-shops-carousel-button"
            >
                Visit Store
            </Link>
        </div>
    );
};

export default SponsoredShopsCarouselPropsMobile;