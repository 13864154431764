import React, { useState } from "react";
import { AiOutlineFolderAdd, AiOutlineGift } from "react-icons/ai";
import { FiPackage, FiShoppingBag, FiCreditCard } from "react-icons/fi";
import { MdOutlineEventNote, MdOutlineSubscriptions, MdOutlineAdsClick } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { CiMoneyBill, CiSettings, CiDeliveryTruck } from "react-icons/ci";
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileSwitchButtonShop from "../ShopSwitch";

const DashboardSideBar = ({ active }) => {
  const { seller } = useSelector((state) => state.seller);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  // Define menu categories with their respective items
  const menuCategories = [
    {
      category: "Your Shop",
      items: [
        { id: 1, path: `/dashboard/${seller?.handle}`, icon: RxDashboard, label: "Dashboard" },
        { id: 2, path: "/dashboard-messages", icon: BiMessageSquareDetail, label: "Shop Inbox" },
        { id: 3, path: "/dashboard-subscription", icon: MdOutlineSubscriptions, label: "Subscription" },
        { id: 4, path: "/settings", icon: CiSettings, label: "Settings" },
      ],
    },
    {
      category: "Products",
      items: [
        { id: 5, path: "/dashboard-shipping", icon: CiDeliveryTruck, label: "Shipping" },
        { id: 6, path: "/dashboard-create-product", icon: AiOutlineFolderAdd, label: "Create Product" },
        { id: 7, path: "/dashboard-products", icon: FiPackage, label: "All Products" },
      ],
    },
    {
      category: "Marketing",
      items: [
        { id: 8, path: "/dashboard-create-ad", icon: MdOutlineAdsClick, label: "Ad Center" },
        { id: 9, path: "/dashboard-create-brand", icon: AiOutlineFolderAdd, label: "Brand Manager" },
        { id: 10, path: "/dashboard-create-event", icon: MdOutlineEventNote, label: "Create Sale Event" },
        { id: 11, path: "/dashboard-events", icon: MdOutlineEventNote, label: "All Sales Events" },
      ],
    },
    {
      category: "Your Wallet",
      items: [
        { id: 12, path: "/dashboard-orders", icon: FiShoppingBag, label: "All Orders" },
        { id: 13, path: "/dashboard-purchases", icon: FiShoppingBag, label: "All Purchases (B2B)" },
        { id: 14, path: "/dashboard-payments", icon: FiCreditCard, label: "Payments Dashboard" },
        { id: 15, path: "/payment-method", icon: FiCreditCard, label: "Payment Methods" },
        { id: 16, path: "/dashboard-coupons", icon: AiOutlineGift, label: "Discount Codes" },
        { id: 17, path: "/dashboard-withdraw-money", icon: CiMoneyBill, label: "Withdraw Money" },
        { id: 18, path: "/dashboard-refunds", icon: HiOutlineReceiptRefund, label: "Refunds" },
      ],
    },
  ];

  return (
    <>
      {/* Mobile Button */}
      <div className="mobile-sidebar">
        <div className="mobile-button-container">
          <button className="mobile-sidebar-button" onClick={toggleMobileMenu}>
            <img
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724854900/memes/evajdtncfvfdnrihmx6t.png"
              alt="Menu Icon"
              className="mobile-sidebar-icon"
            />
          </button>
          <ProfileSwitchButtonShop className="sidebar-profile-toolbar-avatar" />
        </div>

        {/* Modal displaying the icons */}
        {isMobileMenuOpen && (
          <>
            <div className="mobile-background-overlay" onClick={toggleMobileMenu}></div>
            <div className="mobile-sidebar-modal">
              <nav className="mobile-sidebar-nav">
                {menuCategories.map((category, catIndex) => (
                  <div key={catIndex} className="mobile-sidebar-category">
                    <div className="mobile-sidebar-category-header">{category.category}</div>
                    {category.items.map((item) => (
                      <Link
                        to={item.path}
                        key={item.id}
                        className="mobile-sidebar-item"
                        onClick={toggleMobileMenu}
                      >
                        <item.icon className="mobile-sidebar-icon" />
                        <span className="mobile-sidebar-item-name">{item.label}</span>
                      </Link>
                    ))}
                  </div>
                ))}
                <div className="mobile-sidebar-spacer"></div>
              </nav>
            </div>
          </>
        )}
      </div>

      {/* Sidebar for Larger Screens */}
      <div className="shop-dashboard-sidebar-container">
        {menuCategories.map((category, catIndex) => (
          <div key={catIndex} className="shop-dashboard-sidebar-category">
            <div className="shop-dashboard-sidebar-category-header">{category.category}</div>
            {category.items.map((item) => (
              <div className="shop-dashboard-sidebar-item" key={item.id}>
                <Link to={item.path} className="shop-dashboard-sidebar-link">
                  <item.icon className="icon" color={active === item.id ? "#36d300" : "#555"} />
                  <h5 className={active === item.id ? "shop-dashboard-sidebar-link active" : "shop-dashboard-sidebar-link inactive"}>
                    {item.label}
                  </h5>
                </Link>
              </div>
            ))}
          </div>
        ))}
        <div className="shop-dashboard-sidebar-profile-switch">
          <ProfileSwitchButtonShop />
        </div>
      </div>
    </>
  );
};

export default DashboardSideBar;