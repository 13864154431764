import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { adsServer } from "../../server";
import Loader from "../Layout/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShopAdSetModal from './ShopAdSetModal'; 

const SponsorShopCampaign = ({ onSave, adType, details }) => {
    const [adSpendPerDay, setAdSpendPerDay] = useState(details.adSpend || 50);
    const [adDuration, setAdDuration] = useState(details.adDuration || 7);
    const [totalAdSpend, setTotalAdSpend] = useState(details.adSpend * details.adDuration || 350);
    const [predictedClicks, setPredictedClicks] = useState({ min: 0, max: 0 });
    const [predictedImpressions, setPredictedImpressions] = useState({ min: 0, max: 0 });
    const [displayedPredictedClicks, setDisplayedPredictedClicks] = useState({ min: 0, max: 0 });
    const [displayedPredictedImpressions, setDisplayedPredictedImpressions] = useState({ min: 0, max: 0 });
    const { seller } = useSelector((state) => state.seller);
    const [isLoading, setIsLoading] = useState(false);
    const [isAdSetModalOpen, setIsAdSetModalOpen] = useState(false);
    const [adSet, setAdSet] = useState(details.adSet || {}); 
    const [isAdSetSaved, setIsAdSetSaved] = useState(!!details.adSet); 
    const debounceRef = useRef(null);
    const [activeTimeFrame, setActiveTimeFrame] = useState('total');

    // Pre-fill fields if details are passed for editing
    useEffect(() => {
        if (details) {
            setAdSpendPerDay(details.setAdSpendPerDay || 50);
            setAdDuration(details.adDuration || 7);
            setTotalAdSpend(details.adSpend * details.adDuration || 350);
        }
    }, [details]);

    useEffect(() => {
        setTotalAdSpend(adSpendPerDay * adDuration);
    }, [adSpendPerDay, adDuration]);

    useEffect(() => {
        fetchPredictions(adSpendPerDay * adDuration);
    }, [adSpendPerDay, adDuration]);

    const fetchPredictions = async (spend) => {
        if (debounceRef.current) clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(async () => {
            try {
                const { data } = await axios.post(`${adsServer}/sponsored/predict`, { adSpend: spend }, { withCredentials: true });
                if (data) {
                    setPredictedClicks(data.predictedClicks);
                    setPredictedImpressions(data.predictedImpressions);
                    updateDisplayValues('total');
                }
            } catch (error) {
                console.error('Error fetching predictions:', error);
                toast.error('Failed to fetch predictions');
            }
        }, 500);
    };

    const updateDisplayValues = (mode) => {
        const factor = mode === 'daily' ? 1 / adDuration : 1;
        setDisplayedPredictedClicks({
            min: Math.round(predictedClicks.min * factor),
            max: Math.round(predictedClicks.max * factor)
        });
        setDisplayedPredictedImpressions({
            min: Math.round(predictedImpressions.min * factor),
            max: Math.round(predictedImpressions.max * factor)
        });
    };

    const handleDisplayModeChange = (mode) => {
        setActiveTimeFrame(mode); 
        updateDisplayValues(mode);
    };

    const openAdSetModal = () => {
        setIsAdSetModalOpen(true);
    };

    const handleAdSetSave = (newAdSet) => {
        setAdSet(newAdSet);
        setIsAdSetSaved(true);
        setIsAdSetModalOpen(false);
    };

    const handleSaveAd = () => {
        const details = {
            shopId: seller._id,
            shopName: seller.name,  
            shopImage: seller.banner?.url,
            adSpend: totalAdSpend,
            adDuration,
            adSet,
        };
        
        onSave(adSet, details, adType); // Pass the saved adSet and details

        toast.success("Shop ad configuration saved.");
    };

    // Determine if the Save button should be enabled
    const isSaveEnabled = isAdSetSaved;

    return (
        <>
            {isLoading ? <Loader /> : (
                <div className="sponsor-shop-component-container">
                   
                    <form onSubmit={handleSaveAd}>
                        <h2 className="sponsor-shop-component-title">Sponsor Your Shop</h2>
                        
                        <div className="sponsor-product-component-slider">
                            <label>Set Ad Spend per Day ($):</label>
                            <input 
                                type="text"
                                value={adSpendPerDay}
                                onChange={(e) => {
                                    const newSpend = Number(e.target.value);
                                    setAdSpendPerDay(newSpend);
                                    fetchPredictions(newSpend * adDuration); 
                                }}
                                className="sponsor-shop-component-input"
                            />
                            <input 
                                type="range" 
                                min="1" 
                                max="1000"
                                value={adSpendPerDay}
                                onChange={(e) => {
                                    const newSpend = Number(e.target.value);
                                    setAdSpendPerDay(newSpend);
                                    fetchPredictions(newSpend * adDuration); 
                                }}
                                className="sponsor-product-component-range-slider"
                            />
                        </div>

                        <div className="sponsor-product-component-slider">
                            <label>Set Ad Duration (Days):</label>
                            <input 
                                type="text"
                                value={adDuration}
                                onChange={(e) => {
                                    const newDuration = Number(e.target.value);
                                    setAdDuration(newDuration);
                                    fetchPredictions(adSpendPerDay * newDuration); 
                                }}
                                className="sponsor-shop-component-input"
                            />
                            <input 
                                type="range" 
                                min="1" 
                                max="30"
                                value={adDuration}
                                onChange={(e) => {
                                    const newDuration = Number(e.target.value);
                                    setAdDuration(newDuration);
                                    fetchPredictions(adSpendPerDay * newDuration); 
                                }}
                                className="sponsor-product-component-range-slider"
                            />
                        </div>

                        <div className="sponsor-shop-component-total-spend">
                            <label>Total Ad Spend ($): {totalAdSpend}</label>
                        </div>

                        <div className="sponsor-product-component-display-mode">
                        <button
                            type="button"
                            onClick={() => handleDisplayModeChange('daily')}
                            className={`time-button ${activeTimeFrame === 'daily' ? 'active-time-button' : ''}`}
                        >
                            Daily
                        </button>
                        <button
                            type="button"
                            onClick={() => handleDisplayModeChange('total')}
                            className={`time-button ${activeTimeFrame === 'total' ? 'active-time-button' : ''}`}
                        >
                            Total
                        </button>
                    </div>

                        <div className="sponsor-shop-component-predictions">
                            {displayedPredictedClicks.min !== 0 || displayedPredictedClicks.max !== 0 ? (
                                <>
                                    <div className="sponsor-shop-component-prediction-item">
                                        <label>Predicted Clicks Range:</label>
                                        <span>{displayedPredictedClicks.min} to {displayedPredictedClicks.max}</span>
                                    </div>
                                    <div className="sponsor-shop-component-prediction-item">
                                        <label>Predicted Impressions Range:</label>
                                        <span>{displayedPredictedImpressions.min} to {displayedPredictedImpressions.max}</span>
                                    </div>
                                </>
                            ) : (
                                <div className="sponsor-shop-component-null-message">
                                    Please choose your ad spend/time duration
                                </div>
                            )}
                        </div>

                        <div className="customize-ad-set-button-container">
                            <button type="button" onClick={openAdSetModal} className="customize-ad-set-button">
                                Customize Ad Set
                            </button>
                        </div>

                        <button 
                            type="button" 
                            className={`sponsor-shop-component-submit-button ${isSaveEnabled ? 'enabled' : 'disabled'}`} 
                            onClick={isSaveEnabled ? handleSaveAd : null} 
                            disabled={!isSaveEnabled}
                        >
                            Save Ad Configuration
                        </button>
                    </form>

                    <ShopAdSetModal 
                        isOpen={isAdSetModalOpen} 
                        onClose={() => setIsAdSetModalOpen(false)} 
                        onSave={handleAdSetSave} 
                        adFormats={['Top Banner Ad', 'Top Banner Mobile Ad', 'Banner Ad', 'Banner Mobile Ad', 'Sponsored Shops', 'Sponsored Shop Carousel', 'Dual Carousel', 'Quad Carousel', 'Sponsored Feed Ads']}
                        shopImage={seller?.banner?.url}
                        shopName={seller?.name}
                        shopAvatar={seller?.avatar?.url}
                    />
                </div>
            )}
        </>
    );
};

export default SponsorShopCampaign;


