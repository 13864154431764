import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ecommerceServer } from '../../server';
import AddEditShippingRuleForm from './AddEditShippingRuleForm';
import { PlusIcon } from '@heroicons/react/24/outline';

const ShippingRules = () => {
    const [shippingRules, setShippingRules] = useState([]);
    const [editingRule, setEditingRule] = useState(null);
    const currentProfileName = sessionStorage.getItem('currentProfile');

    useEffect(() => {
        fetchShippingRules();
    }, []);

    const fetchShippingRules = async () => {
        try {
            const response = await axios.get(`${ecommerceServer}/shippingrule/shipping-rules`, { 
                withCredentials: true,
                headers: {
                  'current-profile': currentProfileName || 'defaultProfile',
                }, 
            });
            setShippingRules(response.data);
        } catch (error) {
            console.error('Error fetching shipping rules:', error);
        }
    };

    const handleEdit = (rule) => {
        setEditingRule(rule);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${ecommerceServer}/shippingrule/shipping-rules/${id}`, { 
                withCredentials: true,
                headers: {
                  'current-profile': currentProfileName || 'defaultProfile',
                }, 
            });
            fetchShippingRules();
        } catch (error) {
            console.error('Error deleting shipping rule:', error);
        }
    };

    return (
        <div className="shipping-rules-component">
            {editingRule ? (
                <AddEditShippingRuleForm rule={editingRule} setEditingRule={setEditingRule} fetchShippingRules={fetchShippingRules} />
            ) : (
                <button className="shipping-rules-component-add-rule-btn" onClick={() => setEditingRule({})}>
                <PlusIcon className="shipping-rules-component-add-rule-icon" /> Add New Rate
                </button>
            )}
            <table className="shipping-rules-component-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Region</th>
                        <th>Transit Time (days)</th>
                        <th>Min Weight (kg)</th>
                        <th>Max Weight (kg)</th>
                        <th>Dimensions (LxWxH)</th>
                        <th>Min Order Price ($)</th>
                        <th>Max Order Price ($)</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {shippingRules.map((rule) => (
                        <tr key={rule._id}>
                            <td>{rule.name}</td>
                            <td>{rule.price.toFixed(2)}</td>
                            <td>{rule.region.name}</td>
                            <td>{rule.transitionTime}</td>
                            <td>{rule.weightRange?.min || 'N/A'}</td>
                            <td>{rule.weightRange?.max || 'N/A'}</td>
                            <td>{`${rule.dimensions?.length || 'N/A'} x ${rule.dimensions?.width || 'N/A'} x ${rule.dimensions?.height || 'N/A'}`}</td>
                            <td>{rule.minOrderPrice}</td>
                            <td>{rule.maxOrderPrice !== null ? rule.maxOrderPrice : 'No limit'}</td>
                            <td>
                                <button onClick={() => handleEdit(rule)}>Edit</button>
                                <button onClick={() => handleDelete(rule._id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ShippingRules;

