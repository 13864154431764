import React from 'react';

const BrandCreationGuide = () => {
  return (
    <div className="doc-container">
      <h1 className="doc-title">Guide to Creating a Brand</h1>
      <p className="doc-intro">
        This guide will help you create a new brand for your shop, including adding brand details, uploading images, and managing your brand effectively.
      </p>

      <h2 className="doc-step h2">Step-by-Step Guide to Creating a Brand</h2>
      <ol className="doc-steps">
        <li className="doc-step">
          <h2>Enter Brand Information</h2>
          <p>To create a new brand, follow these steps:</p>
          <ul>
            <li><strong>Enter Brand Name</strong>: Provide the name of your brand in the <strong>Name</strong> field.</li>
            <li><strong>Enter Description</strong>: Add a description for your brand to provide more information about it.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Upload Brand Images</h2>
          <p>You can upload images to represent your brand:</p>
          <ul>
            <li><strong>Cover Image</strong>: Click the <strong>Cover Image</strong> field to upload a cover image for your brand. This image will be displayed prominently on your brand page.</li>
            <li><strong>Avatar Image</strong>: Click the <strong>Avatar Image</strong> field to upload an avatar image for your brand. This image will be used as the brand’s icon.</li>
            <li><strong>Preview Images</strong>: After selecting images, you will see a preview to ensure they are correct before submission.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Submit the Brand</h2>
          <p>Once you have entered all the information and uploaded the images, follow these steps to complete the brand creation:</p>
          <ul>
            <li><strong>Click Create Brand</strong>: Click the <strong>Create Brand</strong> button to submit your brand details.</li>
            <li><strong>Confirmation</strong>: If successful, you will see a confirmation message indicating that the brand was created successfully.</li>
          </ul>
        </li>
      </ol>

      <div className="doc-additional">
        <h2>Important Tips</h2>
        <ul>
          <li><strong>Image Quality</strong>: Ensure that the images you upload are high quality to give your brand a professional appearance.</li>
          <li><strong>Brand Description</strong>: Write a clear and concise description that effectively communicates what your brand represents.</li>
          <li><strong>Review Before Submitting</strong>: Double-check all details before clicking the submit button to avoid errors.</li>
        </ul>
      </div>
    </div>
  );
};

export default BrandCreationGuide;
