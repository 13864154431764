import React from 'react';

const SKUExplanation = () => {
  return (
    <div className="doc-container">
      <h1 className="doc-title">What is a SKU?</h1>
      <p className="doc-intro">
        A Stock Keeping Unit (SKU) is a unique identifier used to track and manage inventory. It helps distinguish each product variant by combining attributes such as size, color, and style. SKUs are crucial for efficient inventory management, allowing businesses to quickly locate and organize their products.
      </p>
      <p className="doc-intro">
        A recommended SKU format typically includes a combination of letters and numbers that reflect product attributes. For example, you might use a format like "BRD-TSH-RED-M" where "BRD" represents the brand, "TSH" represents the product type (T-shirt), "RED" is the color, and "M" stands for medium size. Keeping SKUs consistent and descriptive makes managing your inventory much easier.
      </p>
    </div>
  );
};

export default SKUExplanation;
