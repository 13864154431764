import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mode: 'light',
  isFriendListPanelOpen: false,
  isNotificationSidebarOpen: false,
  isCartOpen: false,
  isWishlistOpen: false,
  isAvatarDropdownOpen: false,  
  isWalletSidebarOpen: false,
  walletSidebarType: null,
  isTopLevelDropdownOpen: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === 'light' ? 'dark' : 'light';
    },
    toggleTopLevelDropdown: (state) => {
      // Close other UI elements when opening `TopLevelDropdown`
      if (state.isFriendListPanelOpen) state.isFriendListPanelOpen = false;
      if (state.isNotificationSidebarOpen) state.isNotificationSidebarOpen = false;
      if (state.isCartOpen) state.isCartOpen = false;
      if (state.isWishlistOpen) state.isWishlistOpen = false;
      if (state.isAvatarDropdownOpen) state.isAvatarDropdownOpen = false;

      state.isTopLevelDropdownOpen = !state.isTopLevelDropdownOpen;
    },
    setTopLevelDropdownOpen: (state, action) => {
      if (state.isFriendListPanelOpen) state.isFriendListPanelOpen = false;
      if (state.isNotificationSidebarOpen) state.isNotificationSidebarOpen = false;
      if (state.isCartOpen) state.isCartOpen = false;
      if (state.isWishlistOpen) state.isWishlistOpen = false;
      if (state.isAvatarDropdownOpen) state.isAvatarDropdownOpen = false;

      state.isTopLevelDropdownOpen = action.payload;
    },
    toggleFriendListPanel: (state) => {
      console.log("Friend list panel open state before:", state.isFriendListPanelOpen);
      if (state.isNotificationSidebarOpen) {
        state.isNotificationSidebarOpen = false;
      }
      if (state.isCartOpen) {
        state.isCartOpen = false;
      }
      if (state.isWishlistOpen) {
        state.isWishlistOpen = false;
      }
      if (state.isAvatarDropdownOpen) {
        state.isAvatarDropdownOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isFriendListPanelOpen = !state.isFriendListPanelOpen;
      console.log("Friend list panel open state after:", state.isFriendListPanelOpen);
    },    
    setFriendListPanelOpen: (state, action) => {
      if (state.isNotificationSidebarOpen) {
        state.isNotificationSidebarOpen = false;
      }
      if (state.isCartOpen) {
        state.isCartOpen = false;
      }
      if (state.isWishlistOpen) {
        state.isWishlistOpen = false;
      }
      if (state.isAvatarDropdownOpen) {
        state.isAvatarDropdownOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isFriendListPanelOpen = action.payload;
    },
    toggleNotificationSidebar: (state) => {
      console.log("Notifications list panel open state before:", state.isNotificationSidebarOpen);
      if (state.isFriendListPanelOpen) {
        state.isFriendListPanelOpen = false;
      }
      if (state.isCartOpen) {
        state.isCartOpen = false;
      }
      if (state.isWishlistOpen) {
        state.isWishlistOpen = false;
      }
      if (state.isAvatarDropdownOpen) {
        state.isAvatarDropdownOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isNotificationSidebarOpen = !state.isNotificationSidebarOpen;
      console.log("Notifications list panel open state after:", state.isNotificationSidebarOpen);
    },
    setNotificationSidebarOpen: (state, action) => {
      if (state.isFriendListPanelOpen) {
        state.isFriendListPanelOpen = false;
      }
      if (state.isCartOpen) {
        state.isCartOpen = false;
      }
      if (state.isWishlistOpen) {
        state.isWishlistOpen = false;
      }
      if (state.isAvatarDropdownOpen) {
        state.isAvatarDropdownOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isNotificationSidebarOpen = action.payload;
    },
    toggleCart: (state) => {
      if (state.isFriendListPanelOpen) {
        state.isFriendListPanelOpen = false;
      }
      if (state.isNotificationSidebarOpen) {
        state.isNotificationSidebarOpen = false;
      }
      if (state.isWishlistOpen) {
        state.isWishlistOpen = false;
      }
      if (state.isAvatarDropdownOpen) {
        state.isAvatarDropdownOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isCartOpen = !state.isCartOpen;
    },
    setCartOpen: (state, action) => {
      if (state.isFriendListPanelOpen) {
        state.isFriendListPanelOpen = false;
      }
      if (state.isNotificationSidebarOpen) {
        state.isNotificationSidebarOpen = false;
      }
      if (state.isWishlistOpen) {
        state.isWishlistOpen = false;
      }
      if (state.isAvatarDropdownOpen) {
        state.isAvatarDropdownOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isCartOpen = action.payload;
    },
    toggleWishlist: (state) => {
      if (state.isFriendListPanelOpen) {
        state.isFriendListPanelOpen = false;
      }
      if (state.isNotificationSidebarOpen) {
        state.isNotificationSidebarOpen = false;
      }
      if (state.isCartOpen) {
        state.isCartOpen = false;
      }
      if (state.isAvatarDropdownOpen) {
        state.isAvatarDropdownOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isWishlistOpen = !state.isWishlistOpen;
    },
    setWishlistOpen: (state, action) => {
      if (state.isFriendListPanelOpen) {
        state.isFriendListPanelOpen = false;
      }
      if (state.isNotificationSidebarOpen) {
        state.isNotificationSidebarOpen = false;
      }
      if (state.isCartOpen) {
        state.isCartOpen = false;
      }
      if (state.isAvatarDropdownOpen) {
        state.isAvatarDropdownOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isWishlistOpen = action.payload;
    },
    toggleAvatarDropdown: (state) => {
      if (state.isFriendListPanelOpen) {
        state.isFriendListPanelOpen = false;
      }
      if (state.isNotificationSidebarOpen) {
        state.isNotificationSidebarOpen = false;
      }
      if (state.isCartOpen) {
        state.isCartOpen = false;
      }
      if (state.isWishlistOpen) {
        state.isWishlistOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isAvatarDropdownOpen = !state.isAvatarDropdownOpen;
    },
    setAvatarDropdownOpen: (state, action) => {
      if (state.isFriendListPanelOpen) {
        state.isFriendListPanelOpen = false;
      }
      if (state.isNotificationSidebarOpen) {
        state.isNotificationSidebarOpen = false;
      }
      if (state.isCartOpen) {
        state.isCartOpen = false;
      }
      if (state.isWishlistOpen) {
        state.isWishlistOpen = false;
      }
      if (state.isTopLevelDropdownOpen) state.isTopLevelDropdownOpen = false;
      state.isAvatarDropdownOpen = action.payload;
    },
    toggleWalletSidebar: (state) => { 
      state.isNotificationSidebarOpen = false;
      state.isFriendListPanelOpen = false;
      state.isCartOpen = false;
      state.isWishlistOpen = false;
      state.isAvatarDropdownOpen = false;
      state.isTopLevelDropdownOpen = false;
      state.isWalletSidebarOpen = !state.isWalletSidebarOpen;
      state.walletSidebarType = null; 
    },
    setWalletSidebarOpen: (state, action) => {
      state.isWalletSidebarOpen = action.payload.isOpen;
      state.walletSidebarType = action.payload.type || null;
    },
  },
});

export const { 
  setMode, 
  toggleFriendListPanel, 
  setFriendListPanelOpen, 
  toggleNotificationSidebar, 
  setNotificationSidebarOpen, 
  toggleCart, 
  setCartOpen, 
  toggleWishlist, 
  setWishlistOpen,
  toggleAvatarDropdown,
  setAvatarDropdownOpen,
  toggleWalletSidebar,
  setWalletSidebarOpen,
  toggleTopLevelDropdown,
  setTopLevelDropdownOpen,
} = uiSlice.actions;

export default uiSlice.reducer;




