import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import NewsletterSignup from "../NewsletterSignup";

const Footer = () => {
  return (
    <div className="footer-component-bg">
      <NewsletterSignup/>
      <div className="footer-component-grid">
        <ul className="footer-component-column footer-component-logo-column">
          <img
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1706549678/Asset-1_yjnmot.png"
                alt="BuzzVibe"
          />
          <br />
          <p>Culture Marketplace</p>
          <div className="footer-component-social-icons">
            <AiFillFacebook size={25} className="footer-component-icon" />
            <AiOutlineTwitter size={25} className="footer-component-icon" />
            <AiFillInstagram size={25} className="footer-component-icon" />
            <AiFillYoutube size={25} className="footer-component-icon" />
          </div>
        </ul>

        <ul className="footer-component-column">
          <h1 className="footer-component-title">Company</h1>
          {footerProductLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="footer-component-link"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>


        <ul className="footer-component-column">
          <h1 className="footer-component-title">Support</h1>
          {footerSupportLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="footer-component-link"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="footer-component-bottom">
        <span>© 2024 BuzzVibe. All rights reserved.</span>
        
      </div>
    </div>
  );
};

export default Footer;

