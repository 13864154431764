import React from "react";
import { AiOutlineClose, AiOutlineEye, AiOutlineEdit, AiOutlineDelete, AiOutlinePlus, AiOutlineUnorderedList } from "react-icons/ai";
import { Link } from "react-router-dom";

const ProductDetailsModal = ({ product, onClose, handleEdit, openDeleteModal, handleAddInventory, handleViewInventoryLog }) => {
  if (!product) {
    return null;
  }

  return (
    <div className="all-products-mobile-detail-card-modal">
      <div className="all-products-mobile-detail-card-container">
      <AiOutlineClose
        className="all-products-mobile-detail-card-close-icon"
        onClick={onClose}
      />
        <div className="all-products-mobile-detail-card-header">
          <img
            src={product.images?.[0]?.url || "defaultProductImageUrl"}
            alt="Product"
            className="all-products-mobile-detail-card-image"
          />
          <h2 className="all-products-mobile-detail-card-title">{product.name}</h2>
        </div>
        <div className="all-products-mobile-detail-card-content">
          <div className="all-products-mobile-detail-card-item">
            <span className="all-products-mobile-detail-card-label">Price:</span>
            <span className="all-products-mobile-detail-card-value">${product.discountPrice.toFixed(2)}</span>
          </div>
          <div className="all-products-mobile-detail-card-item">
            <span className="all-products-mobile-detail-card-label">Product Type:</span>
            <span className="all-products-mobile-detail-card-value">{Array.isArray(product.productType) ? product.productType.join(', ') : product.productType}</span>
          </div>
          <div className="all-products-mobile-detail-card-item">
            <span className="all-products-mobile-detail-card-label">Stock:</span>
            <span className="all-products-mobile-detail-card-value">{product.stock}</span>
          </div>
          <div className="all-products-mobile-detail-card-item">
            <span className="all-products-mobile-detail-card-label">Category:</span>
            <span className="all-products-mobile-detail-card-value">{product.category}</span>
          </div>
          <div className="all-products-mobile-detail-card-item">
            <span className="all-products-mobile-detail-card-label">SKU:</span>
            <span className="all-products-mobile-detail-card-value">{product.SKU}</span>
          </div>
        </div>

        {product.variants && product.variants.length > 0 && (
          <div className="all-products-mobile-variants">
            <h3 className="all-products-mobile-variants-title">Variants</h3>
            {product.variants.map((variant) => (
              <div key={variant._id} className="all-products-mobile-variant-item">
                <img
                  src={variant.image?.url || product.images?.[0]?.url || "defaultProductImageUrl"}
                  alt={variant.sku}
                  className="all-products-mobile-variant-image"
                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                />
                <div className="all-products-mobile-variant-details">
                  <div>
                    <span className="all-products-mobile-variant-label">SKU:</span>
                    <span className="all-products-mobile-variant-value">{variant.sku}</span>
                  </div>
                  <div>
                    <span className="all-products-mobile-variant-label">Price:</span>
                    <span className="all-products-mobile-variant-value">US$ {variant.price.toFixed(2)}</span>
                  </div>
                  <div>
                    <span className="all-products-mobile-variant-label">Stock:</span>
                    <span className="all-products-mobile-variant-value">{variant.stock}</span>
                  </div>
                  <div>
                    <span className="all-products-mobile-variant-label">Color:</span>
                    <span className="all-products-mobile-variant-value">{variant.color || "N/A"}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Action buttons */}
        <div className="all-products-mobile-detail-card-actions">
          <Link to={`/product/${product._id}`} className="all-products-mobile-detail-card-action">
            <AiOutlineEye size={24} title="Preview" />
          </Link>
          <button onClick={() => handleEdit(product._id)} className="all-products-mobile-detail-card-action">
            <AiOutlineEdit size={24} title="Edit" />
          </button>
          <button onClick={() => openDeleteModal(product._id)} className="all-products-mobile-detail-card-action delete">
            <AiOutlineDelete size={24} title="Delete" />
          </button>
          <button onClick={() => handleAddInventory(product._id)} className="all-products-mobile-detail-card-action add-inventory">
            <AiOutlinePlus size={24} title="Add Inventory" />
          </button>
          <button onClick={() => handleViewInventoryLog(product._id)} className="all-products-mobile-detail-card-action view-log">
            <AiOutlineUnorderedList size={24} title="View Inventory Log" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsModal;

