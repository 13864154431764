import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'; 

const ShopGrid = ({ shops }) => {
  const [currentLargeShop, setCurrentLargeShop] = useState(0);
  const [currentSmallShopSlide, setCurrentSmallShopSlide] = useState(0);
  const [smallShopsSlides, setSmallShopsSlides] = useState([]);

  // Ensure shops is a valid array
  const validShops = Array.isArray(shops) && shops.length > 0 ? shops : [];

  const totalShops = validShops.length;
  const itemsPerSlide = 2; // Number of small shops per slide

  // Helper function to group array into chunks
  const groupIntoChunks = useCallback((array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }, []);

  // Update smallShopsSlides whenever currentLargeShop or shops change
  useEffect(() => {
    if (totalShops === 0) {
      setSmallShopsSlides([]);
      setCurrentSmallShopSlide(0);
      return;
    }

    const largeShop = validShops[currentLargeShop];

    if (!largeShop) {
      console.warn('Large shop is undefined. Check currentLargeShop index.');
      setSmallShopsSlides([]);
      setCurrentSmallShopSlide(0);
      return;
    }

    // Exclude the large shop from small shops
    const smallShops = validShops.filter((shop) => shop._id !== largeShop._id);

    // Group small shops into slides
    const groupedSmallShops = groupIntoChunks(smallShops, itemsPerSlide);

    setSmallShopsSlides(groupedSmallShops);

    // Reset small shop slide index if current slide is out of bounds
    if (currentSmallShopSlide >= groupedSmallShops.length) {
      setCurrentSmallShopSlide(0);
    }
  }, [
    currentLargeShop,
    validShops,
    totalShops,
    itemsPerSlide,
    currentSmallShopSlide,
    groupIntoChunks,
  ]);

  const nextLargeShop = () => {
    setCurrentLargeShop((prev) => (prev + 1) % totalShops);
  };

  const prevLargeShop = () => {
    setCurrentLargeShop((prev) => (prev - 1 + totalShops) % totalShops);
  };

  const nextSmallShopSlide = () => {
    if (smallShopsSlides.length === 0) return;
    setCurrentSmallShopSlide((prev) => (prev + 1) % smallShopsSlides.length);
  };

  const prevSmallShopSlide = () => {
    if (smallShopsSlides.length === 0) return;
    setCurrentSmallShopSlide((prev) => (prev - 1 + smallShopsSlides.length) % smallShopsSlides.length);
  };

  if (!validShops.length) {
    return <div className="shop-grid-error">No shops to display.</div>;
  }

  // Get current small shops to display
  const currentSmallShops =
    smallShopsSlides.length > 0
      ? smallShopsSlides[currentSmallShopSlide]
      : [];

  // Debugging Logs
  console.log('validShops:', validShops);
  console.log('currentLargeShop index:', currentLargeShop);
  console.log('largeShop:', validShops[currentLargeShop]);

  return (
    <div className="shop-grid-bg-black">
      <div className="shop-grid-container">
        <div className="shop-grid-header-container">
          <h2 className="shop-grid-heading">Explore Our Shops</h2>
          <Link to="/shops" className="shop-grid-browse-link">
            Browse all shops
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>

        <div className="shop-grid-carousel">
          <div className="shop-grid-grid">
            {/* Large Shop Tile */}
            {totalShops > 0 && (
              <div
                className="shop-grid-item large"
                style={{
                  backgroundImage: `url(${validShops[currentLargeShop].banner?.url || '/placeholder-banner.jpg'})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                {/* Move Link to wrap only the content that should be clickable */}
                <Link to={`/shop/${validShops[currentLargeShop].handle || ''}`} className="shop-grid-link">
                  {/* Overlay */}
                  <div className="shop-grid-overlay" />

                  {/* Centered Content */}
                  <div className="shop-grid-center-content">
                    {/* Avatar */}
                    {validShops[currentLargeShop].avatar && validShops[currentLargeShop].avatar.url ? (
                      <img
                        src={validShops[currentLargeShop].avatar.url}
                        alt={validShops[currentLargeShop].name || 'Featured Shop'}
                        className="shop-grid-avatar"
                        loading="lazy"
                      />
                    ) : (
                      <div className="shop-grid-avatar-placeholder">No Avatar</div>
                    )}

                    {/* Shop Name */}
                    <h3 className="shop-grid-shop-name">
                      {validShops[currentLargeShop].name || 'Unnamed Shop'}
                    </h3>
                  </div>
                </Link>

                {/* Large Shop Carousel Controls */}
                {totalShops > 1 && (
                  <>
                    <button
                      className="shop-grid-carousel-button prev"
                      onClick={prevLargeShop}
                      aria-label="Previous Shop"
                    >
                      &#10094;
                    </button>
                    <button
                      className="shop-grid-carousel-button next"
                      onClick={nextLargeShop}
                      aria-label="Next Shop"
                    >
                      &#10095;
                    </button>
                  </>
                )}
              </div>
            )}

            {/* Small Shops Container */}
            <div className="shop-grid-small-tiles-container">
              {currentSmallShops.map((shop) => (
                <div
                  className="shop-grid-item small"
                  key={shop._id}
                  style={{
                    backgroundImage: `url(${shop.banner?.url || '/placeholder-banner.jpg'})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  {/* Move Link to wrap only the content that should be clickable */}
                  <Link to={`/shop/${shop.handle || ''}`} className="shop-grid-link">
                    {/* Overlay */}
                    <div className="shop-grid-overlay" />

                    {/* Centered Content */}
                    <div className="shop-grid-center-content">
                      {/* Avatar */}
                      {shop.avatar && shop.avatar.url ? (
                        <img
                          src={shop.avatar.url}
                          alt={shop.name || 'Unnamed Shop'}
                          className="shop-grid-avatar"
                          loading="lazy"
                        />
                      ) : (
                        <div className="shop-grid-avatar-placeholder">No Avatar</div>
                      )}

                      {/* Shop Name */}
                      <h3 className="shop-grid-shop-name">{shop.name || 'Unnamed Shop'}</h3>
                    </div>
                  </Link>
                </div>
              ))}

              {/* Small Shops Carousel Controls */}
              {smallShopsSlides.length > 1 && (
                <>
                  <button
                    className="shop-grid-carousel-button shop-prev"
                    onClick={prevSmallShopSlide}
                    aria-label="Previous Shops"
                  >
                    &#10094;
                  </button>
                  <button
                    className="shop-grid-carousel-button shop-next"
                    onClick={nextSmallShopSlide}
                    aria-label="Next Shops"
                  >
                    &#10095;
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ShopGrid.propTypes = {
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      handle: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        url: PropTypes.string,
        key: PropTypes.string,
        _id: PropTypes.string,
      }),
      banner: PropTypes.shape({
        url: PropTypes.string,
        key: PropTypes.string,
        _id: PropTypes.string,
      }),
      bannerPosition: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
      bannerScale: PropTypes.number,
    })
  ).isRequired,
};

export default ShopGrid;