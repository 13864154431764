import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { server } from '../../server';
import RoomAddMemberForm from './RoomAddMemberForm';
import RoomDemo from './RoomDemo';

const RoomDetails = () => {
  const { handle } = useParams();
  const [room, setRoom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddMemberForm, setShowAddMemberForm] = useState(false); 

  useEffect(() => {
    const fetchRoom = async () => {
      try {
        const response = await axios.get(`${server}/room/rooms/${handle}`, {
          withCredentials: true,
        });
        setRoom(response.data.room);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch room details');
        setLoading(false);
      }
    };

    fetchRoom();
  }, [handle]);

  const handleMemberAdded = () => {
    // Logic to refresh room details or members list if necessary
    // This function will be passed to AddMemberForm and can be used to refresh data
  };

  if (loading) return <p>Loading room details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="room-details-page">
      {room.banner?.url && (
        <div
          className="room-banner"
          style={{
            backgroundImage: `url(${room.banner.url})`,
          }}
        >
          <div className="room-banner-overlay">
            <h2 className="room-name">{room.name}</h2>
            <p className="room-description">{room.description}</p>
          </div>
        </div>
      )}
      <div className="room-details-content">
        {/* Display Room Members */}
        <div className="room-members">
          <h3>Members</h3>
          <div className="room-members-container">
          <div className="room-members-avatar-display">
            {room.members.slice(0, 5).map((member, index) => (
              <div key={index} className="room-member-item">
                <img
                  className="room-member-avatar"
                  src={member.avatar || '/default-avatar.png'}
                  alt={member.name || 'Member'}
                />
                <span>{member.name}</span>
              </div>
            ))}
          </div>
          
          <div className="room-members-count">
            <span>{room.members.length} Members</span>
          </div>
          </div>
          {/* Button to open AddMemberForm */}
          <button
            className="add-member-button"
            onClick={() => setShowAddMemberForm(true)}
          >
            Add Members
          </button>
        </div>
      </div>

      {/* Conditionally render the AddMemberForm */}
      {showAddMemberForm && (
        <RoomAddMemberForm
          roomId={room._id}
          currentMembers={room.members}
          onMemberAdded={handleMemberAdded}
          onClose={() => setShowAddMemberForm(false)} 
        />
      )}
      
    </div>
  );
};

export default RoomDetails;




