import axios from "axios";
import { server } from "../../server";

// Action Types
export const FOLLOW_UNFOLLOW_REQUEST = "FollowUnfollowRequest";
export const FOLLOW_UNFOLLOW_SUCCESS = "FollowUnfollowSuccess";
export const FOLLOW_UNFOLLOW_FAIL = "FollowUnfollowFail";

export const FETCH_MY_CONNECTIONS_REQUEST = "FetchMyConnectionsRequest";
export const FETCH_MY_CONNECTIONS_SUCCESS = "FetchMyConnectionsSuccess";
export const FETCH_MY_CONNECTIONS_FAIL = "FetchMyConnectionsFail";

export const FETCH_CONNECTIONS_REQUEST = "FetchConnectionsRequest";
export const FETCH_CONNECTIONS_SUCCESS = "FetchConnectionsSuccess";
export const FETCH_CONNECTIONS_FAIL = "FetchConnectionsFail";

export const FETCH_IS_FOLLOWING_REQUEST = "FetchIsFollowingRequest";
export const FETCH_IS_FOLLOWING_SUCCESS = "FetchIsFollowingSuccess";
export const FETCH_IS_FOLLOWING_FAIL = "FetchIsFollowingFail";

export const FETCH_RECOMMENDED_CONNECTIONS_REQUEST = "FetchRecommendedConnectionsRequest";
export const FETCH_RECOMMENDED_CONNECTIONS_SUCCESS = "FetchRecommendedConnectionsSuccess";
export const FETCH_RECOMMENDED_CONNECTIONS_FAIL = "FetchRecommendedConnectionsFail";

export const followUnfollow = (connectionId, profileType, connectionProfileType) => async (dispatch) => {
    dispatch({ type: FOLLOW_UNFOLLOW_REQUEST });
    try {
        await axios.put(
            `${server}/connections/follow-unfollow/${connectionId}/${profileType}/${connectionProfileType}`, 
            {}, 
            { 
                withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            }
        );
        dispatch({ type: FOLLOW_UNFOLLOW_SUCCESS });
    } catch (error) {
        dispatch({
            type: FOLLOW_UNFOLLOW_FAIL,
            payload: error.response ? error.response.data.message : "Failed to follow/unfollow.",
        });
    }
};



export const fetchConnections = (userId, profileType, relationship) => async (dispatch) => {
    // Dispatch request action with relationship
    dispatch({ type: FETCH_CONNECTIONS_REQUEST, payload: { relationship } });
    try {
      const response = await axios.get(
        `${server}/connections/get-connections/${userId}/${profileType}/${relationship}`,
        { withCredentials: true }
      );
      dispatch({
        type: FETCH_CONNECTIONS_SUCCESS,
        payload: { connections: response.data.connections, relationship },
      });
    } catch (error) {
      dispatch({
        type: FETCH_CONNECTIONS_FAIL,
        payload: {
          error: error.response ? error.response.data.message : "Failed to fetch connections.",
          relationship,
        },
      });
    }
  };
  
  export const fetchMyConnections = (userId, profileType, relationship) => async (dispatch) => {
    dispatch({ type: FETCH_MY_CONNECTIONS_REQUEST, payload: { relationship } });
    try {
      const response = await axios.get(
        `${server}/connections/get-connections/${userId}/${profileType}/${relationship}`,
        { withCredentials: true }
      );
      dispatch({
        type: FETCH_MY_CONNECTIONS_SUCCESS,
        payload: { connections: response.data.connections, relationship },
      });
      return response; 
    } catch (error) {
      dispatch({
        type: FETCH_MY_CONNECTIONS_FAIL,
        payload: {
          error: error.response ? error.response.data.message : "Failed to fetch my connections.",
          relationship,
        },
      });
      throw error; 
    }
  };
  

export const fetchIsFollowing = (currentProfileId, connectionId, profileType, connectionProfileType) => async (dispatch) => {
    dispatch({ type: FETCH_IS_FOLLOWING_REQUEST });
    try {
      const response = await axios.get(`${server}/connections/get-is-following/${currentProfileId}/${connectionId}/${profileType}/${connectionProfileType}`, {
        withCredentials: true,
      });
      dispatch({
        type: FETCH_IS_FOLLOWING_SUCCESS,
        payload: { connectionId, isFollowing: response.data.isFollowing }, 
      });
    } catch (error) {
      dispatch({
        type: FETCH_IS_FOLLOWING_FAIL,
        payload: error.response ? error.response.data.message : "Failed to check following status.",
      });
    }
  };
  

export const fetchRecommendedConnections = () => async (dispatch) => {
    dispatch({ type: FETCH_RECOMMENDED_CONNECTIONS_REQUEST });
    try {
        const response = await axios.get(`${server}/connections/recommend-connections`, {
            withCredentials: true, headers: {
                'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
              },
        });
        dispatch({
            type: FETCH_RECOMMENDED_CONNECTIONS_SUCCESS,
            payload: response.data.recommendedConnections,
        });
    } catch (error) {
        console.error('Error fetching recommended connections:', error.response || error);  
        dispatch({
            type: FETCH_RECOMMENDED_CONNECTIONS_FAIL,
            payload: error.response ? error.response.data.message : "Failed to fetch recommended connections.",
        });
    }
};
