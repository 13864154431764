import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import { toast } from 'react-toastify';
import { server } from "../../server";
import Loader from '../Layout/Loader';

const ActiveStreams = ({ onSelectStream }) => { // Added onSelectStream prop
  const [activeStreams, setActiveStreams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchActiveStreams = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await axios.get(`${server}/stream/active`); // Fixed template literal
        setActiveStreams(response.data);
      } catch (err) {
        console.error('Error fetching active streams:', err);
        setError(true);
        toast.error('Failed to fetch active streams');
      } finally {
        setLoading(false);
      }
    };

    fetchActiveStreams();

    // Optional: Polling every 30 seconds to update streams
    const interval = setInterval(fetchActiveStreams, 30000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className="error-message">Unable to load active streams.</div>;
  }

  return (
    <div className="active-streams-container">
      <h2>Active Livestreams</h2>

      {activeStreams.length === 0 ? (
        <p>No active streams at the moment.</p>
      ) : (
        <div className="streams-grid">
          {activeStreams.map((stream) => (
            <div
              key={`${stream.role}-${stream.id}`} // Fixed template literal
              className="stream-card"
              onClick={() => onSelectStream(stream)} // Handle stream selection
              style={{ cursor: 'pointer' }} // Indicate clickable
            >
              <h3>{stream.name}'s Stream ({stream.role})</h3>
              <iframe
                src={stream.playbackUrl}
                title={`${stream.name}'s Livestream`} // Fixed template literal
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="stream-iframe"
              ></iframe>
              {/* Removed Link component */}
              <button className="watch-button">Watch Now</button> {/* Optional: Button for accessibility */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActiveStreams;

