import React, { useState } from 'react';
import { categoriesData } from "../../static/data";
import CommunityDropdown from './CommunityDropdown';
import MusicDropdown from './MusicDropdown';
import MagazineDropdown from './MagazineDropdown';
import StreamsDropdown from './StreamsDropdown';
import StudiosDropdown from './StudiosDropdown';
import MarketplaceDropdown from './MarketplaceDropdown';

const TopLevelDropdown = ({ setDropDown }) => {
  const [currentCategory, setCurrentCategory] = useState("Marketplace");

  const topCategories = [
    "Marketplace",
    "Community",
    "Music",
    "Streams",
    "Magazine",
    "Studios"
  ];

  // Inside TopLevelDropdown component
const handleCategoryClick = (category) => {
    if (category === currentCategory) {
      setDropDown(false); 
    } else {
      setCurrentCategory(category); 
      setDropDown(true); 
    }
  };

  return (
    <div
      className="top-level-dropdown"
      onClick={(e) => e.stopPropagation()}  
    >
      <nav className="top-level-dropdown-nav">
        {topCategories.map((category) => (
          <span
            key={category}
            className={`top-category-item ${currentCategory === category ? "active" : ""}`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </span>
        ))}
      </nav>

      <div className="top-level-dropdown-content">
        {currentCategory === "Marketplace" && (
          <MarketplaceDropdown categoriesData={categoriesData} setDropDown={setDropDown} />
        )}
        {currentCategory === "Community" && <CommunityDropdown />}
        {currentCategory === "Music" && <MusicDropdown />}
        {currentCategory === "Magazine" && <MagazineDropdown />}
        {currentCategory === "Streams" && <StreamsDropdown />}
        {currentCategory === "Studios" && <StudiosDropdown />}
      </div>
    </div>
  );
};

export default TopLevelDropdown;

