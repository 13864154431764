import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ecommerceServer } from '../../server';

const InventoryLog = ({ productId }) => {
  const [inventoryLogs, setInventoryLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInventoryLogs = async () => {
      try {
        const response = await axios.get(`${ecommerceServer}/inventory/product/${productId}/inventory-log`, {
          withCredentials: true,
          headers: {
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          },
        });
        setInventoryLogs(response.data.inventoryLogs);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching inventory logs:', error);
        setLoading(false);
      }
    };

    fetchInventoryLogs();
  }, [productId]);

  if (loading) {
    return <div>Loading inventory logs...</div>;
  }

  if (inventoryLogs.length === 0) {
    return <div>No inventory updates found.</div>;
  }

  return (
    <div className="inventory-log-component">
      <h2>Inventory Log</h2>
      <ul className="inventory-log-timeline">
        {inventoryLogs.map((log) => (
          <li key={log._id} className="inventory-log-item">
            <div className="inventory-log-date">{new Date(log.receivedDate).toLocaleDateString()}</div>
            <div className="inventory-log-details">
              <span className="inventory-log-batch">Batch: {log.batchNumber}</span>
              <span className="inventory-log-quantity">Quantity: {log.quantity}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InventoryLog;
