import React from 'react';

const BrandCatalogItemCreationGuide = () => {
  return (
    <div className="doc-container">
      <h1 className="doc-title">Guide to Creating a Brand Catalog Item</h1>
      <p className="doc-intro">
        This guide will help you create a new catalog item for your brand, including adding product details, uploading images, videos, and managing other product attributes.
      </p>

      <h2 className="doc-step h2">Step-by-Step Guide to Creating a Brand Catalog Item</h2>
      <ol className="doc-steps">
        <li className="doc-step">
          <h2>Enter Product Information</h2>
          <p>To create a new catalog item, follow these steps:</p>
          <ul>
            <li><strong>Enter Product Name</strong>: Provide the name of your product in the <strong>Product Name</strong> field.</li>
            <li><strong>Enter Description</strong>: Add a description for your product to provide more information about it.</li>
            <li><strong>Select Category</strong>: Choose an appropriate category for your product from the dropdown list.</li>
            <li><strong>Set SKU</strong>: Add a unique SKU for your product to help identify it.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Upload Product Media</h2>
          <p>You can upload images and videos to showcase your product:</p>
          <ul>
            <li><strong>Product Images</strong>: Click the <strong>Upload a file</strong> button to add product images. You can upload multiple images, and preview them to ensure they are correct.</li>
            <li><strong>Product Videos</strong>: Click the <strong>Upload a file</strong> button under the <strong>Videos</strong> section to add product videos. Videos help customers better understand the product.</li>
            <li><strong>Nutrition Information</strong>: If applicable, upload images of nutrition information to provide customers with important details about the product.</li>
            <li><strong>Test Results</strong>: Upload images of any test results if required for the product.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Set Product Details</h2>
          <p>Provide additional product details:</p>
          <ul>
            <li><strong>Price Floor</strong>: Set the minimum selling price for your product.</li>
            <li><strong>Product Type</strong>: Select whether the product is available for <strong>Retail</strong>, <strong>Wholesale</strong>, or both by using the checkboxes.</li>
            <li><strong>Unit of Measurement</strong>: Select the appropriate unit of measurement for your product (e.g., grams, pounds, kilograms).</li>
            <li><strong>Tags</strong>: Add relevant tags to help categorize and search for the product.</li>
            <li><strong>Tax Code</strong>: Select the applicable tax code for your product to ensure proper compliance.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Submit the Catalog Item</h2>
          <p>Once you have entered all the information and uploaded the media, follow these steps to complete the catalog item creation:</p>
          <ul>
            <li><strong>Click Create</strong>: Click the <strong>Create</strong> button to submit your catalog item details.</li>
            <li><strong>Confirmation</strong>: If successful, you will see a confirmation message indicating that the catalog item was created successfully.</li>
          </ul>
        </li>
      </ol>

      <div className="doc-additional">
        <h2>Important Tips</h2>
        <ul>
          <li><strong>Image and Video Quality</strong>: Ensure that the images and videos you upload are high quality to give your product a professional appearance.</li>
          <li><strong>Product Description</strong>: Write a clear and concise description that effectively communicates what your product offers.</li>
          <li><strong>Review Before Submitting</strong>: Double-check all details before clicking the submit button to avoid errors.</li>
        </ul>
      </div>
    </div>
  );
};

export default BrandCatalogItemCreationGuide;
 