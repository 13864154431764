import React from 'react';

export default function PageNotFound() {
  return (
    <>
      <div className="page-not-found-container">
        <header className="page-not-found-header">
          <a href="/">
            <span className="page-not-found-sr-only">Your Company</span>
            <img
              alt="Company Logo"
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724797193/BuzzVibe-TM-Gradient-outline_jfxvg6.png"
              className="page-not-found-logo"
            />
          </a>
        </header>

        <main className="page-not-found-main">
          <img
            alt="Background"
            src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1728648725/boliviainteligente-beCUr7D24Vs-unsplash_t1s8x5.jpg"
            className="page-not-found-background"
          />
          <div className="page-not-found-content">
            <p className="page-not-found-status">404</p>
            <h1 className="page-not-found-title">Page not found</h1>
            <p className="page-not-found-description">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="page-not-found-back-link">
              <a href="/">← Back to home</a>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
