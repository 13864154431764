import React from 'react';
import Header from "../components/Layout/Header";
import Sidebar from '../components/Layout/Sidebar';
import EventDetail from '../components/Events/EventDetail';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';


const EventDetailsPage = () => {
  return (
    <div className="event-details-page">
      <Header activeHeading={1} />
      <Sidebar />
      
      <div className="back-to-events">
        <Link to="/events" className="back-to-events-link" aria-label="Back to Events">
          <ArrowLeftIcon className="back-to-events-icon" />
          <span className="back-to-events-text">Back to Events</span>
        </Link>
      </div>
      
      <EventDetail />
    </div>
  );
};

export default EventDetailsPage;
