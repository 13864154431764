// HomePage.jsx
import React, { useEffect, useState } from 'react';
import Header from "../components/Layout/Header";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Sales/Events";
import Footer from "../components/Layout/Footer";
import Sidebar from '../components/Layout/Sidebar';
import SignupCTA from '../components/SignupCTA';
import ShopBySeller from '../components/Shop/ShopBySeller';
import SponsoredProductCarousel from '../components/Sponsored/SponsoredProductsCarousel';
import SponsoredShopsCarousel from '../components/Sponsored/SponsoredShopsCarousel';
import BannerAd from '../components/Sponsored/BannerAd';
import HeroSliderHome from '../components/Route/Hero/HeroSliderHome';
import DualProductAdCarousel from '../components/Sponsored/DualProductAd';
import QuadProductAdCarousel from '../components/Sponsored/QuadProductAdCarousel';
import TopBannerAd from '../components/Sponsored/TopBannerAd';
import EventCarousel from '../components/Events/EventCarousel';
import SponsoredBrandCarousel from '../components/Sponsored/SponsoredBrandCarousel';
import RecommendedFriends from '../components/Followers/RecommendedFriends';
import axios from 'axios';
import { adsServer, ecommerceServer } from '../server';
import DualProductAdCarouselProps from '../components/Sponsored/DualProductAdCarouselProps';
import SponsoredProductCarouselProps from '../components/Sponsored/SponsoredProductCarouselProps';
import SponsoredShopsCarouselProps from '../components/Sponsored/SponsoredShopsCarouselProps';
import QuadProductAdCarouselProps from '../components/Sponsored/QuadProductCarouselProps';
import SponsoredBrandCarouselProps from '../components/Sponsored/SponsoredBrandCarouselProps';
import BannerAdProps from '../components/Sponsored/BannerAdProps';
import TopBannerAdProps from '../components/Sponsored/TopBannerAdProps';
import useWindowSize from "../hooks/useWindowSize";
import TopBannerAdPropsMobile from '../components/Sponsored/TopBannerAdPropMobile';
import BannerAdPropsMobile from '../components/Sponsored/BannerAdPropsMobile';
import SponsoredProductCarouselPropsMobile from '../components/Sponsored/SponsoredProductsCarouselPropsMobile';
import MixedSponsoredAdsProps from '../components/Sponsored/MixedSponsoredAdsProps';
import BentoCTA from '../components/BentoCTA';
import SponsoredBrandCarouselPropsMobile from '../components/Sponsored/SponsoredBrandCarouselPropsMobile';
import SponsoredShopsCarouselPropsMobile from '../components/Sponsored/SponsoredShopsCarouselPropsMobile';

// New Components to Import
import ThreeTileGrid from "../components/Shop/ThreeTileGrid";
import SingleLargeProductGrid from "../components/Marketplace/SingleLargeProductGrid";
import ShopGrid from "../components/Marketplace/ShopGrid";

// Redux and Router imports
import { useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import MarketplaceProductCarousel from '../components/Marketplace/MarketplaceProductCarousel';

const HomePage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [productAds, setProductAds] = useState([]);
  const [shopAds, setShopAds] = useState([]);
  const [brandAds, setBrandAds] = useState([]);
  const [customAds, setCustomAds] = useState([]);
  const [buzzVibeProducts, setBuzzVibeProducts] = useState([]);
  const [shopImages, setShopImages] = useState([]); // New state for shop images
  const [sellers, setSellers] = useState([]); // New state for sellers
  const windowSize = useWindowSize();

  // Fetch sponsored ads (products, shops, and brands)
  useEffect(() => {
    const fetchSponsoredAds = async () => {
      try {
        const response = await axios.get(`${adsServer}/sponsored/sponsored-sidebar-ads`, {
          withCredentials: true,
          headers: {
              'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          }
        });
        const allAds = response.data;
        const productAds = allAds.filter(ad => ad.type === 'product');
        const shopAds = allAds.filter(ad => ad.type === 'shop');
        const brandAds = allAds.filter(ad => ad.type === 'brand');
        const customAds = allAds.filter(ad => ad.type === 'custom');

        setProductAds(productAds);
        setShopAds(shopAds);
        setBrandAds(brandAds);
        setCustomAds(customAds); 
      } catch (error) {
        console.error('Error fetching sponsored ads:', error);
      }
    };

    fetchSponsoredAds();
  }, []);

  // Fetch sellers from ecommerce server
  useEffect(() => {
    const fetchSellers = async () => {
      try {
        const response = await axios.get(`${ecommerceServer}/shop/all-sellers`, { withCredentials: true });
        setSellers(response.data.sellers);

        // Optionally set the first seller as the featured shop
        if (response.data.sellers.length > 0) {
          // If you have a FeaturedShop component, you can set it here
          // setFeaturedShopId(response.data.sellers[0]._id);
        }
      } catch (error) {
        console.error('Error fetching sellers:', error);
      }
    };
    fetchSellers();
  }, []);

  // Fetch and set buzzVibeProducts, shopImages, and data
  useEffect(() => {
    const fetchData = () => {
      try {
        let filteredProducts = allProducts || [];

        // Filter for retail products
        filteredProducts = filteredProducts.filter((product) =>
          product.productType.includes('retail')
        );

        // Further filter by category if necessary
        if (categoryData) {
          filteredProducts = filteredProducts.filter(
            (product) => product.category === categoryData
          );
        }

        // Filter products from BuzzVibe Boutique using shop.handle
        const buzzVibeProducts = filteredProducts.filter(
          (product) =>
            product.shop &&
            product.shop.handle &&
            product.shop.handle.trim().toLowerCase() === 'buzzvibeboutique'
        );

        setBuzzVibeProducts(buzzVibeProducts);

        // Define shopImages manually or fetch from shop data
        // For demonstration, we'll define a static array
        const shopImages = [
          {
            url: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/385d8f3f-e649-4b34-e3b4-cadceaa19900/public',
            alt: 'BuzzVibe Boutique - Image 1',
          },
          {
            url: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/6a508243-40c8-4cbb-eaf8-042ddc025d00/public',
            alt: 'BuzzVibe Boutique - Image 2',
          },
          {
            url: 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/74bb0517-36a1-48ad-2eab-1b1d6a8bc600/public',
            alt: 'BuzzVibe Boutique - Image 3',
          },
          // Add more images as needed
        ];

        setShopImages(shopImages);
        setData(filteredProducts);
      } catch (error) {
        console.error('Error processing data:', error);
      }
    };

    fetchData();
  }, [allProducts, categoryData]);

  // Select a specific product ad for TopBannerAdProps and BannerAdProps
  const topBannerAd = productAds.length > 0 ? productAds[0] : null;
  const bannerAd = productAds.length > 1 ? productAds[1] : null;

  const isMobile = windowSize.width <= 768;

  return (
    <div className="home-page-container">
      
      {bannerAd && (isMobile ? (
                  <BannerAdPropsMobile ad={bannerAd} />
                ) : (
                  <BannerAdProps ad={bannerAd} />
                ))}
      <Header activeHeading={1} />
      
      <Sidebar />
      <div className="home-page-content">
        
      {topBannerAd && (isMobile ? (
              <TopBannerAdPropsMobile ad={topBannerAd} />
            ) : (
              <TopBannerAdProps ad={topBannerAd} />
            ))}
        <HeroSliderHome />
        
        {/* Integrate New Grids Here */}
        <div className="marketplace-page-grid-container">
          <div className="marketplace-page-left-container">
            <ShopBySeller />
            <DualProductAdCarouselProps ads={productAds} />
            <ThreeTileGrid products={buzzVibeProducts} shopImages={shopImages} />
            <SingleLargeProductGrid products={data} />
            <ShopGrid shops={sellers} />

            {isMobile ? (
                      <SponsoredProductCarouselPropsMobile  ads={productAds} />
                    ) : (
                      <SponsoredProductCarouselProps  ads={productAds} />
                    )}
   
   
            {isMobile ? (
                      <SponsoredShopsCarouselPropsMobile ads={shopAds} />
                    ) : (
                      <SponsoredShopsCarouselProps ads={shopAds} />
                    )}
                     <RecommendedFriends />
            <QuadProductAdCarouselProps ads={productAds} />
            
            <BestDeals />
            <EventCarousel />
            {isMobile ? (
                      <SponsoredBrandCarouselPropsMobile ads={brandAds} />
                    ) : (
                      <SponsoredBrandCarouselProps ads={brandAds} />
                    )}
             <MarketplaceProductCarousel data={data} />
            <SignupCTA />
          </div>
         
          <div className="marketplace-page-right-container">
            <MixedSponsoredAdsProps ads={[...productAds, ...brandAds, ...shopAds]} />
          </div>
        </div>
        
      </div>
     
      <Footer />
    </div>
  );
};

export default HomePage;


