import React from 'react';
import ShopLogin from "../components/Shop/ShopLogin";

const ShopLoginPage = () => {

  return (
    <div>
      <ShopLogin />
    </div>
  );
}

export default ShopLoginPage;
