import React from 'react'
import Sidebar from '../../components/Layout/Sidebar';
import ShopBySellerProds from '../../components/Shop/ShopBySellerProds';
import Header from '../../components/Layout/Header';

const ShopsPage = () => {
  return (
    <div>
        <Header />
        <Sidebar />
        <ShopBySellerProds />
        
    </div>
  )
}

export default ShopsPage