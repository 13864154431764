import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import SignatureCanvas from 'react-signature-canvas';
import { server } from "../../server";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const MembershipCheckout = ({ prevStep, formData, handleChange }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [priceDetails, setPriceDetails] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [sessionId, setSessionId] = useState('');
    const sigCanvas = useRef({});

    useEffect(() => {
        const fetchPriceDetails = async () => {
            try {
                const response = await axios.get(`${server}/membership/memberships/price-details`);
                setPriceDetails(response.data);
            } catch (error) {
                console.error('Failed to fetch price details', error);
                toast.error('Failed to load pricing information.');
            }
        };

        fetchPriceDetails();
    }, []);

    const handlePaymentSubmission = async (event) => {
        event.preventDefault();

        if (sigCanvas.current.isEmpty()) {
            toast.error('Please provide your digital signature.');
            return;
        }

        setIsLoading(true);
        handleChange('signature1')(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));

        try {
            const { data } = await axios.post(`${server}/membership/create-membership-checkout-session`, {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                dob: formData.dob,
                photo: formData.photo,
                priceId: priceDetails.id,
                signature1: formData.signature1
            }, {
                headers: {
                    "Content-Type": "application/json",
                }
            });

            setClientSecret(data.clientSecret);
            setSessionId(data.sessionId);
        } catch (error) {
            console.error('Checkout session creation failed:', error);
            toast.error('Failed to create checkout session. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        handleChange('signature1')('');
    };

    return (
        <div className="membership-checkout-container">
            <div className="membership-checkout-grid">
                <div className="membership-checkout-info">
                    {priceDetails && (
                        <div className="membership-checkout-details">
                            <h2>{`Level up with your new: ${priceDetails.product.name}`}</h2>
                            <p>{priceDetails.product.description}</p>
                            <p>Price: ${(priceDetails.unit_amount / 100).toFixed(2)} {priceDetails.currency.toUpperCase()}</p>
                        </div>
                    )}
                    <div className="membership-checkout-summary">
                    <div className="membership-checkout-summary-row">
                        <dt>First Name</dt>
                        <dd>{formData.firstName}</dd> 
                    </div>
                    <div className="membership-checkout-summary-row">
                        <dt>Last Name</dt>
                        <dd>{formData.lastName}</dd>
                    </div>
                    <div className="membership-checkout-summary-row">
                        <dt>Email</dt>
                        <dd>{formData.email}</dd> 
                    </div>
                    <div className="membership-checkout-summary-row">
                        <dt>Date of Birth</dt>
                        <dd>{formData.dob}</dd> 
                    </div>
                    <div className="membership-checkout-summary-row">
                        <dt>Photo</dt>
                        <dd>
                            <img src={formData.photo} alt="Member" className="membership-checkout-photo" /> 
                        </dd>
                    </div>

                        <div className="membership-checkout-signature">
                            <label>Signature</label>
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="black"
                                canvasProps={{ className: 'membership-checkout-signature-canvas' }}
                            />
                            <button onClick={clearSignature} className="membership-checkout-clear-signature">Clear</button>
                        </div>
                    </div>
                    
                    <form onSubmit={handlePaymentSubmission} className="membership-checkout-form">
                        <div>
                            <label>Name On Card</label>
                            <input type="text" className="membership-checkout-input" placeholder="Full Name" value={`${formData.firstName} ${formData.lastName}`} readOnly />
                        </div>
                        <div>
                            <label>Email Address</label>
                            <input type="email" className="membership-checkout-input" placeholder="Email Address" value={formData.email} readOnly />
                        </div>
                        <button type="submit" className="membership-checkout-submit-button" disabled={isLoading}>
                            {isLoading ? 'Processing...' : 'Confirm Membership & Pay Now'}
                        </button>
                    </form>
                    <button onClick={prevStep} className="membership-checkout-back-button">Back</button>
                </div>
                {clientSecret && (
                    <div className="membership-checkout-payment">
                        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                            <EmbeddedCheckout sessionId={sessionId} />
                        </EmbeddedCheckoutProvider>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MembershipCheckout;

