import React from 'react';
import { FaMapMarkerAlt, FaClock, FaCalendarAlt } from 'react-icons/fa'; 
import GoogleCalendarButton from './GoogleCalendarButton';
import OutlookCalendarButton from './OutlookCalendarButton';
import DownloadICSButton from './DownloadICSButton';
import { useNavigate } from 'react-router-dom';

const MyEventCard = ({ event, onCancelRSVP }) => {
    const eventDate = new Date(event.date);
    const formattedDate = eventDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    }); 
    const formattedTime = eventDate.toLocaleTimeString();
    const navigate = useNavigate();

    const goToEventDetail = () => {
        navigate(`/events/${event._id}`);
    };

    return (
        <div className="my-event-card-component-card" onClick={goToEventDetail}>
            <img src={event.image} alt={event.title} className="my-event-card-component-image" />
            <div className="my-event-card-component-info">
            <div className="event-card-component-owner-info">
            {event.owner && (
                <div className="event-card-owner-details">
                    {event.owner.avatar && event.owner.avatar.url && (
                        <img 
                            src={event.owner.avatar.url} 
                            alt={event.owner.name} 
                            className="event-card-component-owner-avatar"
                        />
                    )}
                    <span className="event-card-component-owner-name">
                        {event.owner.name}
                    </span>
                </div>
            )}
        </div>
                <h3 className="my-event-card-component-title">{event.title}</h3>
                <p className="my-event-card-component-description">{event.description}</p>
                <div className="my-event-card-component-datetime">
                    <p className="my-event-card-component-date">
                        <FaCalendarAlt className="my-event-card-icon" /> {formattedDate}
                    </p>
                    <p className="my-event-card-component-time">
                        <FaClock className="my-event-card-icon" /> {formattedTime}
                    </p>
                </div>
                <p className="my-event-card-component-location">
                    <FaMapMarkerAlt className="my-event-card-icon" /> {event.location.address}
                </p>
                <button
                   onClick={(e) => {
                    e.stopPropagation(); 
                    onCancelRSVP(event._id);
                }}
                    className="my-event-card-component-button"
                >
                    Going...
                </button>
                
            </div>
        </div>
    );
};

export default MyEventCard;
