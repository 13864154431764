import React, { useState, Fragment, useCallback, useMemo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  likeOrUnlikePost,
  postComment,
  editPost,
  deletePost,
  deleteComment,
  editComment,
  resharePost,
  fetchLikes,
  reportPost 
} from '../redux/actions/post';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Box, Divider, IconButton, Typography, Modal, Backdrop, Fade, TextField, Button, MenuItem } from "@mui/material";
import {
  ChatBubbleOutlineOutlined,
  ChatBubble,
  RocketLaunchOutlined
} from "@mui/icons-material";
import { Popover, Transition } from '@headlessui/react';
import {
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline';
import FlexBetween from "../components/FlexBetween";
import Friend from "../components/Friend";
import WidgetWrapper from "../components/WidgetWrapper";
import VideoPlayer from "../components/Content/VideoPlayer";
import Comments from "../components/Comments/Comments";

const PostWidgetRedux = ({
  postId,
  postUserId,
  owner,
  name,
  description,
  profileType,
  location,
  images = [],
  videos = [],
  avatar,
  likes = [],
  comments = [],
  totalComments,
  createdAt,
  onShareSuccess,
  resharedBy,
  reshareDescription,
  reshareAvatar,
  link,
  isLikedByCurrentUser,
}) => {
  const [isComments, setIsComments] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false); 
  const [reportReason, setReportReason] = useState(''); 
  const [reportDescription, setReportDescription] = useState(''); 
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const loggedInUserId = user?._id;
  const loggedInSellerId = seller?._id;
  const [scrollPosition, setScrollPosition] = useState(0); 
  const [showReshareInput, setShowReshareInput] = useState(false);
  const [reshareInput, setReshareInput] = useState('');
  const [isReshare, setIsReshare] = useState(!!resharedBy);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedReshareDescription, setEditedReshareDescription] = useState(reshareDescription);
  const formattedDate = format(new Date(createdAt), "PPP");
  const currentProfileType = sessionStorage.getItem('currentProfile');
  const currentProfileId = useMemo(() => {
    return currentProfileType === 'User' ? user?._id : seller?._id;
  }, [user, seller, currentProfileType]);
  const [commentText, setCommentText] = useState('');
  const currentProfile = useSelector(state => state.user.currentProfile);
  const commentProfileType = currentProfileType === 'User' ? 'User' : 'Shop';
  const [newComments, setNewComments] = useState([]);
  const navigate = useNavigate();
  const [likeCount, setLikeCount] = useState(Object.keys(likes).length);
  const isInitiallyLiked = useMemo(() => {
    // Ensure that likes is an array before calling some
    return Array.isArray(likes) && likes.some((like) => like.likedBy === currentProfileId);
  }, [likes, currentProfileId]);
  
  const [isLiked, setIsLiked] = useState(isInitiallyLiked); 
  const reshareInputRef = useRef(null);
  const [totalCommentsCount, setTotalCommentsCount] = useState(totalComments);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [images]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  

  
  const toggleEditMode = useCallback((e) => {
    if (e) e.preventDefault();
    if (!isEditMode) {
      setScrollPosition(window.scrollY);
    } else {
      window.scrollTo(0, scrollPosition);
    }

    setIsEditMode(!isEditMode);

    if (isEditMode) {
      setEditedDescription(description);
    }
  }, [description, isEditMode, scrollPosition]);

  const handleCancelReshare = () => {
    setShowReshareInput(false); 
    setReshareInput(''); 
  };
  const handleShowReshareInput = () => {
    setReshareInput('');
    setShowReshareInput(true);
  
    // Add a delay to allow the element to render before scrolling
    setTimeout(() => {
      if (reshareInputRef.current) {
        reshareInputRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center", // Ensures the element is centered in the viewport
        });
      }
    }, 100); // Slight delay to allow UI updates
  };
  

  const handleCancelEdit = () => {
    setEditedDescription(description); 
    setEditedReshareDescription(reshareDescription); 
    setIsEditMode(false); 
  };
  

  const handleLikeOrUnlikePost = () => {
    const wasLiked = isLiked; // Track if the post was previously liked
    const updatedLikeCount = wasLiked ? likeCount - 1 : likeCount + 1; // Adjust like count based on action
  
    // Update UI optimistically
    setIsLiked(!wasLiked); // Toggle the liked state
    setLikeCount(updatedLikeCount); // Update the like count in the UI
  
    // Dispatch the action to the server
    dispatch(likeOrUnlikePost(postId, currentProfileType))
      .then(() => {
        // Optionally handle success
      })
      .catch((error) => {
        // Revert the UI changes if there is an error
        setIsLiked(wasLiked); // Revert liked state
        setLikeCount(likeCount); // Revert like count
        toast.error('Failed to update like status');
      });
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
  
    if (!commentText.trim()) {
      toast.error('Comment cannot be empty');
      return;
    }
  
    // Create the optimistic comment with user/shop data
    const newComment = {
      _id: Math.random().toString(36).substr(2, 9), // Temporary ID
      commentText: commentText,
      profileType: commentProfileType,
      userId: commentProfileType === 'User' ? { ...user } : null,
      shopId: commentProfileType === 'Shop' ? { ...seller } : null,
      createdAt: new Date().toISOString(),
    };
  
    // Optimistically add the comment to state
    setNewComments((prev) => [...prev, newComment]);
    setCommentText(''); // Clear input field
  
    // Dispatch the action to post the comment
    dispatch(postComment(postId, commentText, currentProfileType))
      .then((serverComment) => {
        // Merge server comment with optimistic comment to retain user/shop info
        const updatedComment = {
          ...newComment, // Keep optimistic data like user/shop info
          ...serverComment, // Override with server response
        };
  
        // Replace the optimistic comment with the updated comment
        setNewComments((prev) =>
          prev.map((comment) =>
            comment._id === newComment._id ? updatedComment : comment
          )
        );
  
       
      })
      .catch(() => {
        // Remove the optimistic comment if the submission fails
        setNewComments((prev) =>
          prev.filter((comment) => comment._id !== newComment._id)
        );
        toast.error('Failed to post comment');
      });
  };
  
  


  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(editPost(postId, editedDescription, reshareDescription))
      .catch((error) => {
        toast.error('Failed to edit post');
      });
    setIsEditMode(false);
  };

  const handleDeletePost = (e) => {
    e.preventDefault();
    dispatch(deletePost(postId)).catch((error) => {
      toast.error('Failed to delete post');
    });
  };

  const handleSharePost = () => {
    const reshareData = {
      profileType: currentProfile,
      reshareDescription: reshareInput, 
    };
    dispatch(resharePost(postId, reshareData))
      .then(() => {
        setShowReshareInput(false);
        setReshareInput(''); 
        toast.success('Post reshared successfully');
      })
      .catch((error) => {
        toast.error('Failed to reshare post');
      });
  };

  const handleReportPost = () => {
    if (!reportReason || !reportDescription) {
      toast.error('Please provide both a reason and a description.');
      return;
    }

    const reportData = {
      postId,
      reason: reportReason,
      description: reportDescription,
    };

    dispatch(reportPost(reportData))
      .then(() => {
        setShowReportModal(false);
        setReportReason('');
        setReportDescription('');
        toast.success('Post reported successfully.');
      })
      .catch((error) => {
        toast.error('Failed to report post');
      });
  };

  const renderEditForm = () => (
    <form onSubmit={handleEditSubmit} style={{ width: '100%' }}>
      <textarea
        autoFocus
        className="edit-description-textarea"
        value={isReshare ? editedReshareDescription : editedDescription}
        onChange={(e) => isReshare ? setEditedReshareDescription(e.target.value) : setEditedDescription(e.target.value)}
        rows={3}
        style={{
            width: '100%',
            background: 'none',
            border: 'none',
            fontSize: 'inherit', 
            color: 'inherit', 
            lineHeight: 'inherit', 
            resize: 'none', 
            outline: 'none', 
          }}
      />
      <div className="flex justify-end space-x-2 mt-3">
        <button type="submit" className="inline-flex items-center justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Save Changes
        </button>
        <button type="button" onClick={handleCancelEdit} className="inline-flex items-center justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Cancel
        </button>
      </div>
    </form>
  );

  const parseHashtags = useCallback((text) => {
    const words = text.split(' ');
    return words.map((word, index) => {
      if (word.startsWith('#')) {
        const hashtag = word.substring(1);
        return (
          <span
            key={index}
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => navigate(`/hashtag/${hashtag}`)}
          >
            {word}
          </span>
        );
      }
      return `${word} `;
    });
  }, [navigate]);

  const renderLinkPreview = (link) => {
    if (!link || !link.url) return null;

    return (
      <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-post-preview-link">
        <Box className="link-post-preview-container">
          {link.image && (
            <img src={link.image} alt={link.title} className="link-post-preview-image" />
          )}
          <Box className="link-post-preview-content">
            <Typography variant="h6" component="div" className="link-post-preview-title">
              {link.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" className="link-post-preview-description">
              {link.description}
            </Typography>
            <Typography variant="body2" color="primary" className="link-post-preview-url">
              {link.url}
            </Typography>
          </Box>
        </Box>
      </a>
    );
  };

  return (
    <>
      <ToastContainer />
      <WidgetWrapper m="2rem 0" className="post-widget-wrapper">
        {(loggedInUserId === owner || loggedInSellerId === owner) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon className="post-widget-icon" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={(e) => toggleEditMode(e)} 
                    className="post-widget-popover-item"
                  >
                    Edit
                  </a>
                  <a
                    href="#"
                    onClick={(e) => handleDeletePost(e)} 
                    className="post-widget-popover-item"
                  >
                    Delete
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
  
        {(loggedInUserId !== owner && loggedInSellerId !== owner) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon className="post-widget-icon" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={() => setShowReportModal(true)} 
                    className="post-widget-popover-item"
                  >
                    Report
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
  
        {resharedBy && (
          <div className="post-widget-reshare">
            <div className="post-widget-reshare-header">
              <img 
                src={reshareAvatar || 'default-avatar-url'} 
                alt={resharedBy}
                className="post-widget-reshare-avatar" 
              />
              <span className="post-widget-reshare-by">Reshared by {resharedBy}</span>
            </div>
            {reshareDescription && (
              <div className="post-widget-reshare-description">
                {isEditMode && isReshare ? renderEditForm() : reshareDescription}
              </div>
            )}
          </div>
        )}
  
        {showReshareInput && (
        <div ref={reshareInputRef} className="post-widget-reshare-container">
          <textarea
            className="post-widget-reshare-textarea"
            value={reshareInput}
            onChange={(e) => setReshareInput(e.target.value)}
            placeholder="Add a description to your reshare..."
          />
          <div className="post-widget-reshare-buttons-container">
            <button className="post-widget-reshare-button post-widget-cancel-button" onClick={handleCancelReshare}>
              Cancel
            </button>
            <button className="post-widget-reshare-button" onClick={handleSharePost}>
              Share
            </button>
          </div>
        </div>
      )}

        
        <Friend
          friendId={isReshare ? postUserId : owner} 
          friendType={profileType}
          name={name}
          subtitle={location}
          avatar={avatar}
        />
        <div className="post-widget-date">
          {formattedDate}
        </div>
        <div>
          {isEditMode && !isReshare ? renderEditForm() : (
            <Typography color="#1f9c29" sx={{ mt: "1rem" }}>
              {parseHashtags(description)}
            </Typography>
          )}
        </div>
        
        {link && renderLinkPreview(link)}
  
        <div className="post-widget-media-container">
        {images.length > 0 && (
        <div className="post-widget-image-gallery">
          {images.length > 1 ? (
            <div className="post-widget-slideshow-container">
              <div className="post-widget-slideshow">
                <button onClick={prevImage} className="post-widget-prev-button">
                  &#10094;
                </button>
                <LazyLoadImage
                  src={images[currentImageIndex].url}
                  alt={`Slide ${currentImageIndex + 1}`}
                  className="post-widget-slideshow-image"
                 
                  placeholderSrc="path/to/placeholder/image.jpg"
                />
                <button onClick={nextImage} className="post-widget-next-button">
                  &#10095;
                </button>
              </div>
              <div className="post-widget-thumbnails-container">
                {images.map((image, index) => (
                  <LazyLoadImage
                    key={index}
                    src={image.url}
                    alt={`Thumbnail ${index + 1}`}
                    className={`post-widget-thumbnail-image ${
                      index === currentImageIndex ? 'active' : ''
                    }`}
                    onClick={() => setCurrentImageIndex(index)}
                    width={60} 
                    height={60}
                    
                    placeholderSrc="path/to/placeholder/image.jpg"
                  />
                ))}
              </div>
            </div>
          ) : (
            
            <LazyLoadImage
              key={0}
              width="100%"
              height="auto"
              alt={`post image 1`}
              className="post-widget-image"
              src={images[0].url}
               
              placeholderSrc="path/to/placeholder/image.jpg"
            />
          )}
        </div>
      )}


          {videos.length > 0 && (
            <div className="post-widget-video-gallery">
              {videos.map((video, index) => (
            <div key={index} className="post-widget-video-container">
              <VideoPlayer videoUrl={video.key} /> 
            </div>
          ))}
            </div>
          )}
        </div>
  
        <FlexBetween mt="0.25rem">
          <FlexBetween gap="1rem">
            <FlexBetween gap="0.3rem">
              <IconButton onClick={handleLikeOrUnlikePost}>
                {isLiked ? (
                  <img src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/b0d6f7b5-d727-4f1d-cda5-a1cf207eae00/public" alt="Liked" className="post-widget-like-icon" />
                ) : (
                  <img src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/1d169027-443a-4f85-8c7d-707000933f00/public" alt="Not Liked" className="post-widget-like-icon" />
                )}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </FlexBetween>
  
            <FlexBetween gap="0.3rem">
            <IconButton onClick={() => setIsComments(!isComments)}>
            {(comments.length + newComments.length) > 0 ? (
              <ChatBubble style={{ color: 'white' }} />
            ) : (
              <ChatBubbleOutlineOutlined style={{ color: 'white' }} />
            )}
            </IconButton>
            <Typography>
              {totalCommentsCount}
            </Typography>

          </FlexBetween>
          </FlexBetween>
  
          <IconButton onClick={handleShowReshareInput} title="Share Post">
          <img src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/91301ff3-393c-42b0-6a3f-f4790206be00/public" style={{ color: 'white' }} className="post-widget-like-icon"/>
    </IconButton>
        </FlexBetween>
        {isComments && (
  <Comments
    postId={postId}
    totalComments={totalCommentsCount}
    setTotalComments={setTotalCommentsCount}
    comments={comments} 
    loggedInUserId={loggedInUserId}
    loggedInSellerId={loggedInSellerId}
    commentProfileType={commentProfileType}
    currentProfileId={currentProfileId}
  />
)}

      </WidgetWrapper>
  
      <Modal
        open={showReportModal}
        onClose={() => setShowReportModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showReportModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: '#333',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Report Post
            </Typography>
            <TextField
              select
              label="Reason"
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Spam">Spam</MenuItem>
              <MenuItem value="Harassment">Harassment</MenuItem>
              <MenuItem value="Inappropriate Content">Inappropriate Content</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
            <TextField
              label="Description"
              value={reportDescription}
              onChange={(e) => setReportDescription(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleReportPost}
              fullWidth
            >
              Submit Report
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );}

export default React.memo(PostWidgetRedux);




