import React, { useState, useEffect } from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

const navigation = [
  {
    name: 'Products',
    children: [
      { name: 'How to Create a Product', href: '/docs/create-a-product' },
      { name: 'Setting Up Variants', href: '/docs/product-variants' },
      { name: 'Tags', href: '/docs/tags' },
      { name: 'Tax Codes', href: '/docs/tax-codes' },
      { name: 'SKUs Explained', href: '/docs/what-is-a-sku' },
      { name: 'Managing Inventory', href: '/docs/inventory' },
    ],
  },
  {
    name: 'Brands',
    children: [
      { name: 'Create Brand', href: '/docs/brand-creation' },
      { name: 'Catalog', href: '/docs/brand-catalog' },
    ],
  },
  {
    name: 'Advertising',
    children: [
      { name: 'Create Ad Campaign', href: '/docs/campaign-creation' },
    ],
  },
  { name: 'FAQ', href: '/faq' },
  { name: 'Support', href: '/support' },
];

function DocumentationSidebar() {
  const [openSections, setOpenSections] = useState({});
  const [activeSection, setActiveSection] = useState(null);
  const [activeSubSection, setActiveSubSection] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    
    // Find the active section and sub-section based on the path
    navigation.forEach((section) => {
      if (section.children) {
        section.children.forEach((subSection) => {
          if (subSection.href === path) {
            setActiveSection(section.name);
            setActiveSubSection(subSection.name);
            setOpenSections((prev) => ({ ...prev, [section.name]: true }));
          }
        });
      } else if (section.href === path) {
        setActiveSection(section.name);
      }
    });
  }, [window.location.pathname]);

  const toggleSection = (sectionName) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  return (
    <div className="documentation-sidebar-container">
      <div className="documentation-sidebar-header">
        <img
          alt="BuzzVibe"
          src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724797193/BuzzVibe-TM-Gradient-outline_jfxvg6.png"
          className="documentation-sidebar-logo"
        />
      </div>
      <nav className="documentation-sidebar-nav">
        <ul className="documentation-sidebar-list">
          {navigation.map((item) => (
            <li key={item.name} className="documentation-sidebar-list-item">
              {!item.children ? (
                <a
                  href={item.href}
                  className={`documentation-sidebar-link ${item.name === activeSection ? 'current' : ''}`}
                >
                  {item.name}
                </a>
              ) : (
                <div className="documentation-sidebar-disclosure">
                  <button
                    className="documentation-sidebar-disclosure-button"
                    onClick={() => toggleSection(item.name)}
                  >
                    <span
                      aria-hidden="true"
                      className={`documentation-sidebar-chevron-icon ${openSections[item.name] ? 'open' : ''}`}
                    >
                      ▶
                    </span>
                    {item.name}
                  </button>
                  {openSections[item.name] && (
                    <ul className="documentation-sidebar-sublist">
                      {item.children.map((subItem) => (
                        <li key={subItem.name} className="documentation-sidebar-sublist-item">
                          <a
                            href={subItem.href}
                            className={`documentation-sidebar-sublink ${subItem.name === activeSubSection ? 'current' : ''}`}
                          >
                            {subItem.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className="documentation-sidebar-profile">
        <a href="/contact-us" className="documentation-sidebar-profile-link">
          <EnvelopeIcon className="documentation-sidebar-profile-img" />
          <span className="documentation-sidebar-profile-name">Contact Us</span>
        </a>
      </div>
    </div>
  );
}

export default DocumentationSidebar;
