import axios from "axios";
import { server } from "../../server";

export const SET_SELLER_ON_LOGIN = "SET_SELLER_ON_LOGIN";
export const SET_ACTIVE_SELLER_PROFILE = 'SET_ACTIVE_SELLER_PROFILE';
export const LOAD_SELLERS_SUCCESS = 'LOAD_SELLERS_SUCCESS';
export const SET_ACTIVE_SELLER = 'SET_ACTIVE_SELLER';

export const LOAD_ALL_SELLERS_REQUEST = 'LOAD_ALL_SELLERS_REQUEST';
export const LOAD_ALL_SELLERS_SUCCESS = 'LOAD_ALL_SELLERS_SUCCESS';
export const LOAD_ALL_SELLERS_FAIL = 'LOAD_ALL_SELLERS_FAIL';


export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const LOCATION_ERROR = 'LOCATION_ERROR';
export const SAVE_LOCATION_SUCCESS = 'SAVE_LOCATION_SUCCESS';
export const SAVE_LOCATION_ERROR = 'SAVE_LOCATION_ERROR';

export const FETCH_USER_AND_SUBSCRIPTION_REQUEST = 'FETCH_USER_AND_SUBSCRIPTION_REQUEST';
export const FETCH_USER_AND_SUBSCRIPTION_SUCCESS = 'FETCH_USER_AND_SUBSCRIPTION_SUCCESS';
export const FETCH_USER_AND_SUBSCRIPTION_FAILURE = 'FETCH_USER_AND_SUBSCRIPTION_FAILURE';

export const FETCH_SHOP_AND_SUBSCRIPTIONS_REQUEST = 'FETCH_SHOP_AND_SUBSCRIPTIONS_REQUEST';
export const FETCH_SHOP_AND_SUBSCRIPTIONS_SUCCESS = 'FETCH_SHOP_AND_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SHOP_AND_SUBSCRIPTIONS_FAILURE = 'FETCH_SHOP_AND_SUBSCRIPTIONS_FAILURE';


// Set user
export const setUser = (user) => {
  return {
    type: "SetUser",
    payload: user,
  };
};

// set seller on login
export const setSellerOnLogin = (seller) => {
  return {
    type: SET_SELLER_ON_LOGIN,
    payload: seller,
  };
};

// load user
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadUserRequest",
    });
    const { data } = await axios.get(`${server}/user/getuser`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadUserSuccess",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "LoadUserFail",
      payload: "There was a problem loading the user data. Please try again.",
    });
  }
};

// Load sellers
export const loadSellers = () => async (dispatch) => {
  try {
    
    dispatch({ type: LOAD_ALL_SELLERS_REQUEST });
    const { data } = await axios.get(`${server}/user/my-shops`, { withCredentials: true });
    
    dispatch({ type: LOAD_SELLERS_SUCCESS, payload: data.shops });
  } catch (error) {
    console.log('Loading sellers: Error', error.response?.data?.message || "There was a problem loading the user's shops.");
    dispatch({ type: LOAD_ALL_SELLERS_FAIL , payload: error.response?.data?.message || "There was a problem loading the user's shops." });
  }
};


export const loadAllSellers = (brand) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_ALL_SELLERS_REQUEST });
    const endpoint = brand ? `${server}/shop/all-sellers?brand=${brand}` : `${server}/shop/all-sellers`;
    const { data } = await axios.get(endpoint);
    dispatch({ type: LOAD_ALL_SELLERS_SUCCESS, payload: data.allSellers });
  } catch (error) {
    dispatch({
      type: LOAD_ALL_SELLERS_FAIL,
      payload: error.response?.data?.message || "There was a problem loading the sellers."
    });
  }
};

export const setActiveSeller = (seller) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_SELLER,
    payload: seller,
  });
  // Assuming seller has a 'name' property
  dispatch({
    type: "SwitchProfile",
    payload: seller.name,
  });
};


// load seller
export const loadSeller = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadSellerRequest",
    });

    const currentProfile = sessionStorage.getItem('currentProfile'); // Retrieve currentProfile from session storage

    const { data } = await axios.get(`${server}/shop/getSeller`, {
      withCredentials: true,
      headers: {
        'Current-Profile': currentProfile, // Send currentProfile as a custom header
      },
    });

    dispatch({
      type: "LoadSellerSuccess",
      payload: data.seller,
    });
    dispatch({
      type: SET_ACTIVE_SELLER,
      payload: data.seller,
    });
  } catch (error) {
    dispatch({
      type: "LoadSellerFail",
      payload: error.response.data.message,
    });
  }
};

export const fetchUserAndSubscription = () => async (dispatch) => {
  dispatch({ type: FETCH_USER_AND_SUBSCRIPTION_REQUEST });
  

  try {
    const userResponse = await axios.get(`${server}/user/getuser`, { withCredentials: true });
    

    const subscriptionResponse = await axios.get(`${server}/subscriptions/my-subscriptions`, { withCredentials: true });
    

    dispatch({
      type: FETCH_USER_AND_SUBSCRIPTION_SUCCESS,
      payload: {
        user: userResponse.data.user,
        subscriptions: subscriptionResponse.data,
      },
    });
    
  } catch (error) {
    console.error("Failed to fetch user and subscription data:", error.response ? error.response.data.message : error.message);
    dispatch({
      type: FETCH_USER_AND_SUBSCRIPTION_FAILURE,
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};

export const fetchShopAndSubscriptions = (shopId) => async (dispatch) => {
  dispatch({ type: FETCH_SHOP_AND_SUBSCRIPTIONS_REQUEST });
  

  try {
      const currentProfile = sessionStorage.getItem('currentProfile');
      // Fetch shop details
      const shopResponse = await axios.get(`${server}/shop/getSeller`, { withCredentials: true, headers: {
        'Current-Profile': currentProfile, 
      }, });
      

      // Fetch shop subscriptions
      const subscriptionResponse = await axios.get(`${server}/subscriptions/shop-subscriptions/my-subscriptions`, { withCredentials: true,
        headers: {
        'Current-Profile': currentProfile, 
      },});
      

      dispatch({
          type: FETCH_SHOP_AND_SUBSCRIPTIONS_SUCCESS,
          payload: {
              shop: shopResponse.data, 
              subscriptions: subscriptionResponse.data, 
          },
      });
      
  } catch (error) {
      console.error("Failed to fetch shop and subscription data:", error.response ? error.response.data.message : error.message);
      dispatch({
          type: FETCH_SHOP_AND_SUBSCRIPTIONS_FAILURE,
          payload: error.response ? error.response.data.message : error.message,
      });
  }
};

// Switch profile
export const switchProfile = () => (dispatch, getState) => {
  const currentState = getState();
  const currentProfile = currentState.user.currentProfile;
  const userProfileName = currentState.user.user.name;
  const activeSellerName = currentState.seller.activeSeller?.name;

  // Determine the new profile based on the current profile
  let newProfile;
  if (currentProfile === 'User') {
    newProfile = activeSellerName || "User"; // Switch to active seller or default to "User"
  } else {
    newProfile = "User"; // Switch to user profile or default to "User"
  }

  console.log("New profile to switch:", newProfile);

  sessionStorage.setItem('currentProfile', newProfile); 
  dispatch({ type: "SwitchProfile", payload: newProfile });

  // Dispatch actions to load the appropriate data
  if (newProfile === "User") {
    dispatch(loadUser());
  } else if (newProfile === activeSellerName) {
    dispatch(loadSeller());
  }
};





// Add this action creator function in your actions file
export const setActiveSellerProfile = (index) => {
  return {
    type: SET_ACTIVE_SELLER_PROFILE,
    payload: index,
  };
};




// user update information
export const updateUserInformation =
  (name, email, phoneNumber, password) => async (dispatch) => {
    try {
      dispatch({
        type: "updateUserInfoRequest",
      });

      const { data } = await axios.put(
        `${server}/user/update-user-info`,
        {
          email,
          password,
          phoneNumber,
          name,
        },
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Credentials": true,
          },
        }
      );

      dispatch({
        type: "updateUserInfoSuccess",
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: "updateUserInfoFailed",
        payload: "There was a problem updating the user data. Please try again.",
      });
    }
  };

// update user address
export const updatUserAddress =
  (country, city, address1, address2, zipCode, addressType) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "updateUserAddressRequest",
      });

      const { data } = await axios.put(
        `${server}/user/update-user-addresses`,
        {
          country,
          city,
          address1,
          address2,
          zipCode,
          addressType,
        },
        { withCredentials: true }
      );

      dispatch({
        type: "updateUserAddressSuccess",
        payload: {
          successMessage: "User address updated succesfully!",
          user: data.user,
        },
      });
    } catch (error) {
      dispatch({
        type: "updateUserAddressFailed",
        payload: "There was a problem updating the user address data. Please try again.",
      });
    }
  };

// delete user address
export const deleteUserAddress = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteUserAddressRequest",
    });

    const { data } = await axios.delete(
      `${server}/user/delete-user-address/${id}`,
      { withCredentials: true }
    );

    dispatch({
      type: "deleteUserAddressSuccess",
      payload: {
        successMessage: "User deleted successfully!",
        user: data.user,
      },
    });
  } catch (error) {
    dispatch({
      type: "deleteUserAddressFailed",
      payload: "There was a problem deleting the user address. Please try again.",
    });
  }
};

// get all users --- admin
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsersRequest",
    });

    const { data } = await axios.get(`${server}/user/admin-all-users`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllUsersSuccess",
      payload: data.users,
    });
  } catch (error) {
    dispatch({
      type: "getAllUsersFailed",
      payload: "There was a problem loading the user data. Please try again.",
    });
  }
};

//Save User Location
export const saveLocation = (locationData) => async (dispatch) => {
  try {
      const response = await axios({
          method: 'post',
          url: `${server}/locations/locations`,
          data: locationData,
          withCredentials: true, 
          headers: {
              'Content-Type': 'application/json'
          }
      });
      dispatch({
          type: SAVE_LOCATION_SUCCESS,
          payload: response.data 
      });
  } catch (error) {
      dispatch({
          type: SAVE_LOCATION_ERROR,
          payload: error.response ? error.response.data.message : error.message
      });
  }
};

export const setUserLocation = (location) => ({
  type: SET_USER_LOCATION,
  payload: location,
});

export const locationError = (error) => ({
  type: LOCATION_ERROR,
  payload: error,
});