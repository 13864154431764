import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useStripeConnect } from "../../useStripeConnect";
import axios from "axios"; 
import { loadSeller } from "../../redux/actions/user";
import { server } from '../../server';
import { useNavigate } from 'react-router-dom'; 
import OnboardingSteps from "../../components/Shop/OnboardingSteps";
import useWindowSize from "../../hooks/useWindowSize";
import OnboardingStepsMobile from "../../components/Shop/OnboardingStepsMobile";

export default function ShopPaymentSettingsPage() {
  const [error, setError] = useState(false);
  const { seller } = useSelector((state) => state.seller); 
  const connectedAccountId = seller?.stripeAccountId;
  const stripeConnectInstance = useStripeConnect(connectedAccountId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  useEffect(() => {
    dispatch(loadSeller());
  }, [dispatch]);

  // Function to update onboarding status
  const updateOnboardingStatus = async () => {
    try {
      const response = await axios.post(
        `${server}/shop/update-onboarding-status`, 
        {},
        { withCredentials: true,  headers: {
          'current-profile': sessionStorage.getItem('currentProfile'),
      },}
      );
      if (response.status === 200) {
        console.log("Onboarding status updated successfully.");
        // Reload seller info
        dispatch(loadSeller());
        navigate('/payment-method-setup'); 
      } else {
        console.error("Failed to update onboarding status:", response.data);
      }
    } catch (err) {
      console.error("Error updating onboarding status:", err);
    }
  };


   // Function to check onboarding status once
   const checkOnboardingStatus = async () => {
    try {
      const { data } = await axios.get(`${server}/shop/onboarding-status`, {
        withCredentials: true,
        headers: {
          'current-profile': sessionStorage.getItem('currentProfile'),
      },
      });
      if (data.onboardingCompleted) {
        console.log("Onboarding is completed.");
        updateOnboardingStatus();
      } else {
        console.log("Onboarding is not completed.");
      }
    } catch (err) {
      console.error("Error fetching onboarding status:", err);
    }
  };

  const isMobile = windowSize.width <= 768; 

  return (
    <div>  {isMobile ? (
      <OnboardingStepsMobile currentStep={3} />
    ) : (
      <OnboardingSteps currentStep={3} />
    )}

    <div className="shop-onboarding-container">
      <div className="shop-onboarding-banner">
        <h2>{seller?.name || "Seller"}</h2>
      </div>
      <div className="shop-onboarding-content">
        {!stripeConnectInstance && (
          <h2>Add information to start accepting money</h2>
        )}
        {error && (
          <p className="shop-onboarding-error">
            Something went wrong, please try again!
          </p>
        )}
        {stripeConnectInstance && (
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <div className="shop-onboarding-onboarding-container">
              <ConnectAccountOnboarding
                onExit={() => {
                  console.log("Onboarding process has exited.");
                  checkOnboardingStatus(); 
                }}
                onError={(error) => {
                  console.error("Onboarding process error:", error);
                  setError(true);
                }}
              />
            </div>
          </ConnectComponentsProvider>
        )}
      </div>
    </div>
    </div>
  );
}



