import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { adsServer } from "../../server";

const TopBannerAdPropsMobile = ({ ad }) => {
    const [isDismissed, setIsDismissed] = useState(false);
    const adRef = useRef(null); 

    const handleDismiss = () => {
        setIsDismissed(true); 
    };

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/click/${adId}`, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };


    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Viewed impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };


    useEffect(() => {
        if (ad && adRef.current) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const adId = ad._id;

                            // Start a timer for 1 second to log viewed impression
                            const timer = setTimeout(() => {
                                logViewedImpression(adId);
                            }, 1000);

                            // Store the timer in the DOM element
                            entry.target._timer = timer;
                        } else {
                            clearTimeout(entry.target._timer); // Clear the timer if the element goes out of view
                        }
                    });
                },
                { threshold: 0.5 } // 50% of the ad must be visible
            );

            observer.observe(adRef.current);

            return () => {
                if (adRef.current) observer.unobserve(adRef.current);
            };
        }
    }, [ad]);

    if (!ad || isDismissed) return null;

    const imageUrl = ad.adSet.images["Top Banner Mobile Ad"];
    const position = ad.adSet.positions["Top Banner Mobile Ad"] || { x: 0, y: 0 };
    const scale = ad.adSet.scales["Top Banner Mobile Ad"] || 1;

    return (
        <div className="top-banner-ad-mobile-container">
            <div className="top-banner-ad-mobile-content">
                <div className="top-banner-ad-image-mobile-container">
                    <img
                        src={imageUrl}
                        alt="Product"
                        className="top-banner-ad-mobile-image"
                        style={{
                            position: 'absolute',
                            left: `${position.x}%`,
                            top: `${position.y}%`,
                            transform: `scale(${scale})`,
                            transformOrigin: 'center',
                        }}
                    />
                </div>
                <div className="top-banner-ad-mobile-text">
                    <Link 
                        to={`/product/${ad?.product?._id}`}
                        onClick={() => logAdClick(ad?._id)}
                        className="top-banner-ad-mobile-link"
                    >
                        <strong>{ad.product.name}</strong>
                        <span> – Explore Now &rarr;</span>
                    </Link>
                </div>
                <button onClick={handleDismiss} className="top-banner-ad-dismiss">
                    <XMarkIcon className="top-banner-ad-icon" aria-hidden="true" />
                    <span className="sr-only">Dismiss</span>
                </button>
            </div>
        </div>
    );
    
};

export default TopBannerAdPropsMobile;