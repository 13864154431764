import React, { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  likeOrUnlikePost,
  editPost,
  deletePost,
  resharePost,
  fetchSinglePostLikes,
  reportPost,
  fetchSinglePost,
} from '../redux/actions/post';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Box, IconButton, Typography, Modal, Backdrop, Fade, TextField, Button, MenuItem } from "@mui/material";
import { Popover, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import {
  ChatBubbleOutlineOutlined,
  ChatBubble,
  RocketLaunchOutlined
} from "@mui/icons-material";

import FlexBetween from "../components/FlexBetween";
import Friend from "../components/Friend";
import WidgetWrapper from "../components/WidgetWrapper";
import VideoPlayer from "../components/Content/VideoPlayer"; // Import VideoPlayer
import Comments from "../components/Comments/Comments";
import PropTypes from 'prop-types';

const SinglePostWidgetRedux = ({
  postId,
  postUserId,
  owner,
  name,
  description,
  profileType,
  location,
  images = [],
  videos = [],
  avatar,
  likes = {},
  createdAt,
  resharedBy,
  reshareDescription,
  reshareAvatar,
  link,
  initialIsComments = false, // Existing prop
  initialShowReshareInput = false, // New prop
}) => {
  const [isComments, setIsComments] = useState(initialIsComments); // Initialize with prop
  const [showReportModal, setShowReportModal] = useState(false); 
  const [reportReason, setReportReason] = useState(''); 
  const [reportDescription, setReportDescription] = useState(''); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const seller = useSelector((state) => state.seller.activeSeller);
  const loggedInUserId = user?._id;
  const loggedInSellerId = seller?._id;
  const currentProfileType = sessionStorage.getItem('currentProfile');
  const currentProfileId = useMemo(() => currentProfileType === 'User' ? user?._id : seller?._id, [currentProfileType, user, seller]);

  const [scrollPosition, setScrollPosition] = useState(0); 
  const [showReshareInput, setShowReshareInput] = useState(initialShowReshareInput); // Initialize with prop
  const [reshareInput, setReshareInput] = useState('');
  const [isReshare] = useState(!!resharedBy);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedReshareDescription, setEditedReshareDescription] = useState(reshareDescription);
  const formattedDate = useMemo(() => format(new Date(createdAt), "PPP"), [createdAt]);

  // State for totalComments
  const [totalComments, setTotalComments] = useState(0);

  // State for image navigation
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Reset currentImageIndex when images change
  useEffect(() => {
    setCurrentImageIndex(0);
  }, [images]);

  // Functions to navigate images
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Select single post data from Redux store
  const postData = useSelector((state) => state.posts.singlePost);
  const comments = postData?.comments || [];

  // Local state for isLiked and likeCount
  const [isLiked, setIsLiked] = useState(postData?.isLikedByCurrentUser || false);
  const [likeCount, setLikeCount] = useState(postData?.likeCount || 0);

  // Update local state when postData changes
  useEffect(() => {
    setIsLiked(postData?.isLikedByCurrentUser || false);
    setLikeCount(postData?.likeCount || 0);
  }, [postData]);

  // Update totalComments when postData changes
  useEffect(() => {
    setTotalComments(postData?.totalComments || 0);
  }, [postData]);

  // Fetch single post data on mount and when postId changes
  useEffect(() => {
    dispatch(fetchSinglePost(postId)).catch((error) => {
      console.error('Error fetching single post:', error.message);
      toast.error('Failed to fetch post: ' + error.message);
    });
  }, [dispatch, postId]);

  // Fetch likes for the single post
  useEffect(() => {
    dispatch(fetchSinglePostLikes(postId));
  }, [dispatch, postId]);

  // Toggle edit mode for the post
  const toggleEditMode = useCallback((e) => {
    if (e) e.preventDefault();
    if (!isEditMode) {
      setScrollPosition(window.scrollY);
    } else {
      window.scrollTo(0, scrollPosition);
    }
    setIsEditMode(!isEditMode);
    if (isEditMode) {
      setEditedDescription(description);
    }
  }, [description, isEditMode, scrollPosition]);

  // Handle canceling reshare
  const handleCancelReshare = () => {
    setShowReshareInput(false); 
    setReshareInput(''); 
  };

  // Handle showing reshare input
  const handleShowReshareInput = () => {
    setReshareInput('');
    setShowReshareInput(true);
  };

  // Handle canceling edit mode
  const handleCancelEdit = () => {
    setEditedDescription(description); 
    setEditedReshareDescription(reshareDescription); 
    setIsEditMode(false); 
  };

  // Handle like or unlike post
  const handleLikeOrUnlikePost = () => {
    const wasLiked = isLiked;
    const updatedLikeCount = wasLiked ? likeCount - 1 : likeCount + 1;

    // Update UI optimistically
    setIsLiked(!wasLiked);
    setLikeCount(updatedLikeCount);

    dispatch(likeOrUnlikePost(postId, currentProfileType))
      .then(() => {
        // Optionally, handle success
      })
      .catch((error) => {
        // Revert UI changes if there is an error
        setIsLiked(wasLiked);
        setLikeCount(likeCount);
        toast.error('Failed to update like status: ' + error.message);
      });
  };

  // Handle post editing submission
  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(editPost(postId, editedDescription, reshareDescription))
      .catch((error) => {
        toast.error('Failed to edit post');
      });
    setIsEditMode(false);
  };

  // Handle post deletion
  const handleDeletePost = (e) => {
    e.preventDefault();
    dispatch(deletePost(postId)).catch((error) => {
      toast.error('Failed to delete post');
    });
  };

  // Handle sharing the post
  const handleSharePost = () => {
    const reshareData = {
      profileType: currentProfileType,
      reshareDescription: reshareInput, 
    };
    dispatch(resharePost(postId, reshareData))
      .then(() => {
        setShowReshareInput(false);
        setReshareInput(''); 
        toast.success('Post reshared successfully');
      })
      .catch((error) => {
        toast.error('Failed to reshare post');
      });
  };

  // Handle reporting the post
  const handleReportPost = () => {
    if (!reportReason || !reportDescription) {
      toast.error('Please provide both a reason and a description.');
      return;
    }

    const reportData = {
      postId,
      reason: reportReason,
      description: reportDescription,
    };

    dispatch(reportPost(reportData))
      .then(() => {
        setShowReportModal(false);
        setReportReason('');
        setReportDescription('');
        toast.success('Post reported successfully.');
      })
      .catch((error) => {
        toast.error('Failed to report post');
      });
  };

  // Render edit form for the post
  const renderEditForm = () => (
    <form onSubmit={handleEditSubmit} style={{ width: '100%' }}>
      <textarea
        autoFocus
        className="edit-description-textarea"
        value={isReshare ? editedReshareDescription : editedDescription}
        onChange={(e) => isReshare ? setEditedReshareDescription(e.target.value) : setEditedDescription(e.target.value)}
        rows={3}
        style={{
            width: '100%',
            background: 'none',
            border: 'none',
            fontSize: 'inherit', 
            color: 'inherit', 
            lineHeight: 'inherit', 
            resize: 'none', 
            outline: 'none', 
          }}
      />
      <div className="flex justify-end space-x-2 mt-3">
        <button type="submit" className="inline-flex items-center justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Save Changes
        </button>
        <button type="button" onClick={handleCancelEdit} className="inline-flex items-center justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Cancel
        </button>
      </div>
    </form>
  );

  // Parse hashtags in the post description
  const parseHashtags = useCallback((text) => {
    if (!text) return null;
    const words = text.split(' ');
    return words.map((word, index) => {
      if (word.startsWith('#')) {
        const hashtag = word.substring(1);
        return (
          <span
            key={index}
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => navigate(`/hashtag/${hashtag}`)}
          >
            {word}{' '}
          </span>
        );
      }
      return `${word} `;
    });
  }, [navigate]);

  // Render link preview if available
  const renderLinkPreview = (link) => {
    if (!link || !link.url) return null;

    return (
      <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-post-preview-link">
        <Box className="link-post-preview-container">
          {link.image && (
            <img src={link.image} alt={link.title} className="link-post-preview-image" />
          )}
          <Box className="link-post-preview-content">
            <Typography variant="h6" component="div" className="link-post-preview-title">
              {link.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" className="link-post-preview-description">
              {link.description}
            </Typography>
            <Typography variant="body2" color="primary" className="link-post-preview-url">
              {link.url}
            </Typography>
          </Box>
        </Box>
      </a>
    );
  };

  return (
    <>
      <ToastContainer />
      <WidgetWrapper m="2rem 0" className="post-widget-wrapper">
        {/* Post Options Popover for Owners */}
        {(loggedInUserId === owner._id || loggedInSellerId === owner._id) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon className="post-widget-icon" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={(e) => toggleEditMode(e)} 
                    className="post-widget-popover-item"
                  >
                    Edit
                  </a>
                  <a
                    href="#"
                    onClick={(e) => handleDeletePost(e)} 
                    className="post-widget-popover-item"
                  >
                    Delete
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
      
        {/* Post Options Popover for Non-Owners */}
        {(loggedInUserId !== owner._id && loggedInSellerId !== owner._id) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon className="post-widget-icon" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={() => setShowReportModal(true)} 
                    className="post-widget-popover-item"
                  >
                    Report
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
      
        {/* Reshare Information */}
        {resharedBy && (
          <div className="post-widget-reshare">
            <div className="post-widget-reshare-header">
              <img 
                src={reshareAvatar || 'default-avatar-url'} 
                alt={resharedBy}
                className="post-widget-reshare-avatar" 
              />
              <span className="post-widget-reshare-by">Reshared by {resharedBy}</span>
            </div>
            {reshareDescription && (
              <div className="post-widget-reshare-description">
                {isEditMode && isReshare ? renderEditForm() : reshareDescription}
              </div>
            )}
          </div>
        )}
      
        {/* Reshare Input */}
        {showReshareInput && (
          <div className="post-widget-reshare-container">
            <textarea
              className="post-widget-reshare-textarea"
              value={reshareInput}
              onChange={(e) => setReshareInput(e.target.value)}
              placeholder="Add a description to your reshare..."
            />
            <div className="post-widget-reshare-buttons-container">
              <button className="post-widget-reshare-button post-widget-cancel-button" onClick={handleCancelReshare}>
                Cancel
              </button>
              <button className="post-widget-reshare-button" onClick={handleSharePost}>
                Share
              </button>
            </div>
          </div>
        )}
        
        {/* Post Header: Friend Component */}
        <Friend
          friendId={isReshare ? postUserId : owner._id} 
          friendType={profileType}
          name={name}
          subtitle={location}
          avatar={avatar}
        />
        <div className="post-widget-date">
          {formattedDate}
        </div>
        <div>
          {isEditMode && !isReshare ? renderEditForm() : (
            <Typography color="#1f9c29" sx={{ mt: "1rem" }}>
              {parseHashtags(description)}
            </Typography>
          )}
        </div>
        
        {/* Link Preview */}
        {link && renderLinkPreview(link)}
  
        {/* Media Content */}
        <div className="post-widget-media-container">
          {images.length > 0 && (
            <div className="post-widget-image-gallery">
              {images.length > 1 ? (
                <div className="post-widget-slideshow-container">
                  <div className="post-widget-slideshow">
                    <button onClick={prevImage} className="post-widget-prev-button">
                      &#10094;
                    </button>
                    <LazyLoadImage
                      src={images[currentImageIndex].url}
                      alt={`Slide ${currentImageIndex + 1}`}
                      className="post-widget-slideshow-image"
                      placeholderSrc="path/to/placeholder/image.jpg"
                    />
                    <button onClick={nextImage} className="post-widget-next-button">
                      &#10095;
                    </button>
                  </div>
                  <div className="post-widget-thumbnails-container">
                    {images.map((image, index) => (
                      <LazyLoadImage
                        key={index}
                        src={image.url}
                        alt={`Thumbnail ${index + 1}`}
                        className={`post-widget-thumbnail-image ${
                          index === currentImageIndex ? 'active' : ''
                        }`}
                        onClick={() => setCurrentImageIndex(index)}
                        width={60} 
                        height={60}
                        placeholderSrc="path/to/placeholder/image.jpg"
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <LazyLoadImage
                  key={0}
                  width="100%"
                  height="auto"
                  alt={`post image 1`}
                  className="post-widget-image"
                  src={images[0].url}
                  placeholderSrc="path/to/placeholder/image.jpg"
                />
              )}
            </div>
          )}

          {videos.length > 0 && (
            <div className="post-widget-video-gallery">
              {videos.map((video, index) => (
                <div key={index} className="post-widget-video-container">
                  <VideoPlayer videoUrl={video.key || video.url} /> 
                </div>
              ))}
            </div>
          )}

        </div>
  
        {/* Post Actions: Like, Comment, Share */}
        <FlexBetween mt="0.25rem">
          <FlexBetween gap="1rem">
            <FlexBetween gap="0.3rem">
              <IconButton onClick={handleLikeOrUnlikePost}>
                {isLiked ? (
                  <img src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/b0d6f7b5-d727-4f1d-cda5-a1cf207eae00/public" alt="Liked" className="post-widget-like-icon" />
                ) : (
                  <img src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/1d169027-443a-4f85-8c7d-707000933f00/public" alt="Not Liked" className="post-widget-like-icon" />
                )}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </FlexBetween>

            <FlexBetween gap="0.3rem">
              <IconButton onClick={() => setIsComments(!isComments)}>
                {totalComments > 0 ? (
                  <ChatBubble style={{ color: 'white' }}/>
                ) : (
                  <ChatBubbleOutlineOutlined style={{ color: 'white' }}/> 
                )}
              </IconButton>
              <Typography>{totalComments}</Typography>
            </FlexBetween>
          </FlexBetween>

          <IconButton onClick={handleShowReshareInput} title="Share Post">
          <img src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/91301ff3-393c-42b0-6a3f-f4790206be00/public" style={{ color: 'white' }} className="post-widget-like-icon"/>
          </IconButton>
        </FlexBetween>

        {/* Comments Component */}
        {isComments && (
          <Comments
            postId={postId}
            totalComments={totalComments}
            setTotalComments={setTotalComments}
            comments={comments}
            loggedInUserId={loggedInUserId}
            loggedInSellerId={loggedInSellerId}
            commentProfileType={currentProfileType}
            currentProfileId={currentProfileId}
          />
        )}

      </WidgetWrapper>

      {/* Report Post Modal */}
      <Modal
        open={showReportModal}
        onClose={() => setShowReportModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showReportModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: '#333',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2" color="white">
              Report Post
            </Typography>
            <TextField
              select
              label="Reason"
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: { color: '#fff' }, // Adjust label color if necessary
              }}
              InputProps={{
                style: { color: '#fff' }, // Adjust input text color if necessary
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      backgroundColor: '#444', // Adjust dropdown background color
                    },
                  },
                },
              }}
            >
              <MenuItem value="Spam">Spam</MenuItem>
              <MenuItem value="Harassment">Harassment</MenuItem>
              <MenuItem value="Inappropriate Content">Inappropriate Content</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
            <TextField
              label="Description"
              value={reportDescription}
              onChange={(e) => setReportDescription(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleReportPost}
              fullWidth
            >
              Submit Report
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

SinglePostWidgetRedux.propTypes = {
  postId: PropTypes.string.isRequired,
  postUserId: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.shape({
      url: PropTypes.string,
    }),
    location: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  profileType: PropTypes.string.isRequired,
  location: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
  avatar: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  resharedBy: PropTypes.string,
  reshareDescription: PropTypes.string,
  reshareAvatar: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
  initialIsComments: PropTypes.bool, // Existing prop
  initialShowReshareInput: PropTypes.bool, // New prop
};

export default React.memo(SinglePostWidgetRedux);




