import React, { useState } from 'react';
import axios from 'axios';
import { ecommerceServer } from "../../server";
import IncompleteProductEditor from './IncompleteProductEditor';

const ImportShopifyProducts = () => {
  const [shopifyStoreUrl, setShopifyStoreUrl] = useState('');
  const [shopifyAccessToken, setShopifyAccessToken] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const [incompleteProducts, setIncompleteProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImport = async () => {
    setMessage('');
    setErrors([]);
    setIncompleteProducts([]);
    setLoading(true);

    try {

      const response = await axios.post(
        `${ecommerceServer}/product/import-shopify-products`,
        {
          shopifyStoreUrl,
          shopifyAccessToken,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'current-profile': sessionStorage.getItem('currentProfile') || 'User',
          },
        }
      );

      setLoading(false);

      if (response.data.success) {
        setMessage(response.data.message);
        if (response.data.errors && response.data.errors.length > 0) {
          setErrors(response.data.errors);
        }
        if (response.data.incompleteProducts && response.data.incompleteProducts.length > 0) {
          setIncompleteProducts(response.data.incompleteProducts);
        }
      } else {
        setMessage(response.data.message || 'Failed to import products.');
        if (response.data.errors && response.data.errors.length > 0) {
          setErrors(response.data.errors);
        }
      }
    } catch (error) {
      console.error('Error importing products:', error);
      setMessage('An error occurred while importing products.');
      setLoading(false);
    }
  };

  const isFormValid = () => {
    return shopifyStoreUrl.trim() !== '' && shopifyAccessToken.trim() !== '';
  };

  // Handler to update incomplete products
  const handleUpdateProduct = (updatedProduct) => {
    setIncompleteProducts((prevProducts) =>
      prevProducts.map((product) =>
        product._id === updatedProduct._id ? updatedProduct : product
      )
    );
  };

  return (
    <div className="shopify-import-container">
      <h2 className="shopify-import-title">Import Products from Shopify</h2>
      <div className="shopify-import-form">
        <div className="shopify-import-form-group">
          <label className="shopify-import-label" htmlFor="storeUrl">
            Shopify Store URL:
          </label>
          <input
            id="storeUrl"
            type="text"
            className="shopify-import-input"
            value={shopifyStoreUrl}
            onChange={(e) => setShopifyStoreUrl(e.target.value)}
            placeholder="your-store-name.myshopify.com"
          />
        </div>
        <div className="shopify-import-form-group">
          <label className="shopify-import-label" htmlFor="accessToken">
            Shopify Access Token:
          </label>
          <input
            id="accessToken"
            type="password"
            className="shopify-import-input"
            value={shopifyAccessToken}
            onChange={(e) => setShopifyAccessToken(e.target.value)}
            placeholder="Your Shopify Access Token"
          />
        </div>
        <button
          className="shopify-import-button"
          onClick={handleImport}
          disabled={loading || !isFormValid()}
        >
          {loading ? 'Importing...' : 'Import Products'}
        </button>
        {message && <p className="shopify-import-message">{message}</p>}
        {errors.length > 0 && (
          <div className="shopify-import-errors">
            <h3 className="shopify-import-error-title">Errors:</h3>
            <ul className="shopify-import-error-list">
              {errors.map((error, index) => (
                <li key={index} className="shopify-import-error-item">
                  <strong>{error.product}:</strong> {error.error}
                </li>
              ))}
            </ul>
          </div>
        )}
        {incompleteProducts.length > 0 && (
          <div className="shopify-incomplete-products">
            <h3 className="shopify-incomplete-products-title">Products Requiring Additional Information:</h3>
            {incompleteProducts.map((product) => (
              <IncompleteProductEditor
                key={product._id}
                product={product}
                onUpdate={handleUpdateProduct}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportShopifyProducts;

