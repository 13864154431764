import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Password updated successfully!");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="profile-content-component-password-change">
      <h1 className="profile-content-component-title">Change Password</h1>
      <form onSubmit={passwordChangeHandler} className="profile-content-component-form">
        <div className="profile-content-component-form-group">
          <label className="profile-content-component-form-label">Enter your old password</label>
          <input
            type="password"
            className="profile-content-component-form-input"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="profile-content-component-form-group">
          <label className="profile-content-component-form-label">Enter your new password</label>
          <input
            type="password"
            className="profile-content-component-form-input"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="profile-content-component-form-group">
          <label className="profile-content-component-form-label">Enter your confirm password</label>
          <input
            type="password"
            className="profile-content-component-form-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="profile-content-component-submit-btn">
          Update
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
