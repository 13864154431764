import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { adsServer } from '../../server';
import { AiOutlineClose } from "react-icons/ai";
import Select, { components } from 'react-select';


const TagSelector = ({ selectedTags, updateTags }) => {
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [customTag, setCustomTag] = useState('');

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const response = await axios.get(`${adsServer}/tags/tags`, { withCredentials: true });
      setAvailableTags(response.data); // Fetch all tags, both approved and unapproved
    } catch (error) {
      console.error('Failed to fetch tags:', error);
    }
  };

  const handleTagSelection = (option) => {
    if (option) {
      const tagToAdd = availableTags.find(tag => tag._id === option.value);
      
      if (!selectedTags.some(tag => tag._id === tagToAdd._id)) {
        const newSelectedTags = [...selectedTags, tagToAdd];
        updateTags(newSelectedTags);
      }
      setSelectedTag(null);
    }
  };

  const handleCustomTagAddition = async () => {
    console.log("Custom Tag: ", customTag); // Log custom tag name
    if (customTag.trim() && !selectedTags.some(tag => tag.name.toLowerCase() === customTag.toLowerCase())) {
      try {
        const response = await axios.post(`${adsServer}/tags/user-tags`, { name: customTag }, { withCredentials: true });
        if (response.data.tag) { // Ensure response contains the tag object
          const newTag = { ...response.data.tag, approved: false };
          const newSelectedTags = [...selectedTags, newTag];
          updateTags(newSelectedTags);
          setCustomTag('');
        }
      } catch (error) {
        console.error('Error adding custom tag:', error);
      }
    }
  };

  const handleRemoveTag = (tagId) => {
    const updatedTags = selectedTags.filter(tag => tag._id !== tagId);
    updateTags(updatedTags);
  };

  // Sort tags: approved first
  const sortedTags = useMemo(() => {
    return [...availableTags].sort((a, b) => {
      if (a.approved === b.approved) return 0;
      return a.approved ? -1 : 1;
    });
  }, [availableTags]);

  // Prepare options for react-select
  const options = sortedTags.map(tag => ({
    value: tag._id,
    label: tag.name,
    approved: tag.approved,
  }));

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(250, 250, 250, 0)',
      borderColor: 'rgba(250, 250, 250, 0)',
      boxShadow: 'none',
      
      '&:hover': {
        borderColor: '#aaa',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }),
    option: (provided, state) => ({
      ...provided,
      border: '1px solid',
      borderColor: state.data.approved ? '#36d300' : '#FFA500',
      backgroundColor: state.isFocused 
        ? (state.data.approved ? 'rgba(54, 211, 0, 0.3)' : 'rgba(255, 165, 0, 0.3)') 
        : 'rgba(0, 0, 0, 0.75)',
      color: 'white',
      padding: 10,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgba(250, 250, 250, 0.6)',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#fff',
      '&:hover': {
        color: '#fff',
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: '#ccc',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
  };

  // Custom option component to include approval status
  const Option = (props) => (
    <components.Option {...props}>
      <span
        className={`tag-option ${props.data.approved ? 'approved' : 'unapproved'}`}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {props.label}
      </span>
    </components.Option>
  );

  return (
    <div className="tag-selector">
      <div className="tag-dropdown-container">
        <Select
          value={selectedTag}
          onChange={handleTagSelection}
          options={options}
          styles={customStyles}
          components={{ Option }}
          placeholder="Select a tag"
          isClearable
        />
      </div>
      <div className="custom-tag-container">
        <input
          type="text"
          value={customTag}
          onChange={(e) => setCustomTag(e.target.value)}
          className="tag-custom-input"
          placeholder="Add custom tag"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); 
              handleCustomTagAddition();
            }
          }}
        />
        <button type="button" onClick={handleCustomTagAddition} className="tag-selector-button">Add Custom Tag</button>
      </div>
      <div className="selected-tags-list">
        {selectedTags.map(tag => (
          <span key={tag._id} className={`tag-badge ${tag.approved ? 'approved' : 'unapproved'}`}>
            {tag.name}
            <button onClick={() => handleRemoveTag(tag._id)} className="remove-tag-button">
              <AiOutlineClose size={14} />
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagSelector;

