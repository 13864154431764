import React from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import CreateProduct from "../../components/Shop/CreateProduct";
import BulkProductUpload from "../../components/Shop/BulkProductUpload";
import ImportShopifyProductsModal from '../../components/Shop/ImportShopifyProductsModal';

const ShopCreateProduct = () => {
  return (
    <div className="shop-create-product-page-container">
      <DashboardHeader />
      <div className="shop-create-product-page-main">
        <div className="shop-create-product-page-sidebar">
          <DashboardSideBar active={4} />
        </div>
        <div className="shop-create-product-page-content">
          <div className="shop-create-product-page-header">
            <h3 className="shop-create-product-page-title">Create Products</h3>
            <div className="shop-create-product-page-upload">
              <BulkProductUpload />
              <ImportShopifyProductsModal />
            </div>
          </div>
          <CreateProduct />
        </div>
      </div>
    </div>
  );
};

export default ShopCreateProduct;
