import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';

import HelpContactForm from '../../components/Documentation/HelpContactForm';

const HelpContactPage = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <HelpContactForm />
    </div>
  );
};

export default HelpContactPage
