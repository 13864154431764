import React from 'react';

const CampaignManagementGuide = () => {
  return (
    <div className="doc-container">
      <h1 className="doc-title">Guide to Creating and Managing Ad Campaigns</h1>
      <p className="doc-intro">
        The ad campaign management feature in your UI allows you to create, customize, and manage ad campaigns for products, shops, brands, and more. This guide will help you navigate the campaign creation process, from selecting ad types to reviewing and submitting your campaign.
      </p>

      <h2 className="doc-step h2">Step-by-Step Guide to Creating an Ad Campaign</h2>
      <ol className="doc-steps">
        <li className="doc-step">
          <h2>Start a New Campaign</h2>
          <p>To start creating an ad campaign, follow these steps:</p>
          <ul>
            <li><strong>Click Start Campaign</strong>: Begin by clicking the <strong>Start Campaign</strong> button to initiate the campaign creation process.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Choose Your Ad Type</h2>
          <p>In this step, you will select the type of ads you want to run:</p>
          <ul>
            <li><strong>Product Ads</strong>: Promote specific products in your shop.</li>
            <li><strong>Shop Ads</strong>: Advertise your shop to attract more customers.</li>
            <li><strong>Brand Ads</strong>: Promote a particular brand associated with your shop.</li>
            <li><strong>Custom Ads</strong>: Create custom ads with specific text, images, and links.</li>
          </ul>
          <p>Use the toggle switches to select one or more ad types.</p>
        </li>

        <li className="doc-step">
          <h2>Customize Your Ads</h2>
          <p>After selecting your ad types, customize each ad:</p>
          <ul>
            <li><strong>Customize Ad Format</strong>: Customize the different ad formats such as banners or sidebars.</li>
            <li><strong>Upload Images</strong>: Upload images for your ads or select from existing images.</li>
            <li><strong>Set Ad Details</strong>: Provide details like <strong>Ad Spend</strong>, <strong>Duration</strong>, and specific product, shop, or brand information.</li>
          </ul>
          <p>Click <strong>Save</strong> to save your ad configurations.</p>
        </li>

        <li className="doc-step">
          <h2>Review Your Ads</h2>
          <p>Once you have customized your ads, review them before submitting:</p>
          <ul>
            <li><strong>View Ad Overview</strong>: Review each ad’s configuration, including images, ad spend, and duration.</li>
            <li><strong>Edit Ads</strong>: If needed, click <strong>Edit</strong> to make changes to any ad.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Submit Your Campaign</h2>
          <p>After reviewing your ads, proceed to the checkout step:</p>
          <ul>
            <li><strong>Review Campaign Summary</strong>: Check the total number of ads, total duration, and total ad spend.</li>
            <li><strong>Submit Campaign</strong>: Click the <strong>Complete</strong> button to finalize and submit your campaign.</li>
          </ul>
        </li>
      </ol>

      <div className="doc-additional">
        <h2>Important Tips</h2>
        <ul>
          <li><strong>Ad Types</strong>: Select ad types that best match your campaign goals (e.g., product-specific promotions or brand awareness).</li>
          <li><strong>Save Configurations</strong>: Save your ad configurations regularly to avoid losing your progress.</li>
          <li><strong>Review Thoroughly</strong>: Double-check all ad details before submitting to ensure accuracy.</li>
        </ul>
      </div>
    </div>
  );
};

export default CampaignManagementGuide;
