/* global google */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import ShopMarker from '../Map/Marker'; 
import { server } from '../../server';

const initialCenter = {
  lat: 34.0549, // Default latitude
  lng: -118.2426, // Default longitude
};

const MapComponent = ({ selectedBrand, selectedShopList }) => {
  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [currentRegion, setCurrentRegion] = useState('');
  const mapRef = React.useRef(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const customMarkerIcon = isLoaded ? {
    url: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1703164410/BuzzWeed_Map_Pin_yrteuc.svg',
    scaledSize: new window.google.maps.Size(80, 80),
  } : null;

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const url = selectedBrand
          ? `${server}/shop/all-sellers?brand=${selectedBrand.value}`
          : `${server}/shop/all-sellers`;
        const response = await axios.get(url, { withCredentials: true });
        setShops(response.data.sellers);
      } catch (error) {
        console.error('Error fetching shops:', error);
      }
    };
    fetchShops();
  }, [selectedBrand]);

  useEffect(() => {
    if(selectedShopList) {
      setSelectedShop(selectedShopList);
      mapRef.current && mapRef.current.panTo({ lat: parseFloat(selectedShopList.latitude), lng: parseFloat(selectedShopList.longitude) });
    }
  }, [selectedShopList]);

  const geocodeLatLng = (geocoder, latLng) => {
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const placeName = results.find(result => result.types.includes('locality'))?.formatted_address;
        setCurrentRegion(placeName || "Unknown location");
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  };

  const performInitialSearch = (map) => {
    const center = map.getCenter();
    const geocoder = new google.maps.Geocoder();
    geocodeLatLng(geocoder, { lat: center.lat(), lng: center.lng() });
  };

  const redoSearchInArea = () => {
    if (!mapRef.current || !isLoaded) return;
    const center = mapRef.current.getCenter();
    const geocoder = new google.maps.Geocoder();
    geocodeLatLng(geocoder, { lat: center.lat(), lng: center.lng() });
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <>
      <div className="map-component-container">
        <h2 className="map-component-title">
          {currentRegion || "Loading..."}
        </h2>
        <button
          onClick={redoSearchInArea}
          className="map-component-button"
        >
          Redo search in this area
        </button>
      </div>
      <GoogleMap
        mapContainerClassName="map-component"
        zoom={8}
        center={initialCenter}
        onLoad={(map) => {
          mapRef.current = map;
          performInitialSearch(map);
        }}
      >
        {shops.map((shop, index) => (
          <Marker
            key={index}
            position={{ lat: shop.latitude, lng: shop.longitude }}
            icon={customMarkerIcon}
            onClick={() => setSelectedShop(shop)}
          />
        ))}

        {selectedShop && (
          <InfoWindow
            position={{ lat: selectedShop.latitude, lng: selectedShop.longitude }}
            onCloseClick={() => setSelectedShop(null)}
          >
            <ShopMarker shop={selectedShop} />
          </InfoWindow>
        )}
        {selectedShopList && (
          <InfoWindow
            position={{ lat: parseFloat(selectedShopList.latitude), lng: parseFloat(selectedShopList.longitude) }}
            onCloseClick={() => setSelectedShop(null)}
          >
            <div>
              <ShopMarker shop={selectedShopList} />
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  );
};

export default MapComponent;




