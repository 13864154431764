import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { adsServer } from "../../server";

const SponsoredBrandCarouselProps = ({ ads }) => {
    const brandRefs = useRef(new Map());
    const [currentIndex, setCurrentIndex] = useState(0); 

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/viewed-impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Viewed impression logged for brand:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/click/${adId}`, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Click logged for brand ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    // Intersection observer to track impressions
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);
                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer); 
                    }
                });
            },
            { threshold: 0.5 }
        );

        const currentBrand = brandRefs.current.get(ads[currentIndex]?._id);
        if (currentBrand) {
            observer.observe(currentBrand);
        }

        return () => {
            observer.disconnect();
        };
    }, [currentIndex, ads]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % ads.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + ads.length) % ads.length);
    };

    return (
        <div className="sponsored-brand-ad-carousel">
            {ads.length > 1 && (
                    <>
                    <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>‹</button>
                    <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>›</button>
                </> 
            )}
            {ads[currentIndex] && (
                <div
                    key={ads[currentIndex]._id}
                    className="sponsored-brand-ad-carousel-item"
                    style={{ backgroundImage: `url(${ads[currentIndex].brand.coverImage.url})` }}
                    data-ad-id={ads[currentIndex]._id}
                    ref={(el) => el && brandRefs.current.set(ads[currentIndex]._id, el)}
                >
                    <Item brand={ads[currentIndex].brand} logAdClick={() => logAdClick(ads[currentIndex]._id)} />
                </div>
            )}
        </div>
    );
};

const Item = ({ brand, logAdClick }) => {
    return (
        <div className="sponsored-brand-ad-carousel-content">
            <h2 className="sponsored-brand-ad-carousel-title">{brand.name}</h2>
            <p className="sponsored-brand-ad-carousel-description">{brand.description}</p>
            <Link to={`/brand/${brand.name}`} onClick={logAdClick} className="sponsored-brand-ad-carousel-button">
                Visit Brand
            </Link>
        </div>
    );
};

export default SponsoredBrandCarouselProps;
