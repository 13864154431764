import React from 'react';

const categories = [
  {
    name: "New Arrivals",
    href: "/new-arrivals", 
    imageSrc: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/3f900050-b900-4ad8-81b9-d54d574de000/public",
  },
  {
    name: "BuzzVibe Favorites",
    href: "/shop/buzzvibehollywood",
    imageSrc: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/a86de765-0633-422b-a146-015478822700/public",
  },
  {
    name: "Top Rated",
    href: "/top-rated", 
    imageSrc: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/8fd45f3e-3032-4d15-c75c-9efb2ddd1a00/public",
  },
  {
    name: "Trending",
    href: "/trending", 
    imageSrc: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/1e33e2b0-4f28-4f66-a5b8-808bd8384200/public",
  },
  {
    name: "Sale",
    href: "/sale",
    imageSrc: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/a321f0ce-c621-4613-9489-ed823bd00e00/public",
  },
];

export default function RetailCategorySection() {
  return (
    <div className="category-marketplace-section-component-bg-black">
      <div className="category-marketplace-section-component-section-wrapper">
        <div className="category-marketplace-section-component-header-wrapper">
          <h2 className="category-marketplace-section-component-title">Shop by Category</h2>
        </div>

        <div className="category-marketplace-section-component-categories-wrapper">
          <div className="category-marketplace-section-component-categories-inner-wrapper">
            <div className="category-marketplace-section-component-categories-list">
              <div className="category-marketplace-section-component-categories-list-inner">
                {categories.map((category) => (
                  <a
                    key={category.name}
                    href={category.href}
                    className="category-marketplace-section-component-category-item"
                  >
                    <span aria-hidden="true" className="category-marketplace-section-component-category-image-wrapper">
                      <img src={category.imageSrc} alt="" className="category-marketplace-section-component-category-image" />
                    </span>
                    <span
                      aria-hidden="true"
                      className="category-marketplace-section-component-category-overlay"
                    />
                    <span className="category-marketplace-section-component-category-name">{category.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="category-marketplace-section-component-browse-link-wrapper">
          <a href="#" className="category-marketplace-section-component-browse-link-sm">
            Browse all categories
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  )
}
