import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import OrderTracking from "../Shipping/OrderTracking";

const UserAllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  return (
    <div className="user-order-history-container">
      <div className="user-order-history-header">
        <h1 className="user-order-history-title">Order history</h1>
        <p className="user-order-history-description">
          Check the status of recent orders, manage returns, and download invoices.
        </p>
      </div>

      <div className="user-order-history-orders-list">
        {orders && orders.length > 0 ? (
          [...orders]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by date and time (newest first)
            .map((order) => {
              console.log('Order:', order); // Log the order object

              return (
                <div key={order._id} className="user-order-history-order-card">
                  <div className="user-order-history-order-details">
                    <dl className="user-order-history-order-info">
                      <div className="user-order-history-info-item">
                        <dt className="user-order-history-info-label">Order number</dt>
                        <dd className="user-order-history-info-value">{order._id}</dd>
                      </div>
                      <div className="user-order-history-info-item">
                        <dt className="user-order-history-info-label">Date placed</dt>
                        <dd className="user-order-history-info-value">
                          <time dateTime={order.createdAt}>{new Date(order.createdAt).toLocaleString()}</time>
                        </dd>
                      </div>
                      <div className="user-order-history-info-item">
                        <dt className="user-order-history-info-label">Total amount</dt>
                        <dd className="user-order-history-info-value">{`US$ ${order.totalPrice}`}</dd>
                      </div>
                    </dl>
                    <div className="user-order-history-order-actions">
                      <Link
                        to={`/user/order/${order._id}`}
                        className="user-order-history-order-action-button"
                      >
                        View Order
                        <AiOutlineArrowRight className="user-order-history-order-icon" />
                      </Link>
                    </div>
                  </div>

                  {order.cart && order.cart.length > 0 && (
                    <div className="user-order-history-products-list">
                      {order.cart.map((product) => {
                        console.log('Product:', product); // Log the product object

                        return (
                          <div key={product._id || product.id || product.productId} className="user-order-history-product-item">
                            <div className="user-order-history-product-image-container">
                              <img
                                alt={product.productName}
                                src={product.images || "https://via.placeholder.com/150"}
                                className="user-order-history-product-image"
                              />
                            </div>
                            <div className="user-order-history-product-details">
                              <div className="user-order-history-product-info">
                                <h4 className="user-order-history-product-name">{product.productName}</h4>
                                <p className="user-order-history-product-description">{product.description}</p>
                                <p className="user-order-history-product-price">{`US$ ${(product.unitAmount / 100).toFixed(2)}`}</p>
                                <div className="user-order-history-product-actions">
                                 
                                    <Link to={`/product/${product.productId}`} className="user-order-history-product-link">
                                      Buy Again
                                    </Link>

                                  
                                  
                                </div>
                              </div>
                              <div className="user-order-history-status-info-container">
                                <dd className="user-order-history-status-info-value">{order.status}</dd>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })
        ) : (
          <p className="user-order-history-no-orders">You have no orders yet.</p>
        )}
      </div>
    </div>
  );
};

export default UserAllOrders;


