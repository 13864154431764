import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropzone from 'react-dropzone';
import { createPost as createPostAction } from '../redux/actions/post';
import HashtagSuggestions from '../components/Hashtags/HashtagSuggestions';
import Avatar from '../components/Layout/Avatar';
import axios from 'axios';
import { server } from '../server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons';
import ImageCropperModal from '../components/Image/ImageCropperModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const MyPostWidgetRedux = () => {
  const dispatch = useDispatch();
  const [post, setPost] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hashtagQuery, setHashtagQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const userProfile = useSelector((state) => state.user);
  const sellerProfile = useSelector((state) => state.seller);
  const inputRef = useRef(null);
  const [linkPreview, setLinkPreview] = useState(null);
  const debounceTimer = useRef(null);
  const [showCropper, setShowCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageQueue, setImageQueue] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const fetchLinkPreview = async (url) => {
    try {
      const response = await axios.post(`${server}/posts/link-preview`, { url });
      setLinkPreview(response.data);
    } catch (error) {
      console.error('Error fetching link preview:', error);
    }
  };

  const debounceFetchLinkPreview = (url) => {
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      fetchLinkPreview(url);
    }, 500);
  };

  const toggleImageUpload = () => {
    setIsImage(!isImage);
    setIsVideo(false);
  };

  const toggleVideoUpload = () => {
    setIsVideo(!isVideo);
    setIsImage(false);
  };

  const createPost = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('description', post);
    formData.append('currentProfile', currentProfile);
    if (currentProfile === 'User') {
      formData.append('userId', userProfile.id);
    } else if (currentProfile === 'Shop') {
      formData.append('userId', sellerProfile.id);
    }

    images.forEach((image) => {
      formData.append('images', image);
    });

    videos.forEach((video) => {
      formData.append('videos', video);
    });

    dispatch(createPostAction(formData))
      .then(() => {
        setIsLoading(false);
        setPost('');
        setImages([]);
        setVideos([]);
        setImagePreviews([]);
        setVideoPreviews([]);
        setLinkPreview(null);
        setIsImage(false);
        setIsVideo(false);
        setCroppedImage(null);
        setCurrentImageIndex(0);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error('Error creating post');
      });
  };

  useEffect(() => {
    if (images.length > 0) {
      const newPreviews = images.map((image) => URL.createObjectURL(image));
      setImagePreviews(newPreviews);
      return () => newPreviews.forEach((url) => URL.revokeObjectURL(url));
    } else {
      setImagePreviews([]);
    }
  }, [images]);

  useEffect(() => {
    if (videos.length > 0) {
      const newPreviews = videos.map((video) => URL.createObjectURL(video));
      setVideoPreviews(newPreviews);
      return () => newPreviews.forEach((url) => URL.revokeObjectURL(url));
    } else {
      setVideoPreviews([]);
    }
  }, [videos]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPost(value);

    const lastWord = value.split(' ').pop();
    if (lastWord.startsWith('#')) {
      setHashtagQuery(lastWord.substring(1));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }

    const urlRegex = /((https?:\/\/)?(www\.)?[\w-]+\.[a-z]+(\.[a-z]+)?(\/\S*)?)/gi;
    const urlMatch = value.match(urlRegex);
    if (urlMatch) {
      let url = urlMatch[0];
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'http://' + url;
      }
      debounceFetchLinkPreview(url);
    } else {
      setLinkPreview(null);
    }
  };

  const handleHashtagSelect = (hashtag) => {
    const words = post.split(' ');
    words[words.length - 1] = `#${hashtag}`;
    setPost(words.join(' '));
    setShowSuggestions(false);
    inputRef.current.focus();
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
    setImagePreviews(imagePreviews.filter((_, i) => i !== index));
    if (currentImageIndex >= images.length - 1 && currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleRemoveVideo = (index) => {
    setVideos(videos.filter((_, i) => i !== index));
    setVideoPreviews(videoPreviews.filter((_, i) => i !== index));
  };

  const handleImageDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections && fileRejections.length > 0) {
      toast.error(
        'Some files were rejected. Please upload images in JPG, JPEG, PNG, GIF, WEBP, BMP, or TIFF formats.'
      );
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
      setShowCropper(true);
      setImageQueue(acceptedFiles.slice(1));
    }
  };

  const handleVideoDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections && fileRejections.length > 0) {
      toast.error('Some files were rejected. Please upload videos in MP4 format.');
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      setVideos([...videos, ...acceptedFiles]);
    }
  };

  const updateCroppedImage = (croppedFile) => {
    setCroppedImage(croppedFile);
    setImages((prevImages) => [...prevImages, croppedFile]);
  
    if (imageQueue.length > 0) {
      const nextFile = imageQueue[0];
      setImageQueue(imageQueue.slice(1));
      setShowCropper(false); 
      setTimeout(() => {
        setSelectedFile(nextFile);
        setShowCropper(true); 
      }, 0);
    } else {
      setShowCropper(false);
      setSelectedFile(null); 
    }
  };
  

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(images);
    const [movedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, movedImage);

    setImages(reorderedImages);

    const reorderedPreviews = Array.from(imagePreviews);
    const [movedPreview] = reorderedPreviews.splice(result.source.index, 1);
    reorderedPreviews.splice(result.destination.index, 0, movedPreview);

    setImagePreviews(reorderedPreviews);

    // Update current image index if necessary
    if (currentImageIndex === result.source.index) {
      setCurrentImageIndex(result.destination.index);
    } else if (
      currentImageIndex >= Math.min(result.source.index, result.destination.index) &&
      currentImageIndex <= Math.max(result.source.index, result.destination.index)
    ) {
      if (result.source.index < result.destination.index) {
        setCurrentImageIndex((prevIndex) => prevIndex - 1);
      } else {
        setCurrentImageIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  const handleDragStart = (e, index, type) => {
    e.dataTransfer.setData('index', index);
    e.dataTransfer.setData('type', type);
  };

  const handleDrop = (e, dropIndex, type) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('index'), 10);
    const itemType = e.dataTransfer.getData('type');

    if (itemType === type) {
      if (type === 'image') {
        const reorderedImages = [...images];
        const [draggedImage] = reorderedImages.splice(dragIndex, 1);
        reorderedImages.splice(dropIndex, 0, draggedImage);
        setImages(reorderedImages);
      } else if (type === 'video') {
        const reorderedVideos = [...videos];
        const [draggedVideo] = reorderedVideos.splice(dragIndex, 1);
        reorderedVideos.splice(dropIndex, 0, draggedVideo);
        setVideos(reorderedVideos);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="my-post-widget-wrapper">
        <div className="my-post-widget-flex-between" style={{ gap: '1.5rem' }}>
          <Avatar />
          <input
            type="text"
            placeholder="What's on your mind..."
            onChange={handleInputChange}
            value={post}
            className="my-post-widget-input-base"
            ref={inputRef}
          />
        </div>

        {showSuggestions && (
          <HashtagSuggestions query={hashtagQuery} onSelect={handleHashtagSelect} />
        )}

        {linkPreview && (
          <div
            className="my-post-widget-link-post-preview-container"
            style={{ marginTop: '1rem' }}
          >
            {linkPreview.images?.[0] && (
              <img
                src={linkPreview.images[0]}
                alt="Preview"
                className="my-post-widget-link-post-preview-image"
              />
            )}
            <div className="my-post-widget-link-post-preview-content">
              <h6 className="my-post-widget-link-post-preview-title">
                {linkPreview.title}
              </h6>
              <p className="my-post-widget-link-post-preview-description">
                {linkPreview.description}
              </p>
              <p className="my-post-widget-link-post-preview-url">{linkPreview.url}</p>
            </div>
          </div>
        )}

        <hr className="my-post-widget-divider" />

        <div className="my-post-widget-flex-between my-post-widget-image-video-container">
          <div
            className="my-post-widget-flex-between"
            style={{ gap: '0.25rem' }}
            onClick={toggleImageUpload}
          >
            <FontAwesomeIcon className="my-post-widget-icon" icon={faCamera} />
            <p className="my-post-widget-typography">Image</p>
          </div>

          <div
            className="my-post-widget-flex-between"
            style={{ gap: '0.25rem' }}
            onClick={toggleVideoUpload}
          >
            <FontAwesomeIcon className="my-post-widget-icon" icon={faVideo} />
            <p className="my-post-widget-typography">Video</p>
          </div>

          <button
            className="my-post-widget-post-button"
            disabled={
              (!post.trim() && images.length === 0 && videos.length === 0) || isLoading
            }
            onClick={createPost}
          >
            {isLoading ? 'Loading...' : 'POST'}
          </button>
        </div>

        {isImage && (
          <div className="my-post-widget-image-dropzone-container">
            <Dropzone
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': ['.png'],
                'image/gif': ['.gif'],
                'image/webp': ['.webp'],
                'image/bmp': ['.bmp'],
                'image/tiff': ['.tiff', '.tif'],
              }}
              multiple={true}
              onDrop={(acceptedFiles, fileRejections) =>
                handleImageDrop(acceptedFiles, fileRejections)
              }
            >
              {({ getRootProps, getInputProps }) => (
                <div className="my-post-widget-dropzone-inner">
                  <div {...getRootProps()} className="my-post-widget-dropzone-box">
                    <input {...getInputProps()} />
                    <p>
                      {images.length > 0
                        ? `${images.length} image(s) selected`
                        : 'Add Image(s) Here'}
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>

            {images.length > 0 && (
              <div className="my-post-widget-slideshow-container">
                <div className="my-post-widget-slideshow">
                  <button onClick={prevImage} className="my-post-widget-prev-button">
                    &#10094;
                  </button>
                  <img
                    src={URL.createObjectURL(images[currentImageIndex])}
                    alt={`Slide ${currentImageIndex}`}
                    className="my-post-widget-slideshow-image"
                  />
                  <button onClick={nextImage} className="my-post-widget-next-button">
                    &#10095;
                  </button>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="image-thumbnails" direction="horizontal">
                    {(provided) => (
                      <div
                        className="my-post-widget-thumbnails-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {images.map((image, index) => (
                          <Draggable key={`image-${index}`} draggableId={`image-${index}`} index={index}>
                            {(provided) => (
                              <div
                                className={`my-post-widget-thumbnail ${
                                  index === currentImageIndex ? 'active' : ''
                                }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => setCurrentImageIndex(index)}
                              >
                                <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Thumbnail ${index}`}
                                  className="my-post-widget-thumbnail-image"
                                />
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveImage(index);
                                  }}
                                  className="my-post-widget-thumbnail-delete-button"
                                >
                                  🗑️
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </div>
        )}

        {isVideo && (
          <div className="my-post-widget-video-dropzone-container">
            <Dropzone
              accept={{ 'video/mp4': ['.mp4'] }}
              multiple={true}
              onDrop={(acceptedFiles, fileRejections) =>
                handleVideoDrop(acceptedFiles, fileRejections)
              }
            >
              {({ getRootProps, getInputProps }) => (
                <div className="my-post-widget-dropzone-inner">
                  <div {...getRootProps()} className="my-post-widget-dropzone-box">
                    <input {...getInputProps()} />
                    <p>
                      {videos.length > 0
                        ? `${videos.length} video(s) selected`
                        : 'Add Video(s) Here'}
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
            {videoPreviews.length > 0 && (
              <div className="my-post-widget-video-preview-container">
                {videoPreviews.map((preview, index) => (
                  <div
                    key={index}
                    className="my-post-widget-flex-between draggable"
                    draggable
                    onDragStart={(e) => handleDragStart(e, index, 'video')}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index, 'video')}
                  >
                    <video className="my-post-widget-video-preview" controls>
                      <source src={preview} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <div className="my-post-widget-delete-icon-button-container">
                      <button
                        onClick={() => handleRemoveVideo(index)}
                        className="my-post-widget-delete-icon-button"
                      >
                        🗑️
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {showCropper && selectedFile && (
        <ImageCropperModal
          key={selectedFile.name + images.length}
          updateAvatar={updateCroppedImage}
          closeModal={() => setShowCropper(false)}
          initialImage={selectedFile}
          aspectRatio={4 / 5}
          minWidth={300}
          isAvatar={false}
          isPost={true}
        />
      )}
    </>
  );
};

export default MyPostWidgetRedux;





