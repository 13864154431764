// src/WHIPClient.js

export default class WHIPClient {
    constructor(url, videoElement) {
      this.url = url;
      this.videoElement = videoElement;
      this.pc = new RTCPeerConnection({
        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
      });
      this.init();
    }
  
    async init() {
      try {
        // Get media stream from the video element
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        this.videoElement.srcObject = stream;
  
        // Add tracks to PeerConnection
        stream.getTracks().forEach((track) => this.pc.addTrack(track, stream));
  
        // Handle ICE candidates
        this.pc.onicecandidate = (event) => {
          if (event.candidate) {
            this.sendIceCandidate(event.candidate);
          }
        };
  
        // Create offer
        const offer = await this.pc.createOffer();
        await this.pc.setLocalDescription(offer);
  
        // Send offer to WHIP server and receive answer
        await this.sendOffer(offer);
      } catch (error) {
        console.error('Error initializing WHIPClient:', error);
      }
    }
  
    async sendOffer(offer) {
      try {
        const response = await fetch(this.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/sdp',
            'X-Stream-ID': this.url.split('/').slice(-2, -1)[0], // Extract UID from URL
          },
          body: offer.sdp,
        });
  
        if (response.ok) {
          const answerSdp = await response.text();
          await this.pc.setRemoteDescription(new RTCSessionDescription({ type: 'answer', sdp: answerSdp }));
          console.log('WHIP connection established successfully.');
        } else {
          const errorData = await response.text();
          console.error('WHIP offer failed:', errorData);
        }
      } catch (error) {
        console.error('Error sending WHIP offer:', error);
      }
    }
  
    async sendIceCandidate(candidate) {
      try {
        // WHIP may handle trickle ICE automatically or may require manual sending.
        // If manual, implement sending the ICE candidate to the server here.
        // For Cloudflare's implementation, this might not be necessary.
      } catch (error) {
        console.error('Error sending ICE candidate:', error);
      }
    }
  
    close() {
      if (this.pc) {
        this.pc.close();
      }
    }
  }
  