import React from "react";
import { AiOutlineCamera } from "react-icons/ai";

const UserAvatar = ({
  isUser,
  user,
  handleAvatarChange,
  handleSaveAvatar,
  showSaveButton,
  croppedFile,
}) => {

  return (
    <div>
      <div className="profile-avatar-wrapper">
        <img
          src={user?.avatar?.url || 'default-avatar.png'}
          alt={user.name}
          className="profile-avatar"
        />
        {isUser && (
          <div className="profile-upload-container">
            <input
              type="file"
              id="avatar"
              className="hidden"
              accept=".jpg, .jpeg, .png, .gif, .webp"
              onChange={handleAvatarChange}
            />
            <label htmlFor="avatar">
              <AiOutlineCamera className="user-avatar-camera-icon" />
            </label>
            {showSaveButton && (
              <button
                className="user-avatar-save-button"
                onClick={handleSaveAvatar}
                disabled={!croppedFile}
              >
                Save
              </button>
            )}
          </div>
        )}
      </div>
      
    </div>
  );
};

export default UserAvatar;
