import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { adsServer } from "../../server";
import { AiOutlineClose } from 'react-icons/ai';
import Loader from "../Layout/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdSetModal from './AdSetModal';

const SponsorProductCampaign = ({ onSave, adType, details }) => {
    const [selectedProductId, setSelectedProductId] = useState(details.productId || '');
    const [adSpendPerDay, setAdSpendPerDay] = useState(details.adSpend || 50); 
    const [adDuration, setAdDuration] = useState(details.adDuration || 7);
    const [totalAdSpend, setTotalAdSpend] = useState(details.adSpend * details.adDuration || 350);
    const [predictedClicks, setPredictedClicks] = useState({ min: 0, max: 0 });
    const [predictedImpressions, setPredictedImpressions] = useState({ min: 0, max: 0 });
    const [displayedPredictedClicks, setDisplayedPredictedClicks] = useState({ min: 0, max: 0 });
    const [displayedPredictedImpressions, setDisplayedPredictedImpressions] = useState({ min: 0, max: 0 });
    const { seller } = useSelector((state) => state.seller);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAdSetModalOpen, setIsAdSetModalOpen] = useState(false);
    const [adSet, setAdSet] = useState({});
    const [isAdSetSaved, setIsAdSetSaved] = useState(false); 
    const debounceRef = useRef(null);
    const [activeTimeFrame, setActiveTimeFrame] = useState('total');

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            try {
                const { data } = await axios.get(`${adsServer}/product/get-all-products-shop/${seller._id}`, { withCredentials: true });
                setProducts(data.products);
            } catch (error) {
                setError(error.response.data.message);
            }
            setIsLoading(false);
        };

        fetchProducts();
    }, [seller]);

    useEffect(() => {
        setTotalAdSpend(adSpendPerDay * adDuration);
    }, [adSpendPerDay, adDuration]);

    useEffect(() => {
        fetchPredictions(totalAdSpend);
    }, [totalAdSpend]);

    const fetchPredictions = async (spend) => {
        if (debounceRef.current) clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(async () => {
            try {
                const { data } = await axios.post(`${adsServer}/sponsored/predict`, { adSpend: spend }, { withCredentials: true });
                if (data) {
                    setPredictedClicks(data.predictedClicks);
                    setPredictedImpressions(data.predictedImpressions);
                    updateDisplayValues('total');
                }
            } catch (error) {
                console.error('Error fetching predictions:', error);
                toast.error('Failed to fetch predictions');
            }
        }, 500);
    };

    const updateDisplayValues = (mode) => {
        const factor = mode === 'daily' ? 1 / adDuration : 1;
        setDisplayedPredictedClicks({
            min: Math.round(predictedClicks.min * factor),
            max: Math.round(predictedClicks.max * factor)
        });
        setDisplayedPredictedImpressions({
            min: Math.round(predictedImpressions.min * factor),
            max: Math.round(predictedImpressions.max * factor)
        });
    };

    const handleDisplayModeChange = (mode) => {
        setActiveTimeFrame(mode); 
        updateDisplayValues(mode);
    };

    const handleDeselectProduct = (event) => {
        event.stopPropagation();
        setSelectedProductId('');
    };

    const openAdSetModal = () => {
        setIsAdSetModalOpen(true);
    };

    const handleAdSetSave = (newAdSet) => {
        setAdSet(newAdSet);
        setIsAdSetSaved(true);
        setIsAdSetModalOpen(false);
    };

    useEffect(() => {
        if (details) {
            setSelectedProductId(details.productId || '');
            setAdSpendPerDay(details.adSpendPerDay || 50);
            setAdDuration(details.adDuration || 7);
            setTotalAdSpend(details.adSpendPerDay * details.adDuration || 350);
        }
    }, [details]);

    const handleSaveAd = () => {
        if (!selectedProductId) {
            toast.error("Please select a product before saving.");
            return;
        }
    
        const selectedProduct = products.find(product => product._id === selectedProductId);

        const details = {
            productId: selectedProductId,
            productName: selectedProduct?.name,
            productImage: selectedProduct?.images[0]?.url,
            adSpend: totalAdSpend,
            adDuration,
        }
    
        onSave(adSet,
            details,
            adType 
        );
    
        toast.success("Product ad configuration saved.");
    };

    // Determine if the Save button should be enabled
    const isSaveEnabled = isAdSetSaved && selectedProductId;

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="sponsor-product-component-container">
                    <h2 className="sponsor-product-component-title">Sponsor a Product</h2>

                    <div className="sponsor-product-component-product-selection">
                        <label>Select a Product:</label>
                        <div className="sponsor-product-component-product-list">
                            {products.map(product => (
                                <div key={product._id} 
                                     onClick={() => setSelectedProductId(product._id)} 
                                     className={`sponsor-product-component-product-item ${selectedProductId === product._id ? 'selected' : ''}`}>
                                    <img src={product.images[0].url} alt={product.name} />
                                    <span>{product.name}</span>
                                    {selectedProductId === product._id && (
                                        <AiOutlineClose 
                                            onClick={handleDeselectProduct}
                                            className="sponsor-product-component-close-icon"
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    {selectedProductId && (
                        <>
                            <div className="customize-ad-set-button-container">
                                <button type="button" onClick={openAdSetModal} className="customize-ad-set-button">
                                    Customize Ad Set
                                </button>
                            </div>
                        </>
                    )}

                    <div className="sponsor-product-component-slider">
                        <label>Set Ad Spend per Day ($):</label>
                        <input 
                            type="text"
                            value={adSpendPerDay}
                            onChange={(e) => {
                                const newSpend = Number(e.target.value);
                                setAdSpendPerDay(newSpend);
                                fetchPredictions(newSpend * adDuration); 
                            }}
                        />
                        <input 
                            type="range" 
                            min="1" 
                            max="1000"
                            value={adSpendPerDay}
                            onChange={(e) => {
                                const newSpend = Number(e.target.value);
                                setAdSpendPerDay(newSpend);
                                fetchPredictions(newSpend * adDuration); 
                            }}
                            className="sponsor-product-component-range-slider"
                        />
                    </div>

                    <div className="sponsor-product-component-slider">
                        <label>Set Ad Duration (Days):</label>
                        <input 
                            type="text"
                            value={adDuration}
                            onChange={(e) => {
                                const newDuration = Number(e.target.value);
                                setAdDuration(newDuration);
                                fetchPredictions(adSpendPerDay * newDuration); 
                            }}
                        />
                        <input 
                            type="range" 
                            min="1" 
                            max="30"
                            value={adDuration}
                            onChange={(e) => {
                                const newDuration = Number(e.target.value);
                                setAdDuration(newDuration);
                                fetchPredictions(adSpendPerDay * newDuration); 
                            }}
                            className="sponsor-product-component-range-slider"
                        />
                    </div>

                    <div className="sponsor-product-component-total-spend">
                        <label>Total Ad Spend ($): {totalAdSpend}</label>
                    </div>

                    <div className="sponsor-product-component-display-mode">
                        <button
                            type="button"
                            onClick={() => handleDisplayModeChange('daily')}
                            className={`time-button ${activeTimeFrame === 'daily' ? 'active-time-button' : ''}`}
                        >
                            Daily
                        </button>
                        <button
                            type="button"
                            onClick={() => handleDisplayModeChange('total')}
                            className={`time-button ${activeTimeFrame === 'total' ? 'active-time-button' : ''}`}
                        >
                            Total
                        </button>
                    </div>

                    <div className="sponsor-product-component-predictions">
                        {displayedPredictedClicks.min !== 0 || displayedPredictedClicks.max !== 0 ? (
                            <>
                                <div className="sponsor-product-component-prediction-item">
                                    <label>Predicted Clicks Range:</label>
                                    <span>{displayedPredictedClicks.min} to {displayedPredictedClicks.max}</span>
                                </div>
                                <div className="sponsor-product-component-prediction-item">
                                    <label>Predicted Impressions Range:</label>
                                    <span>{displayedPredictedImpressions.min} to {displayedPredictedImpressions.max}</span>
                                </div>
                            </>
                        ) : (
                            <div className="sponsor-product-component-null-message">
                                Please choose your ad spend/time duration
                            </div>
                        )}
                    </div>

                    <button 
                        type="button" 
                        className={`sponsor-product-component-submit-button ${isSaveEnabled ? 'enabled' : 'disabled'}`} 
                        onClick={isSaveEnabled ? handleSaveAd : null} 
                        disabled={!isSaveEnabled}
                    >
                        Save Ad Configuration
                    </button>

                    <AdSetModal 
                        key={selectedProductId}
                        isOpen={isAdSetModalOpen} 
                        onClose={() => setIsAdSetModalOpen(false)} 
                        onSave={handleAdSetSave} 
                        adFormats={['Top Banner Ad', 'Top Banner Mobile Ad', 'Banner Ad', 'Banner Mobile Ad', 'Mobile Bar Banner Ad', 'Sponsored Products', 'Dual Carousel', 'Quad Carousel', 'Sponsored Product Carousel' , 'Sponsored Product Carousel Mobile' , 'Sponsored Feed Ads', 'Map Ad Slider']}
                        productImage={products.find(product => product._id === selectedProductId)?.images[0]?.url}
                        productName={products.find(product => product._id === selectedProductId)?.name}
                    />
                </div>
            )}
        </>
    );
};

export default SponsorProductCampaign;

