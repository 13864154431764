import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BrandCatalogItemCreate from './BrandCatalogItemCreate';
import { fetchOwnedBrands, updateBrand } from '../../redux/actions/brand';
import { loadSeller } from "../../redux/actions/user";
import { AiOutlineCamera } from "react-icons/ai";


const OwnedBrand = () => {
  const { brandId } = useParams(); // Extract brandId from URL
  const dispatch = useDispatch();
  const { seller } = useSelector((state) => state.seller);
  const { isLoading, error, ownedBrands } = useSelector((state) => state.brand);
  const [open, setOpen] = useState(false);
  const [editingBrand, setEditingBrand] = useState(false);
  const [brandData, setBrandData] = useState({
    description: '',
  });
  const [coverImage, setCoverImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);

  // Find the specific brand from the ownedBrands array using brandId
  const selectedBrand = ownedBrands.find(brand => brand._id === brandId);

  useEffect(() => {
    dispatch(loadSeller());
  }, [dispatch]);

  useEffect(() => {
    if (seller) {
      dispatch(fetchOwnedBrands());
    }
  }, [seller, dispatch]);

  useEffect(() => {
    if (selectedBrand) {
      setBrandData({ description: selectedBrand.description });
    }
  }, [selectedBrand]);

  const handleFileChange = (e) => {
    if (e.target.name === 'coverImage') {
      setCoverImage(e.target.files[0]);
    } else if (e.target.name === 'avatarImage') {
      setAvatarImage(e.target.files[0]);
    }
  };

  const handleDescriptionChange = (e) => {
    setBrandData({ ...brandData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (coverImage) formData.append('coverImage', coverImage);
    if (avatarImage) formData.append('avatarImage', avatarImage);
    formData.append('description', brandData.description);

    if (selectedBrand && selectedBrand._id) {
      dispatch(updateBrand(selectedBrand._id, formData));
    }
    setEditingBrand(false); // Reset editing state after submit
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!selectedBrand) return <p>Brand not found.</p>;

  return (
    <div>
      <div className="owned-brand-component-container">
        <div className="owned-brand-component-cover-image-container">
          <img src={selectedBrand.coverImage.url} alt="Cover" className="owned-brand-component-cover-image"/>
          {editingBrand && (
            <>
              <label htmlFor="coverImage" className="owned-brand-component-camera-icon-container">
                <AiOutlineCamera className="h-5 w-5" style={{ color: 'white' }} />
              </label>
              <input type="file" id="coverImage" name="coverImage" onChange={handleFileChange} style={{ display: 'none' }} />
            </>
          )}
        </div>
        <div className="owned-brand-component-avatar-container">
          <img src={selectedBrand.avatarImage.url} alt="Avatar" className="owned-brand-component-avatar-image" />
          {editingBrand && (
            <>
              <label htmlFor="avatarImage" className="owned-brand-component-camera-icon-container">
                <AiOutlineCamera className="h-5 w-5" style={{ color: 'white' }} />
              </label>
              <input type="file" id="avatarImage" name="avatarImage" onChange={handleFileChange} style={{ display: 'none' }} />
            </>
          )}
        </div>
        <h3 className="owned-brand-component-brand-name">{selectedBrand.name}</h3>
        {editingBrand ? (
          <div>
            <textarea id="description" name="description" value={brandData.description} onChange={handleDescriptionChange} className="owned-brand-component-textarea" />
          </div>
        ) : (
          <p className="owned-brand-component-brand-description">{selectedBrand.description}</p>
        )}
        {editingBrand ? (
          <>
            <button type="submit" onClick={handleSubmit} className="owned-brand-component-button">Update Brand</button>
            <button type="button" onClick={() => setEditingBrand(false)} className="owned-brand-component-button cancel">Cancel</button>
          </>
        ) : (
          <div className="owned-brand-component-flex-center">
            <button onClick={() => setEditingBrand(true)} className="owned-brand-component-button">Edit your brand</button>
          </div>
        )}
      </div>
      <div className="owned-brand-component-add-catalog-item">
        <button onClick={() => setOpen(true)} className="owned-brand-component-button">Add Catalog Item</button>
      </div>

      {open && (
        <>
          <div className="owned-brand-component-modal-overlay" onClick={() => setOpen(false)}></div>
          <div className="owned-brand-component-modal-content">
            <BrandCatalogItemCreate brandId={brandId} />
          </div>
        </>
      )}
    </div>
  );
};

export default OwnedBrand;

