import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import AdFormatIconRow from './AdFormatIconRow'; 

const CampaignReview = ({ campaignAds, onEdit }) => {
    const [selectedFormat, setSelectedFormat] = useState('Top Banner Ad'); 

    const formats = [
        { name: 'Top Banner Ad', label: 'Top Banner', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087610/top_banner_ad-2_hll7nm.svg', viewType: 'desktop' },
        { name: 'Top Banner Mobile Ad', label: 'Top Banner (Mobile)', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087610/top_banner_ad-2_hll7nm.svg', viewType: 'mobile' },
        { name: 'Banner Ad', label: 'Bottom Banner', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087984/bottom_banner_ad-2_srdajl.svg', viewType: 'desktop' },
        { name: 'Banner Mobile Ad', label: 'Bottom Banner (Mobile)', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087984/bottom_banner_ad-2_srdajl.svg', viewType: 'mobile' },
        { name: 'Mobile Bar Banner Ad', label: 'Mobile Bar Banner', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1728515141/bottom_ticker_ad_l1qs0j.svg', viewType: 'mobile' },
        { name: 'Sponsored Products', label: 'Sidebar Ad', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727089158/sidebar_ad-2_flagic.svg', viewType: 'both' },
        { name: 'Dual Carousel', label: 'Dual Carousel', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727089990/dual_carousel_ad-2_qusoev.svg', viewType: 'both' },
        { name: 'Quad Carousel', label: 'Quad Carousel', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090138/quad_carousel_ad-2_vmsayt.svg', viewType: 'both' },
        { name: 'Sponsored Product Carousel', label: 'Single Carousel', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090221/single_carousel_ad-2_hxmkw6.svg', viewType: 'desktop' },
        { name: 'Sponsored Product Carousel Mobile', label: 'Single Carousel (Mobile)', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090221/single_carousel_ad-2_hxmkw6.svg', viewType: 'mobile' },
        { name: 'Map Ad Slider', label: 'Map Slider', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090365/map_carousel_ad_r2d0ve.svg', viewType: 'both' },
        { name: 'Sponsored Feed Ads', label: 'Feed Ad', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090568/feed_ad_efwtaf.svg', viewType: 'both' },
    ];

    useEffect(() => {
        console.log('campaignAds:', campaignAds);
    }, [campaignAds]);

    useEffect(() => {
        console.log('Selected format:', selectedFormat); 
    }, [selectedFormat]);
    

    return (
        <div className="ad-campaign-ad-preview-container">

        <AdFormatIconRow 
                formats={formats.map((format) => ({
                    name: format.name,
                    label: format.label,
                    icon: format.icon,
                    viewType: format.viewType,
                }))} 
                selectedFormat={selectedFormat}
                onFormatSelect={setSelectedFormat} 
            />      

            {Object.keys(campaignAds).length === 0 ? (
                <p className="ad-campaign-ad-preview-empty-message">No ads have been added to your campaign yet.</p>
            ) : (
                <div className="ad-campaign-ad-preview-list">
                    {Object.keys(campaignAds).map((adType) =>
                        campaignAds[adType].map((ad, index) => (
                            <div key={`${adType}-${index}`} className="ad-campaign-ad-preview-item">
                                <h3 className="ad-campaign-ad-preview-item-title">
                                    {adType === "product" ? "Sponsored Product" :
                                        adType === "shop" ? "Sponsored Shop" :
                                        adType === "brand" ? "Sponsored Brand" :
                                        "Custom Ad"}
                                </h3>

                                <div className="ad-campaign-ad-preview-details">
                                    

                                    <div className="ad-campaign-ad-preview-images">
                                        <p><strong>Ad Preview:</strong></p>
                                        {ad.details && ad.adSet.images && ad.adSet.images[selectedFormat] ? (
                                            <div className={`ad-preview-${selectedFormat.toLowerCase().replace(/\s+/g, '-')} `}>
                                                {selectedFormat === 'Top Banner Ad' && (
                                                    <div className="ad-preview-top-banner-container">
                                                        <div className="ad-preview-top-banner-wrapper">
                                                        <div className="ad-preview-top-banner-content">
                                                       
                                                            <div className="ad-preview-top-banner-image-container">
                                                                <img
                                                                    src={
                                                                        ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                            ? URL.createObjectURL(ad.adSet.files[selectedFormat]) 
                                                                            : ad.adSet.images[selectedFormat] 
                                                                    }
                                                                    alt={`${selectedFormat} preview`}
                                                                    className="ad-preview-top-banner-image"
                                                                    style={{
                                                                        left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                        top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                        position: 'absolute',
                                                                        transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`,  
                                                                        transformOrigin: 'center',
                                                                    }}
                                                                />
                                                            </div>
                                                           
                                                            <div className="ad-preview-top-banner-overlay">
                                                            <div className="ad-preview-top-banner-text">
                                                                <strong className="ad-preview-top-banner-ad-link">{ad.details.productName}</strong>
                                                                <span className="ad-preview-top-banner-ad-link"> – Explore Now &rarr;</span>
                                                            </div>
                                                            <button className="ad-preview-top-banner-ad-dismiss">
                                                                <XMarkIcon className="ad-preview-top-banner-ad-icon" aria-hidden="true" />
                                                                <span className="sr-only">Dismiss</span>
                                                            </button>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Top Banner Mobile Ad' && (
                                                <div className="ad-preview-mobile-container">
                                                    <div className="ad-preview-top-banner-mobile-container">
                                                        <div className="ad-preview-top-banner-content-mobile">
                                                            <div className="ad-preview-top-banner-image-container">
                                                                <img
                                                                    src={
                                                                        ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                            ? URL.createObjectURL(ad.adSet.files[selectedFormat])
                                                                            : ad.adSet.images[selectedFormat]
                                                                    }
                                                                    alt={`${selectedFormat} preview`}
                                                                    className="ad-preview-top-banner-image"
                                                                    style={{
                                                                        left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                        top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                        position: 'absolute',
                                                                        transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`,
                                                                        transformOrigin: 'center',
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="ad-preview-top-banner-text">
                                                                <strong className="ad-preview-top-banner-ad-link">{ad.details.productName}</strong>
                                                                <span className="ad-preview-top-banner-ad-link"> – Explore Now &rarr;</span>
                                                            </div>
                                                            <button className="ad-preview-top-banner-ad-dismiss">
                                                                <XMarkIcon className="ad-preview-top-banner-ad-icon" aria-hidden="true" />
                                                                <span className="sr-only">Dismiss</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                                {selectedFormat === 'Banner Ad' && (
                                                    <div className="ad-preview-banner-ad-container">
                                                        <div className="ad-preview-banner-ad-image-container">
                                                            <img
                                                               src={
                                                                ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                    ? URL.createObjectURL(ad.adSet.files[selectedFormat]) 
                                                                    : ad.adSet.images[selectedFormat] 
                                                            }
                                                                alt={`${selectedFormat} preview`}
                                                                className="ad-preview-banner-ad-image"
                                                                style={{
                                                                    left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                    top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                    position: 'absolute',
                                                                    transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`,  
                                                                    transformOrigin: 'center',
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="ad-preview-banner-ad-content">
                                                            <strong className="ad-preview-banner-ad-link">{ad.details.productName}</strong>
                                                            <span className="ad-preview-banner-ad-link"> – Explore Now &rarr;</span>
                                                            <button className="ad-preview-banner-ad-dismiss">
                                                                <XMarkIcon className="ad-preview-banner-ad-icon" aria-hidden="true" />
                                                                <span className="sr-only">Dismiss</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Banner Mobile Ad' && (
                                                <div className="ad-preview-mobile-container">
                                                    <div className="ad-preview-banner-mobile-ad-container">
                                                        <div className="ad-preview-banner-mobile-ad-image-container">
                                                            <img
                                                                src={
                                                                    ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                        ? URL.createObjectURL(ad.adSet.files[selectedFormat])
                                                                        : ad.adSet.images[selectedFormat]
                                                                }
                                                                alt={`${selectedFormat} preview`}
                                                                className="ad-preview-banner-mobile-ad-image"
                                                                style={{
                                                                    left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                    top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                    position: 'absolute',
                                                                    transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`,
                                                                    transformOrigin: 'center',
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="ad-preview-banner-mobile-ad-content">
                                                            <strong className="ad-preview-banner-mobile-ad-link">{ad.details.productName}</strong>
                                                            <span className="ad-preview-banner-mobile-ad-link"> – Explore Now &rarr;</span>
                                                            <button className="ad-preview-banner-mobile-ad-dismiss">
                                                                <XMarkIcon className="ad-preview-banner-mobile-ad-icon" aria-hidden="true" />
                                                                <span className="sr-only">Dismiss</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                                
                                                {selectedFormat === 'Sponsored Products' && (
                                                    <div className="ad-preview-sponsored-products-container">
                                                        <div className="ad-preview-sponsored-products-list">
                                                            <div className="ad-preview-sponsored-products-item">
                                                                <div className="ad-preview-sponsored-products-image-container">
                                                                    <img
                                                                       src={
                                                                        ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                            ? URL.createObjectURL(ad.adSet.files[selectedFormat]) 
                                                                            : ad.adSet.images[selectedFormat] 
                                                                    }
                                                                        alt={`${selectedFormat} preview`}
                                                                        className="ad-preview-sponsored-products-image"
                                                                        style={{
                                                                            left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                            top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                            position: 'absolute',
                                                                            transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`,  
                                                                            transformOrigin: 'center',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="ad-preview-sponsored-products-info">
                                                                    <div>
                                                                        <h3 className="ad-preview-sponsored-products-product-name">{ad.details.productName}</h3>
                                                                        <p className="ad-preview-sponsored-products-product-price">$100</p>
                                                                    </div>
                                                                    <span className="ad-preview-sponsored-products-visit-store">Visit Store</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Dual Carousel' && (
                                                    <div className="ad-preview-dual-product-carousel-container">
                                                        <div className="ad-preview-dual-product-carousel-wrapper">
                                                        <div className="ad-preview-dual-product-carousel-item empty-box"></div>
                                                            <div className="ad-preview-dual-product-carousel-item">
                                                                <div className="ad-preview-dual-product-carousel-image-container">
                                                                    <img
                                                                        src={
                                                                            ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                                ? URL.createObjectURL(ad.adSet.files[selectedFormat]) 
                                                                                : ad.adSet.images[selectedFormat] 
                                                                        }
                                                                        alt={`${selectedFormat} preview`}
                                                                        className="ad-preview-dual-product-carousel-image"
                                                                        style={{
                                                                            left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                            top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                            position: 'absolute',
                                                                            transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`, 
                                                                            transformOrigin: 'center',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="ad-preview-dual-product-carousel-content">
                                                                    <h2 className="ad-preview-dual-product-carousel-title">{ad.details.productName}</h2>
                                                                    <p className="ad-preview-dual-product-carousel-price">$100</p>
                                                                    <span className="ad-preview-dual-product-carousel-button">Buy now</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Quad Carousel' && (
                                                    <div className="ad-preview-quad-product-carousel-container">
                                                        <div className="ad-preview-quad-product-carousel-wrapper">
                                                        <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                                            <div className="ad-preview-quad-product-carousel-item">
                                                                <div className="ad-preview-quad-product-carousel-image-container">
                                                                    <img
                                                                        src={
                                                                            ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                                ? URL.createObjectURL(ad.adSet.files[selectedFormat]) 
                                                                                : ad.adSet.images[selectedFormat] 
                                                                        }
                                                                        alt={`${selectedFormat} preview`}
                                                                        className="ad-preview-quad-product-carousel-image"
                                                                        style={{
                                                                            left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                            top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                            position: 'absolute',
                                                                            transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`, 
                                                                            transformOrigin: 'center',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="ad-preview-quad-product-carousel-content">
                                                                    <h2 className="ad-preview-quad-product-carousel-title">{ad.details.productName}</h2>
                                                                    <p className="ad-preview-quad-product-carousel-price">$100</p>
                                                                    <span className="ad-preview-quad-product-carousel-button">Buy now</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Sponsored Product Carousel' && (
                                                    <div className="ad-preview-sponsored-product-carousel-container">
                                                        <div className="ad-preview-sponsored-product-carousel-wrapper">
                                                            <div className="ad-preview-sponsored-product-carousel-item">
                                                                <div className="ad-preview-sponsored-product-carousel-image-container">
                                                                    <img
                                                                        src={
                                                                            ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                                ? URL.createObjectURL(ad.adSet.files[selectedFormat]) 
                                                                                : ad.adSet.images[selectedFormat] 
                                                                        }
                                                                        alt={`${selectedFormat} preview`}
                                                                        className="ad-preview-sponsored-product-carousel-image"
                                                                        style={{
                                                                            left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                            top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                            position: 'absolute',
                                                                            transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`,
                                                                            transformOrigin: 'center',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="ad-preview-sponsored-product-carousel-content">
                                                                    <h2 className="ad-preview-sponsored-product-carousel-title">{ad.details.productName}</h2>
                                                                    <p className="ad-preview-sponsored-product-carousel-price">$100</p>
                                                                    <span className="ad-preview-sponsored-product-carousel-button">Buy now</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                    {selectedFormat === 'Sponsored Product Carousel Mobile' && (
                                                        <div className="ad-preview-mobile-container">
                                                            <div className="ad-preview-sponsored-product-carousel-wrapper">
                                                                <div className="ad-preview-sponsored-product-carousel-mobile-item">
                                                                    <div className="ad-preview-sponsored-product-carousel-mobile-image-container">
                                                                        <img
                                                                            src={
                                                                                ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                                    ? URL.createObjectURL(ad.adSet.files[selectedFormat])
                                                                                    : ad.adSet.images[selectedFormat]
                                                                            }
                                                                            alt={`${selectedFormat} preview`}
                                                                            className="ad-preview-sponsored-product-carousel-mobile-image"
                                                                            style={{
                                                                                left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                                top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                                position: 'absolute',
                                                                                transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`,
                                                                                transformOrigin: 'center',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="ad-preview-sponsored-product-carousel-mobile-content">
                                                                        <h2 className="ad-preview-sponsored-product-carousel-mobile-title">{ad.details.productName}</h2>
                                                                        <p className="ad-preview-sponsored-product-carousel-mobile-price">$100</p>
                                                                        <span className="ad-preview-sponsored-product-carousel-mobile-button">Buy now</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}



                                                {selectedFormat === 'Sponsored Feed Ads' && (
                                                    <div className="ad-preview-sponsored-feed-products-container">
                                                    <div className="ad-preview-sponsored-feed-products-wrapper">
                                                        <div className="ad-preview-sponsored-feed-products-item">
                                                            <div className="ad-preview-sponsored-feed-products-image-container">
                                                                    <img
                                                                        src={
                                                                            ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                                ? URL.createObjectURL(ad.adSet.files[selectedFormat]) 
                                                                                : ad.adSet.images[selectedFormat]
                                                                        }
                                                                        alt={`${selectedFormat} preview`}
                                                                        className="ad-preview-sponsored-feed-ads-image"
                                                                        style={{
                                                                            left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                            top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                            transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`, 
                                                                            position: 'absolute',
                                                                            transformOrigin: 'center',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="ad-preview-sponsored-feed-products-content">
                                                                    <h2 className="ad-preview-sponsored-feed-products-title">{ad.details.productName}</h2>
                                                                    <p className="ad-preview-sponsored-feed-products-price">$100</p>
                                                                    <span className="ad-preview-sponsored-feed-products-store-link">Buy Now</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Map Ad Slider' && (
                                                <div className="ad-preview-map-product-slider-container">
                                                    <div className="ad-preview-map-product-slider-wrapper">
                                                        <div className="ad-preview-map-product-slider-item">
                                                            <div className="ad-preview-map-product-slider-image-container">
                                                                <img
                                                                    src={
                                                                        ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                            ? URL.createObjectURL(ad.adSet.files[selectedFormat])
                                                                            : ad.adSet.images[selectedFormat]
                                                                    }
                                                                    alt={`${selectedFormat} preview`}
                                                                    className="ad-preview-map-product-slider-image"
                                                                    style={{
                                                                        left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                        top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                        position: 'absolute',
                                                                        transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`,
                                                                        transformOrigin: 'center',
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="ad-preview-map-product-slider-content">
                                                                <h2 className="ad-preview-map-product-slider-title">{ad.details.productName}</h2>
                                                                <p className="ad-preview-map-product-slider-price">$100</p>
                                                                <span className="ad-preview-map-product-slider-button">Buy now</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}


                                                    {selectedFormat === 'Mobile Bar Banner Ad' && (
                                                        <div className="ad-preview-mobile-container">
                                                            <div className="ad-preview-banner-mobile-container">
                                                                <div className="ad-preview-banner-image-container">
                                                                    <img
                                                                        src={
                                                                            ad.adSet.files && ad.adSet.files[selectedFormat] instanceof File
                                                                                ? URL.createObjectURL(ad.adSet.files[selectedFormat])
                                                                                : ad.adSet.images[selectedFormat]
                                                                        }
                                                                        alt={`${selectedFormat} preview`}
                                                                        className="ad-preview-mobile-image"
                                                                        style={{
                                                                            left: `${ad.adSet.positions[selectedFormat]?.x || 0}%`,
                                                                            top: `${ad.adSet.positions[selectedFormat]?.y || 0}%`,
                                                                            position: 'absolute',
                                                                            transform: `scale(${ad.adSet.scales[selectedFormat] || 1})`,
                                                                            transformOrigin: 'center',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="ad-preview-banner-content">
                                                                    <strong className="ad-preview-banner-link">{ad.details.productName}</strong>
                                                                    <span className="ad-preview-banner-link"> – Explore Now</span>
                                                                    <button className="ad-preview-banner-dismiss">
                                                                        <XMarkIcon className="ad-preview-banner-icon" aria-hidden="true" />
                                                                        <span className="sr-only">Dismiss</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                            </div>
                                        ) : (
                                            <p>No ad preview available for {selectedFormat}</p>
                                        )}
                                    </div>

                                    <button
                                        className="ad-campaign-ad-preview-edit-button"
                                        onClick={() => onEdit(adType, index)}
                                    >
                                        Edit
                                    </button>
                                    {adType === "product" && (
                                        <>
                                            <p><strong>Product Name:</strong> {ad.details.productName}</p>
                                            <p><strong>Ad Spend:</strong> ${ad.details.adSpend}</p>
                                            <p><strong>Duration:</strong> {ad.details.adDuration} days</p>
                                        </>
                                    )}
                                    {adType === "shop" && (
                                        <>
                                            <p><strong>Shop Name:</strong> {ad.details.shopName}</p>
                                            <p><strong>Ad Spend:</strong> ${ad.details.adSpend}</p>
                                            <p><strong>Duration:</strong> {ad.details.adDuration} days</p>
                                        </>
                                    )}
                                    {adType === "brand" && (
                                        <>
                                            <p><strong>Brand Name:</strong> {ad.details.brandName}</p>
                                            <p><strong>Ad Spend:</strong> ${ad.details.adSpend}</p>
                                            <p><strong>Duration:</strong> {ad.details.adDuration} days</p>
                                        </>
                                    )}
                                    {adType === "custom" && (
                                        <> 
                                            <p><strong>Ad Text:</strong> {ad.details.adText}</p>
                                            <p><strong>Link:</strong> {ad.details.adLink}</p>
                                            <p><strong>Ad Spend:</strong> ${ad.details.adSpend}</p>
                                            <p><strong>Duration:</strong> {ad.details.adDuration} days</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default CampaignReview;



