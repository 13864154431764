import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import axios from 'axios';
import { FaUpload } from 'react-icons/fa';
import { server } from "../../server";

const ShopImagesModal = ({ isOpen, onClose, onImageSelect }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [adFiles, setAdFiles] = useState([]);
    const [uploadedPreviews, setUploadedPreviews] = useState([]);
    const [uploadedImageMap, setUploadedImageMap] = useState(new Map());
    const [activeTab, setActiveTab] = useState('uploadedImages');
    const [activeSubTab, setActiveSubTab] = useState('all'); 
    const [currentPage, setCurrentPage] = useState(1); 
    const imagesPerPage = 12;

    useEffect(() => {
        if (isOpen) {
            const fetchImages = async () => {
                try {
                    setLoading(true);
                    const response = await axios.get(`${server}/image/shop-images?page=${currentPage}&limit=${imagesPerPage}`, {
                        withCredentials: true,
                        headers: {
                            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                        },
                    });
                    setImages(response.data.images);
                } catch (error) {
                    setError('Failed to load images');
                } finally {
                    setLoading(false);
                }
            };
            fetchImages();
        }
    }, [isOpen, currentPage]);

    const nextPage = () => setCurrentPage((prev) => prev + 1);
    const prevPage = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));

    // Handle image selection from shop
    const handleImageClick = (image) => {
        if (onImageSelect) {
            onImageSelect(image);
        }
        onClose();
    };

    // Handle new image uploads
    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const newUploadedPreviews = [];
        const newUploadedFiles = [];

        files.forEach((file) => {
            const previewUrl = URL.createObjectURL(file);
            if (!uploadedImageMap.has(file.name)) {
                uploadedImageMap.set(file.name, { file, previewUrl });
                newUploadedPreviews.push(previewUrl);
                newUploadedFiles.push(file);
            }
        });

        setAdFiles(prevFiles => [...prevFiles, ...newUploadedFiles]);
        setUploadedPreviews(prevPreviews => [...prevPreviews, ...newUploadedPreviews]);

        return () => newUploadedPreviews.forEach(url => URL.revokeObjectURL(url));
    };

    const handleUploadClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleUploadSave = (fileName) => {
        if (uploadedImageMap.has(fileName) && onImageSelect) {
            const uploadedImage = uploadedImageMap.get(fileName);
            onImageSelect(uploadedImage);
        }
        onClose();
    };

    // Filter images based on origin (product, post, or ad)
    const filteredImages = images.filter((image) => {
        if (activeSubTab === 'all') return true;
        return image.origin === activeSubTab;
    });

    if (!isOpen) return null;

    return (
        <div className="shop-images-modal-overlay">
            <div className="shop-images-modal">
                <button onClick={onClose} className="shop-images-modal-close-button">
                    <AiOutlineClose />
                </button>
                <h2>Select or Upload an Image</h2>

                {/* Tab Navigation */}
                <div className="shop-images-modal-tabs">
                    <button
                        className={`shop-images-modal-tab ${activeTab === 'uploadedImages' ? 'active' : ''}`}
                        onClick={() => setActiveTab('uploadedImages')}
                    >
                        Uploaded Images
                    </button>
                    <button
                        className={`shop-images-modal-tab ${activeTab === 'uploadNewImage' ? 'active' : ''}`}
                        onClick={() => setActiveTab('uploadNewImage')}
                    >
                        Upload New Image
                    </button>
                </div>

                {/* Responsive Dropdown for small screens */}
                <div className="shop-images-modal-sub-tabs-select-wrapper">
                    <label htmlFor="tabs-select" className="sr-only">Select a tab</label>
                    <select
                        id="tabs-select"
                        name="tabs"
                        value={activeSubTab}
                        className="shop-images-modal-sub-tabs-select"
                        onChange={(e) => setActiveSubTab(e.target.value)}
                    >
                        <option value="all">All</option>
                        <option value="product">Product Content</option>
                        <option value="post">Post Content</option>
                        <option value="ad">Ad Content</option>
                    </select>
                </div>

                {/* Sub-tabs under Uploaded Images for larger screens */}
                {activeTab === 'uploadedImages' && (
                    <>
                        <div className="shop-images-modal-sub-tabs">
                            <button
                                className={`shop-images-modal-sub-tab ${activeSubTab === 'all' ? 'active' : ''}`}
                                onClick={() => setActiveSubTab('all')}
                            >
                                All
                            </button>
                            <button
                                className={`shop-images-modal-sub-tab ${activeSubTab === 'product' ? 'active' : ''}`}
                                onClick={() => setActiveSubTab('product')}
                            >
                                Product Content
                            </button>
                            <button
                                className={`shop-images-modal-sub-tab ${activeSubTab === 'post' ? 'active' : ''}`}
                                onClick={() => setActiveSubTab('post')}
                            >
                                Post Content
                            </button>
                            <button
                                className={`shop-images-modal-sub-tab ${activeSubTab === 'ad' ? 'active' : ''}`}
                                onClick={() => setActiveSubTab('ad')}
                            >
                                Ad Content
                            </button>
                        </div>

                        {/* Content based on the active tab */}
                        {loading && <p className="shop-images-modal-loading">Loading images...</p>}
                        {error && <p className="shop-images-modal-error">{error}</p>}
                        {!loading && filteredImages.length === 0 && <p className="shop-images-modal-no-images">No images found.</p>}

                        {/* Image Grid for existing images */}
                        <div className="shop-images-modal-image-grid">
                            {filteredImages.map((image) => (
                                <div
                                    key={image.public_id}
                                    className="shop-images-modal-image-item"
                                    onClick={() => handleImageClick(image)}
                                >
                                    <img src={image.url} alt="Uploaded" className="shop-images-modal-image-preview" />
                                </div>
                            ))}
                        </div>
                        <div className="shop-images-pagination">
                                <button onClick={prevPage} disabled={currentPage === 1}>
                                    Previous
                                </button>
                                <span>Page {currentPage}</span>
                                <button onClick={nextPage} disabled={images.length < imagesPerPage}>
                                    Next
                                </button>
                            </div>
                    </>
                )}

                {activeTab === 'uploadNewImage' && (
                    <>
                        {/* Upload Section */}
                        <div className="shop-images-modal-upload-section">
                            <div className="ad-preview-upload-icon-wrapper" onClick={handleUploadClick}>
                                <FaUpload className="ad-preview-upload-icon" />
                                <p className="ad-preview-upload-text">Upload Custom Images</p>
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImageChange}
                                    className="ad-preview-upload-input"
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>

                        {/* Preview and Save Section for Uploaded Images */}
                        {uploadedPreviews.length > 0 && (
                            <div className="uploaded-images-grid">
                                {adFiles.map((file, index) => (
                                    <div
                                        key={index}
                                        className="uploaded-image-preview"
                                        onClick={() => handleUploadSave(file.name)}
                                    >
                                        <img src={uploadedPreviews[index]} alt={`Uploaded ${index + 1}`} className="uploaded-image" />
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ShopImagesModal;




