import React from 'react'
import Header from "../components/Layout/Header"
import Sidebar from '../components/Layout/Sidebar';
import TermsAndConditions from '../components/Admin/TermsAndConditions';
import Footer from '../components/Layout/Footer';

const TermsAndConditionsPage = () => {
  
  return (
    <div>
        <Header />
        <Sidebar />
        <div>
        <TermsAndConditions />
        </div>
        <Footer />
    </div>
  )
}

export default TermsAndConditionsPage ;