import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';
import { RxCross1 } from 'react-icons/rx';
import { toggleAvatarDropdown } from '../../state/uiSlice';


const AvatarDropdown = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.user);
  const sellerProfiles = useSelector((state) => state.seller.sellers);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const userSubscriptions = useSelector((state) => state.user.subscriptions || []);
  const sellerSubscriptions = useSelector((state) => state.seller.subscriptions || []);
  const isAvatarDropdownOpen = useSelector((state) => state.ui.isAvatarDropdownOpen);
  const profile = currentProfile === 'User' ? userProfile : sellerProfiles.find((seller) => seller.name === currentProfile);
  const avatarUrl = profile?.avatar?.url;
  const isUser = currentProfile === 'User';

  const getSubscriptionImageUrl = () => {
    let maxTier = 0;

    if (currentProfile === 'User' && userSubscriptions.length) {
      maxTier = userSubscriptions.reduce((max, sub) => {
        return sub.tier === 'Super OG' ? 3 : sub.tier === 'OG' ? 2 : max;
      }, 0);
    } else if (sellerSubscriptions.length) {
      maxTier = sellerSubscriptions.reduce((max, sub) => {
        return sub.tier === 'Super OG Shop' ? 3 : sub.tier === 'OG Shop' ? 2 : max;
      }, 0);
    }

    switch (maxTier) {
      case 3:
        return 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1715090157/super_og_youavf.svg';
      case 2:
        return 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1715090152/og_ircw65.svg';
      default:
        return null;
    }
  };

  const subscriptionIconUrl = getSubscriptionImageUrl();

  const handleToggleAvatarDropdown = () => {
    dispatch(toggleAvatarDropdown());
  };

  const logoutHandler = () => {
    axios
      .post(`${server}/user/logout`, {}, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        setTimeout(() => {
          window.location.href = 'https://buzzvibe.com';
        }, 1000);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  if (!isAvatarDropdownOpen) return null;

  return (
    <div className="avatar-dropdown-menu-overlay">
      <div className="avatar-dropdown-menu-popup">
      <div className="avatar-dropdown-menu-close-btn-container" >
    <RxCross1 size={25} className="avatar-dropdown-menu-close-btn" onClick={handleToggleAvatarDropdown}/>
      </div>

        <div className="avatar-dropdown-menu-header">
          <img src={avatarUrl || 'default-avatar-url'} alt={`${currentProfile} Avatar`} className="avatar-dropdown-menu-avatar" />
        </div>
        <div className="avatar-dropdown-menu-items">
          {subscriptionIconUrl && (
            <div className="avatar-dropdown-menu-subscription-icon-container">
              <img src={subscriptionIconUrl} alt="Subscription Status" className="avatar-dropdown-menu-subscription-icon" />
            </div>
          )}
          <div className="avatar-dropdown-menu-item">
            <Link to={isUser ? `/my-profile/${profile?._id}` : `/shop/preview/${profile?.handle}`} className="avatar-dropdown-menu-link">
              My Profile
            </Link>
          </div>
          <div className="avatar-dropdown-menu-item">
            <Link to={isUser ? '/profile' : '/settings'} className="avatar-dropdown-menu-link">
              Account Settings
            </Link>
          </div>
          <div className="avatar-dropdown-menu-item">
            <Link to="/shop-create" className="avatar-dropdown-menu-link">
              Create a Shop
            </Link>
          </div>
          <div className="avatar-dropdown-menu-item">
            <div onClick={logoutHandler} className="avatar-dropdown-menu-link avatar-dropdown-menu-link-cursor">
              Log out
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarDropdown;



