import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaPause, FaForward, FaBackward } from 'react-icons/fa';

const MusicDropdown = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);

  const musicSections = [
    { title: "Now Playing", description: "Listen to what’s playing live right now." },
    { title: "Top Artists", description: "Explore the most popular artists on the platform." },
    { title: "New Releases", description: "Stay up-to-date with the latest albums and singles." },
    { title: "Genres", description: "Browse by genre to find music that suits your mood." },
  ];

  useEffect(() => {
    const audio = audioRef.current;

    const setAudioMetadata = () => {
      setDuration(audio.duration);
    };

    const updateCurrentTime = () => {
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener('loadedmetadata', setAudioMetadata);
    audio.addEventListener('timeupdate', updateCurrentTime);

    return () => {
      audio.removeEventListener('loadedmetadata', setAudioMetadata);
      audio.removeEventListener('timeupdate', updateCurrentTime);
    };
  }, []);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (time) => {
    if (isNaN(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleProgressChange = (e) => {
    const audio = audioRef.current;
    const newTime = (e.target.value / 100) * duration;
    audio.currentTime = newTime;
    setCurrentTime(newTime);
  };

  return (
    <div className="music-dropdown-container">
      <div className="music-dropdown-header">
        <h3 className="music-dropdown-title">Music</h3>
        <div className="music-player">
          <div className="music-player-cover">
            <img
              src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/030c8e48-d3b3-42f6-e924-49ed94b50f00/public" 
              alt="Album Cover"
              className="music-player-cover-image"
            />
          </div>
          <div className="music-player-info">
            <p className="music-player-title">DooWoop - Shiloh x Lyana</p>
            <div className="music-progress-bar">
              <span className="current-time">{formatTime(currentTime)}</span>
              <input
                type="range"
                min="0"
                max="100"
                value={(currentTime / duration) * 100 || 0}
                onChange={handleProgressChange}
                className="progress-slider"
              />
              <span className="duration">{formatTime(duration)}</span>
            </div>
          </div>
          <div className="music-player-controls">
            <FaBackward className="player-icon" />
            {isPlaying ? (
              <FaPause className="player-icon play-pause-icon" onClick={handlePlayPause} />
            ) : (
              <FaPlay className="player-icon play-pause-icon" onClick={handlePlayPause} />
            )}
            <FaForward className="player-icon" />
          </div>
          {/* Audio element with Cloudflare R2 URL for playing the MP3 file */}
          <audio ref={audioRef} src="https://images.buzzvibe.com/doowoop_Shiloh%20x%20Iyana_173_Bbmaj_DARKPOP.mp3" preload="none" />
        </div>
      </div>
      <div className="music-dropdown-sections">
        {musicSections.map((section, index) => (
          <div key={index} className="music-dropdown-section">
            <h4 className="music-dropdown-section-title">{section.title}</h4>
            <p className="music-dropdown-section-description">{section.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MusicDropdown;




