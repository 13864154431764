import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ShopBySellerCircle from '../Shop/ShopBySellerCircle';
import RecommendedConnections from '../Followers/RecommendedFriends';
import { ecommerceServer } from '../../server'; // Ensure ecommerceServer is imported

const CommunityDropdown = () => {
  const [sellers, setSellers] = useState([]); // State to hold the list of sellers

  // Fetch sellers from the ecommerce server
  useEffect(() => {
    const fetchSellers = async () => {
      try {
        const response = await axios.get(`${ecommerceServer}/shop/all-sellers`, { withCredentials: true });
        setSellers(response.data.sellers);
      } catch (error) {
        console.error('Error fetching sellers:', error);
      }
    };

    fetchSellers();
  }, []);

  return (
    <div className="community-dropdown-container">
      <div className="community-dropdown-inner">
        <h3 className="community-dropdown-title">Community</h3>
        {/* Pass fetched sellers to ShopBySellerCircle */}
        <ShopBySellerCircle sellers={sellers} />
        <RecommendedConnections />
      </div>
    </div>
  );
};

export default CommunityDropdown;

