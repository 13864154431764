import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { adsServer } from "../../server";

const SavePaymentMethod = ({ onPaymentMethodRetrieved }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentMethodInfo, setPaymentMethodInfo] = useState(null);
  const [openModal, setOpenModal] = useState(false); 

  const fetchPaymentMethod = async () => {
    try {
      const response = await axios.get(`${adsServer}/sponsored/get-payment-method`, {
        withCredentials: true, 
        headers: {
          "Content-Type": "application/json",
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        } 
      });

      if (response.data.paymentMethod) {
        setPaymentMethodInfo(response.data.paymentMethod);
        onPaymentMethodRetrieved(true); 
      } else {
        setPaymentMethodInfo(null);
        onPaymentMethodRetrieved(false); 
      }
    } catch (error) {
      console.error('Error fetching payment method:', error.response?.data);
      setPaymentMethodInfo(null);
      onPaymentMethodRetrieved(false);
    }
  };

  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('[error]', error);
      alert(error.message);
      setLoading(false);
    } else {
      try {
        const response = await axios.post(`${adsServer}/sponsored/save-payment-method`, {
          paymentMethodId: paymentMethod.id,
        }, { withCredentials: true, headers: {
          "Content-Type": "application/json",
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        }});

        alert('Payment method saved successfully!');
        fetchPaymentMethod(); // Fetch the newly saved payment method
        setOpenModal(false); // Close the modal after saving
      } catch (err) {
        console.error('Error saving payment method:', err.response?.data);
        alert(err.response?.data.error || 'Failed to save the payment method.');
      }
      setLoading(false);
    }
  };

  return (
    <div className="save-payment-method-container">
      {paymentMethodInfo ? (
        <div className="save-payment-method-details">
          <h3>Current Payment Method:</h3>
          <p>Brand: {paymentMethodInfo.card.brand}</p>
          <p>Last four: {paymentMethodInfo.card.last4}</p>
          <p>Expiry: {paymentMethodInfo.card.exp_month}/{paymentMethodInfo.card.exp_year}</p>
          <button onClick={() => setOpenModal(true)} className="save-payment-method-open-btn">
            Add New Payment Method
          </button>
        </div>
      ) : (
        <>
          <button onClick={() => setOpenModal(true)} className="save-payment-method-open-btn">
            Add Payment Method
          </button>
        </>
      )}

      {openModal && (
        <div className="save-payment-method-modal">
          <div className="save-payment-method-modal-content">
            <span onClick={() => setOpenModal(false)} className="save-payment-method-close-btn">&times;</span>
            <h2>Add Payment Method</h2>
            <form onSubmit={handleSubmit} className="save-payment-method-form">
              <CardElement className="save-payment-method-card-element" />
              <button type="submit" className="save-payment-method-submit-btn" disabled={!stripe || loading}>
                {loading ? "Saving..." : "Save Payment Method"}
              </button>
            </form>
            <p className="save-payment-method-disclaimer">
              By saving your payment method, you agree that this payment method will be stored securely in Stripe and will be kept on file for all subscription and ad-related purchases and activities.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavePaymentMethod;