import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { adsServer } from "../../server";

const QuadProductAdCarouselProps = ({ ads }) => { 
    const [currentIndex, setCurrentIndex] = useState(0);
    const adRefs = useRef(new Map());

    // Group ads into quads
    const groupAdsInQuads = (ads) => {
        const quads = [];
        for (let i = 0; i < ads.length; i += 4) {
            const quad = ads.slice(i, i + 4); // Group ads in groups of 4
            quads.push(quad);
        }
        return quads;
    };

    const adQuads = groupAdsInQuads(ads); // Group ads into quads

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/viewed-impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log(`Viewed impression logged for ad: ${adId}`);
        } catch (error) {
            console.error(`Error logging viewed impression for ad: ${adId}`, error);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const adId = entry.target.dataset.adId;
                    if (entry.isIntersecting) {
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);
                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer);
                    }
                });
            },
            { threshold: 0.5 }
        );

        const currentAdQuad = adQuads[currentIndex] || [];
        currentAdQuad.forEach((ad) => {
            const adElement = adRefs.current.get(ad._id);
            if (adElement) {
                observer.observe(adElement);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [adQuads, currentIndex]);

    const logAdClick = async (ad_id) => {
        try {
            await axios.post(`${adsServer}/sponsored/click/${ad_id}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Click logged for ad:', ad_id);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % adQuads.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + adQuads.length) % adQuads.length);
    };

    if (adQuads.length === 0) {
        return <div className="quad-product-ad-carousel-loading"></div>;
    }

    if (ads.length < 4) {
        return null;
    }

    const currentAdQuad = adQuads[currentIndex]; // Get the current quad of ads

    return (
        <div className="quad-product-ad-carousel-container">
            {adQuads.length > 0 && (
                <>
                    {/* Quad wrapper with translateX transition */}
                    <div
                        className="quad-product-ad-carousel-wrapper"
                        style={{
                            display: 'flex',
                            width: '100%',
                            transform: `translateX(-${currentIndex * 100}%)`,
                            transition: 'transform 0.5s ease-in-out',
                        }}
                    >
                        {adQuads.map((quad, index) => (
                            <div key={index} className="quad-product-ad-carousel-quad" style={{ flex: '1 0 100%', display: 'flex' }}>
                                {quad.map((ad, idx) => (
                                    ad.product && ad.product._id ? (
                                        <Item key={ad._id} ad={ad} logAdClick={() => logAdClick(ad._id)} adRefs={adRefs} />
                                    ) : (
                                        <div key={idx} className="quad-product-ad-carousel-error">Error: Missing product data.</div>
                                    )
                                ))}
                            </div>
                        ))}
                    </div>
                    {adQuads.length > 1 && (
                        <>
                            <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>‹</button>
                            <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>›</button>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const Item = ({ ad, logAdClick, adRefs }) => {
    const { images, positions, scales } = ad.adSet;
    const imageUrl = images && images["Quad Carousel"];
    const position = positions && positions["Quad Carousel"] || { x: 0, y: 0 };
    const scale = scales && scales["Quad Carousel"] || 1;

    return (
        <Link
            to={`/product/${ad.product._id}`}
            onClick={logAdClick}
            className="quad-product-ad-carousel-item-link"
        >
            <div
                className="quad-product-ad-carousel-item"
                data-ad-id={ad._id} 
                ref={(el) => el && adRefs.current.set(ad._id, el)} 
            >
                <div className="quad-product-ad-carousel-image-wrapper">
                    <img
                        src={imageUrl}
                        alt={ad.product.name}
                        className="quad-product-ad-carousel-image"
                        style={{
                            position: 'absolute',
                            left: `${position.x}%`,
                            top: `${position.y}%`,
                            transform: `scale(${scale})`,
                            transformOrigin: 'center',
                        }}
                    />
                </div>
                <div className="quad-product-ad-carousel-content">
                    <h2 className="quad-product-ad-carousel-title">{ad.product.name}</h2>
                    <p className="quad-product-ad-carousel-price">{`$${ad.product.discountPrice || ad.product.originalPrice}`}</p>
                    {/* Button remains for design purposes, but the entire item is clickable */}
                    <button className="quad-product-ad-carousel-button">
                        Buy now
                    </button>
                </div>
            </div>
        </Link>
    );
};


export default QuadProductAdCarouselProps;
