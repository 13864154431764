import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ecommerceServer } from '../../server';


const ShopBySellerProds = () => {
  const [sellers, setSellers] = useState([]);
  const [currentSlides, setCurrentSlides] = useState({}); // To track current slide per seller
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchSellersWithProducts = async () => {
      try {
        const response = await axios.get(
          `${ecommerceServer}/shop/sellers-with-products`,
          { withCredentials: true }
        );
        setSellers(response.data.sellers);
      } catch (error) {
        console.error('Error fetching sellers with products:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchSellersWithProducts();
  }, []);

  const handleNext = (sellerId, totalProducts) => {
    setCurrentSlides((prev) => {
      const current = prev[sellerId] || 0;
      const maxSlide = Math.ceil(totalProducts / 2) - 1;
      return { ...prev, [sellerId]: current < maxSlide ? current + 1 : 0 };
    });
  };

  const handlePrev = (sellerId, totalProducts) => {
    setCurrentSlides((prev) => {
      const current = prev[sellerId] || 0;
      const maxSlide = Math.ceil(totalProducts / 2) - 1;
      return { ...prev, [sellerId]: current > 0 ? current - 1 : maxSlide };
    });
  };

  if (loading) {
    return <div className="shop-prods-loading">Loading...</div>;
  }

  if (error) {
    return <div className="shop-prods-error">Failed to load shops. Please try again later.</div>;
  }

  return (
    <div className="shop-prods-list-container">
      {sellers.map((seller) => {
        const totalProducts = seller.products.length;
        const currentSlide = currentSlides[seller._id] || 0;
        const maxSlide = Math.ceil(totalProducts / 2) - 1;
        const hasProducts = totalProducts > 0;

        return (
          <div
            key={seller._id}
            className={`shop-prods-grid ${!hasProducts ? 'no-products' : ''}`}
          >
            {/* Shop Banner */}
            <div className="shop-prods-banner">
              <a href={`https://www.buzzvibe.com/shop/${seller.handle}`}>
                <img
                  src={seller.banner?.url || '/placeholder-banner.jpg'}
                  alt={seller.name}
                  className="shop-prods-banner-image"
                  loading="lazy"
                />
              </a>
              {/* Overlay with Shop Avatar and Name */}
              <div className="shop-prods-banner-overlay">
                <div className="shop-prods-avatar-container">
                  {seller.avatar?.url ? (
                    <img
                      src={seller.avatar.url}
                      alt={`${seller.name} Avatar`}
                      className="shop-prods-avatar"
                      loading="lazy"
                    />
                  ) : (
                    <div className="shop-prods-avatar-placeholder">No Avatar</div>
                  )}
                </div>
                <h2 className="shop-prods-shop-name">{seller.name}</h2>
              </div>
            </div>

            {/* Products Carousel */}
            {hasProducts && (
              <div className="shop-prods-products-carousel">
                <div
                  className="shop-prods-carousel-track"
                  style={{
                    transform: `translateX(-${currentSlide * 100}%)`,
                    width: `${Math.ceil(totalProducts / 2) * 100}%`,
                  }}
                >
                  {Array.from({ length: Math.ceil(totalProducts / 2) }).map((_, slideIndex) => {
                    const productsSlice = seller.products.slice(slideIndex * 2, slideIndex * 2 + 2);
                    return (
                      <div key={slideIndex} className="shop-prods-carousel-slide">
                        {productsSlice.map((product) => (
                          <a
                            href={`https://www.buzzvibe.com/product/${product._id}`}
                            key={product._id}
                            className="shop-prods-product-card"
                          >
                            <div className="shop-prods-product-image-container">
                              <img
                                src={product.images[0]?.url || '/placeholder-product.jpg'}
                                alt={product.name}
                                className="shop-prods-product-image"
                                loading="lazy"
                              />
                              <div className="shop-prods-product-overlay">
                                <div className="shop-prods-product-info">
                                  <h4 className="shop-prods-product-name">{product.name}</h4>
                                  <p className="shop-prods-product-price">${product.price}</p>
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    );
                  })}
                </div>
                {/* Carousel Controls */}
                {totalProducts > 2 && (
                  <>
                    <button
                      className="shop-prods-carousel-button shop-prods-prev"
                      onClick={() => handlePrev(seller._id, totalProducts)}
                      aria-label={`Previous products of ${seller.name}`}
                    >
                      &#10094;
                    </button>
                    <button
                      className="shop-prods-carousel-button shop-prods-next"
                      onClick={() => handleNext(seller._id, totalProducts)}
                      aria-label={`Next products of ${seller.name}`}
                    >
                      &#10095;
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ShopBySellerProds;



