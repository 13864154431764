import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';
import VariantsComponentGuide from '../../components/Documentation/VariantsComponentGuide';

const VariantProductDocs = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <VariantsComponentGuide />
    </div>
  );
};

export default VariantProductDocs;
