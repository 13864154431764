import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedBrand } from '../../redux/actions/brand';

const BrandSelection = ({ ownedBrands }) => { 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBrandSelect = (brand) => {
    dispatch(setSelectedBrand(brand));
    navigate(`/dashboard/brands/${brand._id}`);
  };

  return (
    <div className="brand-selection-container">
      {ownedBrands?.length ? (
        ownedBrands.map((brand) => (
          <BrandCard
            key={brand._id}
            brand={brand}
            onSelect={() => handleBrandSelect(brand)}
          />
        ))
      ) : (
        <p className="brand-selection-message"></p>
      )}
    </div>
  );
};

const BrandCard = ({ brand, onSelect }) => (
  <div className="brand-selection-card" onClick={onSelect}>
    <img
      src={brand.avatarImage.url}
      alt={`${brand.name} Avatar`}
      className="brand-selection-image"
    />
    <div className="brand-selection-overlay">
      <h3 className="brand-selection-name">{brand.name}</h3>
    </div>
  </div>
);

export default BrandSelection;


