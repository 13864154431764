import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Layout/Loader";
import { ecommerceServer } from "../../server";
import { toast } from "react-toastify";
import _ from "lodash";
import { getAllProductsShop } from "../../redux/actions/product";
import useWindowSize from "../../hooks/useWindowSize";

const AllCoupons = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [minAmount, setMinAmount] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]); 
  const [value, setValue] = useState(null);
  const [checkingName, setCheckingName] = useState(false);
  const [nameAvailable, setNameAvailable] = useState(true);
  const [shopWide, setShopWide] = useState(null); 
  const [usageType, setUsageType] = useState("");
  const [maxUses, setMaxUses] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const currentProfile = sessionStorage.getItem("currentProfile");
  const windowSize = useWindowSize();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${ecommerceServer}/coupon/get-coupon/${seller._id}`, {
        withCredentials: true,
        headers: {
          "Current-Profile": currentProfile,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setCoupons(res.data.couponCodes);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const handleDelete = async (id) => {
    axios
      .delete(`${ecommerceServer}/coupon/delete-coupon/${id}`, {
        withCredentials: true,
        headers: {
          "Current-Profile": currentProfile,
        },
      })
      .then((res) => {
        toast.success("Coupon code deleted successfully!");
      });
    window.location.reload();
  };

  const validateName = (name) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(name) && !/\s/.test(name) && name.length <= 30;
  };

  const checkNameAvailability = async (value) => {
    if (validateName(value)) {
      setCheckingName(true);
      try {
        const response = await axios.post(`${ecommerceServer}/coupon/check-name`, {
          name: value,
        });
        setNameAvailable(true);
      } catch (error) {
        setNameAvailable(false);
      } finally {
        setCheckingName(false);
      }
    } else {
      setNameAvailable(false);
      toast.error("Coupon code should not contain spaces and must be alphanumeric.");
    }
  };

  const debouncedCheckNameAvailability = useCallback(
    _.debounce(checkNameAvailability, 1000),
    []
  );

  const handleChangeName = (e) => {
    let value = e.target.value.toLowerCase();
    value = value.replace(/\s+/g, ""); 
    setName(value);
    if (value) {
      debouncedCheckNameAvailability(value);
    } else {
      setNameAvailable(null);
    }
  };


  const handleProductChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(option => ({
      value: option.value,
      label: option.getAttribute('data-label'),
      image: option.getAttribute('data-image')
    }));
    setSelectedProducts(selectedOptions);
  };

  const productOptions = products ? products.map(product => ({
    value: product._id,
    label: product.name,
    image: product.images.length > 0 ? product.images[0].url : ''
  })) : [];
  


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateName(name)) {
      setNameAvailable(false);
      return;
    }

    if (!nameAvailable) {
      return;
    }
    // Extract product IDs from selected options
    const productIds = selectedProducts.map(product => product.value);

    await axios
      .post(
        `${ecommerceServer}/coupon/create-coupon-code`,
        {
          name,
          minAmount,
          maxAmount,
          selectedProducts: shopWide ? [] : productIds,
          value,
          shopId: seller._id,
          shopWide,
          usageType,
          maxUses: usageType === 'number' ? maxUses : null,
          startTime: usageType === 'time' ? startTime : null,
          expiryDate: usageType === 'time' ? expiryDate : null,
        },
        {
          withCredentials: true,
          headers: {
            "Current-Profile": currentProfile,
          },
        }
      )
      .then((res) => {
        toast.success("Coupon code created successfully!");
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const columns = [
    { field: "id", headerName: "Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Coupon Code",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: "Value",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <button onClick={() => handleDelete(params.id)} className="all-coupons-shop-component-delete-button">
            <AiOutlineDelete size={20} />
          </button>
        );
      },
    },
  ];

  const row = [];

  coupons &&
    coupons.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: item.value + " %",
        sold: 10,
      });
    });

    const isMobile = windowSize.width <= 768;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="all-coupons-shop-component-container">
          <div className="all-coupons-shop-component-create-button-container">
            <button
              className="all-coupons-shop-component-create-button"
              onClick={() => setOpen(true)}
            >
              Create Coupon Code
            </button>
          </div>
          <div className="all-coupons-shop-component-data-grid">
          {isMobile ? (
            // Render card layout for mobile
            coupons.map((item) => (
              <div key={item._id} className="all-coupons-shop-component-card">
                <div className="all-coupons-shop-component-card-header">
                  <span className="all-coupons-shop-component-card-title">
                    {item.name}
                  </span>
                  <button
                    onClick={() => handleDelete(item._id)}
                    className="all-coupons-shop-component-card-delete-button"
                  >
                    <AiOutlineDelete size={20} />
                  </button>
                </div>
                <div className="all-coupons-shop-component-card-body">
                  <p>Value: {item.value} %</p>
                </div>
              </div>
            ))
          ) : (
            // Render table layout for desktop
            <table>
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.field} style={{ minWidth: column.minWidth }}>
                      {column.headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {row.map((r) => (
                  <tr key={r.id}>
                    {columns.map((column) => (
                      <td key={column.field} style={{ minWidth: column.minWidth }}>
                        {column.renderCell ? column.renderCell({ id: r.id }) : r[column.field.toLowerCase()]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
          {open && (
            <div className="all-coupons-shop-component-modal">
              <div className="all-coupons-shop-component-modal-content">
                <div className="all-coupons-shop-component-modal-header">
                  <RxCross1
                    size={30}
                    className="all-coupons-shop-component-modal-close"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <h5 className="all-coupons-shop-component-modal-title">
                  Create Coupon code
                </h5>
                <form onSubmit={handleSubmit} className="all-coupons-shop-component-form">
                  <div className="all-coupons-shop-component-input-group">
                    <label>
                      Name <span className="all-coupons-shop-component-required">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      required
                      value={name}
                      onChange={handleChangeName}
                      placeholder="Enter your coupon code name..."
                    />
                    {name && nameAvailable === false && !checkingName && (
                      <p className="all-coupons-shop-component-error">
                        <FaTimesCircle className="all-coupons-shop-component-icon" /> This coupon code is already taken. Please choose another.
                      </p>
                    )}
                    {name && nameAvailable === true && !checkingName && (
                      <p className="all-coupons-shop-component-success">
                        <FaCheckCircle className="all-coupons-shop-component-icon" /> This coupon code is available.
                      </p>
                    )}
                  </div>
                  <div className="all-coupons-shop-component-input-group">
                    <label>Coupon Scope</label>
                    <select
                      value={shopWide === null ? "" : shopWide ? "shop-wide" : "product-specific"}
                      onChange={(e) => setShopWide(e.target.value === "shop-wide")}
                      required
                    >
                      <option value="" disabled>
                        Select coupon scope
                      </option>
                      <option value="product-specific">
                        Product Specific
                      </option>
                      <option value="shop-wide">
                        Shop Wide
                      </option>
                    </select>
                  </div>
                  <div className="all-coupons-shop-component-input-group">
                    <label>Usage Type</label>
                    <select
                      value={usageType}
                      onChange={(e) => setUsageType(e.target.value)}
                      required
                    >
                      <option value="" disabled>
                        Select usage type
                      </option>
                      <option value="time">Time Based</option>
                      <option value="number">Number of Uses</option>
                    </select>
                  </div>
                  {usageType === "time" && (
                    <>
                      <div className="all-coupons-shop-component-input-group">
                        <label>Start Time</label>
                        <input
                          type="datetime-local"
                          name="startTime"
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                          required
                        />
                      </div>
                      <div className="all-coupons-shop-component-input-group">
                        <label>Expiry Date</label>
                        <input
                          type="datetime-local"
                          name="expiryDate"
                          value={expiryDate}
                          onChange={(e) => setExpiryDate(e.target.value)}
                          required
                        />
                      </div>
                    </>
                  )}
                  {usageType === "number" && (
                    <div className="all-coupons-shop-component-input-group">
                      <label>Max Uses</label>
                      <input
                        type="number"
                        name="maxUses"
                        value={maxUses}
                        onChange={(e) => setMaxUses(e.target.value)}
                        required
                      />
                    </div>
                  )}
                  <div className="all-coupons-shop-component-input-group">
                    <label>
                      Discount Percentage <span className="all-coupons-shop-component-required">*</span>
                    </label>
                    <input
                      type="text"
                      name="value"
                      value={value}
                      required
                      onChange={(e) => setValue(e.target.value)}
                      placeholder="Enter your coupon code value..."
                    />
                  </div>
                  <div className="all-coupons-shop-component-input-group">
                    <label>Min Amount</label>
                    <input
                      type="number"
                      name="minAmount"
                      value={minAmount}
                      onChange={(e) => setMinAmount(e.target.value)}
                      placeholder="Enter your coupon code min amount..."
                    />
                  </div>
                  <div className="all-coupons-shop-component-input-group">
                    <label>Max Amount</label>
                    <input
                      type="number"
                      name="maxAmount"
                      value={maxAmount}
                      onChange={(e) => setMaxAmount(e.target.value)}
                      placeholder="Enter your coupon code max amount..."
                    />
                  </div>
                  {shopWide === false && (
                    <div className="all-coupons-shop-component-input-group">
                    <label>Selected Products</label>
                    <select
                      multiple
                      name="products"
                      className="all-coupons-shop-component-multi-select"
                      onChange={handleProductChange}
                      value={selectedProducts.map(product => product.value)}
                    >
                      {productOptions.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                          data-label={option.label}
                          data-image={option.image}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="all-coupons-shop-component-selected-products">
                      {selectedProducts.map(product => (
                        <div key={product.value} className="all-coupons-shop-component-product-item">
                          <img src={product.image} alt={product.label} className="all-coupons-shop-component-product-image" />
                          <span>{product.label}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  )}
                  <div className="all-coupons-shop-component-input-group">
                    <input
                      type="submit"
                      value="Create"
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AllCoupons;

