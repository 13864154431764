import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { adsServer } from '../../server';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import BoostAnimation from './BoostAnimation';
import { FiPlayCircle, FiPauseCircle, FiZap, FiSlash, FiTrash } from 'react-icons/fi';
import { FiLayers, FiPackage, FiShoppingCart, FiTag } from 'react-icons/fi';
import { FiCalendar, FiClock } from 'react-icons/fi';
import useWindowSize from "../../hooks/useWindowSize";
import Loader from '../Layout/Loader';
import { toast } from "react-toastify";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, zoomPlugin);

const DualSponsoredDashboard = ({ sponsoredAds, onUpdateAds, isLoading }) => {
    const [selectedFilter, setSelectedFilter] = useState('both'); // 'both', 'product', 'shop', 'brand', 'custom'
    const [selectedAd, setSelectedAd] = useState(null);
    const [timeResolution, setTimeResolution] = useState('daily'); // 'daily', 'hourly'
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [cancelAdId, setCancelAdId] = useState(null);
    const [isBoostModalVisible, setIsBoostModalVisible] = useState(false);
    const [boostAdId, setBoostAdId] = useState(null);
    const [boostAction, setBoostAction] = useState(null);
    const [showBoostAnimation, setShowBoostAnimation] = useState(false);
    const windowSize = useWindowSize();
    const isMobile = windowSize.width <= 768;
    const chartRef = useRef(null);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Clicks',
                data: [],
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
            {
                label: 'Ad Loads',
                data: [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Viewed Impressions',
                data: [],
                borderColor: 'rgb(255, 159, 64)',
                backgroundColor: 'rgba(255, 159, 64, 0.5)',
            },
            // Optional: Add a separate dataset for Custom Ads
            // Uncomment the below dataset if you want to differentiate Custom Ads
            // {
            //     label: 'Custom Ads Clicks',
            //     data: [],
            //     borderColor: 'rgb(255, 99, 132)',
            //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
            // },
        ],
    });

    useEffect(() => {
        processChartData(sponsoredAds, selectedFilter);
    }, [sponsoredAds, selectedFilter, timeResolution, selectedAd]);

    const processChartData = (ads, filter) => {
        const adsToProcess = selectedAd ? [selectedAd] : ads.filter(ad => {
            if (filter === 'both') return true;
            if (filter === 'product') return ad.product;
            if (filter === 'shop') return ad.shop && !ad.product && !ad.brand && !ad.customAdText;
            if (filter === 'brand') return ad.brand;
            if (filter === 'custom') return !!ad.customAdText;
            return false;
        });

        const aggregationMap = {};

        adsToProcess.forEach(ad => {
            // Process clicks from metrics
            if (ad.metrics && Array.isArray(ad.metrics.clicks)) {
                ad.metrics.clicks.forEach(click => {
                    const date = new Date(click.timestamp);
                    const key = timeResolution === 'daily'
                        ? date.toISOString().split('T')[0]  // For daily, use date only
                        : `${date.toISOString().split('T')[0]} ${date.getHours()}:00`;  // For hourly, use date and hour

                    if (!aggregationMap[key]) {
                        aggregationMap[key] = { clicks: 0, adLoads: 0, viewedImpressions: 0 };
                    }
                    aggregationMap[key].clicks += 1;
                });
            }

            // Process viewed impressions from metrics
            if (ad.metrics && Array.isArray(ad.metrics.viewedImpressions)) {
                ad.metrics.viewedImpressions.forEach(view => {
                    const date = new Date(view.timestamp);
                    const key = timeResolution === 'daily'
                        ? date.toISOString().split('T')[0]
                        : `${date.toISOString().split('T')[0]} ${date.getHours()}:00`;

                    if (!aggregationMap[key]) {
                        aggregationMap[key] = { clicks: 0, adLoads: 0, viewedImpressions: 0 };
                    }
                    aggregationMap[key].viewedImpressions += 1;
                });
            }

            if (ad.metrics && Array.isArray(ad.metrics.adLoads)) {
                ad.metrics.adLoads.forEach(load => {
                    const date = new Date(load.timestamp);
                    const key = timeResolution === 'daily'
                        ? date.toISOString().split('T')[0]
                        : `${date.toISOString().split('T')[0]} ${date.getHours()}:00`;

                    if (!aggregationMap[key]) {
                        aggregationMap[key] = { clicks: 0, adLoads: 0, viewedImpressions: 0 };
                    }
                    aggregationMap[key].adLoads += 1;
                });
            }
        });

        // Sort the labels by actual date and hour
        const labels = Object.keys(aggregationMap).sort((a, b) => new Date(a) - new Date(b));
        const clicksData = labels.map(label => aggregationMap[label].clicks);
        const adLoadsData = labels.map(label => aggregationMap[label].adLoads);
        const viewedImpressionsData = labels.map(label => aggregationMap[label].viewedImpressions);

        // Update the chart data state
        setChartData(prevChartData => ({
            ...prevChartData,
            labels,
            datasets: [
                {
                    label: 'Clicks',
                    data: clicksData,
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                },
                {
                    label: 'Ad Loads',
                    data: adLoadsData,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
                {
                    label: 'Viewed Impressions',
                    data: viewedImpressionsData,
                    borderColor: 'rgb(255, 159, 64)',
                    backgroundColor: 'rgba(255, 159, 64, 0.5)',
                },
                // Optional: Add Custom Ads data if needed
                // {
                //     label: 'Custom Ads Clicks',
                //     data: customClicksData,
                //     borderColor: 'rgb(255, 99, 132)',
                //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
                // },
            ],
        }));
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            title: {
                display: true,
                text: selectedAd
                    ? `${selectedAd.product ? 'Product' : selectedAd.brand ? 'Brand' : selectedAd.shop ? 'Shop' : selectedAd.customAdText ? 'Custom Ad' : 'Unknown'}: ${selectedAd.product ? selectedAd.product.name : selectedAd.brand ? selectedAd.brand.name : selectedAd.shop ? selectedAd.shop.name : selectedAd.customAdText ? selectedAd.customAdText : 'N/A'}`
                    : 'Sponsored Ads Performance',
                font: {
                    size: 20,
                },
                color: '#fff',
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
                },
            },
            legend: {
                labels: {
                    color: '#fff',
                },
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x', // You can set it to 'x', 'y', or 'xy' for both axes
                    threshold: 10, // Minimum distance before applying pan
                },
                zoom: {
                    wheel: {
                        enabled: false, // Disable zoom via the mouse wheel
                    },
                    pinch: {
                        enabled: false, // Disable zoom via pinch gestures
                    },
                    drag: {
                        enabled: false, // Disable drag-to-zoom
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: timeResolution === 'daily' ? 'Date' : 'Date & Hour',
                    color: '#fff',
                },
                ticks: {
                    color: '#fff',
                    callback: function (value, index, values) {
                        // Format the date based on screen size
                        const label = this.getLabelForValue(value);
                        return isMobile ? label.split('-').slice(1).join('-') : label;
                    },
                    maxRotation: isMobile ? 0 : 45, // Reduce rotation for mobile
                    minRotation: 0,
                    font: {
                        size: isMobile ? 10 : 12, // Adjust font size for mobile
                    },
                    stepSize: isMobile ? 2 : 1, // Adjust label density for mobile
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Count',
                    color: '#fff',
                },
                ticks: {
                    color: '#fff',
                },
                beginAtZero: true,
            },
        },
        elements: {
            line: {
                tension: 0.4,
            },
            point: {
                hoverRadius: 7,
                radius: 5,
            },
        },
    };

    const handleFilterChange = (newFilter) => {
        setSelectedFilter(newFilter);
        setSelectedAd(null); // Reset selected ad when filter changes
    };

    const handleAdSelection = (ad) => {
        setSelectedAd(ad);
    };

    const showCancelModal = (adId) => {
        setCancelAdId(adId);
        setIsModalVisible(true);
    };

    const toggleAdActiveStatus = async (adId, isActive) => {
        try {
            await axios.post(`${adsServer}/sponsored/toggle-status`, { adId, isActive }, {
                headers: { 'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile' },
                withCredentials: true
            });
            // Update ad status through callback
            onUpdateAds(prevAds =>
                prevAds.map(ad => ad._id === adId ? { ...ad, isActive: !ad.isActive } : ad)
            );
        } catch (error) {
            console.error('Error toggling ad status:', error);
            toast.error('Failed to toggle ad status.');
        }
    };

    const cancelAd = async (adId) => {
        try {
            await axios.post(`${adsServer}/sponsored/sponsored-products/cancel`, { adId }, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            // Update ads by removing cancelled ad through callback
            onUpdateAds(prevAds => prevAds.filter(ad => ad._id !== adId));
            setIsModalVisible(false);
            toast.success('Ad cancelled successfully.');
        } catch (error) {
            console.error('Error cancelling ad:', error);
            toast.error('Failed to cancel ad.');
        }
    };

    const activateAdBoost = async (adId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${adsServer}/sponsored/boost-ad/${adId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
                withCredentials: true
            });
            // Update ad boost status through callback
            onUpdateAds(prevAds =>
                prevAds.map(ad => ad._id === adId ? { ...ad, boostActive: true, boostStartTime: new Date() } : ad)
            );
            toast.success('Boost activated successfully.');
            setShowBoostAnimation(true);
            setTimeout(() => {
                setShowBoostAnimation(false);
            }, 4200);
        } catch (error) {
            console.error('Error activating boost:', error);
            toast.error('Failed to activate boost.');
        }
    };

    const deactivateAdBoost = async (adId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${adsServer}/sponsored/deboost-ad/${adId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
                withCredentials: true
            });
            // Update ad boost status through callback
            onUpdateAds(prevAds =>
                prevAds.map(ad => ad._id === adId ? { ...ad, boostActive: false, boostStartTime: null } : ad)
            );
            toast.success('Boost deactivated successfully.');
        } catch (error) {
            console.error('Error deactivating boost:', error);
            toast.error('Failed to deactivate boost.');
        }
    };

    const showBoostConfirmation = (adId, action) => {
        setBoostAdId(adId);
        setBoostAction(action);
        setIsBoostModalVisible(true);
    };

    const handleBoostConfirmation = async () => {
        if (boostAction === 'activate') {
            await activateAdBoost(boostAdId);
        } else {
            await deactivateAdBoost(boostAdId);
        }
        setIsBoostModalVisible(false);
    };

    return (
        <div className="sponsored-dashboard">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {showBoostAnimation && <BoostAnimation />}
                    <div className="sponsored-dashboard-container">
                        <div className="sponsored-dashboard-filter-time-row">
                            <div className="sponsored-dashboard-buttons-row">
                                <div className="sponsored-dashboard-filter-row">
                                    <div
                                        className={`sponsored-dashboard-filter-item ${selectedFilter === 'both' ? 'active' : ''}`}
                                        onClick={() => handleFilterChange('both')}
                                        role="button"
                                        tabIndex={0}
                                        onKeyPress={(e) => { if (e.key === 'Enter') handleFilterChange('both'); }}
                                        aria-pressed={selectedFilter === 'both'}
                                        aria-label="Filter All Ads"
                                    >
                                        <FiLayers className="sponsored-dashboard-filter-icon" />
                                        <span>All</span>
                                    </div>
                                    <div
                                        className={`sponsored-dashboard-filter-item ${selectedFilter === 'product' ? 'active' : ''}`}
                                        onClick={() => handleFilterChange('product')}
                                        role="button"
                                        tabIndex={0}
                                        onKeyPress={(e) => { if (e.key === 'Enter') handleFilterChange('product'); }}
                                        aria-pressed={selectedFilter === 'product'}
                                        aria-label="Filter Product Ads"
                                    >
                                        <FiPackage className="sponsored-dashboard-filter-icon" />
                                        <span>Products</span>
                                    </div>
                                    <div
                                        className={`sponsored-dashboard-filter-item ${selectedFilter === 'shop' ? 'active' : ''}`}
                                        onClick={() => handleFilterChange('shop')}
                                        role="button"
                                        tabIndex={0}
                                        onKeyPress={(e) => { if (e.key === 'Enter') handleFilterChange('shop'); }}
                                        aria-pressed={selectedFilter === 'shop'}
                                        aria-label="Filter Shop Ads"
                                    >
                                        <FiShoppingCart className="sponsored-dashboard-filter-icon" />
                                        <span>Shops</span>
                                    </div>
                                    <div
                                        className={`sponsored-dashboard-filter-item ${selectedFilter === 'brand' ? 'active' : ''}`}
                                        onClick={() => handleFilterChange('brand')}
                                        role="button"
                                        tabIndex={0}
                                        onKeyPress={(e) => { if (e.key === 'Enter') handleFilterChange('brand'); }}
                                        aria-pressed={selectedFilter === 'brand'}
                                        aria-label="Filter Brand Ads"
                                    >
                                        <FiTag className="sponsored-dashboard-filter-icon" />
                                        <span>Brands</span>
                                    </div>
                                    <div
                                        className={`sponsored-dashboard-filter-item ${selectedFilter === 'custom' ? 'active' : ''}`}
                                        onClick={() => handleFilterChange('custom')}
                                        role="button"
                                        tabIndex={0}
                                        onKeyPress={(e) => { if (e.key === 'Enter') handleFilterChange('custom'); }}
                                        aria-pressed={selectedFilter === 'custom'}
                                        aria-label="Filter Custom Ads"
                                    >
                                        <FiZap className="sponsored-dashboard-filter-icon" />
                                        <span>Custom</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sponsored-dashboard-time-buttons-row">
                                <div
                                    className={`sponsored-dashboard-time-filter-item ${timeResolution === 'daily' ? 'active' : ''}`}
                                    onClick={() => setTimeResolution('daily')}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={(e) => { if (e.key === 'Enter') setTimeResolution('daily'); }}
                                    aria-pressed={timeResolution === 'daily'}
                                    aria-label="Set Daily View"
                                >
                                    <FiCalendar className="sponsored-dashboard-time-filter-icon" />
                                    <span>Daily View</span>
                                </div>
                                <div
                                    className={`sponsored-dashboard-time-filter-item ${timeResolution === 'hourly' ? 'active' : ''}`}
                                    onClick={() => setTimeResolution('hourly')}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={(e) => { if (e.key === 'Enter') setTimeResolution('hourly'); }}
                                    aria-pressed={timeResolution === 'hourly'}
                                    aria-label="Set Hourly View"
                                >
                                    <FiClock className="sponsored-dashboard-time-filter-icon" />
                                    <span>Hourly View</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '500px', width: '100%' }}>
                        <Line ref={chartRef} data={chartData} options={options} />
                    </div>
                    {/* Updated Table Section */}
                    <table className="sponsored-table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Total Ad Spend</th>
                                <th>Ad Duration (days)</th>
                                <th>Spent So Far</th>
                                <th>AD Status</th>
                                <th>Click Count</th>
                                <th>Impressions</th>
                                <th>Cost Per Click (CPC)</th>
                                <th>Cost Per Impression (CPI)</th>
                                <th>Toggle Status</th>
                                <th>Boost</th>
                                <th>Cancel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sponsoredAds.filter(ad => {
                                // Debugging logs (can be removed in production)
                                console.log('Filtering ad:', ad);
                                if (selectedFilter === 'both') return true;
                                if (selectedFilter === 'product') {
                                    console.log('Product filter applied:', !!ad.product);
                                    return ad.product;
                                }
                                if (selectedFilter === 'shop') {
                                    const isShopAd = ad.shop && !ad.product && !ad.brand && !ad.customAdText;
                                    console.log('Shop filter applied:', isShopAd);
                                    return isShopAd;
                                }
                                if (selectedFilter === 'brand') {
                                    console.log('Brand filter applied:', !!ad.brand);
                                    return ad.brand;
                                }
                                if (selectedFilter === 'custom') {
                                    console.log('Custom filter applied:', !!ad.customAdText);
                                    return !!ad.customAdText;
                                }
                                return false;
                            }).map(ad => {
                                const clickCount = ad.metrics?.clicks?.length || 0;
                                const viewedImpressionCount = ad.metrics?.viewedImpressions?.length || 0;

                                const costPerClick = clickCount > 0 ? (ad.spentSoFar / clickCount).toFixed(2) : '0.00';
                                const costPerImpression = viewedImpressionCount > 0 ? (ad.spentSoFar / viewedImpressionCount).toFixed(2) : '0.00';

                                return (
                                    <tr key={ad._id} onClick={() => handleAdSelection(ad)} style={{ cursor: 'pointer' }}>
                                        <td>
                                            {ad.product ? 'Product' : ad.brand ? 'Brand' : ad.shop ? 'Shop' : ad.customAdText ? 'Custom' : 'Unknown'}
                                        </td>
                                        <td>
                                            {ad.product 
                                                ? ad.product.name 
                                                : ad.brand 
                                                    ? ad.brand.name 
                                                    : ad.shop 
                                                        ? ad.shop.name 
                                                        : ad.customAdText 
                                                            ? ad.customAdText 
                                                            : 'N/A'}
                                        </td>
                                        <td>
                                            {ad.product && ad.product.images && ad.product.images[0] ? (
                                                <img src={ad.product.images[0].url} alt="product" style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
                                            ) : ad.shop && ad.shop.avatar ? (
                                                <img src={ad.shop.avatar.url} alt="shop" style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
                                            ) : ad.brand && ad.brand.avatarImage ? (
                                                <img src={ad.brand.avatarImage.url} alt="brand" style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
                                            ) : ad.customAdImage ? (
                                                <img src={ad.customAdImage.url} alt="custom ad" style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
                                            ) : (
                                                <span>N/A</span>
                                            )}
                                        </td>
                                        <td>${ad.adSpend.toFixed(2)}</td>
                                        <td>{ad.adDuration}</td>
                                        <td>${ad.spentSoFar.toFixed(2)}</td>
                                        <td>{ad.status}</td>
                                        <td>{clickCount}</td>
                                        <td>{viewedImpressionCount}</td>
                                        <td>${costPerClick}</td>
                                        <td>${costPerImpression}</td>
                                        <td>
                                            <button 
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering row click
                                                    toggleAdActiveStatus(ad._id, ad.status !== 'active');
                                                }}
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    color: ad.status === 'active' ? '#f44336' : ad.status === 'paused' ? '#4CAF50' : '#9E9E9E',
                                                    border: 'none', 
                                                    padding: '10px',
                                                    cursor: ad.status === 'active' || ad.status === 'paused' ? 'pointer' : 'not-allowed',
                                                    fontSize: '24px',
                                                }}
                                                disabled={ad.status !== 'active' && ad.status !== 'paused'} 
                                                aria-label={`Toggle status for ${ad.product ? ad.product.name : ad.brand ? ad.brand.name : ad.shop ? ad.shop.name : ad.customAdText ? ad.customAdText : 'Unknown'} ad`}
                                            >
                                                {ad.status === 'paused' ? <FiPlayCircle /> : ad.status === 'active' ? <FiPauseCircle /> : null}
                                            </button>
                                        </td>
                                        <td>
                                            <button 
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering row click
                                                    showBoostConfirmation(ad._id, ad.boostActive ? 'deactivate' : 'activate');
                                                }}
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    color: ad.boostActive ? '#f44336' : '#4CAF50',
                                                    border: 'none',
                                                    padding: '10px',
                                                    cursor: 'pointer',
                                                    fontSize: '24px',
                                                }}
                                                aria-label={`${ad.boostActive ? 'Deactivate' : 'Activate'} boost for ${ad.product ? ad.product.name : ad.brand ? ad.brand.name : ad.shop ? ad.shop.name : ad.customAdText ? ad.customAdText : 'Unknown'} ad`}
                                            >
                                                {ad.boostActive ? <FiSlash /> : <FiZap />}
                                            </button>
                                        </td>
                                        <td>
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering row click
                                                    showCancelModal(ad._id);
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '10px',
                                                    minWidth: 'auto', 
                                                }}
                                                aria-label={`Cancel ${ad.product ? ad.product.name : ad.brand ? ad.brand.name : ad.shop ? ad.shop.name : ad.customAdText ? ad.customAdText : 'Unknown'} ad`}
                                            >
                                                <FiTrash size={20} />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {/* Cancel Ad Confirmation Dialog */}
                    <Dialog 
                        open={isModalVisible} 
                        onClose={() => setIsModalVisible(false)} 
                        PaperProps={{
                            style: {
                                backgroundColor: '#333',  
                                color: 'white'           
                            }
                        }}
                        aria-labelledby="cancel-ad-dialog-title"
                        aria-describedby="cancel-ad-dialog-description"
                    >
                        <DialogTitle id="cancel-ad-dialog-title">{"Cancel Ad"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="cancel-ad-dialog-description">
                                Are you sure you want to cancel this ad campaign?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsModalVisible(false)} color="primary">
                                No
                            </Button>
                            <Button variant="contained" color="warning" onClick={() => {
                                if (cancelAdId) cancelAd(cancelAdId); 
                            }}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Boost Confirmation Dialog */}
                    <Dialog
                        open={isBoostModalVisible}
                        onClose={() => setIsBoostModalVisible(false)}
                        aria-labelledby="boost-dialog-title"
                        aria-describedby="boost-dialog-description"
                        PaperProps={{
                            style: {
                                backgroundColor: '#333',  
                                color: 'white'            
                            }
                        }}
                    >
                        <DialogTitle id="boost-dialog-title">{"Confirm Boost Action"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="boost-dialog-description">
                                Are you sure you want to {boostAction === 'activate' ? 'activate' : 'deactivate'} the boost for this ad?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsBoostModalVisible(false)} color="primary">
                                No
                            </Button>
                            <Button onClick={handleBoostConfirmation} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </div>
    );
};

export default DualSponsoredDashboard;

    

