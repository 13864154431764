import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { ecommerceServer } from "../../server";
import Lottie from "lottie-react";
import animationData from "../../Assests/animations/107043-success.json";
import { clearCart } from '../../redux/actions/cart';
import { useDispatch } from "react-redux";

const OrderSuccess = () => {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);
  const [showAnimation, setShowAnimation] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');

    const fetchOrderDetails = async () => {
      if (sessionId) {
        try {
          const { data } = await axios.get(`${ecommerceServer}/order/order-success?session_id=${sessionId}`);
          setOrderDetails(data);
          console.log("Order Details:", data);
          const profileType = sessionStorage.getItem('currentProfile') || 'User';
          dispatch(clearCart(profileType));
        } catch (error) {
          console.error('Failed to fetch order details:', error);
        }
      }
    };

    fetchOrderDetails();
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimation(false);
    }, 4000); 

    return () => clearTimeout(timer); 
  }, []);

  if (!orderDetails) {
    return (
      <div className="order-confirmation-loading">
        Loading order details...
      </div>
    );
  }

  return (
    <main className="order-confirmation-container">
      <div className="order-confirmation-side-image">
        <img
          src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1706549678/Asset-1_yjnmot.png"
          alt="Thank you for your order"
          className="order-confirmation-hero-image"
        />
      </div>
      <div className="order-confirmation-details">
        {showAnimation && (
          <div className="order-confirmation-animation">
            <Lottie animationData={animationData} loop={false} width={300} height={300} />
            <h5 className="text-center mb-14 text-[25px] text-[#000000a1]">
              Your order is successful 😍
            </h5>
          </div>
        )}
        <h1 className="order-confirmation-title">Thank You for Your Order!</h1>
        <h2 className="order-confirmation-id">Order ID: {orderDetails.orderId}</h2>
        <h3 className="order-confirmation-customer">Customer: {orderDetails.customer}</h3>

        <div className="order-confirmation-products">
          {orderDetails.products.map((product, index) => (
            <div key={index} className="order-confirmation-product-item">
              <img src={product.images} alt={product.productName} className="order-confirmation-product-image" />
              <div className="order-confirmation-product-info">
                <h3>{product.productName}</h3>
                <p>Description: {product.description}</p>
                <p>Quantity: {product.quantity}</p>
                <p>Price per item: ${product.unitAmount / 100}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="order-confirmation-shipping">
          <h3>Shipping Address:</h3>
          <address>
            {orderDetails.shippingAddress.address1 && `${orderDetails.shippingAddress.address1}, `}
            {orderDetails.shippingAddress.address2 && `${orderDetails.shippingAddress.address2}, `}
            {orderDetails.shippingAddress.city && `${orderDetails.shippingAddress.city}, `}
            {orderDetails.shippingAddress.state && `${orderDetails.shippingAddress.state}, `}
            {orderDetails.shippingAddress.zipCode && `${orderDetails.shippingAddress.zipCode}, `}
          </address>
        </div>

        <div className="order-confirmation-payment-info">
          <h3>Payment Details:</h3>
          <p>Card Brand: {orderDetails.paymentDetails.brand}</p>
          <p>Last 4 Digits: **** **** **** {orderDetails.paymentDetails.last4}</p>
        </div>

        <div className="order-confirmation-continue-shopping">
          <Link to="/products">
            <button className="continue-shopping-button">Continue Shopping</button>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default OrderSuccess;
