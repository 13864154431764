import React from 'react';
import MyEventCard from './MyEventCard';
import { Link } from 'react-router-dom';

const MyEventsProps = ({ events, onCancelRSVP }) => {
    if (!events) return <p>Loading...</p>;

    return (
        <div className="my-events-container">
            <h2 className="my-events-title">Events</h2>
            {events.length > 0 ? (
                <div>
                    {events.map(event => (
                        <MyEventCard key={event._id} event={event} onCancelRSVP={onCancelRSVP} />
                    ))}
                </div>
            ) : (
                <div>
                    <Link to="/events">
                        <button className="find-events-button">Find Events</button>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default MyEventsProps;
