import React from 'react';

const ShopBySellerCircle = ({ sellers }) => {
  return (
    <div>
      <div className="circle-shop-list">
        {sellers.map((seller) => (
          <a
            href={`https://www.buzzvibe.com/shop/${seller.handle}`}
            key={seller._id}
            className="circle-shop-card"
          >
            <div className="circle-avatar-container">
              <img src={seller.avatar.url} alt={seller.name} className="circle-avatar" />
            </div>
            <h3 className="circle-shop-name">{seller.name}</h3>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ShopBySellerCircle;
