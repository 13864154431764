import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';
import CampaignManagementGuide from '../../components/Documentation/CampaignManagementGuide';

const CampaignManagementDocs = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <CampaignManagementGuide/>
    </div>
  );
};

export default CampaignManagementDocs;