import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from "../../server";
import styles from "../../styles/styles";

const ShopSubscriptionCheckoutDirect = () => {
    const navigate = useNavigate();
    const { seller } = useSelector(state => state.seller);
    const { priceId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(seller?.email || '');
    const [shopName, setShopName] = useState(seller?.name || '');
    const [couponCode, setCouponCode] = useState('');
    const [priceDetails, setPriceDetails] = useState(null);
    const [discountedPrice, setDiscountedPrice] = useState(null); 

    useEffect(() => {
        const fetchPriceDetails = async () => {
            try {
                const response = await axios.get(`${server}/subscriptions/price-details/${priceId}`);
                setPriceDetails(response.data);
            } catch (error) {
                console.error('Failed to fetch price details', error);
                toast.error('Failed to load pricing information.');
            }
        };

        fetchPriceDetails();
    }, [priceId]);

    // Function to apply the coupon code
    const handleApplyCoupon = async () => {
        if (!couponCode) {
            toast.error('Please enter a coupon code.');
            return;
        }

        try {
            const response = await axios.post(`${server}/subscriptioncouponcode/apply-subscription-coupon`, {
                code: couponCode,
                priceId
            });

            if (response.data.success) {
                toast.success('Coupon applied successfully!');
                setDiscountedPrice(response.data.finalPrice);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to apply coupon.');
        }
    };

    const handlePaymentSubmission = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const { data } = await axios.post(`${server}/subscriptions/shops/create-subscription`, {
                priceId,
                couponCode, 
            }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });

            toast.success('Subscription created successfully!');
            navigate('/'); 
        } catch (error) {
            console.error('Subscription creation failed:', error);
            toast.error('Failed to create subscription. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 max-w-4xl mx-auto">
            <div className="order-2 lg:order-1 bg-black">
                <form onSubmit={handlePaymentSubmission} className={styles.form}>
                    <label className="block pb-2">Shop Name</label>
                    <input type="text" className={styles.input} placeholder="Shop Name" value={shopName} onChange={e => setShopName(e.target.value)} required />
                    
                    <label className="block pb-2">Email Address</label>
                    <input type="email" className={styles.input} placeholder="Email Address" value={email} onChange={e => setEmail(e.target.value)} required />

                    <label className="block pb-2">Coupon Code (Optional)</label>
                    <input type="text" className={styles.input} placeholder="Coupon Code" value={couponCode} onChange={e => setCouponCode(e.target.value)} />
                    
                    <button 
                        type="button" 
                        className={styles.button} 
                        onClick={handleApplyCoupon}
                        disabled={isLoading}
                    >
                        Apply Coupon
                    </button>

                    <button type="submit" className={styles.button} disabled={isLoading}>
                        {isLoading ? 'Processing...' : 'Confirm Subscription'}
                    </button>
                </form>
            </div>
            <div className="order-1 lg:order-2">
                {priceDetails && (
                    <div className="subscription-details">
                        <h2>{`Upgrade your shop with the ${priceDetails.product.name} subscription`}</h2>
                        <p>{priceDetails.product.description}</p>
                        <p>Price: ${(discountedPrice !== null ? discountedPrice / 100 : priceDetails.unit_amount / 100).toFixed(2)} {priceDetails.currency.toUpperCase()}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShopSubscriptionCheckoutDirect;
