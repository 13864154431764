import React, { useState, useRef } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { useSelector } from "react-redux";
import CustomCalendar from './CustomCalendar';
import ImageCropperModal from '../Image/ImageCropperModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const EventCreationForm = () => {
    const currentProfile = useSelector((state) => state.user.currentProfile);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image: null, // This will store the cropped image
        date: new Date(),
        endDate: new Date(),
        location: '',
        startTime: '',
        endTime: ''
    });

    // State variables for Image Cropper Modal
    const [selectedFile, setSelectedFile] = useState(null);
    const [showImageCropper, setShowImageCropper] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null); 

    // State variables for loading and error handling
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image") {
            const file = files[0];
            if (file) {
                setSelectedFile(file);
                setShowImageCropper(true);
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({ ...prev, date }));
    };

    const handleEndDateChange = (endDate) => {
        setFormData(prev => ({ ...prev, endDate }));
    };

    const updateCroppedImage = (croppedFile) => {
        setFormData(prev => ({ ...prev, image: croppedFile }));
        setPreviewUrl(URL.createObjectURL(croppedFile)); 
        setShowImageCropper(false); 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        // Basic front-end validation
        if (!formData.title || !formData.description || !formData.image || !formData.location || !formData.startTime) {
            setError('Please fill in all required fields.');
            setLoading(false);
            return;
        }

        // Ensure start date is before end date
        if (new Date(formData.endDate) < new Date(formData.date)) {
            setError('End date cannot be before start date.');
            setLoading(false);
            return;
        }

        const data = new FormData();
        data.append('title', formData.title);
        data.append('description', formData.description);
        data.append('image', formData.image);
        data.append('date', formData.date.toISOString());
        data.append('endDate', formData.endDate.toISOString());
        data.append('location', formData.location);
        data.append('startTime', formData.startTime);
        data.append('endTime', formData.endTime);
        data.append('currentProfile', currentProfile); 

        try {
            const response = await axios.post(`${server}/event/create`, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            toast.success('Event created successfully!');
            // Reset form
            setFormData({
                title: '',
                description: '',
                image: null,
                date: new Date(),
                endDate: new Date(),
                location: '',
                startTime: '',
                endTime: ''
            });
            setPreviewUrl(null);
        } catch (error) {
            console.error('Error creating event:', error);
            setError(error.response?.data?.message || 'Failed to create event. Please try again.');
            toast.error(`Failed to create event: ${error.response?.data?.message || 'Unknown error'}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="event-creation-form-container">
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
            <form onSubmit={handleSubmit} className="event-creation-form">
                <div className="form-group">
                    <label htmlFor="title" className="event-creation-form-label">Event Title</label>
                    <input 
                        type="text" 
                        name="title" 
                        id="title" 
                        value={formData.title} 
                        onChange={handleChange} 
                        placeholder="Event Title" 
                        required
                        className="event-creation-form-input" 
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="description" className="event-creation-form-label">Event Description</label>
                    <textarea 
                        name="description" 
                        id="description" 
                        value={formData.description} 
                        onChange={handleChange} 
                        placeholder="Event Description" 
                        required
                        className="event-creation-form-textarea" 
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="image" className="event-creation-form-label">Event Image</label>
                    <label htmlFor="image-upload" className="event-upload-label">
                        <FontAwesomeIcon icon={faUpload} /> Upload Image
                    </label>
                    <input 
                        id="image-upload"
                        type="file" 
                        name="image" 
                        accept="image/*" 
                        onChange={handleChange} 
                        style={{ display: 'none' }}
                        required
                    />
                </div>

                {/* Optional: Display Image Preview */}
                {previewUrl && (
                    <div className="image-preview">
                        <img src={previewUrl} alt="Cropped Preview" className="preview-image" />
                    </div>
                )}

                <div className="form-group">
                    <label htmlFor="location" className="event-creation-form-label">Event Location</label>
                    <input 
                        type="text" 
                        name="location" 
                        id="location" 
                        value={formData.location} 
                        onChange={handleChange} 
                        placeholder="Event Location" 
                        required
                        className="event-creation-form-input" 
                    />
                </div>

                <div className="event-creation-form-date-time-row">
                    <div className="date-time-group">
                        <label htmlFor="date" className="event-creation-form-label">Event Start Date</label>
                        <CustomCalendar selectedDate={formData.date} onDateChange={handleDateChange} />
                        <label htmlFor="startTime" className="event-creation-form-label">Event Start Time</label>
                        <input 
                            type="time" 
                            name="startTime" 
                            id="startTime" 
                            value={formData.startTime} 
                            onChange={handleChange} 
                            required
                            className="event-creation-form-input" 
                        />
                    </div>
                    <div className="date-time-group">
                        <label htmlFor="endDate" className="event-creation-form-label">Event End Date</label>
                        <CustomCalendar selectedDate={formData.endDate} onDateChange={handleEndDateChange} />
                        <label htmlFor="endTime" className="event-creation-form-label">Event End Time</label>
                        <input 
                            type="time" 
                            name="endTime" 
                            id="endTime" 
                            value={formData.endTime} 
                            onChange={handleChange}
                            className="event-creation-form-input" 
                        />
                    </div>
                </div>

                {error && <div className="event-error-message">{error}</div>}

                <button 
                    type="submit" 
                    className="event-creation-form-button" 
                    disabled={loading}
                >
                    {loading ? 'Creating Event...' : 'Create Event'}
                </button>
            </form>

            {/* Image Cropper Modal */}
            {showImageCropper && (
                <ImageCropperModal
                    updateAvatar={updateCroppedImage}
                    closeModal={() => setShowImageCropper(false)}
                    initialImage={selectedFile}
                    aspectRatio={1} 
                    minWidth={320} 
                    isAvatar={false} 
                    isPost={false} 
                />
            )}
        </div>
    );
};

export default EventCreationForm;




