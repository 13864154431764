import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';
import TaxCodeGuide from '../../components/Documentation/TaxCodeGuide';

const TaxCodeDocs = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <TaxCodeGuide />
    </div>
  );
};

export default TaxCodeDocs;
