import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import { backend_url } from '../../server';


const ENDPOINT = backend_url;
const socket = socketIOClient(ENDPOINT, { withCredentials: true });

const Chat = ({ streamId, user }) => { 
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!streamId) return;

    // Join the stream room
    socket.emit('joinStream', streamId);

    // Listen for incoming messages
    socket.on('chatMessage', (msg) => {
      setMessages((prev) => [...prev, msg]);
    });

    // Cleanup on unmount
    return () => {
      socket.off('chatMessage');
    };
  }, [streamId]);

  const sendMessage = () => {
    if (message.trim() === '') return;

    const msgData = {
      streamId,
      message,
      user,
    };

    // Emit the message to the server
    socket.emit('chatMessage', msgData);

    // Optionally, add the message to local state
    setMessages((prev) => [...prev, msgData]);
    setMessage('');
  };

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.user === user ? 'own-message' : ''}`}>
            <strong>{msg.user}:</strong> {msg.message}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input 
          type="text" 
          placeholder="Type your message..." 
          value={message} 
          onChange={(e) => setMessage(e.target.value)} 
          onKeyPress={(e) => e.key === 'Enter' ? sendMessage() : null}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default Chat;
