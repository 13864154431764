import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ecommerceServer } from "../../server";
import TaxCodeSelector from './TaxCodeSelector';
import { categoriesData } from '../../static/data';
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import classNames from 'classnames'; 

const IncompleteProductEditor = ({ product, onUpdate }) => {
  const [SKU, setSKU] = useState(product.SKU || '');
  const [taxCode, setTaxCode] = useState(product.taxCode || '');
  const [category, setCategory] = useState(product.category || '');
  const [subCategory, setSubCategory] = useState(product.subCategory || '');
  const [availableSubCategories, setAvailableSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (category) {
      const categoryObj = categoriesData.find(cat => cat.title === category);
      if (categoryObj && categoryObj.subCategories) {
        setAvailableSubCategories(categoryObj.subCategories);
      } else {
        setAvailableSubCategories([]);
      }
    } else {
      setAvailableSubCategories([]);
    }
  }, [category]);

  const handleSave = async () => {
    setLoading(true);
    setMessage('');

    try {
      const response = await axios.post(
        `${ecommerceServer}/product/update-product/${product._id}`,
        {
          SKU,
          taxCode: typeof taxCode === 'object' ? taxCode.id : taxCode,
          category,
          subCategory,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'current-profile': sessionStorage.getItem('currentProfile') || 'User',
          },
        }
      );

      if (response.data.success) {
        setMessage('Product updated successfully.');
        setIsUpdated(true);
        onUpdate(response.data.product);
        // Optionally collapse the editor upon success
        // setIsCollapsed(true);
      } else {
        setMessage('Failed to update product.');
        setIsUpdated(false);
      }
    } catch (error) {
      console.error('Error updating product:', error);
      setMessage('An error occurred while updating the product.');
      setIsUpdated(false);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    return SKU.trim() !== '' && taxCode && category.trim() !== '' && subCategory.trim() !== '';
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={classNames("incomplete-product-editor-container", {
        "updated-background": isUpdated,
      })}
    >
      <div className="incomplete-product-editor-header" onClick={toggleCollapse}>
        <h4 className="incomplete-product-editor-title">{product.name}</h4>
        <button className="collapse-button" aria-label="Toggle Editor">
          {isCollapsed ? <ChevronDownIcon className="icon" /> : <ChevronUpIcon className="icon" />}
        </button>
      </div>

      {!isCollapsed && (
        <div className="incomplete-product-editor-body">
          {/* Category Selection */}
          <div className="incomplete-product-editor-form-group">
            <label className="incomplete-product-editor-label" htmlFor={`category-${product._id}`}>
              Category: <span className="create-product-component-form-required">*</span>
              <a href="/docs/categories" target="_blank" rel="noopener noreferrer" className="help-icon-link" aria-label="Help about Categories">
                <QuestionMarkCircleIcon className="help-icon" />
                <span className="sr-only">Help</span>
              </a>
            </label>
            <select
              id={`category-${product._id}`}
              name="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="incomplete-product-editor-select"
            >
              <option value="">Choose a category</option>
              {categoriesData.map((cat, index) => (
                <option key={index} value={cat.title}>{cat.title}</option>
              ))}
            </select>
          </div>

          {/* Subcategory Selection */}
          {availableSubCategories.length > 0 && (
            <div className="incomplete-product-editor-form-group">
              <label className="incomplete-product-editor-label" htmlFor={`subCategory-${product._id}`}>
                Subcategory: <span className="create-product-component-form-required">*</span>
                <a href="/docs/categories" target="_blank" rel="noopener noreferrer" className="help-icon-link" aria-label="Help about Categories">
                  <QuestionMarkCircleIcon className="help-icon" />
                  <span className="sr-only">Help</span>
                </a>
              </label>
              <select
                id={`subCategory-${product._id}`}
                name="subCategory"
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
                className="incomplete-product-editor-select"
              >
                <option value="">Choose a subcategory</option>
                {availableSubCategories.map((subCat, index) => (
                  <option key={index} value={subCat.title}>{subCat.title}</option>
                ))}
              </select>
            </div>
          )}

          {/* SKU Field */}
          <div className="incomplete-product-editor-form-group">
            <label className="incomplete-product-editor-label" htmlFor={`sku-${product._id}`}>
              SKU: <span className="create-product-component-form-required">*</span>
            </label>
            <input
              id={`sku-${product._id}`}
              type="text"
              className="incomplete-product-editor-input"
              value={SKU}
              onChange={(e) => setSKU(e.target.value)}
              placeholder="Enter SKU..."
            />
          </div>

          {/* Tax Code Selector */}
          <div className="incomplete-product-editor-form-group">
            <TaxCodeSelector
              id={`taxCode-${product._id}`}
              selectedTaxCode={taxCode}
              updateTaxCode={setTaxCode}
              productCategory={category}
              productSubCategory={subCategory}
              productName={product.name}
              productDescription={product.description}
            />
          </div>

          {/* Save Button */}
          <button
            className="incomplete-product-editor-button"
            onClick={handleSave}
            disabled={loading || !isFormValid()}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>

          {/* Message Display */}
          {message && <p className={classNames("incomplete-product-editor-message", { "error-message": !isUpdated })}>{message}</p>}
        </div>
      )}
    </div>
  );
};

export default IncompleteProductEditor;

