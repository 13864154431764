import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductCard from "../Route/ProductCard/ProductCard";
import { ecommerceServer } from "../../server";
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/20/solid';
import { useSelector } from "react-redux";
import CreateProductPrompt from '../Products/CreateProductPrompt';

const IndividualShopProducts = ({ shopId, isOwner }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [priceFilter, setPriceFilter] = useState('');
  const [sortByNewest, setSortByNewest] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const currentProfile = useSelector((state) => state.user.currentProfile);

  // Utility function to normalize strings
  const normalizeString = (str) => str.trim().toLowerCase();

  useEffect(() => {
    axios.get(`${ecommerceServer}/product/get-all-products-shop/${shopId}`, { withCredentials: true })
      .then((response) => {
        const productsData = response.data.products;
        setProducts(productsData);
        setFilteredProducts(productsData);

        // Extract unique categories and subcategories with normalization
        const categoryMap = new Map();

        productsData.forEach((product) => {
          if (product.category) {
            const normalizedCategory = normalizeString(product.category);
            if (!categoryMap.has(normalizedCategory)) {
              categoryMap.set(normalizedCategory, {
                category: normalizedCategory,
                displayCategory: product.category,
                subCategories: new Map(),
              });
            }
            if (product.subCategory) {
              const normalizedSubCategory = normalizeString(product.subCategory);
              const categoryEntry = categoryMap.get(normalizedCategory);
              if (!categoryEntry.subCategories.has(normalizedSubCategory)) {
                categoryEntry.subCategories.set(normalizedSubCategory, product.subCategory);
              }
            }
          }
        });

        const uniqueCategories = Array.from(categoryMap.values()).map((categoryEntry) => ({
          category: categoryEntry.category,
          displayCategory: categoryEntry.displayCategory,
          subCategories: Array.from(categoryEntry.subCategories.entries()).map(([subCategory, displaySubCategory]) => ({
            subCategory,
            displaySubCategory,
          })),
        }));

        setCategories(uniqueCategories);

        // Pre-select the "Fashion" category if it exists
        const fashionCategory = uniqueCategories.find(cat => cat.displayCategory.toLowerCase() === 'fashion');
        if (fashionCategory) {
          setSelectedCategory(fashionCategory.category);
        }
      })
      .catch((error) => console.error('Error fetching products:', error));
  }, [shopId]);

  useEffect(() => {
    applyFilters();
  }, [priceFilter, sortByNewest, products, selectedCategory, selectedSubCategory]);

  const applyFilters = () => {
    let filtered = [...products];

    // Filter by category and subcategory with normalization
    if (selectedCategory) {
      filtered = filtered.filter(
        (product) => normalizeString(product.category) === selectedCategory
      );
      if (selectedSubCategory) {
        filtered = filtered.filter(
          (product) =>
            product.subCategory &&
            normalizeString(product.subCategory) === selectedSubCategory
        );
      }
    }

    // Price Filter
    if (priceFilter) {
      const [min, max] = priceFilter.split('-').map(Number);
      filtered = filtered.filter((product) => {
        const price = product.discountPrice || product.originalPrice;
        return price >= min && (max ? price <= max : true);
      });
    }

    // Sort by Newest
    if (sortByNewest) {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }

    setFilteredProducts(filtered);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? '' : category);
    setSelectedSubCategory(''); // Reset subcategory when category changes
  };

  const handleSubCategoryClick = (subCategory) => {
    setSelectedSubCategory(subCategory === selectedSubCategory ? '' : subCategory);
  };

  return (
    <div className="individual-shop-products-container">
      {isOwner && products.length === 0 ? (
        <CreateProductPrompt />
      ) : (
        <>
          {/* Categories and Subcategories */}
          <div className="individual-shop-category-container">
            {categories.map(({ category, displayCategory, subCategories }) => (
              <div key={category} className="individual-shop-category-item">
                <button
                  className={`individual-shop-category-button ${selectedCategory === category ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {displayCategory}
                </button>
                {selectedCategory === category && subCategories.length > 0 && (
                  <div className="individual-shop-subcategories-container">
                    {subCategories.map(({ subCategory, displaySubCategory }) => (
                      <button
                        key={subCategory}
                        className={`individual-shop-subcategory-button ${selectedSubCategory === subCategory ? 'active' : ''}`}
                        onClick={() => handleSubCategoryClick(subCategory)}
                      >
                        {displaySubCategory}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Filters */}
          <Disclosure as="section" aria-labelledby="filter-heading">
            {({ open }) => (
              <>
                <div className="individual-shop-products-filter-container">
                  <div className="individual-shop-products-filter-content">
                    <Disclosure.Button className="individual-shop-products-filter-button">
                      <FunnelIcon className="individual-shop-products-filter-icon" aria-hidden="true" />
                      Filters
                      <ChevronDownIcon className={`${open ? 'rotate-180' : ''} individual-shop-products-chevron-icon`} />
                    </Disclosure.Button>
                  </div>
                </div>
                <Disclosure.Panel className="individual-shop-products-disclosure-panel">
                  <div className="individual-shop-products-disclosure-content">
                    <div className="individual-shop-products-disclosure-inner">
                      <div>
                        <label htmlFor="price-filter" className="individual-shop-products-label">Price Range</label>
                        <select
                          id="price-filter"
                          className="individual-shop-products-select"
                          value={priceFilter}
                          onChange={(e) => setPriceFilter(e.target.value)}
                        >
                          <option value="">All Prices</option>
                          <option value="0-25">$0 - $25</option>
                          <option value="25-50">$25 - $50</option>
                          <option value="50-75">$50 - $75</option>
                          <option value="75-">$75+</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="newest-filter" className="individual-shop-products-label">Sort by Newest</label>
                        <input
                          id="newest-filter"
                          type="checkbox"
                          className="individual-shop-products-checkbox"
                          checked={sortByNewest}
                          onChange={(e) => setSortByNewest(e.target.checked)}
                        />
                      </div>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          {/* Product Grid */}
          <div className="individual-shop-products-grid">
            {filteredProducts.map((product, index) => (
              <ProductCard data={product} key={index} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default IndividualShopProducts;






