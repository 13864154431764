import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { adsServer } from "../../server";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Function to shuffle ads randomly
const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const MixedSponsoredAdsProps = ({ ads }) => {
    const adRefs = useRef(new Map());
    const viewedImpressionsRef = useRef(new Set());

    // Shuffle ads on component mount
    const shuffledAds = shuffleArray([...ads]);

    // Function to log clicks
    const logAdClick = async (adId, type) => {
        try {
            await axios.post(`${adsServer}/sponsored/click/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log(`${type} ad click logged for ad:`, adId);
        } catch (error) {
            console.error(`Error logging ${type} ad click:`, error);
        }
    };

    // Batch log viewed impressions
    const logViewedImpressionsBatch = async () => {
        if (viewedImpressionsRef.current.size > 0) {
            const adIds = Array.from(viewedImpressionsRef.current);
            viewedImpressionsRef.current.clear();  // Clear the ref after batching
            try {
                await axios.post(`${adsServer}/sponsored/viewed-impressions-batch`, { adIds }, {
                    withCredentials: true,
                    headers: {
                        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                    }
                });
                console.log('Viewed impressions batch logged:', adIds);
            } catch (error) {
                console.error('Error logging viewed impressions:', error);
            }
        }
    };

    // Set up the Intersection Observer to detect viewed impressions
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;
                        viewedImpressionsRef.current.add(adId);  
                    }
                });
            },
            { threshold: 0.5 }  
        );

        // Observe all ad elements
        shuffledAds.forEach(({ _id: adId }) => {
            const adElement = adRefs.current.get(adId);
            if (adElement) observer.observe(adElement);
        });

        // Batch the viewed impressions every 5 seconds
        const intervalId = setInterval(logViewedImpressionsBatch, 5000);

        return () => {
            clearInterval(intervalId);
            observer.disconnect();
        };
    }, [shuffledAds]);

    // Render based on the type of ad (product, brand, shop)
    const renderAd = (ad) => {
        const position = ad.adSet.positions[ad.type === 'product' ? "Sponsored Products" : ad.type === 'brand' ? "Sponsored Brands" : "Sponsored Shops"] || { x: 0, y: 0 };
        const scale = ad.adSet.scales ? ad.adSet.scales[ad.type === 'product' ? "Sponsored Products" : ad.type === 'brand' ? "Sponsored Brands" : "Sponsored Shops"] : 1;

        return (
            <div
                key={ad._id}
                className={`sponsored-${ad.type}-widget-item`}
                data-ad-id={ad._id}
                data-ad-type={ad.type}
                ref={(el) => adRefs.current.set(ad._id, el)}  
            >
                {ad.type === 'product' && (
                    <Link to={`/product/${ad.product._id}`} onClick={() => logAdClick(ad._id, 'product')} className="sponsored-products-component-link">
                        <div className="sponsored-products-component-item">
                            <div className="sponsored-products-component-image-wrapper">
                                <img
                                    src={ad.adSet.images["Sponsored Products"]}
                                    alt={ad.product.name}
                                    className="sponsored-products-component-image"
                                    style={{
                                        position: 'absolute',
                                        left: `${position.x}%`,
                                        top: `${position.y}%`,
                                        transform: `scale(${scale})`,
                                        transformOrigin: 'center',
                                    }}
                                />
                            </div>
                            <div className="sponsored-products-component-info">
                                <div>
                                    <h3 className="sponsored-products-component-product-name">{ad.product.name}</h3>
                                    <p className="sponsored-products-component-product-price">${ad.product.discountPrice || ad.product.originalPrice}</p>
                                </div>
                                {ad.product.handle && (
                                    <Link to={`/shop/${ad.product.handle}`} className="sponsored-products-component-visit-store">Visit Store</Link>
                                )}
                            </div>
                        </div>
                    </Link>
                )}


                {ad.type === 'brand' && (
                    <div className="sponsored-brands-widget-item">
                        <Link to={`/brand/${ad.brand.name}`} onClick={() => logAdClick(ad._id, 'brand')} className="sponsored-brands-widget-link">
                            <div className="sponsored-brands-widget-images">
                                {ad.brand.avatarImage && (
                                    <img 
                                        src={ad.brand.avatarImage.url} 
                                        alt={ad.brand.name} 
                                        className="sponsored-brands-widget-avatar"
                                        style={{
                                            position: 'absolute',
                                            left: `${position.x}%`,
                                            top: `${position.y}%`,
                                            transform: `scale(${scale})`,
                                            transformOrigin: 'center',
                                        }}
                                    />
                                )}
                                {ad.brand.coverImage && (
                                    <img 
                                        src={ad.brand.coverImage.url} 
                                        alt={ad.brand.name} 
                                        className="sponsored-brands-widget-cover"
                                        style={{
                                            transform: `translate(${ad.adSet.positions["Sponsored Brands"]?.x || 0}%, ${ad.adSet.positions["Sponsored Brands"]?.y || 0}%)`,
                                        }}
                                    />
                                )}
                            </div>
                            <div className="sponsored-brands-widget-details">
                                <h3 className="sponsored-brands-widget-name">{ad.brand.name}</h3>
                                <button className="sponsored-brands-widget-button">Visit Brand</button>
                            </div>
                        </Link>
                    </div>
                )}


                {ad.type === 'shop' && (
                    <div className="sponsored-shops-widget-item">
                        <Link to={`/shop/${ad.shop.handle}`} onClick={() => logAdClick(ad._id, 'shop')} className="sponsored-shops-widget-link">
                            <div className="sponsored-shops-widget-banner-container">
                            <div className="sponsored-shops-widget-image-container">
                                {ad.shop.banner && (
                                    <img 
                                        src={ad.shop.banner.url} 
                                        alt={ad.shop.name} 
                                        className="sponsored-shops-widget-banner"
                                        style={{
                                            position: 'absolute',
                                            left: `${position.x}%`,
                                            top: `${position.y}%`,
                                            transform: `scale(${scale})`,
                                            transformOrigin: 'center',
                                        }}
                                    />
                                )}
                               </div>
                                <div className="sponsored-shops-widget-overlay">
                                    {ad.shop.avatar && (
                                        <img 
                                            src={ad.shop.avatar.url} 
                                            alt={ad.shop.name} 
                                            className="sponsored-shops-widget-avatar"
                                        />
                                    )}
                                    <h3 className="sponsored-shops-widget-name">{ad.shop.name}</h3>
                                    <button className="sponsored-shops-widget-button">Visit Store</button>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}

            </div>
        );
    };

    return (
        <div className="mixed-sponsored-ads-container">
            <ToastContainer />
            <div className="sponsored-ads-list">
                {shuffledAds.map((ad) => renderAd(ad))}
            </div>
        </div>
    );
};

export default MixedSponsoredAdsProps;
