import React from 'react';

const TagSelectionGuide = () => {
  return (
    <div className="doc-container">
      <h1 className="doc-title">Guide to Using the Tag Selector</h1>
      <p className="doc-intro">
        This guide will walk you through the process of using the Tag Selector to categorize products by adding tags, including selecting existing tags or creating custom ones.
      </p>

      <h2 className="doc-step h2">Step-by-Step Guide to Using the Tag Selector</h2>
      <ol className="doc-steps">
        <li className="doc-step">
          <h2>Select an Existing Tag</h2>
          <p>To select a tag from the available options:</p>
          <ul>
            <li><strong>Open the Tag Dropdown</strong>: Click on the dropdown labeled <strong>Select a tag</strong>.</li>
            <li><strong>Choose a Tag</strong>: Select an existing tag from the list. Only approved tags will be available in the dropdown.</li>
            <li><strong>Add Tag</strong>: Once selected, the tag will be added to the list of selected tags below.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Add a Custom Tag</h2>
          <p>If you need to add a tag that is not available in the dropdown:</p>
          <ul>
            <li><strong>Enter Custom Tag Name</strong>: Type the name of the custom tag in the <strong>Add custom tag</strong> input field.</li>
            <li><strong>Click Add Custom Tag</strong>: Press the <strong>Add Custom Tag</strong> button or hit Enter to add the tag.</li>
            <li><strong>Pending Approval</strong>: Custom tags will be marked as unapproved until they are reviewed. They will still appear in your selected tags list.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Manage Selected Tags</h2>
          <p>You can manage the tags you have added to your product:</p>
          <ul>
            <li><strong>View Selected Tags</strong>: All selected tags will appear in a list below the dropdown and custom tag input.</li>
            <li><strong>Remove a Tag</strong>: Click the <strong>&times;</strong> button next to a tag to remove it from the list of selected tags.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Submit Tags with Product</h2>
          <p>Once you have selected and added all the necessary tags, they will be included when submitting the product:</p>
          <ul>
            <li><strong>Review Tags</strong>: Ensure all relevant tags are added to properly categorize your product.</li>
            <li><strong>Submit Product Details</strong>: Tags will be included automatically when you submit your product details.</li>
          </ul>
        </li>
      </ol>

      <div className="doc-additional">
        <h2>Additional Tips</h2>
        <ul>
          <li><strong>Use Relevant Tags</strong>: Select tags that accurately describe your product to improve discoverability.</li>
          <li><strong>Custom Tags Approval</strong>: Note that custom tags will require approval before becoming part of the available tag options.</li>
          <li><strong>Avoid Duplicate Tags</strong>: Ensure that the same tag is not added multiple times to avoid redundancy.</li>
        </ul>
      </div>
    </div>
  );
};

export default TagSelectionGuide;
