import React, { useState, useRef } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { server } from "../../server";
import WHIPClient from './WHIPClient'; 

const StartStream = () => {
  const [streamDetails, setStreamDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const [localStream, setLocalStream] = useState(null);
  const [whipClient, setWhipClient] = useState(null); // Track WHIPClient instance

  // New state for streaming method
  const [streamingMethod, setStreamingMethod] = useState('webrtc'); // Default to WebRTC

  // Toggle audio and video (optional enhancements)
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  // Retrieve currentProfile from session storage
  const currentProfile = sessionStorage.getItem('currentProfile') || 'User';

  const handleStartStream = async () => {
    setLoading(true);
    setError(null);

    try {
      // Step 1: Create Live Input via Backend with selected streaming method
      const response = await axios.post(
        `${server}/stream/start`,
        { currentProfile, streamingMethod }, // Send streamingMethod
        { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setStreamDetails(response.data.streamDetails);
      toast.success('Livestream started successfully!');

      if (streamingMethod === 'webrtc') {
        // Step 2: Initialize Media Devices
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setLocalStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }

        // Step 3: Use WHIPClient to stream to Cloudflare
        const whip = new WHIPClient(response.data.streamDetails.webrtcUrl, videoRef.current);
        setWhipClient(whip);
      }
      // For RTMPS, no further action is needed as users will use OBS to stream
    } catch (err) {
      console.error('Error starting stream:', err);
      setError(err.response?.data?.error || 'Failed to start stream');
      toast.error(err.response?.data?.error || 'Failed to start stream');
    } finally {
      setLoading(false);
    }
  };

  const handleStopStream = async () => {
    setLoading(true);
    setError(null);

    try {
      if (streamDetails.method === 'webrtc') {
        // Stop WebRTC stream
        if (localStream) {
          localStream.getTracks().forEach(track => track.stop());
          setLocalStream(null);
        }

        if (whipClient) {
          whipClient.close();
          setWhipClient(null);
        }
      }
      // For RTMPS, OBS handles the stream; backend will stop it

      // Step 1: Stop Livestream via Backend
      const response = await axios.post(
        `${server}/stream/stop`,
        { currentProfile },
        {
          withCredentials: true, 
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setStreamDetails(null);
      toast.success(response.data.message || 'Livestream stopped successfully!');
    } catch (err) {
      console.error('Error stopping stream:', err);
      setError(err.response?.data?.error || 'Failed to stop stream');
      toast.error(err.response?.data?.error || 'Failed to stop stream');
    } finally {
      setLoading(false);
    }
  };

  // Toggle audio muting
  const toggleAudio = () => {
    if (localStream) {
      localStream.getAudioTracks().forEach(track => {
        track.enabled = !track.enabled;
      });
      setIsAudioMuted(!isAudioMuted);
    }
  };

  // Toggle video muting
  const toggleVideo = () => {
    if (localStream) {
      localStream.getVideoTracks().forEach(track => {
        track.enabled = !track.enabled;
      });
      setIsVideoMuted(!isVideoMuted);
    }
  };

  return (
    <div className="start-stream-container">
      <h2>Manage Livestream</h2>

      {!streamDetails ? (
        <div>
          <div className="stream-method-selection">
            <label>
              <input
                type="radio"
                value="webrtc"
                checked={streamingMethod === 'webrtc'}
                onChange={(e) => setStreamingMethod(e.target.value)}
              />
              Browser-Based Streaming (WebRTC)
            </label>
            <label>
              <input
                type="radio"
                value="rtmps"
                checked={streamingMethod === 'rtmps'}
                onChange={(e) => setStreamingMethod(e.target.value)}
              />
              External Software (OBS)
            </label>
          </div>

          <button onClick={handleStartStream} disabled={loading}>
            {loading ? 'Starting Stream...' : 'Start Livestream'}
          </button>
        </div>
      ) : (
        <div className="stream-details">
          <h3>Livestream Details</h3>
          {streamDetails.method === 'webrtc' ? (
            <>
              <p>
                <strong>Playback:</strong> WebRTC playback requires a compatible player.
              </p>
              <video ref={videoRef} autoPlay muted style={{ width: '100%', borderRadius: '20px', marginBottom: '20px' }}></video>
              <div className="stream-controls">
                <button onClick={toggleAudio}>
                  {isAudioMuted ? 'Unmute Audio' : 'Mute Audio'}
                </button>
                <button onClick={toggleVideo}>
                  {isVideoMuted ? 'Enable Video' : 'Disable Video'}
                </button>
                <button onClick={handleStopStream} disabled={loading}>
                  {loading ? 'Stopping Stream...' : 'Stop Livestream'}
                </button>
              </div>
            </>
          ) : (
            <>
              <p>
                <strong>RTMPS URL:</strong> {streamDetails.rtmpsUrl}
              </p>
              <p>
                <strong>Stream Key:</strong> {streamDetails.streamKey}
              </p>
              <p>
                <strong>Playback URL:</strong>{' '}
                <a href={streamDetails.playbackUrl} target="_blank" rel="noopener noreferrer">
                  Watch Livestream
                </a>
              </p>
              <p>Configure OBS with the above RTMPS URL and Stream Key to start streaming.</p>
              <button onClick={handleStopStream} disabled={loading}>
                {loading ? 'Stopping Stream...' : 'Stop Livestream'}
              </button>
            </>
          )}
        </div>
      )}

      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default StartStream;

