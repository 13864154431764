import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ecommerceServer } from '../../server';

const ShippingCalculator = () => {
  const [shipTo, setShipTo] = useState({
    name: '',
    address_line1: '',
    city_locality: '',
    state_province: '',
    postal_code: '',
    country_code: 'US',
  });

  const [packages, setPackages] = useState([
    { 
      weight: { value: 1, unit: 'pound' },
      dimensions: { length: 10, width: 5, height: 5, unit: 'inch' }
    },
  ]);

  const [rates, setRates] = useState([]);
  const [error, setError] = useState(null);

  const currentProfileName = sessionStorage.getItem('currentProfile');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request with only shipTo and packages
      const response = await axios.post(
        `${ecommerceServer}/shipping/calculate-shipping`,
        { shipTo, packages },
        {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile',
          },
          withCredentials: true,
        }
      );
      console.log("Shipping rates response:", response.data);

      if (response.data && response.data.length > 0) {
        setRates(response.data);
        setError(null);
        toast.success('Shipping rates fetched successfully!');
      } else {
        throw new Error('No rates available');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message || 'Error calculating rates';
      setError(errorMessage);
      setRates([]);
      toast.error(errorMessage);
    }
  };

  return (
    <div className="shipengine-calculator-container">
      <h1 className="shipengine-calculator-title">Shipping Cost Calculator</h1>
      <form className="shipengine-calculator-form" onSubmit={handleSubmit}>
        <h2 className="shipengine-calculator-section-title">Ship To</h2>
        <div className="shipengine-calculator-input-group">
          <input
            className="shipengine-calculator-input"
            placeholder="Name"
            onChange={(e) => setShipTo({ ...shipTo, name: e.target.value })}
          />
          <input
            className="shipengine-calculator-input"
            placeholder="Address Line 1"
            onChange={(e) => setShipTo({ ...shipTo, address_line1: e.target.value })}
          />
          <input
            className="shipengine-calculator-input"
            placeholder="City"
            onChange={(e) => setShipTo({ ...shipTo, city_locality: e.target.value })}
          />
          <input
            className="shipengine-calculator-input"
            placeholder="State"
            onChange={(e) => setShipTo({ ...shipTo, state_province: e.target.value })}
          />
          <input
            className="shipengine-calculator-input"
            placeholder="Postal Code"
            onChange={(e) => setShipTo({ ...shipTo, postal_code: e.target.value })}
          />
          <input
            className="shipengine-calculator-input"
            placeholder="Country Code"
            defaultValue="US"
            onChange={(e) => setShipTo({ ...shipTo, country_code: e.target.value })}
          />
        </div>

        <h2 className="shipengine-calculator-section-title">Package Details</h2>
        <div className="shipengine-calculator-input-group">
          <input
            className="shipengine-calculator-input"
            placeholder="Weight (lbs)"
            type="number"
            defaultValue={1}
            onChange={(e) =>
              setPackages([
                {
                  ...packages[0],
                  weight: { value: parseFloat(e.target.value), unit: 'pound' },
                },
              ])
            }
          />
          <input
            className="shipengine-calculator-input"
            placeholder="Length (in)"
            type="number"
            defaultValue={10}
            onChange={(e) =>
              setPackages([
                {
                  ...packages[0],
                  dimensions: { 
                    ...packages[0].dimensions, 
                    length: parseFloat(e.target.value) 
                  },
                },
              ])
            }
          />
          <input
            className="shipengine-calculator-input"
            placeholder="Width (in)"
            type="number"
            defaultValue={5}
            onChange={(e) =>
              setPackages([
                {
                  ...packages[0],
                  dimensions: { 
                    ...packages[0].dimensions, 
                    width: parseFloat(e.target.value) 
                  },
                },
              ])
            }
          />
          <input
            className="shipengine-calculator-input"
            placeholder="Height (in)"
            type="number"
            defaultValue={5}
            onChange={(e) =>
              setPackages([
                {
                  ...packages[0],
                  dimensions: { 
                    ...packages[0].dimensions, 
                    height: parseFloat(e.target.value) 
                  },
                },
              ])
            }
          />
        </div>

        {/* Removed the Warehouse ID section since it's no longer needed */}

        <button className="shipengine-calculator-button" type="submit">
          Calculate Shipping
        </button>
      </form>

      {error && <p className="shipengine-calculator-error">{error}</p>}

      <h2 className="shipengine-calculator-rates-title">Shipping Rates</h2>
      <div className="shipengine-calculator-rates">
      {rates.map((rate, index) => (
  <div key={index} className="shipengine-calculator-rate">
    <p><strong>Service Type:</strong> {rate.serviceCode}</p>
    <p><strong>Price:</strong> ${rate.shippingAmount.amount}</p>
    <p><strong>Delivery Days:</strong> {rate.deliveryDays} days</p>
  </div>
))}

      </div>
    </div>
  );
};

export default ShippingCalculator;



