import React from 'react';

const ShippingInformation = () => {
  return (
    <div className="legal-docs-container">
      <h1 className="legal-docs-title">Shipping Information</h1>

      <section>
        <h2 className="legal-docs-subtitle">📦 Shipping Times</h2>
        <p className="legal-docs-text">
          Shipping times and costs depend on the merchant.
          Details are displayed on each product page and in your cart.
        </p>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">🌍 International Shipping</h2>
        <p className="legal-docs-text">
          Currently BuzzVibe does not facilitate orders outside the U.S.
          <br />
          <strong>Important:</strong> International orders cannot be canceled after placing.
        </p>
      </section>


      <section>
        <h2 className="legal-docs-subtitle">🚚 Order Status</h2>
        <ul className="legal-docs-list">
          <li>You’ll receive a shipping confirmation email with tracking once your order ships.</li>
          <li>Some handmade items may take up to 3 weeks to ship.</li>
          <li>
            For delays past the estimated shipping time, email <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@BuzzVibe.com</a> for help.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">💲 Shipping Costs</h2>
        <p className="legal-docs-text">
          Shipping costs vary by merchant enabled carriers, a selection of shipping options will be offered at checkout.
          Costs are shown on the checkout page and in your final cart.
        </p>
      </section>

      <section>
        <h2 className="legal-docs-subtitle">🔍 Tracking Information</h2>
        <p className="legal-docs-text">
          Yes, all orders include tracking details in the shipping confirmation email.
        </p>
      </section>
    </div>
  );
};

export default ShippingInformation;
