import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { ecommerceServer } from "../server";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import Sidebar from '../components/Layout/Sidebar';
import HeroAllProducts from "../components/Route/Hero/HeroAllProducts";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import TrendingProductsCarousel from "../components/Products/TrendingProductsCarosel";
import CategoryScroller from '../components/Marketplace/CategoryScroller';
import { categoriesData } from "../static/data";

const CategoryProductsPage = ({ specialFilter }) => {
  const { category, subCategory } = useParams();
  const navigate = useNavigate();
  const { allProducts } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [brands, setBrands] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [openPriceRange, setOpenPriceRange] = useState(false);
  const [availableForPickup, setAvailableForPickup] = useState(false);
  const [availableForDelivery, setAvailableForDelivery] = useState(false);
  const [minRating, setMinRating] = useState(0);
  const [sortOption, setSortOption] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCategorySelect = (selectedCategory) => {
    navigate(`/products/${encodeURIComponent(selectedCategory.title)}`);
  };

  const handleSubCategoryClick = (selectedSubCategory) => {
    navigate(`/products/${encodeURIComponent(category)}/${encodeURIComponent(selectedSubCategory.title)}`);
  };

  // Helper function to normalize strings
  const normalizeString = (str) => (str || '').toLowerCase().trim();

  useEffect(() => {
    const fetchProductsByTag = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${ecommerceServer}/tags/products/tag/${encodeURIComponent(subCategory)}`);
        let filteredProducts = response.data;

        const uniqueBrands = new Set(filteredProducts.map(product => product.brand));
        setBrands([...uniqueBrands]);

        const newActiveFilters = [];

        // Sorting logic
        if (sortOption === 'priceLowHigh') {
          filteredProducts.sort((a, b) => a.discountPrice - b.discountPrice);
        } else if (sortOption === 'priceHighToLow') {
          filteredProducts.sort((a, b) => b.discountPrice - a.discountPrice);
        } else if (sortOption === 'newest') {
          filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }

        // Brand filtering
        if (selectedBrand) {
          filteredProducts = filteredProducts.filter(product => product.brand === selectedBrand);
          newActiveFilters.push({ id: 'brand', name: 'Brand', value: selectedBrand });
        }

        // Rating filtering
        if (minRating > 0) {
          filteredProducts = filteredProducts.filter(product => product.ratings >= minRating);
          newActiveFilters.push({ id: 'rating', name: 'Rating', value: `${minRating}+ stars` });
        }

        // Availability filtering
        if (availableForPickup) {
          filteredProducts = filteredProducts.filter(product => product.availableForPickup);
          newActiveFilters.push({ id: 'availability', name: 'Availability', value: 'Pickup' });
        } else if (availableForDelivery) {
          filteredProducts = filteredProducts.filter(product => product.availableForDelivery);
          newActiveFilters.push({ id: 'availability', name: 'Availability', value: 'Delivery' });
        }

        // Price range filtering
        filteredProducts = filteredProducts.filter(product => product.discountPrice >= minPrice && product.discountPrice <= maxPrice);
        newActiveFilters.push({ id: 'priceRange', name: 'Price Range', value: `$${minPrice} - $${maxPrice}` });

        // Add Tag filter
        newActiveFilters.push({ id: 'tag', name: 'Tag', value: subCategory });

        setData(filteredProducts);
        setActiveFilters(newActiveFilters);
      } catch (error) {
        console.error('Error fetching products by tag:', error);
        setData([]);
        setActiveFilters([]);
      } finally {
        setLoading(false);
      }
    };

    if (category === 'tag') {
      // Fetch products by tag
      fetchProductsByTag();
    } else {
      // Existing logic for category and subcategory
      if (allProducts) {
        let filteredProducts = [...allProducts];
        const uniqueBrands = new Set(allProducts.map(product => product.brand));
        setBrands([...uniqueBrands]);

        const newActiveFilters = [];

        // Sorting logic
        if (sortOption === 'priceLowHigh') {
          filteredProducts.sort((a, b) => a.discountPrice - b.discountPrice);
        } else if (sortOption === 'priceHighToLow') {
          filteredProducts.sort((a, b) => b.discountPrice - a.discountPrice);
        } else if (sortOption === 'newest') {
          filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }

        // Category and Subcategory filtering
        if (category) {
          filteredProducts = filteredProducts.filter(product => normalizeString(product.category) === normalizeString(category));
          newActiveFilters.push({ id: 'category', name: 'Category', value: category });
          if (subCategory) {
            filteredProducts = filteredProducts.filter(product => normalizeString(product.subCategory) === normalizeString(subCategory));
            newActiveFilters.push({ id: 'subCategory', name: 'Subcategory', value: subCategory });
          }
        } else if (specialFilter) {
          // Existing special filter logic
          switch (specialFilter) {
            case "new-arrivals":
              filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
              break;
            case "top-rated":
              filteredProducts = filteredProducts.filter(product => product.ratings >= 4);
              break;
            case "trending":
              filteredProducts.sort((a, b) => b.sold_out - a.sold_out);
              break;
            default:
              break;
          }
        }

        // Brand filtering
        if (selectedBrand) {
          filteredProducts = filteredProducts.filter(product => product.brand === selectedBrand);
          newActiveFilters.push({ id: 'brand', name: 'Brand', value: selectedBrand });
        }

        // Rating filtering
        if (minRating > 0) {
          filteredProducts = filteredProducts.filter(product => product.ratings >= minRating);
          newActiveFilters.push({ id: 'rating', name: 'Rating', value: `${minRating}+ stars` });
        }

        // Availability filtering
        if (availableForPickup) {
          filteredProducts = filteredProducts.filter(product => product.availableForPickup);
          newActiveFilters.push({ id: 'availability', name: 'Availability', value: 'Pickup' });
        } else if (availableForDelivery) {
          filteredProducts = filteredProducts.filter(product => product.availableForDelivery);
          newActiveFilters.push({ id: 'availability', name: 'Availability', value: 'Delivery' });
        }

        // Price range filtering
        filteredProducts = filteredProducts.filter(product => product.discountPrice >= minPrice && product.discountPrice <= maxPrice);
        newActiveFilters.push({ id: 'priceRange', name: 'Price Range', value: `$${minPrice} - $${maxPrice}` });

        setData(filteredProducts);
        setActiveFilters(newActiveFilters);
      } else {
        setData([]);
        setBrands([]);
      }
    }
  }, [
    allProducts,
    sortOption,
    category,
    subCategory,
    specialFilter,
    selectedBrand,
    minPrice,
    maxPrice,
    availableForPickup,
    availableForDelivery,
    minRating,
  ]);

  const removeFilter = (filterId) => {
    setActiveFilters(activeFilters.filter(filter => filter.id !== filterId));
    if (filterId === 'brand') {
      setSelectedBrand('');
    } else if (filterId === 'availability') {
      setAvailableForPickup(false);
      setAvailableForDelivery(false);
    } else if (filterId === 'priceRange') {
      setMinPrice(0);
      setMaxPrice(1000);
    } else if (filterId === 'rating') {
      setMinRating(0);
    } else if (filterId === 'subCategory') {
      // Handle subCategory removal
      // Optionally, navigate back to category only
    } else if (filterId === 'tag') {
      // Handle tag removal
      // Optionally, navigate back to all products or previous page
    }
  };

  const renderProductCards = (products) => {
    return products.map((product, index) => <ProductCard data={product} key={index} />);
  };

  const renderActiveFilters = () => {
    return activeFilters.map((filter) => (
      <span key={filter.id} className="category-products-page__active-filter">
        {filter.name}: {filter.value}
        <button onClick={() => removeFilter(filter.id)} className="category-products-page__remove-filter">
          <XMarkIcon className="category-products-page__remove-icon" aria-hidden="true" />
        </button>
      </span>
    ));
  };

  const renderFilters = () => {
    return (
      <div className="category-products-page__filters-container">
        <div className="category-products-page__filters-wrapper">
          <Disclosure as="section" className="category-products-page__disclosure-section">
            {({ open }) => (
              <>
                <Disclosure.Button className="category-products-page__disclosure-button">
                  <span className="category-products-page__disclosure-title">Filters</span>
                  <ChevronDownIcon className={`category-products-page__chevron ${open ? 'rotate-180' : ''}`} />
                </Disclosure.Button>
                <Disclosure.Panel className="category-products-page__disclosure-panel">
                  <div className="category-products-page__filter-options">
                    {/* Brand Filter */}
                    <Menu as="div" className="category-products-page__menu">
                      <Menu.Button className="category-products-page__menu-button">
                        {selectedBrand || 'Select Brand'}
                        <ChevronDownIcon className="category-products-page__chevron" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="category-products-page__transition-enter"
                        enterFrom="category-products-page__transition-enter-from"
                        enterTo="category-products-page__transition-enter-to"
                        leave="category-products-page__transition-leave"
                        leaveFrom="category-products-page__transition-leave-from"
                        leaveTo="category-products-page__transition-leave-to"
                      >
                        <Menu.Items className="category-products-page__menu-items">
                          <div className="category-products-page__menu-content">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                  onClick={() => setSelectedBrand('')}
                                >
                                  All Brands
                                </button>
                              )}
                            </Menu.Item>
                            {brands.map((brand) => (
                              <Menu.Item key={brand}>
                                {({ active }) => (
                                  <button
                                    className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                    onClick={() => setSelectedBrand(brand)}
                                  >
                                    {brand}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    {/* Availability Filter */}
                    <Menu as="div" className="category-products-page__menu">
                      <Menu.Button className="category-products-page__menu-button">
                        Availability
                        <ChevronDownIcon className="category-products-page__chevron" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="category-products-page__transition-enter"
                        enterFrom="category-products-page__transition-enter-from"
                        enterTo="category-products-page__transition-enter-to"
                        leave="category-products-page__transition-leave"
                        leaveFrom="category-products-page__transition-leave-from"
                        leaveTo="category-products-page__transition-leave-to"
                      >
                        <Menu.Items className="category-products-page__menu-items">
                          <div className="category-products-page__menu-content">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                  onClick={() => {
                                    setAvailableForPickup(!availableForPickup);
                                    setAvailableForDelivery(false);
                                  }}
                                >
                                  Pickup
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                  onClick={() => {
                                    setAvailableForDelivery(!availableForDelivery);
                                    setAvailableForPickup(false);
                                  }}
                                >
                                  Delivery
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    {/* Rating Filter */}
                    <Menu as="div" className="category-products-page__menu">
                      <Menu.Button className="category-products-page__menu-button">
                        Rating: {minRating}+
                        <ChevronDownIcon className="category-products-page__chevron" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="category-products-page__transition-enter"
                        enterFrom="category-products-page__transition-enter-from"
                        enterTo="category-products-page__transition-enter-to"
                        leave="category-products-page__transition-leave"
                        leaveFrom="category-products-page__transition-leave-from"
                        leaveTo="category-products-page__transition-leave-to"
                      >
                        <Menu.Items className="category-products-page__menu-items">
                          <div className="category-products-page__menu-content">
                            {[0, 1, 2, 3, 4, 5].map((rating) => (
                              <Menu.Item key={rating}>
                                {({ active }) => (
                                  <button
                                    className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                    onClick={() => setMinRating(rating)}
                                  >
                                    {rating}+ Stars
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    {/* Price Range Filter */}
                    <div className="category-products-page__price-range">
                      <button
                        type="button"
                        className="category-products-page__menu-button"
                        onClick={() => setOpenPriceRange(!openPriceRange)}
                      >
                        Price Range
                        <ChevronDownIcon className="category-products-page__chevron" />
                      </button>
                      {openPriceRange && (
                        <div className="category-products-page__price-range-panel">
                          <div className="category-products-page__price-range-content">
                            <label htmlFor="minPrice" className="category-products-page__price-label">
                              Minimum Price
                            </label>
                            <input
                              type="range"
                              id="minPrice"
                              name="minPrice"
                              min="0"
                              max={maxPrice}
                              value={minPrice}
                              onChange={(e) => setMinPrice(Number(e.target.value))}
                              className="category-products-page__price-range-input"
                            />
                            <span className="category-products-page__price-value">Value: ${minPrice}</span>

                            <label htmlFor="maxPrice" className="category-products-page__price-label">
                              Maximum Price
                            </label>
                            <input
                              type="range"
                              id="maxPrice"
                              name="maxPrice"
                              min={minPrice}
                              max="1000"
                              value={maxPrice}
                              onChange={(e) => setMaxPrice(Number(e.target.value))}
                              className="category-products-page__price-range-input"
                            />
                            <span className="category-products-page__price-value">Value: ${maxPrice}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Sort Options */}
                    <div className="category-products-page__sort-dropdown">
                      <label htmlFor="sortOptions" className="category-products-page__sort-label">
                        Sort by:
                      </label>
                      <div className="category-products-page__sort-wrapper">
                        <select
                          id="sortOptions"
                          name="sortOptions"
                          value={sortOption}
                          onChange={(e) => setSortOption(e.target.value)}
                          className="category-products-page__sort-select"
                        >
                          <option value="">Select</option>
                          <option value="newest">Newest to Oldest</option>
                          <option value="priceHighToLow">Price: High to Low</option>
                          <option value="priceLowHigh">Price: Low to High</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    );
  };

  return (
    <div className="category-products-page__container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header activeHeading={3} />
          <Sidebar />
          
          <div className="category-products-page__category-scroller">
            <CategoryScroller 
              categoriesData={categoriesData} 
              onCategorySelect={handleCategorySelect} 
              currentCategory={category}
            />
          </div>
          {category && (
              <div className="category-products-page__subcategories">
                
                <div className="subcategory-list">
                  {categoriesData.find(cat => cat.title === category)?.subCategories.map((sub) => (
                    <button 
                      key={sub.id} 
                      onClick={() => handleSubCategoryClick(sub)} 
                      className={`subcategory-button ${subCategory === sub.title ? 'active-subcategory' : ''}`}
                    >
                      {sub.title}
                    </button>
                  )) || <p>No subcategories available.</p>}
                </div>
              </div>
            )}
            
          <div className="category-products-page__content">
            <div className="category-products-page__filters">
              {renderFilters()}
            </div>
          </div>
          <div className="category-products-page__active-filters-bar">
            <div className="category-products-page__active-filters">{renderActiveFilters()}</div>
          </div>
          <div className="category-products-page__products">
            {data && data.length > 0 ? (
              <div className="category-products-page__product-grid">
                {renderProductCards(data)}
              </div>
            ) : (
              <div className="category-products-page__no-products">
                <p>No products found under this filter. Check out these instead.</p>
                <TrendingProductsCarousel className="category-products-page__trending-products" />
              </div>
            )}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default CategoryProductsPage;



