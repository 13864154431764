import React, { useState } from 'react';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';
import { ecommerceServer } from '../../server';

const AddInventory = ({ productId, onClose, onInventoryAdded }) => {
  const [batchNumber, setBatchNumber] = useState('');
  const [quantity, setQuantity] = useState('');
  const [receivedDate, setReceivedDate] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${ecommerceServer}/inventory/add-inventory/${productId}`, {
        batchNumber,
        quantity: parseInt(quantity),
        receivedDate,
      }, {
        withCredentials: true,
        headers: {
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        },
      });
      console.log('Inventory added:', response.data);
      setLoading(false);
      onInventoryAdded();
      onClose();
    } catch (error) {
      console.error('Error adding inventory:', error);
      setLoading(false);
    }
  };

  return (
    <div className="add-inventory-component-modal">
      <AiOutlineClose className="add-inventory-component-close-icon" onClick={onClose} />
      <h2>Add Inventory</h2>
      <form onSubmit={handleSubmit} className="add-inventory-component-form">
        <div className="add-inventory-component-form-group">
          <label htmlFor="batchNumber">Batch Number</label>
          <input
            type="text"
            id="batchNumber"
            value={batchNumber}
            onChange={(e) => setBatchNumber(e.target.value)}
            required
          />
        </div>
        <div className="add-inventory-component-form-group">
          <label htmlFor="quantity">Quantity</label>
          <input
            type="number"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
          />
        </div>
        <div className="add-inventory-component-form-group">
          <label htmlFor="receivedDate">Received Date</label>
          <input
            type="date"
            id="receivedDate"
            value={receivedDate}
            onChange={(e) => setReceivedDate(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading} className="add-inventory-component-submit-button">
          {loading ? 'Adding...' : 'Add Inventory'}
        </button>
      </form>
    </div>
  );
};

export default AddInventory;
