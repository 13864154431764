import React from 'react';
import { useSelector } from 'react-redux';

export default function TrendingProductsCarousel() {
  const { allProducts, isLoading } = useSelector((state) => state.products);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const retailProducts = allProducts.filter(product => product.productType.includes('retail'));

  // Group products in sets of 4
  const productGroups = [];
  for (let i = 0; i < retailProducts.length; i += 4) {
    const group = retailProducts.slice(i, i + 4);
    if (group.length === 4) {
      productGroups.push(group);
    }
  }

  return (
    <div className="trending-products-carousel-bg">
      <div className="trending-products-carousel-container">
        <div className="trending-products-carousel-header">
          <h2 className="trending-products-carousel-title">Trending Products</h2>
          <a href="/products" className="trending-products-carousel-link-hidden">
            See more <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="trending-products-carousel-wrapper">
          <div className="trending-products-carousel-scroll">
            {productGroups.map((group, groupIndex) => (
              <ul key={groupIndex} role="list" className="trending-products-carousel-list">
                {group.map((product) => (
                  <li key={product._id} className="trending-products-carousel-item">
                    <div className="trending-products-carousel-card">
                      <a href={`/product/${product._id}`}>
                        <div className="trending-products-carousel-image-container">
                          <img
                            src={product.images[0]?.url}
                            alt={product.name}
                            className="trending-products-carousel-image"
                          />
                        </div>
                        <div className="trending-products-carousel-info">
                          <p className="trending-products-carousel-category">{product.category}</p>
                          <h3 className="trending-products-carousel-product-name">
                            {product.name}
                          </h3>
                          <p className="trending-products-carousel-price">
                            {product.originalPrice ? `$${product.originalPrice}` : 'Price not available'}
                          </p>
                        </div>
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>

        <div className="trending-products-carousel-footer">
          <a href="/products" className="trending-products-carousel-link">
            See more <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  );
}




