// src/redux/reducers/connectionsReducer.js

import { createReducer } from "@reduxjs/toolkit";
import {
  FOLLOW_UNFOLLOW_REQUEST,
  FOLLOW_UNFOLLOW_SUCCESS,
  FOLLOW_UNFOLLOW_FAIL,
  FETCH_MY_CONNECTIONS_REQUEST,
  FETCH_MY_CONNECTIONS_SUCCESS,
  FETCH_MY_CONNECTIONS_FAIL,
  FETCH_CONNECTIONS_REQUEST,
  FETCH_CONNECTIONS_SUCCESS,
  FETCH_CONNECTIONS_FAIL,
  FETCH_IS_FOLLOWING_REQUEST,
  FETCH_IS_FOLLOWING_SUCCESS,
  FETCH_IS_FOLLOWING_FAIL,
  FETCH_RECOMMENDED_CONNECTIONS_REQUEST,
  FETCH_RECOMMENDED_CONNECTIONS_SUCCESS,
  FETCH_RECOMMENDED_CONNECTIONS_FAIL,
} from "../actions/connections";

const initialState = {
  // Separate loading states
  isLoadingMyFollowers: false,
  isLoadingMyFollowing: false,
  isLoadingFollowers: false,
  isLoadingFollowing: false,
  isLoadingFollowUnfollow: false,
  isLoadingIsFollowing: false,
  isLoadingRecommendedConnections: false,

  // Data storage
  myFollowers: [],
  myFollowing: [],
  followers: [],
  following: [],
  recommendedConnections: [],

  // Fetched flags
  hasFetchedMyFollowers: false,
  hasFetchedMyFollowing: false,
  hasFetchedFollowers: false,
  hasFetchedFollowing: false,

  // Other state
  isFollowingById: {}, // Track following status by connectionId
  error: null,
};

export const connectionsReducer = createReducer(initialState, (builder) => {
  builder
    // Handle follow/unfollow actions
    .addCase(FOLLOW_UNFOLLOW_REQUEST, (state) => {
      state.isLoadingFollowUnfollow = true;
      state.error = null;
    })
    .addCase(FOLLOW_UNFOLLOW_SUCCESS, (state) => {
      state.isLoadingFollowUnfollow = false;
      state.error = null;
    })
    .addCase(FOLLOW_UNFOLLOW_FAIL, (state, action) => {
      state.isLoadingFollowUnfollow = false;
      state.error = action.payload;
    })

    // Handle fetching current user's connections
    .addCase(FETCH_MY_CONNECTIONS_REQUEST, (state, action) => {
      const relationship = action.payload.relationship;
      if (relationship === 'followers') {
        state.isLoadingMyFollowers = true;
      } else if (relationship === 'following') {
        state.isLoadingMyFollowing = true;
      }
      state.error = null;
    })
    .addCase(FETCH_MY_CONNECTIONS_SUCCESS, (state, action) => {
      const { connections, relationship } = action.payload;
      if (relationship === 'followers') {
        state.isLoadingMyFollowers = false;
        state.myFollowers = connections;
        state.hasFetchedMyFollowers = true;
      } else if (relationship === 'following') {
        state.isLoadingMyFollowing = false;
        state.myFollowing = connections;
        state.hasFetchedMyFollowing = true;
      }
      state.error = null;
    })
    .addCase(FETCH_MY_CONNECTIONS_FAIL, (state, action) => {
      const { relationship, error } = action.payload;
      if (relationship === 'followers') {
        state.isLoadingMyFollowers = false;
      } else if (relationship === 'following') {
        state.isLoadingMyFollowing = false;
      }
      state.error = error;
    })

    // Handle fetching another user's connections
    .addCase(FETCH_CONNECTIONS_REQUEST, (state, action) => {
      const relationship = action.payload.relationship;
      if (relationship === 'followers') {
        state.isLoadingFollowers = true;
      } else if (relationship === 'following') {
        state.isLoadingFollowing = true;
      }
      state.error = null;
    })
    .addCase(FETCH_CONNECTIONS_SUCCESS, (state, action) => {
      const { connections, relationship } = action.payload;

      if (relationship === 'followers') {
        state.followers = connections;
        state.hasFetchedFollowers = true;
        state.isLoadingFollowers = false;
      } else if (relationship === 'following') {
        state.following = connections;
        state.hasFetchedFollowing = true;
        state.isLoadingFollowing = false;
      }
      state.error = null;
    })
    .addCase(FETCH_CONNECTIONS_FAIL, (state, action) => {
      const { relationship, error } = action.payload;
      if (relationship === 'followers') {
        state.isLoadingFollowers = false;
      } else if (relationship === 'following') {
        state.isLoadingFollowing = false;
      }
      state.error = error;
    })

    // Handle checking if the current user is following another profile
    .addCase(FETCH_IS_FOLLOWING_REQUEST, (state) => {
      state.isLoadingIsFollowing = true;
      state.error = null;
    })
    .addCase(FETCH_IS_FOLLOWING_SUCCESS, (state, action) => {
      state.isLoadingIsFollowing = false;
      state.isFollowingById[action.payload.connectionId] = action.payload.isFollowing;
      state.error = null;
    })
    .addCase(FETCH_IS_FOLLOWING_FAIL, (state, action) => {
      state.isLoadingIsFollowing = false;
      state.error = action.payload;
    })

    // Handle fetching recommended connections
    .addCase(FETCH_RECOMMENDED_CONNECTIONS_REQUEST, (state) => {
      state.isLoadingRecommendedConnections = true;
      state.error = null;
    })
    .addCase(FETCH_RECOMMENDED_CONNECTIONS_SUCCESS, (state, action) => {
      state.isLoadingRecommendedConnections = false;
      state.recommendedConnections = action.payload;
      state.error = null;
    })
    .addCase(FETCH_RECOMMENDED_CONNECTIONS_FAIL, (state, action) => {
      state.isLoadingRecommendedConnections = false;
      state.error = action.payload;
    })

    // Clear errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    });
});

export default connectionsReducer;


  


