import React, { useState } from 'react';
import axios from 'axios';
import { ecommerceServer } from "../../server";

function HelpContactForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${ecommerceServer}/admin/send-help-email`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        alert('Message sent successfully!');
        setFormData({ firstName: '', lastName: '', email: '', phoneNumber: '', message: '' });
      } else {
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="help-contact-form-container">
      <div className="help-contact-form-info">
        <h2 className="help-contact-form-title">Get in touch</h2>
        <p className="help-contact-form-description">
          If you have any questions or need assistance, feel free to reach out. We're here to help!
        </p>
        <div className="help-contact-form-contact">
          <div className="help-contact-form-contact-item">
            <span className="help-contact-form-icon">🏢</span>
            <p>545 Mavis Island, Chicago, IL 99191</p>
          </div>
          <div className="help-contact-form-contact-item">
            <span className="help-contact-form-icon">📞</span>
            <a href="tel:+15552345678" className="help-contact-form-link">+1 (555) 234-5678</a>
          </div>
          <div className="help-contact-form-contact-item">
            <span className="help-contact-form-icon">✉️</span>
            <a href="mailto:info@buzzvibe.com" className="help-contact-form-link">info@buzzvibe.com</a>
          </div>
        </div>
      </div>
      <form className="help-contact-form-form" onSubmit={handleSubmit}>
        <div className="help-contact-form-grid">
          <div className="help-contact-form-field">
            <label htmlFor="firstName" className="help-contact-form-label">First name</label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleChange}
              className="help-contact-form-input"
            />
          </div>
          <div className="help-contact-form-field">
            <label htmlFor="lastName" className="help-contact-form-label">Last name</label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleChange}
              className="help-contact-form-input"
            />
          </div>
          <div className="help-contact-form-field-full">
            <label htmlFor="email" className="help-contact-form-label">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className="help-contact-form-input"
            />
          </div>
          <div className="help-contact-form-field-full">
            <label htmlFor="phoneNumber" className="help-contact-form-label">Phone number</label>
            <input
              id="phoneNumber"
              name="phoneNumber"
              type="tel"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="help-contact-form-input"
            />
          </div>
          <div className="help-contact-form-field-full">
            <label htmlFor="message" className="help-contact-form-label">Message</label>
            <textarea
              id="message"
              name="message"
              rows={4}
              value={formData.message}
              onChange={handleChange}
              className="help-contact-form-textarea"
            />
          </div>
        </div>
        <div className="help-contact-form-submit">
          <button type="submit" className="help-contact-form-button">Send message</button>
        </div>
      </form>
    </div>
  );
}

export default HelpContactForm;

