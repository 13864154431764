import React from "react";
import { useLottie } from "lottie-react";
import animationData from "../../Assests/animations/bolt trace.json"; 

const MiniLoader = () => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return <div className="mini-loader">{View}</div>;
};

export default MiniLoader;

