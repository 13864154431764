import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
];

const tiers = [
  {
    name: 'No G',
    id: 'tier-freelancer',
    description: 'The essentials to start your vibe',
    features: ['Social media access'],
    mostPopular: false,
    price: { monthly: 'Free', annually: 'Free' },
    priceId: { monthly: 'price_1POJqdP4OgHr2xe6gmdWszMF', annually: 'price_1POJqdP4OgHr2xe6gmdWszMF' },
  },
  {
    name: 'OG',
    id: 'tier-startup',
    description: 'A boost to create a Buzz',
    features: [
      'Events',
      'Exclusive deals',
      'Advanced analytics',
      '24-hour support response time',
      'Marketing automations',
    ],
    mostPopular: true,
    price: { monthly: '$20', annually: '$200' },
    priceId: { monthly: 'price_1POJWvP4OgHr2xe6Nz9XxDhz', annually: 'price_1POJXbP4OgHr2xe6c3Tw4HzX' },
  },
  {
    name: 'Super OG',
    id: 'tier-enterprise',
    description: 'V.I.P. Access',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      '1-hour, dedicated support response time',
      'Marketing automations',
      'Custom reporting tools',
    ],
    mostPopular: false,
    price: { monthly: '$100', annually: '$1000' },
    priceId: { monthly: 'price_1POJaOP4OgHr2xe6YQYvrKD5', annually: 'price_1POJaeP4OgHr2xe6nTz9J8el' },
  },
];

export default function SubscriptionPricing() {
  const [frequency, setFrequency] = useState(frequencies[0].value);
  const navigate = useNavigate();

  const handleSubscriptionSelection = (priceId, isFree) => {
    if (isFree) {
      // No subscription option selected, redirect to homepage
      navigate("/");
    } else {
      // Proceed with Stripe checkout
      navigate(`/subscription-checkout/${priceId}`);
    }
  };

  return (
    <div className="subscription-pricing-container">
      <div className="subscription-pricing-inner">
        <div className="subscription-pricing-header">
          <h2 className="subscription-pricing-subtitle">Pricing</h2>
          <p className="subscription-pricing-title">It's time to level up!</p>
        </div>
        <p className="subscription-pricing-description">
          Choose an affordable plan that’s packed with the best features for special deals, access to events, and exclusive entertainment.
        </p>
        <div className="subscription-pricing-frequency">
          {frequencies.map((option) => (
            <div
              key={option.value}
              className={`subscription-pricing-frequency-option ${frequency === option.value ? 'active' : ''}`}
              onClick={() => setFrequency(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
        <div className="subscription-pricing-tiers">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={`subscription-pricing-tier ${tier.mostPopular ? 'most-popular' : ''}`}
            >
              <div className="subscription-pricing-tier-header">
                <h3 id={tier.id} className="subscription-pricing-tier-name">
                  {tier.name}
                </h3>
                {tier.mostPopular && (
                  <p className="subscription-pricing-tier-popular">Most popular</p>
                )}
              </div>
              <p className="subscription-pricing-tier-description">{tier.description}</p>
              <p className="subscription-pricing-tier-price">
                <span>{tier.price[frequency]}</span>
                <span className="subscription-pricing-tier-price-suffix">{frequencies.find(f => f.value === frequency)?.priceSuffix}</span>
              </p>
              <button
                onClick={() => handleSubscriptionSelection(tier.priceId[frequency], tier.price[frequency] === 'Free')}
                className="subscription-pricing-subscribe-btn"
              >
                {tier.price[frequency] === 'Free' ? 'Select Free Plan' : 'Subscribe Now'}
              </button>
              <ul className="subscription-pricing-features">
                {tier.features.map((feature) => (
                  <li key={feature} className="subscription-pricing-feature">
                    <span className="subscription-pricing-feature-check">✔</span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}


