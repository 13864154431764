import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../../redux/actions/cart";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";

const ProductDetailsCard = ({ setOpen, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);

  const handleMessageSubmit = () => {};

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < count) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  return (
    <div className="product-details-card-bg">
      {data ? (
        <div className="product-details-card-container">
          <div className="product-details-card-content">
            <RxCross1
              size={30}
              className="product-details-card-close-icon"
              onClick={() => setOpen(false)}
            />

            <div className="product-details-card-flex">
              <div className="product-details-card-image-section">
                <img src={`${data.images && data.images[0]?.url}`} alt="" />
                <div className="product-details-card-shop-details">
                  <Link to={`/shop/${data.shop._id}`} className="product-details-card-shop-link">
                    <img
                      src={`${data.images && data.images[0]?.url}`}
                      alt=""
                      className="product-details-card-shop-avatar"
                    />
                    <div>
                      <h3 className="product-details-card-shop-name">
                        {data.shop.name}
                      </h3>
                      <h5 className="product-details-card-shop-ratings">{data?.ratings} Ratings</h5>
                    </div>
                  </Link>
                </div>
                <div
                  className="product-details-card-message-button"
                  onClick={handleMessageSubmit}
                >
                  <span className="product-details-card-message-button-text">
                    Message <AiOutlineMessage className="ml-1" />
                  </span>
                </div>
                <h5 className="product-details-card-sold-out">(50) Sold out</h5>
              </div>

              <div className="product-details-card-info-section">
                <h1 className="product-details-card-title">
                  {data.name}
                </h1>
                <p>{data.description}</p>

                <div className="product-details-card-pricing">
                  <h4 className="product-details-card-discount-price">
                    ${data.discountPrice}
                  </h4>
                  <h3 className="product-details-card-original-price">
                    {data.originalPrice ? data.originalPrice + "$" : null}
                  </h3>
                </div>
                <div className="product-details-card-quantity-wishlist">
                  <div className="product-details-card-quantity-controls">
                    <button
                      className="product-details-card-decrement"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className="product-details-card-quantity">
                      {count}
                    </span>
                    <button
                      className="product-details-card-increment"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="product-details-card-wishlist-icon"
                        onClick={() => removeFromWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="product-details-card-wishlist-icon"
                        onClick={() => addToWishlistHandler(data)}
                        title="Add to wishlist"
                      />
                    )}
                  </div>
                </div>
                <div
                  className="product-details-card-add-to-cart-button"
                  onClick={() => addToCartHandler(data._id)}
                >
                  <span className="product-details-card-add-to-cart-button-text">
                    Add to cart <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetailsCard;

