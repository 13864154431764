import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ecommerceServer } from '../../server'; 

const USPSRateCalculator = ({ shopId }) => {
  const [destinationZip, setDestinationZip] = useState('');
  const [weightInOunces, setWeightInOunces] = useState('');
  const [dimensions, setDimensions] = useState({ length: '', width: '', height: '' });
  const [rateDetails, setRateDetails] = useState([]); 
  const currentProfileName = sessionStorage.getItem('currentProfile');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${ecommerceServer}/usps/get-usps-rates`,
        {
          shopId,
          destinationZip,
          weightInOunces,
          dimensions,
        },
        {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile',
          },
          withCredentials: true,
        }
      );

      // Extract and store the detailed rate information
      if (response.data && response.data.rates) {
        const rates = response.data.rates;
        setRateDetails(rates);
        toast.success('Shipping rates fetched successfully!');
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      // Extract a meaningful error message
      const errorMessage = error.response?.data?.error || error.message || 'Failed to fetch shipping rates';
      toast.error(errorMessage); // Display the specific error message in the toast
      console.error('Error fetching shipping rates:', error);
    }
  };

  return (
    <div className="USPS-rate-calculator-container">
      <h2>USPS Rate Calculator</h2>
      <form onSubmit={handleSubmit}>
        <div className="USPS-rate-calculator-form-group">
          <label>Destination Zip Code</label>
          <input
            type="text"
            value={destinationZip}
            onChange={(e) => setDestinationZip(e.target.value)}
            required
          />
        </div>
        <div className="USPS-rate-calculator-form-group">
          <label>Weight (in ounces)</label>
          <input
            type="number"
            value={weightInOunces}
            onChange={(e) => setWeightInOunces(e.target.value)}
            required
          />
        </div>
        <div className="USPS-rate-calculator-form-group">
          <label>Dimensions (in inches)</label>
          <input
            type="number"
            placeholder="Length"
            value={dimensions.length}
            onChange={(e) => setDimensions({ ...dimensions, length: e.target.value })}
            required
          />
          <input
            type="number"
            placeholder="Width"
            value={dimensions.width}
            onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
            required
          />
          <input
            type="number"
            placeholder="Height"
            value={dimensions.height}
            onChange={(e) => setDimensions({ ...dimensions, height: e.target.value })}
            required
          />
        </div>
        <button className="USPS-rate-calculator-button" type="submit">
          Get Rates
        </button>
      </form>
      {rateDetails.length > 0 && (
        <div className="USPS-rate-calculator-rate-result">
          <h3>Available Shipping Rates</h3>
          <ul>
            {rateDetails.map((rate, index) => (
              <li key={index} className="USPS-rate-calculator-rate-item">
                <p><strong>Price:</strong> ${rate.price}</p>
                <p><strong>Description:</strong> {rate.description}</p>
                <p><strong>Mail Class:</strong> {rate.mailClass}</p>
                <p><strong>Zone:</strong> {rate.zone}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default USPSRateCalculator;


