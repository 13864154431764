import React from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import AllOrders from '../../components/Shop/AllOrders';

const ShopAllOrders = () => {
  return (
    <div className="shop-all-orders-page-container">
      <DashboardHeader />
      <div className="shop-all-orders-page-content">
        <div className="shop-all-orders-page-sidebar">
          <DashboardSideBar active={2} />
        </div>
        <div className="shop-all-orders-page-main">
          <AllOrders />
        </div>
      </div>
    </div>
  );
};

export default ShopAllOrders;
