import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Country, State } from "country-state-city";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineDelete } from "react-icons/ai";
import { updatUserAddress, deleteUserAddress } from "../../redux/actions/user";

const UserAddress = () => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressType, setAddressType] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const addressTypeData = [
    { name: "Default" },
    { name: "Home" },
    { name: "Office" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (addressType === "" || country === "" || city === "") {
      toast.error("Please fill all the fields!");
    } else {
      dispatch(updatUserAddress(country, city, address1, address2, zipCode, addressType));
      setOpen(false);
      setCountry("");
      setCity("");
      setAddress1("");
      setAddress2("");
      setZipCode("");
      setAddressType("");
    }
  };

  const handleDelete = (item) => {
    dispatch(deleteUserAddress(item._id));
  };

  return (
    <div className="profile-content-component-address">
      {open && (
        <div className="profile-content-component-modal">
          <div className="profile-content-component-modal-content">
            <div className="profile-content-component-modal-close">
              <RxCross1 size={30} onClick={() => setOpen(false)} />
            </div>
            <h1 className="profile-content-component-modal-title">Add New Address</h1>
            <form onSubmit={handleSubmit}>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Country</label>
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="profile-content-component-form-input"
                >
                  <option value="">Choose Your Country</option>
                  {Country.getAllCountries().map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">City</label>
                <select
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="profile-content-component-form-input"
                >
                  <option value="">Choose Your City</option>
                  {State.getStatesOfCountry(country).map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Address 1</label>
                <input
                  type="text"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  className="profile-content-component-form-input"
                  required
                />
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Address 2</label>
                <input
                  type="text"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  className="profile-content-component-form-input"
                />
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Zip Code</label>
                <input
                  type="text"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="profile-content-component-form-input"
                  required
                />
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Address Type</label>
                <select
                  value={addressType}
                  onChange={(e) => setAddressType(e.target.value)}
                  className="profile-content-component-form-input"
                >
                  <option value="">Choose Your Address Type</option>
                  {addressTypeData.map((item) => (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className="profile-content-component-submit-btn">
                Save
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="profile-content-component-address-header">
        <h1 className="profile-content-component-title">My Addresses</h1>
        <button className="profile-content-component-add-btn" onClick={() => setOpen(true)}>
          Add New
        </button>
      </div>
      {user?.addresses.length > 0 ? (
        user.addresses.map((item, index) => (
          <div className="profile-content-component-address-item" key={index}>
            <div>
              <h5>{item.addressType}</h5>
              <p>
                {item.address1}, {item.address2}, {item.city}, {item.zipCode}
              </p>
            </div>
            <AiOutlineDelete size={25} onClick={() => handleDelete(item)} />
          </div>
        ))
      ) : (
        <p className="profile-content-component-no-address">There are no saved addresses.</p>
      )}
    </div>
  );
};

export default UserAddress;
