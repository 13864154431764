import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Link } from 'react-router-dom';
import { server } from "../../server";
import EventCard from './EventCard';
import CreateEventPrompt from './CreateEventPrompt'; // Import the fallback prompt

const EventCarousel = () => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null); // To handle potential errors

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${server}/event/events`);
                setEvents(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching events:', error);
                setError('Failed to load events.');
                setIsLoading(false);
            }
        };

        fetchEvents();
    }, []);

    // Group events into pairs
    const groupEvents = () => {
        const grouped = [];
        for (let i = 0; i < events.length; i += 2) {
            grouped.push(events.slice(i, i + 2));
        }
        return grouped;
    };

    const groupedEvents = groupEvents();

    return (
        <div className="events-carousel-container">
            {isLoading ? (
                <div className="events-carousel-loading">Loading...</div>
            ) : (
                events.length > 0 ? ( 
                    <Carousel
                        indicators={false} 
                        navButtonsAlwaysVisible={true} 
                        autoPlay={false} 
                    >
                        {groupedEvents.map((eventPair, index) => (
                            <div key={index} className="events-carousel-slide flex justify-center space-x-4">
                                {eventPair.map((event) => (
                                    <Link
                                        key={event._id}
                                        to={`/events/${event._id}`}
                                        className="events-carousel-link"
                                    >
                                        <EventCard event={event} />
                                    </Link>
                                ))}
                            </div>
                        ))}
                    </Carousel>
                ) : (
                    // Render the fallback prompt when there are no events
                    <CreateEventPrompt />
                )
            )}
        </div>
    );
};

export default EventCarousel;




