// ExploreBentoGrid.jsx
import React, { useMemo, useState, useEffect, Suspense } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
import Masonry from "react-masonry-css";
import ItemLoader from "./ItemLoader";
import LazyLoadComponent from "../../hooks/LazyLoadComponent"


// Lazy load the card components for code splitting
const ShopCard = React.lazy(() => import("./ShopCard"));
const ProductCard = React.lazy(() => import("./ProductCard"));
const PostCard = React.lazy(() => import("./PostCard"));
const PlaceholderCard = React.lazy(() => import("./PlaceholderCard"));

// Import Ad Components
const DualProductAdCarouselProps = React.lazy(() => import("../Sponsored/DualProductAdCarouselProps"));
const QuadProductAdCarouselProps = React.lazy(() => import("../Sponsored/QuadProductCarouselProps"));
const SponsoredProductCarouselProps = React.lazy(() => import("../Sponsored/SponsoredProductCarouselProps"));
const SponsoredBrandCarouselProps = React.lazy(() => import("../Sponsored/SponsoredBrandCarouselProps"));
const SponsoredShopsCarouselProps = React.lazy(() => import("../Sponsored/SponsoredShopsCarouselProps"));
const TopBannerAdProps = React.lazy(() => import("../Sponsored/TopBannerAdProps"));
const BannerAdProps = React.lazy(() => import("../Sponsored/BannerAdProps"));
// Import additional ad components as needed

const ITEMS_PER_LOAD = 10; 
const AD_INTERVAL = 10; // Insert an ad after every 10 items

const ExploreBentoGrid = ({ shops, products, posts, productAds, shopAds, brandAds, customAds }) => {
  // Prepare shop, product, and post items
  const shopItems = useMemo(
    () =>
      shops.map((shop) => ({
        type: "shop",
        data: shop,
      })),
    [shops]
  );
  const productItems = useMemo(
    () =>
      products.map((product) => ({
        type: "product",
        data: product,
      })),
    [products]
  );
  const postItems = useMemo(
    () =>
      posts.map((post) => ({
        type: "post",
        data: post,
      })),
    [posts]
  );

  // Shuffle shops, products, and posts separately
  const shuffledShops = useMemo(() => shuffleArray(shopItems), [shopItems]);
  const shuffledProducts = useMemo(() => shuffleArray(productItems), [productItems]);
  const shuffledPosts = useMemo(() => shuffleArray(postItems), [postItems]);

  // Combine shops, products, and posts into a single array, interleaving them
  const combinedItems = useMemo(() => {
    const allItems = [];
    const shopsCopy = [...shuffledShops];
    const productsCopy = [...shuffledProducts];
    const postsCopy = [...shuffledPosts];
  
    // While there are items left in any of the categories
    while (shopsCopy.length || productsCopy.length || postsCopy.length) {
      const availableTypes = [];
      if (shopsCopy.length) availableTypes.push("shop");
      if (productsCopy.length) availableTypes.push("product");
      if (postsCopy.length) availableTypes.push("post");
  
      // Randomly select the next type
      const randomType = availableTypes[Math.floor(Math.random() * availableTypes.length)];
  
      // Push the selected type to the combined array
      if (randomType === "shop") {
        allItems.push(shopsCopy.shift());
      } else if (randomType === "product") {
        allItems.push(productsCopy.shift());
      } else if (randomType === "post") {
        allItems.push(postsCopy.shift());
      }
    }
  
    return allItems;
  }, [shuffledShops, shuffledProducts, shuffledPosts]);
  

  // State to manage displayed items
  const [displayedItems, setDisplayedItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  // Load initial items
  useEffect(() => {
    loadMoreItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to load more items
  const loadMoreItems = () => {
    const currentLength = displayedItems.length;
    let nextItems = combinedItems.slice(currentLength, currentLength + ITEMS_PER_LOAD);

    let placeholdersNeeded = 0;
    if (nextItems.length < ITEMS_PER_LOAD) {
      placeholdersNeeded = ITEMS_PER_LOAD - nextItems.length;
      const placeholders = generatePlaceholders(placeholdersNeeded);
      nextItems = [...nextItems, ...placeholders];
      setHasMore(false); // No more real items, only placeholders
    }

    if (nextItems.length > 0) {
      // Determine if an ad should be inserted
      const totalItems = currentLength + nextItems.length;
      const shouldInsertAd = totalItems % AD_INTERVAL === 0 && (productAds.length > 0 || shopAds.length > 0 || brandAds.length > 0 || customAds.length > 0);

      if (shouldInsertAd) {
        // Select which ad to insert based on your criteria
        // For example, rotate between different ad types
        const adType = determineAdType(totalItems);
        const adComponent = getAdComponent(adType);
        if (adComponent) {
          nextItems.push({ type: "ad", component: adComponent });
        }
      }

      setDisplayedItems([...displayedItems, ...nextItems]);
    }
  };

  // Function to determine which ad type to insert
  const determineAdType = (totalItems) => {
    const adTypes = ["dualProduct", "quadProduct", "sponsoredProduct", "sponsoredBrand", "sponsoredShops", "topBanner", "banner"];
    const index = Math.floor(totalItems / AD_INTERVAL) % adTypes.length;
    return adTypes[index];
  };

  // Function to get the corresponding ad component
  const getAdComponent = (adType) => {
    switch (adType) {
      case "dualProduct":
        return <DualProductAdCarouselProps ads={productAds.slice(0, 2)} />;
      case "quadProduct":
        return <QuadProductAdCarouselProps ads={productAds.slice(0, 4)} />;
      case "sponsoredProduct":
        return <SponsoredProductCarouselProps ads={productAds} />;
      case "sponsoredBrand":
        return <SponsoredBrandCarouselProps ads={brandAds} />;
      case "sponsoredShops":
        return <SponsoredShopsCarouselProps ads={shopAds} />;
      case "topBanner":
        return <TopBannerAdProps ad={productAds[0]} />;
      case "banner":
        return <BannerAdProps ad={productAds[1]} />;
      default:
        return null;
    }
  };

  // Function to generate placeholder items
  const generatePlaceholders = (count) => {
    const placeholders = [];
    for (let i = 0; i < count; i++) {
      placeholders.push({ type: "placeholder", data: {} });
    }
    return placeholders;
  };

  return (
    <section className="explore-bento-section">
      <div className="explore-bento-container">
        <h2 className="explore-bento-title">Explore</h2>
        <InfiniteScroll
          dataLength={displayedItems.length}
          next={loadMoreItems}
          hasMore={hasMore}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>No more items to display.</b>
            </p>
          }
        >
          <Suspense
            fallback={
              <div >
                 <ItemLoader />
              </div>
            }
          >
            <Masonry
              breakpointCols={{
                default: 3,
                1200: 3,
                992: 3,
                768: 3,
                576: 2,
                480: 2,
              }}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {displayedItems.map((item, index) => {
              // Handle ad components
              if (item.type === "ad") {
                return (
                  <div
                    key={`ad-${index}`}
                    className="explore-bento-grid-item ad-item"
                  >
                    <LazyLoadComponent>
                      {item.component}
                    </LazyLoadComponent>
                  </div>
                );
              }

              return (
                <div
                  key={index}
                  className={`explore-bento-grid-item ${
                    item.type === "product" ? "product-bento-item" : ""
                  } ${item.type === "post" ? "post-item" : ""} ${
                    item.type === "shop" ? "shop-item" : ""
                  } ${
                    item.type === "placeholder" ? "placeholder-item" : ""
                  }`}
                >
                  <LazyLoadComponent>
                    {item.type === "shop" ? (
                      <ShopCard shop={item.data} />
                    ) : item.type === "product" ? (
                      <ProductCard product={item.data} />
                    ) : item.type === "post" ? (
                      <PostCard post={item.data} />
                    ) : (
                      <PlaceholderCard />
                    )}
                  </LazyLoadComponent>
                </div>
              );
            })}
            </Masonry>
          </Suspense>
        </InfiniteScroll>
      </div>
    </section>
  );
};

// Helper function to shuffle array
function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

ExploreBentoGrid.propTypes = {
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      handle: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        url: PropTypes.string,
      }),
      banner: PropTypes.shape({
        url: PropTypes.string,
      }),
      description: PropTypes.string,
    })
  ).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
      originalPrice: PropTypes.number.isRequired,
      discountPrice: PropTypes.number,
      description: PropTypes.string,
    })
  ).isRequired,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      owner: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
          handle: PropTypes.string,
          avatar: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      ]),
      profileType: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
      link: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
      }),
      createdAt: PropTypes.string,
    })
  ).isRequired,
  productAds: PropTypes.array.isRequired,
  shopAds: PropTypes.array.isRequired,
  brandAds: PropTypes.array.isRequired,
  customAds: PropTypes.array.isRequired,
};

export default ExploreBentoGrid;









