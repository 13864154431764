import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const AdTypeDistributionChart = ({ ads }) => {
    const [chartData, setChartData] = useState({
        labels: ['Product', 'Shop', 'Brand', 'Custom'],
        datasets: [{
            data: [0, 0, 0, 0],
            backgroundColor: [
                'rgba(34, 139, 34, 0.7)',    // Forest Green
                'rgba(50, 205, 50, 0.7)',    // Lime Green
                'rgba(0, 255, 127, 0.7)',    // Spring Green
                'rgba(144, 238, 144, 0.7)',  // Light Green
            ],            
            borderColor: [
                'rgba(34, 139, 34, 1)',      // Forest Green
                'rgba(50, 205, 50, 1)',      // Lime Green
                'rgba(0, 255, 127, 1)',      // Spring Green
                'rgba(144, 238, 144, 1)',    // Light Green
            ],
            borderWidth: 2,
            hoverBackgroundColor: [
                'rgba(34, 139, 34, 1)',      // Forest Green
                'rgba(50, 205, 50, 1)',      // Lime Green
                'rgba(0, 255, 127, 1)',      // Spring Green
                'rgba(144, 238, 144, 1)',    // Light Green
            ],
            hoverBorderColor: [
                'rgba(34, 139, 34, 1)',      // Forest Green
                'rgba(50, 205, 50, 1)',      // Lime Green
                'rgba(0, 255, 127, 1)',      // Spring Green
                'rgba(144, 238, 144, 1)',    // Light Green
            ],
            hoverBorderWidth: 3,
            hoverOffset: 10,  // Adds a zoom effect on hover
            
            hoverBorderWidth: 3,
            hoverOffset: 10, 
        }],
    });

    useEffect(() => {
        const calculateAdTypeDistribution = () => {
            const distribution = { product: 0, shop: 0, brand: 0, custom: 0 };

            ads.forEach(ad => {
                if (ad.product) distribution.product += 1;
                else if (ad.shop && !ad.product && !ad.brand) distribution.shop += 1;
                else if (ad.brand && !ad.product && ad.shop) distribution.brand += 1;
                else distribution.custom += 1;
            });

            const totalAds = ads.length;
            const percentageDistribution = [
                ((distribution.product / totalAds) * 100).toFixed(2),
                ((distribution.shop / totalAds) * 100).toFixed(2),
                ((distribution.brand / totalAds) * 100).toFixed(2),
                ((distribution.custom / totalAds) * 100).toFixed(2),
            ];

            setChartData(prevData => ({
                ...prevData,
                datasets: [{
                    ...prevData.datasets[0],
                    data: percentageDistribution,
                }],
            }));
        };

        calculateAdTypeDistribution();
    }, [ads]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#00f7ff', // Neon cyan color for labels
                    font: {
                        size: 14,
                        family: "'Orbitron', sans-serif", // Futuristic font
                    },
                },
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark background for tooltip
                titleFont: {
                    size: 16,
                    family: "'Orbitron', sans-serif",
                },
                bodyFont: {
                    size: 14,
                    family: "'Orbitron', sans-serif",
                },
                callbacks: {
                    label: (context) => `${context.label}: ${context.raw}%`,
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                bottom: 20,
            },
        },
        elements: {
            arc: {
                borderWidth: 2,
                borderColor: 'rgba(0, 0, 0, 0.5)', // Adds a subtle border around segments
            },
        },
    };

    return (
        <div className="ad-type-distribution-container">
            <h3 className="ad-type-distribution-title">Ad Type Distribution</h3>
            <Pie data={chartData} options={options} />
        </div>
    );
};

export default AdTypeDistributionChart;

