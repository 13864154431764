import React from 'react';

const StudiosDropdown = () => {
  const studioSections = [
    {
      title: "Studio Rentals",
      description: "Book our professional music studio for your next project.",
      icon: "🎧",
    },
    {
      title: "Upcoming Events",
      description: "See what's happening at our studio, from workshops to live sessions.",
      icon: "🎤",
    },
    {
      title: "Equipment Available",
      description: "Explore the high-quality gear we provide with rentals.",
      icon: "🎸",
    },
    {
      title: "Book a Session",
      description: "Easily schedule your time in the studio today.",
      icon: "📅",
    },
  ];

  const images = [
    'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/0795415a-1f40-4e99-6da7-67274df92800/public',
    'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/551c3678-7076-4ecc-9a8d-1191d8e43300/public',
    'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/54bf9c89-6f5d-4521-8c60-424fddadaa00/public',
    'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/8526f5fe-fbf8-40a6-df36-e65a1bc0ed00/public',
  ];

  return (
    <div className="studios-dropdown-container">
      <div className="studios-dropdown-inner">
        {/* Image Collage Scroller */}
        <div className="studios-dropdown-image-scroller">
          {images.map((image, index) => (
            <div key={index} className="studios-dropdown-image-item">
              <img src={image} alt={`Studio ${index + 1}`} />
            </div>
          ))}
        </div>

        <h3 className="studios-dropdown-title">Our Studios</h3>

        {/* Studio Sections */}
        <div className="studios-dropdown-sections">
          {studioSections.map((section, index) => (
            <div key={index} className="studios-dropdown-section">
              <div className="studios-dropdown-section-icon">
                {section.icon}
              </div>
              <div>
                <h4 className="studios-dropdown-section-title">{section.title}</h4>
                <p className="studios-dropdown-section-description">{section.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudiosDropdown;

