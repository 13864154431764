import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';

import TagSelectionGuide from '../../components/Documentation/TagSelectionGuide';

const TagSelectionDocs = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <TagSelectionGuide />
    </div>
  );
};

export default TagSelectionDocs;
