import React from 'react';


const StreamsDropdown = () => {
  // Mock data for demonstration purposes
  const featuredStream = {
    title: "BuzzVibe Studio Jam - Live Now!",
    streamer: "BuzzVibe Studios",
    viewers: 12500,
    videoUrl: "https://example.com/featured-stream",
    thumbnailUrl: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/6076cf06-8b1b-4dbd-8dfe-9de5f4255400/public",
  };

  const activeStreamers = [
    {
      name: "Blk Stripes",
      game: "Paint with me",
      viewers: 8000,
      thumbnailUrl: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/9e53b23a-f954-4f6b-e0dc-56b0d98f9f00/public",
    },
    {
      name: "DopeKicks",
      game: "Unboxing",
      viewers: 500,
      thumbnailUrl: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/cfdf4949-584a-442d-3f2a-71a8be79d200/public",
    },
    // Add more streamer objects as needed
  ];

  const trendingGames = [
    {
      name: "Creative",
      viewers: 90000,
      thumbnailUrl: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/651f8ef8-4a59-49d8-6215-e07e5e20aa00/public",
    },
    {
      name: "Music & Djs",
      viewers: 85000,
      thumbnailUrl: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/4b346545-1a8f-4535-5ee2-2b0137460b00/public",
    },
    // Add more game objects as needed
  ];

  return (
    <div className="streaming-dropdown-container">
      {/* Featured Stream Section */}
      <div className="streaming-dropdown-featured-stream">
        <div className="streaming-dropdown-video-player">
          {/* Replace img with an actual video player component */}
          <img src={featuredStream.thumbnailUrl} alt={featuredStream.title} />
        </div>
        <div className="streaming-dropdown-stream-info">
          <h2>{featuredStream.title}</h2>
          <p>by {featuredStream.streamer}</p>
          <p>{featuredStream.viewers.toLocaleString()} viewers</p>
        </div>
      </div>

      {/* Active Streamers Section */}
      <div className="streaming-dropdown-section">
        <h3>Active Streamers</h3>
        <div className="streaming-dropdown-grid">
          {activeStreamers.map((streamer, index) => (
            <div key={index} className="streaming-dropdown-card">
              <img src={streamer.thumbnailUrl} alt={streamer.name} />
              <div className="streaming-dropdown-card-info">
                <p className="streaming-dropdown-name">{streamer.name}</p>
                <p className="streaming-dropdown-details">{streamer.game}</p>
                <p className="streaming-dropdown-details">{streamer.viewers.toLocaleString()} viewers</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Trending Games Section */}
      <div className="streaming-dropdown-section">
        <h3>Trending Genres</h3>
        <div className="streaming-dropdown-grid">
          {trendingGames.map((game, index) => (
            <div key={index} className="streaming-dropdown-card">
              <img src={game.thumbnailUrl} alt={game.name} />
              <div className="streaming-dropdown-card-info">
                <p className="streaming-dropdown-name">{game.name}</p>
                <p className="streaming-dropdown-details">{game.viewers.toLocaleString()} viewers</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Additional sections like Upcoming Events can be added here */}
    </div>
  );
};

export default StreamsDropdown;
