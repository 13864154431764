import React from 'react'
import ShopSubscriptionPricing from '../../components/Subscription/ShopSubscriptionPricing';
import OnboardingSteps from '../../components/Shop/OnboardingSteps';
import useWindowSize from "../../hooks/useWindowSize";
import OnboardingStepsMobile from "../../components/Shop/OnboardingStepsMobile";

const ShopSubscriptionPage = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768; 
  return (
    <div>
      {isMobile ? (
      <OnboardingStepsMobile currentStep={5} />
    ) : (
      <OnboardingSteps currentStep={5} />
    )}
        <ShopSubscriptionPricing />
    </div>
  )
}

export default ShopSubscriptionPage;