import { createReducer } from "@reduxjs/toolkit";

const getCurrentProfile = () => sessionStorage.getItem('currentProfile') || 'User';

const getInitialCartState = () => {
  const profileType = getCurrentProfile();
  const cartData = localStorage.getItem(`cartItems_${profileType}`);
  return {
    carts: {
      [profileType]: cartData ? JSON.parse(cartData) : []
    }
  };
};

const initialState = getInitialCartState();

export const cartReducer = createReducer(initialState, {
  addTocart: (state, action) => {
    state.carts[action.profileType] = action.payload;  
  },

  removeFromCart: (state, action) => {
    const { profileType, payload } = action;
    state.carts[profileType] = payload; // Set the cart directly to the updated payload
  },

  clearCart: (state, action) => {
    state.carts[action.profileType] = [];
  },
});


