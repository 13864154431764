import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';
import SKUExplanation from '../../components/Documentation/SKUExplanation';

const SKUExplanationPage = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <SKUExplanation />
    </div>
  );
};

export default SKUExplanationPage;
