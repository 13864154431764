import React from 'react';
import DocumentationSidebar from '../../components/Documentation/DocumentationSidebar';
import BrandCatalogItemCreationGuide from '../../components/Documentation/BrandCatalogItemCreationGuide';

const BrandCatalogItemDocPage = () => {
  return (
    <div className="create-product-doc-page">
      <DocumentationSidebar />
      <BrandCatalogItemCreationGuide />
    </div>
  );
};

export default BrandCatalogItemDocPage;