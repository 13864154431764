export default function SignupCTA() {
  return (
    <div className="signup-cta-container">
      <div className="signup-cta-inner">
        <div className="signup-cta-content">
          <div className="signup-cta-logo-wrapper">
            <div className="signup-cta-info">
              <div className="signup-cta-promo">
                Want to sell your products on our platform?{' '}
                <a href="#" className="signup-cta-read-more">
                  <span className="signup-cta-hidden-span" aria-hidden="true" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
            <h1 className="signup-cta-title">
              Join The BuzzVibe Tribe!
            </h1>
            <p className="signup-cta-description">
              Get access to a vibrant community and curated products not available anywhere else
            </p>
            <div className="signup-cta-actions">
              <a
                href="/signup"
                className="signup-cta-get-started"
              >
                Get started
              </a>
              <a href="#" className="signup-cta-learn-more">
                Learn more <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
        <div className="signup-cta-image-wrapper">
          <img
            className="signup-cta-image"
            src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/20f4d6ec-c919-4b7d-c5c0-512545ead400/public"
            alt="BuzzVibe Sign Up"
          />
        </div>
      </div>
    </div>
  );
}