import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import debounce from "lodash.debounce";
import axios from "axios";
import { server } from "../../server";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [shops, setShops] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const searchBoxRef = useRef(null);
  const searchCache = useRef({});

  // Debounced search function to improve performance
  const performSearch = useCallback(
    debounce(async (term) => {
      if (term.trim() === "") {
        setProducts([]);
        setUsers([]);
        setShops([]);
        setDropdownVisible(false);
        return;
      }

      // Check cache first
      if (searchCache.current[term]) {
        const cachedData = searchCache.current[term];
        setProducts(cachedData.products);
        setUsers(cachedData.users);
        setShops(cachedData.shops);
        setDropdownVisible(true);
        return;
      }

      try {
        const response = await axios.get(`${server}/search/search`, {
          params: { q: term },
        });

        if (response.data.success) {
          const { products, users, shops } = response.data;
          // Cache the results
          searchCache.current[term] = { products, users, shops };
          setProducts(products);
          setUsers(users);
          setShops(shops);
          setDropdownVisible(true);
        } else {
          setProducts([]);
          setUsers([]);
          setShops([]);
          setDropdownVisible(false);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setProducts([]);
        setUsers([]);
        setShops([]);
        setDropdownVisible(false);
      }
    }, 300), // 300ms debounce delay
    []
  );

  useEffect(() => {
    performSearch(searchTerm);
    // Cancel debounce on unmount
    return performSearch.cancel;
  }, [searchTerm, performSearch]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setProducts([]);
    setUsers([]);
    setShops([]);
    setDropdownVisible(false);
  };

  const handleClickOutside = (e) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(e.target)) {
      setDropdownVisible(false); // Only hide the dropdown
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="search-bar-container" ref={searchBoxRef}>
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleChange}
        className="search-bar-input"
        aria-label="Search Products, Users, Shops"
        onFocus={() => {
          if (searchTerm.trim() !== "" && (products.length > 0 || users.length > 0 || shops.length > 0)) {
            setDropdownVisible(true);
          }
        }}
      />
      <AiOutlineSearch size={20} className="search-bar-icon" />
      {searchTerm && (
        <AiOutlineClose
          size={18}
          className="search-bar-clear-icon"
          onClick={clearSearch}
        />
      )}
      {isDropdownVisible && (
        <div className="search-bar-dropdown">
          <div className="search-bar-left-column">
            {products.length > 0 && (
              <div className="search-bar-section">
                <h2 className="search-bar-section-title">Products</h2>
                {products.map((product) => (
                  <Link to={`/product/${product._id}`} key={product._id} onClick={clearSearch}>
                    <div className="search-bar-item">
                      <img
                        src={`${product.images[0]?.url}`}
                        alt={product.name}
                        className="search-bar-item-img"
                        loading="lazy"
                      />
                      <div className="search-bar-item-info">
                        <h1 className="search-bar-item-title">{product.name}</h1>
                        <div className="search-bar-item-details">
                          <span className="search-bar-item-brand">Brand: {product.brand}</span>
                          <span className="search-bar-item-shop">Shop: {product.shop.name}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
          <div className="search-bar-right-column">
            {users.length > 0 && (
              <div className="search-bar-section">
                <h2 className="search-bar-section-title">Users</h2>
                {users.map((user) => (
                  <Link to={`/profile/${user._id}`} key={user._id} onClick={clearSearch}>
                    <div className="search-bar-item">
                      <img
                        src={user.avatar?.url || 'https://via.placeholder.com/50'}
                        alt={user.name}
                        className="search-bar-item-img"
                        loading="lazy"
                      />
                      <div className="search-bar-item-info">
                        <h1 className="search-bar-item-title">{user.name}</h1>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
            {shops.length > 0 && (
              <div className="search-bar-section">
                <h2 className="search-bar-section-title">Shops</h2>
                {shops.map((shop) => (
                  <Link to={`/shop/${shop.handle}`} key={shop._id} onClick={clearSearch}>
                    <div className="search-bar-item">
                      <img
                        src={shop.avatar?.url || 'https://via.placeholder.com/50'}
                        alt={shop.name}
                        className="search-bar-item-img"
                        loading="lazy"
                      />
                      <div className="search-bar-item-info">
                        <h1 className="search-bar-item-title">{shop.name}</h1>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {isDropdownVisible && products.length === 0 && users.length === 0 && shops.length === 0 && searchTerm.trim() !== "" && (
        <div className="search-bar-dropdown">
          <div className="search-bar-no-results">No results found.</div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;


