import React, { useState } from 'react';
import Webcam from 'react-webcam';

const MembershipPersonalData = ({ nextStep, handleChange, formData }) => {
    const [error, setError] = useState('');
    const [takingPhoto, setTakingPhoto] = useState(false);
    const webcamRef = React.useRef(null);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleNext = () => {
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.dob) {
            setError('Please fill out all fields.');
        } else if (!isValidEmail(formData.email)) {
            setError('Please enter a valid email address.');
        } else {
            setError('');
            nextStep();
        }
    };

    const capturePhoto = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        handleChange('photo')({ target: { value: imageSrc } });
        setTakingPhoto(false);
    };

    return (
        <div className="membership-personal-data-container">
            <div className="membership-personal-data-content">
                <h2 className="membership-personal-data-title">About You</h2>
                <div className="membership-personal-data-field">
                    <label>First Name</label>
                    <input
                        type="text"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={(e) => handleChange('firstName')(e)}
                    />
                </div>
                <div className="membership-personal-data-field">
                    <label>Last Name</label>
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={(e) => handleChange('lastName')(e)}
                    />
                </div>
                <div className="membership-personal-data-field">
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) => handleChange('email')(e)}
                    />
                </div>
                <div className="membership-personal-data-field">
                    <label>Date of Birth</label>
                    <input
                        type="date"
                        placeholder="Date of Birth"
                        value={formData.dob}
                        onChange={(e) => handleChange('dob')(e)}
                    />
                </div>
                <div className="membership-personal-data-field">
                    <label>Photo</label>
                    <button onClick={() => setTakingPhoto(true)} className="membership-personal-data-button">
                        {formData.photo ? "Retake Selfie" : "Take Selfie"}
                    </button>
                    {formData.photo && <img src={formData.photo} alt="Member" className="membership-personal-data-photo" />}
                </div>
                {takingPhoto && (
                    <div className="membership-personal-data-webcam">
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                        />
                        <button onClick={capturePhoto} className="membership-personal-data-button">
                            Capture
                        </button>
                    </div>
                )}
                {error && <p className="membership-personal-data-error">{error}</p>}
                <div className="membership-personal-data-buttons">
                    <button onClick={handleNext} className="membership-personal-data-button">Next</button>
                </div>
            </div>
        </div>
    );
};

export default MembershipPersonalData;

