import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { AiOutlineArrowRight } from "react-icons/ai";


const AllOrders = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllOrdersOfShop(seller._id));
    }
  }, [dispatch, seller._id]);

  const rows = orders ? orders.map((item) => {
    const total = item.cart.reduce((acc, curr) => acc + (curr.unitAmount / 100) * curr.quantity, 0);
    return {
      id: item._id,
      itemsQty: item.cart.length,
      total: `US$ ${total.toFixed(2)}`,
      status: item.status,
      paymentStatus: item.paymentInfo.status,
      date: new Date(item.createdAt).toLocaleDateString("en-US"),
    };
  }) : [];

  const getRowClassName = (paymentStatus) => {
    return paymentStatus === 'Pending' ? 'shop-all-orders-pending-row' : '';
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="shop-all-orders-container">
          <div className="shop-all-orders-header">
            <h1 className="shop-all-orders-title">All Orders</h1>
          </div>

          {/* Desktop Table */}
          <div className="shop-all-orders-table desktop-only">
            <table className="table">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Status</th>
                  <th>Items Qty</th>
                  <th>Total</th>
                  <th>Payment Status</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.id} className={getRowClassName(row.paymentStatus)}>
                    <td>{row.id}</td>
                    <td className={row.status === "Delivered" ? "greenColor" : "redColor"}>{row.status}</td>
                    <td>{row.itemsQty}</td>
                    <td>{row.total}</td>
                    <td className={row.paymentStatus === "Pending" ? "yellowColor" : "greenColor"}>{row.paymentStatus}</td>
                    <td>{row.date}</td>
                    <td className="shop-all-orders-actions">
                      <Link to={`/order/${row.id}`}>
                        <AiOutlineArrowRight size={20} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Mobile List */}
          <ul className="shop-all-orders-mobile-list mobile-only">
            {rows.map((row) => (
              <li key={row.id} className="shop-all-orders-mobile-list-item">
                <div className="shop-all-orders-mobile-content">
                  <div className="shop-all-orders-mobile-header">
                    <h3>Order #{row.id}</h3>
                    <p>{row.date}</p>
                  </div>
                  <p>Status: <span className={row.status === "Delivered" ? "greenColor" : "redColor"}>{row.status}</span></p>
                  <p>Items: {row.itemsQty}</p>
                  <p>Total: {row.total}</p>
                  <p>Payment Status: <span className={row.paymentStatus === "Pending" ? "yellowColor" : "greenColor"}>{row.paymentStatus}</span></p>
                </div>
                <div className="shop-all-orders-mobile-action">
                  <Link to={`/order/${row.id}`}>
                    <AiOutlineArrowRight size={20} />
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default AllOrders;




