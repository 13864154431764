import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchConnections } from "../../redux/actions/connections";
import OtherFollowers from './OtherFollowers';
import OtherFollowing from './OtherFollowing';

export default function OtherConnectionsWidget({ userId, profileType }) {
  const dispatch = useDispatch();

  // Extract followers and following state along with their loading states and fetched flags
  const {
    followers,
    following,
    isLoadingFollowers,
    isLoadingFollowing,
    hasFetchedFollowers,
    hasFetchedFollowing,
    error,
  } = useSelector((state) => state.connections);

  // Fetch the connections when the component mounts or when userId/profileType changes
  useEffect(() => {
    if (userId && profileType) {
      if (!hasFetchedFollowers) {
        dispatch(fetchConnections(userId, profileType, 'followers'));
      }
      if (!hasFetchedFollowing) {
        dispatch(fetchConnections(userId, profileType, 'following'));
      }
    }
  }, [dispatch, userId, profileType, hasFetchedFollowers, hasFetchedFollowing]);

  // State for the active tab (followers or following)
  const [activeTab, setActiveTab] = useState('Followers');
  const tabs = [
    { name: 'Followers', current: activeTab === 'Followers' },
    { name: 'Following', current: activeTab === 'Following' },
  ];

  // Determine the data and loading state based on the active tab
  const data = activeTab === 'Followers' ? followers : following;
  const isLoadingData = activeTab === 'Followers' ? isLoadingFollowers : isLoadingFollowing;

  return (
    <div className="connections-widget">
      <div className="connections-widget-container">
        <div className="connections-widget-header">
          <h1>Social Connections</h1>
        </div>
        <div className="connections-widget-tabs">
          <nav aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={`connections-widget-tab ${tab.current ? 'active' : ''}`}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => setActiveTab(tab.name)}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
        {activeTab === 'Followers' ? (
          <OtherFollowers
            key={userId}
            followers={followers}
            isLoading={isLoadingFollowers}
            error={error}
          />
        ) : null}
        {activeTab === 'Following' ? (
          <OtherFollowing
            key={userId}
            following={following}
            isLoading={isLoadingFollowing}
            error={error}
          />
        ) : null}
      </div>
    </div>
  );
}


