import React from 'react';
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import Footer from '../components/Layout/Footer';


const PrivacyPolicy = () => {
  return (
    <>
    <Header />
    <Sidebar />
    <div className="legal-docs-container">
      <h1 className="legal-docs-title">PRIVACY POLICY</h1>
      <p className="legal-docs-text">Effective Date: May [1], 2024.</p>

      <p className="legal-docs-text">
        BuzzVibe LLC (“BuzzVibe,” “we,” “us,” and “our”) operates an online marketplace curated for buyers and sellers of unique merchandise. This Privacy Policy governs how we treat your personal data in connection with your use of our platform (the “Platform”), including websites, applications, products, and services that we provide (collectively, the “Services”).
      </p>

      <p className="legal-docs-text">
        Your use of the Platform and/or Services is at all times subject to our Terms of Service, which incorporates this Privacy Policy. Any terms we use in this Privacy Policy without defining them have the definitions given to them in the Terms of Service.
      </p>

      <p className="legal-docs-text">
        If you have a disability, you may access this Privacy Policy in an alternative format by contacting <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a>.
      </p>

      <h2 className="legal-docs-subtitle">WHAT THIS POLICY COVERS</h2>

      <p className="legal-docs-text">
        This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. “Personal Data” means any information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable information” or “personal information” under applicable data privacy laws, rules, or regulations. This Privacy Policy does not cover the practices of entities we do not own or control or individuals we do not manage.
      </p>

      <h2 className="legal-docs-subtitle">Acknowledgement and Consent</h2>

      <p className="legal-docs-text">
        By using or accessing the Platform and/or Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you consent that we can collect, use, and share your Personal Data and information in accordance with this Privacy Policy.
      </p>

      <h2 className="legal-docs-subtitle">TYPES OF PERSONAL DATA WE COLLECT</h2>

      <p className="legal-docs-text">
        The following chart details the categories of Personal Data that we collect and have collected over the past twelve (12) months, including examples and categories of third parties with whom the Personal Data may be shared.
      </p>

      <div className="legal-docs-table-container">
        <table className="legal-docs-table">
          <thead>
            <tr>
              <th>Category of Personal Data</th>
              <th>Examples of Personal Data We Collect</th>
              <th>Categories of Third Parties With Whom We Share this Personal Data</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Profile or Contact Data</td>
              <td>
                First and last name, Alias, Postal address, Email address, Photos, Phone number, Unique identifiers like passwords
              </td>
              <td>
                Service providers, Advertising partners, Business partners, Select partners, Parties you authorize, access or authenticate
              </td>
            </tr>
            <tr>
              <td>Payment Data</td>
              <td>
                Payment card type, Last 4 digits of the payment card, Billing address, phone number, and email, PayPal ID/Email
              </td>
              <td>
                Services providers (specifically our payment processing partners, including Stripe, Inc.)
              </td>
            </tr>
            <tr>
              <td>Commercial Data</td>
              <td>
                Purchase history, Consumer profiles, Records of products or services purchased on the Platform
              </td>
              <td>
                Service providers, Advertising partners, Business partners, Select partners, Parties you authorize, access or authenticate
              </td>
            </tr>
            <tr>
              <td>Devices/IP Data</td>
              <td>
                IP address, Device ID, Domain server, Type of device / operating system/browser used to access the Services
              </td>
              <td>
                Service providers, Advertising partners, Business partners, Select partners, Parties you authorize, access or authenticate
              </td>
            </tr>
            <tr>
              <td>Web Analytics</td>
              <td>
                Web page interactions, Referring webpage/source through which you accessed the Platform, Non-identifiable request IDs, Statistics associated with the interaction between device or browser and the Platform
              </td>
              <td>
                Service providers, Advertising partners, Business partners, Select partners, Parties you authorize, access or authenticate
              </td>
            </tr>
            <tr>
              <td>Social Network Data</td>
              <td>
                Email, Phone number, User name, IP address, Device ID
              </td>
              <td>
                Service providers, Advertising partners, Business partners, Select partners, Parties you authorize, access or authenticate
              </td>
            </tr>
            <tr>
              <td>Consumer Demographic Data</td>
              <td>
                Zip Code, In some cases, age, gender, country of birth, nationality, country of residence, employment status, family status, interests and preference
              </td>
              <td>
                Service providers, Advertising partners, Business partners, Select partners, Parties you authorize, access or authenticate
              </td>
            </tr>
            <tr>
              <td>Geolocation Data</td>
              <td>
                IP-address-based location information
              </td>
              <td>
                Service providers, Advertising partners, Business partners, Select partners, Parties you authorize, access or authenticate
              </td>
            </tr>
            <tr>
              <td>Inferences Drawn From Other Personal Information</td>
              <td>
                Profiles reflecting user attributes or preferences, Profiles reflecting user behavior
              </td>
              <td>
                Service providers, Advertising partners, Business partners, Select partners, Parties you authorize, access or authenticate
              </td>
            </tr>
            <tr>
              <td>Job Applicant Information</td>
              <td>
                First and last name, Postal address, Email address, Phone number, Social security number, Employment history
              </td>
              <td>
                Service providers, Parties you authorize, access or authenticate
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 className="legal-docs-subtitle">SOURCES OF PERSONAL DATA</h2>

      <h3 className="legal-docs-subsubtitle">Directly from You</h3>
      <p className="legal-docs-text">
        We collect Personal Data about you when you provide information directly to us by doing any of the following:
      </p>
      <ul className="legal-docs-list">
        <li>Creating an account or using our interactive tools and Services.</li>
        <li>Voluntarily providing information in free-form text boxes through the Services or through responses to surveys or questionnaires.</li>
        <li>Sending us an email or otherwise contacting us.</li>
      </ul>

      <h3 className="legal-docs-subsubtitle">Your Use of the Platform</h3>
      <p className="legal-docs-text">
        When you use the Services, Personal Data is collected automatically in the following ways:
      </p>
      <ul className="legal-docs-list">
        <li>Through Cookies (defined in the “Tracking Tools, Advertising, and Opt-Out” section below).</li>
        <li>If you download our mobile application or use a location-enabled browser, we may receive information about your location and mobile device, as applicable.</li>
        <li>If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.</li>
      </ul>

      <h3 className="legal-docs-subsubtitle">Other Users and/or Individuals</h3>
      <p className="legal-docs-text">
        We may contact you in connection with interactions and/or transactions with other Users (as defined in our Terms of Service) and/or individuals. You must have permission from other Users and/or individuals before you share their contact information with us. If you do not have permission to share another User’s or individual’s information with us, then you must not submit any such information to BuzzVibe.
      </p>

      <h3 className="legal-docs-subsubtitle">Third parties</h3>
      <p className="legal-docs-text">
        We may also obtain Personal Data from the following third parties:
      </p>
      <ul className="legal-docs-list">
        <li>
          <strong>Vendors:</strong> We may use analytics service providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support. We may use vendors to obtain information to generate leads and create user profiles.
        </li>
        <li>
          <strong>Advertising and marketing partners:</strong> We receive information about you from some of our vendors who assist us with marketing or promotional services. This may include information related to how you interact with our Platform, Services, advertisements, or communications. Additionally, we may receive contact information and certain commercial information from data co-ops and other third party data aggregators.
        </li>
      </ul>

      <h3 className="legal-docs-subsubtitle">Social Networks</h3>
      <p className="legal-docs-text">
        If you provide your social network account credentials to us or otherwise sign in to the Platform through a third-party site or service, some content and/or information in those accounts may be transmitted into your account with us, for example, your name and email address, and additional information that may be necessary to authenticate your access to the Platform in the future.
      </p>

      <h2 className="legal-docs-subtitle">Commercial or Business Purposes for Collecting Personal Data</h2>

      <p className="legal-docs-text">
        We collect your Personal Data for commercial and business purposes in order to do all of the following things.
      </p>

      <h3 className="legal-docs-subsubtitle">Providing, Customizing, and Improving the Services</h3>
      <ul className="legal-docs-list">
        <li>Creating and managing your account or other User profiles.</li>
        <li>Processing orders or other transactions, including billing.</li>
        <li>Providing you with the products, services or information you request.</li>
        <li>Meeting or fulfilling the reason you provided the information to us.</li>
        <li>Providing support and assistance for the Platform and/or Services.</li>
        <li>Improving the Services, including testing, research, internal analytics, and product development.</li>
        <li>Personalize the Services, website content, and communications based on your preferences.</li>
        <li>Doing fraud protection, security, and debugging.</li>
        <li>Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act (the “CCPA”).</li>
      </ul>

      <h3 className="legal-docs-subsubtitle">Marketing the Services</h3>
      <ul className="legal-docs-list">
        <li>Marketing and promotions the Platform and Services, including sending promotional materials by email or other means.</li>
        <li>Displaying advertisements, including interest-based or online behavioral advertising.</li>
      </ul>

      <h3 className="legal-docs-subsubtitle">Correspondence</h3>
      <ul className="legal-docs-list">
        <li>Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about BuzzVibe, the Platform, or Services.</li>
        <li>Sending emails and other communications according to your preferences or that display content that we think may interest you.</li>
        <li>
          If you opt to have promotional offers from us sent to your mobile device, we may send you SMS, MMS, or other text messages (“Text Message”) regarding such promotional offers to the telephone number you provided to us. We may collect information regarding such communications, such as confirmation when you read a Text Message, and use such information to operate and improve our Platform and/or Services, including our customer service, or as otherwise permitted by this Privacy Policy. To opt out of receiving promotional Text Messages from us, reply “STOP” to any such message.
        </li>
      </ul>

      <h3 className="legal-docs-subsubtitle">Meeting Legal Requirements and Enforcing Legal Terms</h3>
      <ul className="legal-docs-list">
        <li>Fulfilling our legal obligations under applicable law, regulation, court order, or other legal processes, such as preventing, detecting, and investigating security incidents and potentially illegal or prohibited activities.</li>
        <li>Protecting the rights, property, or safety of you, BuzzVibe or another party.</li>
        <li>Enforcing any agreements with you.</li>
        <li>Responding to claims that any posting or other content violates third-party rights.</li>
        <li>Resolving disputes.</li>
      </ul>

      <p className="legal-docs-text">
        We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated, or incompatible purposes without providing you notice.
      </p>

      <h2 className="legal-docs-subtitle">How Your Personal Data is Shared</h2>

      <p className="legal-docs-text">
        We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a “sale” of your Personal Data. For more information, please refer to the state-specific sections below.
      </p>

      <h3 className="legal-docs-subsubtitle">With Service Providers</h3>
      <p className="legal-docs-text">
        These parties help us provide the Platform and/or Services or perform business functions on our behalf. They include:
      </p>
      <ul className="legal-docs-list">
        <li>Hosting, technology, and communication providers.</li>
        <li>Security and fraud prevention consultants.</li>
        <li>Analytics providers.</li>
        <li>Support and customer service vendors.</li>
        <li>Product fulfillment and delivery providers.</li>
        <li>
          Payment processors. Our payment processing partner, Stripe, Inc. (“Stripe”) collects your voluntarily-provided payment card information necessary to process your payment. Please note that your payment card details are not shared with us by our providers. Please see Stripe’s <a href="https://stripe.com/privacy" className="legal-docs-link">Terms of Service</a> and <a href="https://stripe.com/privacy" className="legal-docs-link">Privacy Policy</a> for information on their use and storage of your Personal Data.
        </li>
      </ul>

      <h3 className="legal-docs-subsubtitle">With Advertising Partners and Select Partners</h3>
      <p className="legal-docs-text">
        These parties help us market our services and provide you with other offers that may be of interest to you. They include:
      </p>
      <ul className="legal-docs-list">
        <li>Ad networks.</li>
        <li>Data brokers and marketing cooperatives.</li>
        <li>Marketing providers.</li>
      </ul>
      <p className="legal-docs-text">
        For example, we may disclose information to certain advertising partners, companies that perform advertising-related services, data brokers and marketing cooperatives (our “Select Partners”) in order to provide you with more relevant advertising tailored to your interests. This information may include identifiers such as your IP address, advertising identifiers, your email address, your age and gender, your internet or other electronic network information such as your interaction with an ad, geolocation data, and commercial information such as your purchase history. We may combine information you give us online or through our catalogs with publicly available information and information we receive from or cross-reference with our Select Partners in order to personalize the Services and communicate with you about offers that may be of interest to you. Additionally, we may rent, exchange, share and/or cross-reference information, including contact information about you, with our data broker and marketing cooperative partners in a manner that will enable such persons to send you promotional materials and catalogs for other products and services that may be of interest to you.
      </p>

      <h3 className="legal-docs-subsubtitle">With Business Partners</h3>
      <p className="legal-docs-text">
        These parties partner with us in offering various services. They include:
      </p>
      <ul className="legal-docs-list">
        <li>Businesses that you have a relationship with.</li>
        <li>Companies that we partner with to offer joint promotional offers or opportunities.</li>
      </ul>

      <h3 className="legal-docs-subsubtitle">With Parties You Authorize, Access, or Authenticate</h3>
      <p className="legal-docs-text">
        Third parties you access through the services.
      </p>
      <ul className="legal-docs-list">
        <li>Social media services.</li>
      </ul>

      <h3 className="legal-docs-subsubtitle">Other Users</h3>
      <p className="legal-docs-text">
        When you make a purchase, we share your shipping information with the Seller (as defined in the Terms of Service). Without express consent, Sellers should only contact Users and use their Personal Data for BuzzVibe-related communications and for completing the transaction for which the information was provided. Sellers should not use this information to distribute unsolicited commercial messages. If you send messages to other Users, your name (first name, last initial), username and the content of such messages will be available to such other Users, and if you publicly post any content on the Platform, such content, your name (first name, last initial) and your username will be publicly available.
      </p>

      <h3 className="legal-docs-subsubtitle">To Fulfill Legal Obligations</h3>
      <p className="legal-docs-text">
        We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Commercial or Business Purposes for Collecting Personal Data” sections in this Privacy Policy.
      </p>

      <h3 className="legal-docs-subsubtitle">In Connection with Business Transfers</h3>
      <p className="legal-docs-text">
        All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.
      </p>

      <h3 className="legal-docs-subsubtitle">Data that is Not Personal Data</h3>
      <p className="legal-docs-text">
        We may create aggregated, de-identified, or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified, or anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve the Platform and/or Services and promote our business, provided that we will not share such data in a manner that could identify you.
      </p>

      <h2 className="legal-docs-subtitle">TRACKING TOOLS, ADVERTISING, AND OPT-OUT</h2>

      <p className="legal-docs-text">
        The Platform uses tracking tools, including cookies and similar technologies such as pixel tags, web beacons, clear GIFs, and JavaScript (collectively, “Cookies”) to enable our servers to recognize you/your web browser and tell us how and when you visit and use our Platform, to analyze trends, learn about our user base and operate and improve our Platform and/or Services. Cookies are small pieces of data– usually text files – placed on your computer, tablet, phone, or similar device when you use that device to visit our Platform. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Platform does not support “Do Not Track” requests sent from a browser at this time.
      </p>

      <h3 className="legal-docs-subsubtitle">We use the following types of Cookies:</h3>

      <ul className="legal-docs-list">
        <li>
          <strong>Essential Cookies:</strong> Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.
        </li>
        <li>
          <strong>Functional Cookies:</strong> Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Platform. These Cookies help us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).
        </li>
        <li>
          <strong>Performance/Analytical Cookies:</strong> Performance/Analytical Cookies allow us to understand how visitors use our Platform and/or Services, such as by collecting information about the number of visitors to the Platform, what pages visitors view and how long visitors are viewing pages. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Platform content for those who engage with our advertising. For example, Google, Inc. (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and share information collected by Google Analytics about your visits to the Platform is subject to the <a href="https://analytics.google.com/analytics/web/provision/#/provision" className="legal-docs-link">Google Analytics Terms of Use</a> and the <a href="https://policies.google.com/privacy" className="legal-docs-link">Google Privacy Policy</a>. You have the option to opt out of Google’s use of Cookies by visiting the Google advertising opt-out page at <a href="https://www.google.com/privacy_ads.html" className="legal-docs-link">www.google.com/privacy_ads.html</a> or the Google Analytics Opt-out Browser Add-on at <a href="https://tools.google.com/dlpage/gaoptout/" className="legal-docs-link">https://tools.google.com/dlpage/gaoptout/</a>.
        </li>
        <li>
          <strong>Retargeting/Advertising Cookies:</strong> Retargeting/Advertising Cookies collect data about your online activity and identify your interests so that we can provide advertising that we believe is relevant to you. For more information about this, please see the section below titled “Information about Interest-Based Advertisements.”
        </li>
      </ul>

      <p className="legal-docs-text">
        When you visit the Platform for the first time, you will be given the option to select which Cookies you accept. With the exception of essential Cookies, you can choose whether or not to accept the other types of Cookies. You can manage your Cookie preferences at any time by clicking on <strong>Manage Cookies</strong> in the footer of the BuzzVibe website.
      </p>

      <p className="legal-docs-text">
        You can also decide whether or not to accept Cookies through your internet browser’s settings.
      </p>

      <p className="legal-docs-text">
        To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your browser’s menu. To find out more information about Cookies, including information about how to manage and delete Cookies, please visit <a href="http://www.allaboutcookies.org/" className="legal-docs-link">www.allaboutcookies.org</a>.
      </p>

      <h2 className="legal-docs-subtitle">INFORMATION REGARDING INTEREST-BASED ADVERTISEMENTS</h2>

      <p className="legal-docs-text">
        We may present advertisements, and also allow third-party ad networks, including third-party ad servers, ad agencies, ad technology vendors and research firms, to present advertisements through the Platform. These advertisements may be targeted to Users who fit certain general profile categories or display certain preferences or behaviors (“Interest-Based Ads”). Information for Internet-Based Ads (including Personal Data) may be provided to us by you, or derived from the usage patterns of particular Users on the Platform and/or services of third parties. Such information may be gathered through tracking Users’ activities across time and unaffiliated properties, including when you leave the Platform. To accomplish this, we or our service providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network to you through the Platform. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Web beacons allow ad networks to view, edit or set their own Cookies on your browser, just as if you had requested a web page from their site.
      </p>

      <p className="legal-docs-text">
        We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for Online Behavioral Advertising. Through the DAA and Network Advertising Initiative (“NAI”), several media and marketing associations have developed an industry self-regulatory program to give consumers a better understanding of, and greater control over, ads that are customized based on a consumer’s online behavior across different websites and properties. To make choices about Interest-Based Ads from participating third parties, including to opt-out of receiving behaviorally targeted advertisements from participating organizations, please visit the DAA’s or NAI’s consumer opt-out pages, which are located at <a href="http://www.networkadvertising.org/choices/" className="legal-docs-link">http://www.networkadvertising.org/choices/</a> or <a href="http://www.aboutads.info/choices" className="legal-docs-link">www.aboutads.info/choices</a>.
      </p>

      <h2 className="legal-docs-subtitle">DATA SECURITY AND RETENTION</h2>

      <p className="legal-docs-text">
        We seek to protect your Personal Data from unauthorized access, use, and disclosure using appropriate physical, technical, organizational, and administrative security measures based on the type of Personal Data and how we are processing that data. For example, the Platform uses industry-standard Secure Sockets Layer (SSL) technology to allow for the encryption of Personal Data you provide to us. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanisms; limiting access to your computer or device and browser, and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the Internet or storing data is completely secure. We cannot guarantee the complete security of any data you share with us, and except as expressly required by law, we are not responsible for the theft, destruction, loss or inadvertent disclosure of your information or content.
      </p>

      <p className="legal-docs-text">
        We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you with access to the Platform or Services. In some cases, we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes, or collect fees owed, or is otherwise permitted or required by applicable law, rule, or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.
      </p>

      <h3 className="legal-docs-subsubtitle">PERSONAL DATA OF MINORS</h3>
      <p className="legal-docs-text">
        As noted in the Terms of Service, we do not knowingly collect or solicit Personal Data about anyone under 18 years of age; if you are under the age of 18, please do not attempt to register for or otherwise use the Platform or Services or send us any Personal Data. If we learn we have collected Personal Data from anyone under 18 years of age, we will delete that information as quickly as possible. If you believe that someone under 18 years of age may have provided us Personal Data, please contact us at <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a>.
      </p>

      <h2 className="legal-docs-subtitle">RIGHTS OF CALIFORNIA RESIDENTS</h2>

      <p className="legal-docs-text">
        If you are a California resident, you have the rights set forth in this section. Please note that we may process Personal Data of our customers’ end users or employees in connection with our provision of certain services to our customers. If we are processing your Personal Data as a service provider, you should contact the entity that collected your Personal Data in the first instance to address your rights with respect to such data.
      </p>

      <p className="legal-docs-text">
        If there are any conflicts between this section and any other provision of this Privacy Policy and you are a California resident, the portion that is more protective of Personal Data shall control, to the extent of such conflict. If you have any questions about this section or whether any of the following rights apply to you, please contact us at <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a>.
      </p>

      <h3 className="legal-docs-subsubtitle">Right to Access</h3>
      <p className="legal-docs-text">
        You have the right to request certain information about our collection and use of your Personal Data over the past 12 months. In response, we will provide you with the following information:
      </p>
      <ul className="legal-docs-list">
        <li>The categories of Personal Data that we have collected about you.</li>
        <li>The categories of sources from which that Personal Data was collected.</li>
        <li>The business or commercial purpose for collecting or selling your Personal Data.</li>
        <li>The categories of third parties with whom we have shared your Personal Data.</li>
        <li>The specific pieces of Personal Data that we have collected about you.</li>
        <li>If we have disclosed your Personal Data to any third parties for a business purpose over the past 12 months, we will identify the categories of Personal Data shared with each category of third-party recipient.</li>
        <li>If we have sold your Personal Data over the past 12 months, we will identify the categories of Personal Data sold to each category of third-party recipient.</li>
      </ul>

      <h3 className="legal-docs-subsubtitle">Right to be Deleted</h3>
      <p className="legal-docs-text">
        You have the right to request that we delete the Personal Data that we have collected from you. Under the California Consumer Privacy Act (“CCPA”), this right is subject to certain exceptions: for example, we may need to retain your Personal Data to provide you with the Services or complete a transaction or other action you have requested. If your deletion request is subject to one of these exceptions, we may deny your deletion request.
      </p>

      <h3 className="legal-docs-subsubtitle">Right to Opt-Out of Personal Data “Sales”</h3>
      <p className="legal-docs-text">
        You have the right to opt-out of the “sale” of your Personal Data. In this section, we use the terms “sell” or “sale” as it is defined in the CCPA. We sell your Personal Data, subject to your right to opt-out of these sales.
      </p>
      <p className="legal-docs-text">
        As described in the “Tracking Tools, Advertising, and Opt-Out” section above, we have incorporated Cookies from certain third parties into our Services. These Cookies allow those third parties to receive information about your activity on our Platform that is associated with your browser or device. Those third parties may use that data to serve you relevant ads on our Platform or on other websites you visit. Under the CCPA, sharing your data through third-party Cookies for online advertising may be considered a “sale” of information. You can opt-out of these sales by following the instructions in this section.
      </p>

      <p className="legal-docs-text">
        We sell your Personal Data to the following categories of third parties:
      </p>
      <ul className="legal-docs-list">
        <li>Ad networks.</li>
        <li>Data brokers and marketing cooperatives.</li>
        <li>Marketing providers.</li>
      </ul>

      <p className="legal-docs-text">
        Over the past twelve (12) months, we have sold the following categories of your Personal Data to categories of third parties listed above:
      </p>
      <ul className="legal-docs-list">
        <li>Profile or Contact Data.</li>
        <li>Commercial Data.</li>
        <li>Devices/IP Data.</li>
        <li>Web Analytics.</li>
        <li>Consumer Demographic Data.</li>
        <li>Geolocation Data.</li>
        <li>Inferences drawn from other personal information.</li>
      </ul>

      <p className="legal-docs-text">
        Our use of analytics platforms and our sharing information with Select Partners described above may be considered “sales” under the CCPA.
      </p>

      <p className="legal-docs-text">
        You have the right to opt-out of the sale of your Personal Data. You can opt-out using the following methods:
      </p>
      <ul className="legal-docs-list">
        <li>You can complete the online form found here: [<a href="#" className="legal-docs-link">Do Not Sell My Personal Information</a>].</li>
        <li>Email us at <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a></li>
      </ul>

      <p className="legal-docs-text">
        Once you have submitted an opt-out request, we will not ask you to reauthorize the sale of your Personal Data for at least 12 months.
      </p>

      <p className="legal-docs-text">
        To our knowledge, we do not sell the Personal Data of minors under 16 years of age.
      </p>

      <h3 className="legal-docs-subsubtitle">Right Not to Be Discriminated Against</h3>
      <p className="legal-docs-text">
        We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or services, charge you different prices or rates, or provide you a lower quality of goods and services if you exercise your rights under the CCPA. However, we may offer different tiers of our Services as allowed by applicable data privacy laws (including the CCPA) with varying prices, rates, or levels of quality of the goods or services you receive related to the value of Personal Data that we receive from you.
      </p>

      <h3 className="legal-docs-subsubtitle">Exercising Your Rights</h3>
      <p className="legal-docs-text">
        To exercise the rights described above, you or your Authorized Agent (defined below) must send us a request that: (1) provides sufficient information to allow us to verify that you are the person about whom we have collected Personal Data, and (2) describes your request in sufficient detail to allow us to understand, evaluate, and respond to it. Each request that meets both of these criteria will be considered a “Valid Request.” We may not respond to requests that do not meet these criteria. We will only use Personal Data provided in a Valid Request to verify your identity and complete your request. You do not need an account to submit a Valid Request.
      </p>

      <p className="legal-docs-text">
        We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for making a Valid Request unless your Valid Request(s) is excessive, repetitive, or manifestly unfounded. If we determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your request.
      </p>

      <p className="legal-docs-text">
        You may submit a Valid Request using the following methods:
      </p>
      <ul className="legal-docs-list">
        <li>Submit a form at this address: www.buzzvibe.com/contact-us</li>
        <li>Email us at: <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a></li>
      </ul>

      <p className="legal-docs-text">
        You may also authorize an agent (an “Authorized Agent”) to exercise your rights on your behalf. To do this, you must provide your Authorized Agent with written permission to exercise your rights on your behalf, and we may request a copy of this written permission from your Authorized Agent when they make a request on your behalf.
      </p>

      <h3 className="legal-docs-subsubtitle">Notice of Financial Incentive</h3>
      <p className="legal-docs-text">
        We offer our trade members a rewards program that provides certain perks, such as rewards. We may also provide other programs, such as promotional offers or sweepstakes, (collectively, the “Programs”). When you sign up for one of these Programs, we typically ask you to provide your name and contact information (such as email address and/or telephone number). Because our Programs involve the collection of personal information, they may be interpreted as a “financial incentive” program under California law. The value of your personal information to us is related to the value of the discounted products or services, or other benefits that you obtain or that are provided as part of the applicable Program, less the expense related to offering those products, services, and benefits to Program participants.
      </p>

      <p className="legal-docs-text">
        You may withdraw from participating in a Program at any time by contacting us using the designated method set forth in the applicable Program rules.
      </p>

      <h3 className="legal-docs-subsubtitle">Shine the Light Requests</h3>
      <p className="legal-docs-text">
        Under California Civil Code Sections 1798.83-1798.84 (California’s ‘Shine the Light’ law), California residents are entitled once per year to request and obtain certain information regarding our disclosure, if any, of your personally identifiable information to third parties for their direct marketing purposes during the immediately prior calendar year. If you are a California resident and want such a list, please contact us at <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a>. For all such requests, you must put the statement “Your California Privacy Rights” in the message field of your request, as well as your name, street address, city, state, and zip code. Please note that we will not accept these requests by telephone, mail or fax, and we are not responsible for notices that are not labeled or sent properly, or that do not have complete information. We reserve the right to confirm your California residency before processing these requests.
      </p>

      <h2 className="legal-docs-subtitle">RIGHTS IN OTHER JURISDICTIONS</h2>

      <h3 className="legal-docs-subsubtitle">Nevada Resident Rights</h3>
      <p className="legal-docs-text">
        If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a> with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address associated with your account.
      </p>

      <h2 className="legal-docs-subtitle">CHANGES TO THIS POLICY</h2>
      <p className="legal-docs-text">
        The Platform and/or Services are subject to enhancements and changes over time. Accordingly, so we may need to change this Privacy Policy from time to time as well. We will alert you to any such changes by placing a notice on the BuzzVibe website, by sending you an email, and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Platform and Services, and you are still responsible for reading and understanding them. If you use the Platform or Services after any changes to the Privacy Policy have been posted, that means you automatically agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.
      </p>

      <h2 className="legal-docs-subtitle">CONTACT INFORMATION</h2>
      <p className="legal-docs-text">
        If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data, or your choices and rights regarding such collection and use, please do not hesitate to contact us:
      </p>
      <p className="legal-docs-text">
        via email: <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a>
      </p>
    </div>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;
