import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ecommerceServer } from "../../server";
import { useSelector } from "react-redux";

const BrandCreate = () => {
  const { seller } = useSelector((state) => state.seller);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [avatarImagePreview, setAvatarImagePreview] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (coverImage) formData.append('coverImage', coverImage);
    if (avatarImage) formData.append('avatarImage', avatarImage);

    try {
      const response = await axios.post(`${ecommerceServer}/brand/create-brand`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'current-profile': seller.name || 'defaultProfile',
        },
        withCredentials: true,
      });
      if (response.data.success) {
        toast.success('Brand created successfully!');
        setName('');
        setDescription('');
        setCoverImage(null);
        setAvatarImage(null);
        setCoverImagePreview(null);
        setAvatarImagePreview(null);
      } else {
        toast.error('Brand creation failed.');
      }
    } catch (error) {
      console.error('Error creating brand:', error);
      toast.error('Failed to create brand.');
    }
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    setCoverImage(file);
    setCoverImagePreview(URL.createObjectURL(file));
  };

  const handleAvatarImageChange = (e) => {
    const file = e.target.files[0];
    setAvatarImage(file);
    setAvatarImagePreview(URL.createObjectURL(file));
  };

  return (
    <div className="brand-create-component-container">
      <form onSubmit={handleSubmit} className="brand-create-component-form">
        <div className="brand-create-component-form-group">
          <label htmlFor="name" className="brand-create-component-label">
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            required
            className="brand-create-component-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="brand-create-component-form-group">
          <label htmlFor="description" className="brand-create-component-label">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            rows={4}
            className="brand-create-component-textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        <div className="brand-create-component-form-group">
          <label htmlFor="coverImage" className="brand-create-component-label">
            Cover Image
          </label>
          <input
            id="coverImage"
            name="coverImage"
            type="file"
            className="brand-create-component-file-input"
            onChange={handleCoverImageChange}
          />
          {coverImagePreview && (
            <img
              src={coverImagePreview}
              alt="Cover Preview"
              className="brand-create-component-image-preview"
            />
          )}
        </div>

        <div className="brand-create-component-form-group">
          <label htmlFor="avatarImage" className="brand-create-component-label">
            Avatar Image
          </label>
          <input
            id="avatarImage"
            name="avatarImage"
            type="file"
            className="brand-create-component-file-input"
            onChange={handleAvatarImageChange}
          />
          {avatarImagePreview && (
            <img
              src={avatarImagePreview}
              alt="Avatar Preview"
              className="brand-create-component-image-preview"
            />
          )}
        </div>

        <button type="submit" className="brand-create-component-submit-button">
          Create Brand
        </button>
      </form>
    </div>
  );
};

export default BrandCreate;


