import React from 'react';

const CreateProductDoc = () => {
  return (
    <div className="doc-container">
      <h1 className="doc-title">How to Create a Product on BuzzVibe</h1>
      <p className="doc-intro">
        Creating a product on our platform is a straightforward process. This guide will walk you through each step of the product creation process, from selecting a brand type to adding images, pricing, and variants.
      </p>

      <ol className="doc-steps">
        <li className="doc-step">
          <h2>Step 1: Start Creating a Product</h2>
          <p>Navigate to the Create Product Page: You can access this page from your dashboard by selecting "Create Product."</p>
          <p>Form Overview: The form is divided into multiple sections, including:</p>
          <ul>
            <li>Brand Type</li>
            <li>Basic Information (Product Name, Description, etc.)</li>
            <li>Images and Videos</li>
            <li>Pricing and Stock</li>
            <li>Variants (if applicable)</li>
            <li>Additional Details</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Step 2: Select Brand Type</h2>
          <p>
            <strong>Custom Brand:</strong> For products that belong to your own unique brand.
            <br />
            <strong>Branded Product:</strong> For products associated with an established brand.
          </p>
          <p>If you select Branded, you will need to select the specific brand from the dropdown. Once selected, you can also choose from existing catalog items that belong to that brand.</p>
        </li>

        <li className="doc-step">
          <h2>Step 3: Enter Product Information</h2>
          <p>Fill in the basic information about your product:</p>
          <ul>
            <li><strong>Product Name:</strong> Enter the name of your product.</li>
            <li><strong>Description:</strong> Provide a detailed description of the product.</li>
            <li><strong>Category:</strong> Select the most appropriate category for your product from the dropdown list.</li>
            <li><strong>SKU:</strong> Add a unique identifier for your product.</li>
            <li><strong>Brand:</strong> Enter your brand name or select from the catalog if it's branded.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Step 4: Upload Images</h2>
          <p>
            <strong>Drag and Drop Images:</strong> You can drag and drop product images or click to upload them.
            <br />
            <strong>Reorder Images:</strong> After uploading, you can drag and reorder images to set the display order.
            <br />
            <strong>Remove Images:</strong> To remove an image, click the "×" button.
          </p>
        </li>

        <li className="doc-step">
          <h2>Step 5: Upload Videos (Optional)</h2>
          <p>Upload a product video by dragging and dropping or clicking the upload area. Once uploaded, you can preview and remove videos similarly to images.</p>
        </li>

        <li className="doc-step">
          <h2>Step 6: Set Pricing and Stock</h2>
          <ul>
            <li><strong>Price:</strong> Enter the product’s price.</li>
            <li><strong>Discount Price:</strong> (Optional) If the product is on sale, enter the discounted price. The original price must be higher than the discount price.</li>
            <li><strong>Stock:</strong> Define the available stock for this product.</li>
            <li><strong>Low Inventory Threshold:</strong> Set the threshold for when the platform should notify you of low stock.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Step 7: Select Product Type</h2>
          <p>You can switch between Retail and Wholesale using the provided toggles.</p>
        </li>

        <li className="doc-step">
          <h2>Step 8: Add Tags and Tax Code</h2>
          <ul>
            <li><strong>Tags:</strong> Add relevant tags to help categorize and search for the product.</li>
            <li><strong>Tax Code:</strong> Select the appropriate tax code for your product, ensuring compliance with tax regulations.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Step 9: Enable Pickup and Delivery Options</h2>
          <p>You can toggle whether the product is available for Pickup or Delivery by using the switches.</p>
        </li>

        <li className="doc-step">
          <h2>Step 10: Set Weight and Dimensions</h2>
          <p>If the product is available for delivery, you'll need to set the following:</p>
          <ul>
            <li><strong>Weight:</strong> Enter the weight and select the unit of measurement (grams, pounds, kilograms).</li>
            <li><strong>Dimensions:</strong> Enter the length, width, and height along with the appropriate unit of measurement (centimeters, inches, meters).</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Step 11: Barcode and Nutrition Information (Optional)</h2>
          <ul>
            <li><strong>Barcode:</strong> Enter a barcode or upload barcode images if applicable.</li>
            <li><strong>Nutrition Information:</strong> For edible products, you can upload nutrition information images.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Step 12: Configure Product Variants (If Applicable)</h2>
          <p>If your product has variants (e.g., different colors, sizes, or custom options), you can enable the Variable Product switch. This will allow you to add variants with specific attributes like color, size, and custom fields.</p>
          <ul>
            <li><strong>Add Variants:</strong> Define the types of variants your product will have (e.g., color, size, edition).</li>
            <li><strong>Generate Variants:</strong> The system will automatically generate all possible combinations based on the attributes you provide.</li>
            <li><strong>Customize Each Variant:</strong> After generating variants, you can set a unique SKU, price, stock, and even upload individual images for each variant.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Step 13: Submit the Product</h2>
          <p>
            <strong>Review the Details:</strong> Double-check all the information to ensure accuracy.
            <br />
            <strong>Submit the Form:</strong> Click the "Create Product" button to submit the product.
            <br />
            <strong>Success Notification:</strong> If successful, you will see a notification that your product was created successfully.
          </p>
        </li>
      </ol>

      <div className="doc-additional">
        <h2>Additional Features:</h2>
        <ul>
          <li><strong>Image Cropping:</strong> After uploading images, you can crop them as needed using the built-in cropping tool.</li>
          <li><strong>Drag-and-Drop Support:</strong> Reorder images and videos easily by dragging and dropping them into place.</li>
          <li><strong>Loader:</strong> A loading indicator will appear while the product is being created.</li>
        </ul>
      </div>
    </div>
  );
};

export default CreateProductDoc;
