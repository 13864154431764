import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toggleFriendListPanel, toggleNotificationSidebar, toggleWalletSidebar } from '../../state/uiSlice'; 
import { loadNotifications, resetUnseenNotifications } from '../../redux/actions/notifications'; 
import {
  CalendarIcon,
  ChartBarIcon,
  HomeIcon,
  BellIcon,
  BuildingStorefrontIcon,
  UsersIcon,
  WalletIcon,
  MapIcon
} from '@heroicons/react/24/outline';
import ProfileSwitchButton from '../ProfileSwitchButton';
import ConnectionsPanel from '../Connections/ConnectionsPanel';
import NotificationSidebar from '../NotificationSidebar';
import CryptoWalletSidebar from '../../widgets/CryptoWallet';


// Bell Icon with Notification Badge
const BellWithNotifications = ({ unseenCount }) => (
  <div className="sidebar-relative">
    <BellIcon className="sidebar-icon" aria-hidden="true" />
    {unseenCount > 0 && (
      <span className="sidebar-badge">
        {unseenCount}
      </span>
    )}
  </div>
);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, user, currentProfile } = useSelector((state) => state.user);
  const { isFriendListPanelOpen, isNotificationSidebarOpen, isWalletSidebarOpen } = useSelector((state) => state.ui);
  const { unseenCount } = useSelector((state) => state.notifications);
  const { seller } = useSelector((state) => state.seller);

  const userId = user?._id;
  const sellerId = seller?._id;
  const profileId = currentProfile === 'User' ? userId : sellerId;

  useEffect(() => {
    if (profileId) {
      dispatch(loadNotifications(currentProfile, profileId));
    }
  }, [dispatch, currentProfile, profileId]);

  const handleNavigationClick = (item) => {
    if (item.onClick === 'openConnections') {
      dispatch(toggleFriendListPanel());
    }
    if (item.onClick === 'openNotifications') {
      dispatch(toggleNotificationSidebar());
      dispatch(resetUnseenNotifications(profileId, currentProfile));
    }
    if (item.onClick === 'openWalletSidebar') {
      dispatch(toggleWalletSidebar());  
    }
  };

  // Define your navigation array with a unique id for each item
  let navigation = [
    { id: 'home', name: 'Home', icon: HomeIcon, href: '/', current: location.pathname === '/' },
    { id: 'notifications', name: 'Notifications', icon: () => <BellWithNotifications unseenCount={unseenCount} />, href: '#', current: false, onClick: 'openNotifications' },
    { id: 'connections', name: 'Connections', icon: UsersIcon, href: '#', current: false, onClick: 'openConnections' },
    { id: 'shop', name: 'Shop', icon: BuildingStorefrontIcon, href: '/marketplace', current: location.pathname === '/marketplace' },
    { id: 'map', name: 'Map', icon: MapIcon, href: '/map', current: location.pathname === '/map', hideOnMobile: true }, // Added `hideOnMobile` flag
    { id: 'events', name: 'Events', icon: CalendarIcon, href: '/events', current: location.pathname === '/events' },
  ];

  if (currentProfile !== 'User') {
    navigation.push({ id: 'dashboard', name: 'Dashboard', icon: ChartBarIcon, href: '/shop-login', current: location.pathname === '/shop-login' });
  }

  return (
    <>
      {/* Mobile Bottom Navigation */}
      <div className="mobile-bottom-nav lg:hidden">
        <nav className="mobile-nav-icons">
          {navigation
            .filter((item) => !item.hideOnMobile) // Filter out items with `hideOnMobile`
            .map((item) => (
              <a
                key={item.id}
                href={item.href}
                className={classNames(
                  item.current ? 'mobile-nav-item-active' : 'mobile-nav-item',
                  'mobile-nav-link'
                )}
                onClick={() => handleNavigationClick(item)}
              >
                {typeof item.icon === 'function' ? (
                  <item.icon />
                ) : (
                  <item.icon aria-hidden="true" />
                )}
              </a>
            ))}
        </nav>
      </div>

      {/* Sidebar for Larger Screens */}
      <div id="desktop-sidebar" className="sidebar-desktop hidden lg:flex">
        <div className="sidebar-container">
          <div className="sidebar-content">
            <ProfileSwitchButton className="sidebar-profile-toolbar-avatar" />
            <nav className="sidebar-nav-large" aria-label="Sidebar">
              {navigation.map((item) => (
                <a
                  key={item.id}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'sidebar-nav-item-current'
                      : 'sidebar-nav-item-default',
                    'sidebar-nav-item-large'
                  )}
                  onClick={() => handleNavigationClick(item)}
                >
                  {typeof item.icon === 'function' ? <item.icon /> : <item.icon
                    className={classNames(
                      item.current ? 'sidebar-icon-current' : 'sidebar-icon-default',
                      'sidebar-icon-large'
                    )}
                    aria-hidden="true"
                  />}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Conditional Panels */}
      {isFriendListPanelOpen && <ConnectionsPanel />}
      {isNotificationSidebarOpen && <NotificationSidebar />}
      {isWalletSidebarOpen && <CryptoWalletSidebar />}
    </>
  );
}



