import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInformation } from "../../redux/actions/user";
import { toast } from "react-toastify";

const UserProfileForm = () => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  return (
    <div className="profile-content-component-form-container">
      <form onSubmit={handleSubmit}>
        <div className="profile-content-component-form-row">
          <div className="profile-content-component-form-group">
            <label className="profile-content-component-form-label">Full Name</label>
            <input
              type="text"
              className="profile-content-component-form-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="profile-content-component-form-group">
            <label className="profile-content-component-form-label">Email Address</label>
            <input
              type="email"
              className="profile-content-component-form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="profile-content-component-form-row">
          <div className="profile-content-component-form-group">
            <label className="profile-content-component-form-label">Phone Number</label>
            <input
              type="text"
              className="profile-content-component-form-input"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
        </div>
        <button type="submit" className="profile-content-component-submit-btn">
          Update
        </button>
      </form>
    </div>
  );
};

export default UserProfileForm;
