import React from 'react';

const InventoryManagementGuide = () => {
  return (
    <div className="doc-container">
      <h1 className="doc-title">Guide to Managing Product Inventory</h1>
      <p className="doc-intro">
        The inventory management feature in your product management UI helps you keep track of existing product stock levels, add new inventory, and view historical inventory changes. This guide will walk you through adding inventory, setting low inventory thresholds, and reviewing inventory logs.
      </p>

      <h2 className="doc-step h2">Step-by-Step Guide to Inventory Management</h2>
      <ol className="doc-steps">
        <li className="doc-step">
          <h2>Adding New Inventory</h2>
          <p>To add new inventory for an existing product, follow these steps:</p>
          <ul>
            <li><strong>Open the Add Inventory Modal</strong>: Click the <strong>Add Inventory</strong> button next to the product you want to update.</li>
            <li><strong>Enter Inventory Details</strong>: Fill in the required details such as <strong>Batch Number</strong>, <strong>Quantity</strong>, and <strong>Received Date</strong>.</li>
            <li><strong>Submit the Form</strong>: Click the <strong>Add Inventory</strong> button to submit the details. Once submitted, the inventory will be updated for the selected product.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Viewing Inventory Logs</h2>
          <p>To view historical inventory updates for a product:</p>
          <ul>
            <li><strong>Open the Inventory Log Modal</strong>: Click the <strong>View Inventory Log</strong> button next to the product you want to review.</li>
            <li><strong>Review Inventory Changes</strong>: The inventory log will display all updates made to the product's inventory, including batch numbers, quantities, and received dates.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Handling Inventory Errors</h2>
          <p>If there is an error while adding inventory, an error message will be displayed. Make sure that all fields are filled correctly and try again.</p>
        </li>

        <li className="doc-step">
          <h2>Setting Low Inventory Thresholds</h2>
          <p>
            The low inventory threshold is a feature that helps you manage inventory levels by setting a minimum stock threshold. When the stock reaches or falls below this level, an automated email notification will be sent to alert you.
          </p>
          <ul>
            <li><strong>Set Threshold Value</strong>: While adding or editing a product, enter a value in the <strong>Low Inventory Threshold</strong> field. This value represents the stock level at which you want to receive notifications.</li>
            <li><strong>Receive Notifications</strong>: Once the stock reaches or goes below this threshold, you will receive an email notification, allowing you to take action promptly.</li>
          </ul>
        </li>
      </ol>

      <div className="doc-additional">
        <h2>Important Tips</h2>
        <ul>
          <li><strong>Batch Numbers</strong>: Keep track of batch numbers for easier identification of inventory batches in the log.</li>
          <li><strong>Accurate Quantities</strong>: Ensure the correct quantity is entered to maintain accurate inventory levels.</li>
          <li><strong>Low Inventory Threshold</strong>: Regularly review and adjust the low inventory threshold based on sales trends to help avoid stockouts.</li>
          <li><strong>Inventory Logs</strong>: Use the inventory logs to track inventory movement over time, which can help in making informed business decisions.</li>
        </ul>
      </div>
    </div>
  );
};

export default InventoryManagementGuide;
