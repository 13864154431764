import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../Layout/Loader';
import { server } from "../../server";
import WHEPClient from './WHEPClient'; // Import the WHEPClient
import Chat from './Chat'; 

const WatchStream = ({ streamId, currentUser }) => {
  const [stream, setStream] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const videoRef = useRef(null);
  const [whepClient, setWhepClient] = useState(null); // Track WHEPClient instance

  useEffect(() => {
    if (!streamId) return;

    const fetchStreamDetails = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await axios.get(`${server}/stream/playback/${streamId}`);
        setStream(response.data);
      } catch (err) {
        console.error('Error fetching stream details:', err);
        setError(true);
        toast.error(err.response?.data?.error || 'Failed to load stream');
      } finally {
        setLoading(false);
      }
    };

    fetchStreamDetails();
  }, [streamId]);

  useEffect(() => {
    if (!stream) return;

    if (stream.method === 'webrtc') {
      // Initialize WHEPClient for WebRTC playback
      const whep = new WHEPClient(stream.playbackUrl, videoRef.current);
      setWhepClient(whep);
    } else {
      // For RTMPS, no additional setup is required
    }

    // Cleanup on component unmount
    return () => {
      if (whepClient) {
        whepClient.close();
      }
    };
  }, [stream]);

  if (loading) {
    return <Loader />;
  }

  if (error || !stream) {
    return <div className="error-message">Stream not available.</div>;
  }

  return (
    <div className="watch-stream-container">
      <div className="stream-header">
        <img src={stream.avatar} alt={`${stream.name} Avatar`} className="stream-avatar" />
        <div className="stream-info">
          <h2>{stream.name}'s Livestream</h2>
          <span className="stream-role">{stream.role === 'user' ? 'Streamer' : 'Shop'}</span>
        </div>
      </div>
      
      <div className="stream-player">
        {stream.method === 'webrtc' ? (
          <video 
            ref={videoRef} 
            controls 
            autoPlay 
            style={{ width: '100%', borderRadius: '20px' }}
          />
        ) : (
          <iframe
            src={stream.playbackUrl}
            title={`${stream.name}'s Livestream`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="stream-iframe"
          ></iframe>
        )}
      </div>
      
      <div className="stream-description">
        <h3>About the Stream</h3>
        <p>{stream.description || 'No description available.'}</p>
      </div>
      
      <div className="stream-chat">
        <h3>Chat</h3>
        <Chat streamId={streamId} user={currentUser} />
      </div>
    </div>
  );
};

export default WatchStream;