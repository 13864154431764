import React, { useState, useEffect } from "react";
import ExplorePage from "./ExplorePage";
import LandingPageTest from "./LandingPageTest";
import Header from "../components/Layout/Header";
import Sidebar from "../components/Layout/Sidebar";


const MainPage = () => {
  const [activeTab, setActiveTab] = useState("explore");
  const [showNav, setShowNav] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [ticking, setTicking] = useState(false);

  const renderActiveTab = () => {
    switch (activeTab) {
      case "explore":
        return <ExplorePage />;
      case "home":
        return <LandingPageTest />;
      default:
        return <ExplorePage />;
    }
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (currentScrollY > lastScrollY && currentScrollY > 100) {
          // Scrolling down
          setShowNav(false);
        } else {
          // Scrolling up
          setShowNav(true);
        }

        setLastScrollY(currentScrollY);
        setTicking(false);
      });

      setTicking(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, ticking]);

  return (
    <div className="main-page">
      <Header />
      <Sidebar />

      {/* Tab Navigation */}
      <div className="main-page-tab-content-container">
      <div
        className={`main-page-tab-navigation ${
          showNav ? "visible" : "hidden"
        }`}
      >
        <button
          className={`main-page-tab-button ${
            activeTab === "explore" ? "main-page-active-tab" : ""
          }`}
          onClick={() => setActiveTab("explore")}
        >
          Explore
        </button>
        <button
          className={`main-page-tab-button ${
            activeTab === "home" ? "main-page-active-tab" : ""
          }`}
          onClick={() => setActiveTab("home")}
        >
          My Feed
        </button>
      </div>
      </div>

      {/* Content Area */}
      <div className="main-page-tab-content">{renderActiveTab()}</div>
    </div>
  );
};

export default MainPage;

