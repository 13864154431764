import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid'; 

const CreateEventPrompt = () => {
  return (
    <div className="create-product-prompt">
      <svg
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="create-product-prompt__icon"
      >
        <path
          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
          strokeWidth={2}
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <h3 className="create-product-prompt__title">No Events</h3>
      <p className="create-product-prompt__text">Get started by creating a new event.</p>
      <div className="create-product-prompt__button-container">
        <a href="/events">
          <button
            type="button"
            className="create-product-prompt__button"
          >
            <PlusIcon aria-hidden="true" className="create-product-prompt__button-icon" />
            Create an Event
          </button>
        </a>
      </div>
    </div>
  );
};

export default CreateEventPrompt;
