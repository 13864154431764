import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import { Box, Typography, TextField } from '@mui/material';
import Friend from '../components/Friend';
import WidgetWrapper from '../components/WidgetWrapper';
import { server } from '../server';

const FriendListWidget = ({ userId, profileType }) => {
  const [friends, setFriends] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const token = Cookie.get('token');

  const getFriends = async () => {
    try {
      const response = await axios.get(`${server}/friends/get-friends/${userId}/${profileType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setFriends(response.data);
      } else {
        setError('Failed to fetch friends. Please try again later.');
        console.error('Error fetching friends:', response.data);
      }
    } catch (error) {
      setError('Network error while fetching friends.');
      console.error('Error fetching friends:', error);
    }
  };

  useEffect(() => {
    getFriends();
  }, [userId, profileType]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredFriends = searchTerm
    ? friends.filter((friend) => friend.name.toLowerCase().includes(searchTerm))
    : friends;

    return (
      <WidgetWrapper className="friend-list-widget-wrapper">
        <Typography variant="h5" className="friend-list-widget-title">
          Social Connections
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Search friends"
          fullWidth
          onChange={handleSearchChange}
          className="friend-list-widget-search"
        />
        <Box className="friend-list-widget-box">
          {filteredFriends.length > 0 ? filteredFriends.map((friend) => (
            <Friend key={friend._id} friendId={friend._id}{...friend} friendType={friend.friendType}/>
          )) : <Typography>{error || 'No friends found.'}</Typography>}
        </Box>
      </WidgetWrapper>
    );
  };

export default FriendListWidget;




