import React from 'react';

const MagazineDropdown = () => {
  const sections = [
    { title: "Latest Articles", description: "Discover the newest stories and insights." },
    { title: "Popular Topics", description: "Explore trending topics in the magazine." },
    { title: "Editor’s Picks", description: "Curated content selected by our editors." },
    { title: "Interviews", description: "Read in-depth interviews with industry leaders." },
  ];

  // Mock magazine data for the right column
  const mockMagazineArticles = [
    {
      title: "The Future of Fashion",
      author: "Jane Smith",
      imageUrl: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1718108735/banners/hd2iauw5ydeyhpgbsg10.jpg",
    },
    {
      title: "Understanding Sound",
      author: "John Doe",
      imageUrl: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/4b346545-1a8f-4535-5ee2-2b0137460b00/public",
    },
    {
      title: "Health and Wellness Tips",
      author: "Emily Johnson",
      imageUrl: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/b1a27a5d-f81a-4d7a-617f-a29e5b1aad00/public",
    },
    {
      title: "Travel Destinations 2024",
      author: "Michael Brown",
      imageUrl: "https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/0bb27962-8ca2-41a5-5f8c-1a259dc12500/public",
    },
  ];

  return (
    <div className="magazine-dropdown-container">
      <div className="magazine-dropdown-inner">
        <h3 className="magazine-dropdown-title">Magazine</h3>
        <div className="magazine-dropdown-content">
          {/* Left Column */}
          <div className="magazine-dropdown-left">
            {sections.map((section, index) => (
              <div key={index} className="magazine-dropdown-section">
                <h4 className="magazine-dropdown-section-title">{section.title}</h4>
                <p className="magazine-dropdown-section-description">{section.description}</p>
              </div>
            ))}
          </div>

          {/* Right Column */}
          <div className="magazine-dropdown-right">
            <div className="magazine-dropdown-mock-magazine">
              {mockMagazineArticles.map((article, index) => (
                <div key={index} className="magazine-dropdown-article">
                <div className="magazine-dropdown-article-image-container">
                  <img
                    src={article.imageUrl}
                    alt={article.title}
                    className="magazine-dropdown-article-image"
                  />
                  </div>
                  <h5 className="magazine-dropdown-article-title">{article.title}</h5>
                  <p className="magazine-dropdown-article-author">By {article.author}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagazineDropdown;
