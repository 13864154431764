// ItemLoader.jsx
import React from 'react';


const ItemLoader = () => {
  return (
    <div className="item-loader">
      <div className="skeleton skeleton-image"></div>
      <div className="skeleton skeleton-text"></div>
      <div className="skeleton skeleton-text short"></div>
    </div>
  );
};

export default ItemLoader;
