import React, { useState } from 'react';


const TermsAndConditions = () => {
  const [openSections, setOpenSections] = useState(Array(31).fill(false));

  const termsData = [
    {
        title: 'ACCEPTANCE OF TERMS',
        content: `These Terms are a binding contract between you and BuzzVibe LLC (“BuzzVibe,” “we,” “us,” and “our”). 
        Your use of the Platform and/or Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Platform. 
        These Terms include the provisions in this document, as well as those in our Privacy Policy. 
        Your use of the Platform or participation in certain Services may also be subject to additional policies, rules, and/or conditions (“Additional Terms”), which are incorporated herein by reference, 
        and you understand and agree that by using the Platform or participating in any such Services, you agree to also comply with any Additional Terms. 
        
        Please read these Terms carefully. They cover important information about Services provided to you and any charges, taxes, and fees we bill you. 
        These Terms include information about future changes to these Terms, limitations of liability, a class action waiver, and resolution of disputes by arbitration instead of in court. 
        
        PLEASE NOTE THAT YOUR USE OF AND ACCESS TO THE PLATFORM AND/OR SERVICES IS SUBJECT TO THESE TERMS. 
        IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE PLATFORM OR SERVICES IN ANY MANNER. 
        
        ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, 
        YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
        
        Please check these Terms of Service periodically for updates.`,
      },
      {
        title: 'MODIFICATIONS',
        content: `We’re always trying to improve the Platform and Services. We may suspend or discontinue any part of the Platform and/or Services, 
        or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Platform Services. 
        We reserve the right to remove any Content (as defined below) from the Platform at any time, for any reason 
        (including, but not limited to, if someone alleges you contributed Content in violation of these Terms), in our sole discretion, and without notice. 
        
        As we are constantly trying to improve the Platform and Services, these Terms may need to be modified accordingly. BuzzVibe reserves the right to change or modify the Platform and Services, 
        any of the terms and conditions contained in these Terms, or any applicable policy at any time, including the commission or payment structure. 
        
        You are responsible for regularly reviewing any updates to these Terms. If you do not agree with the new Terms, you are free to reject them, however, that means you will no longer be able to use the Platform or Services. 
        If you use the Platform or Services in any way after a change to the Terms is effective, that means you agree to all of the changes. 
        
        Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.`,
      },
      {
        title: 'USE OF THE PLATFORM AND/OR SERVICES',
        content: `As a condition of using the Platform or Services, you represent and warrant that you are an individual of legal age to form a binding contract (i.e., at least 18 years of age). 
        If you are agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization’s or entity’s behalf and bind them to these Terms 
        (in which case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity). 
        
        You will only use the Platform and Services for your own internal use, and not on behalf of or for the benefit of any third party. 
        You will comply with all laws that apply to you, your use of the Platform and Services, and your actions and omissions 
        (for example, Sellers must comply with all laws that relate to the Seller Merchandise). 
        If your use of the Services is prohibited by applicable laws, then you are not authorized to use the Platform or Services. 
        BuzzVibe is not responsible for any use of the Platform or Services in any manner that violates the law.  
        
        BuzzVibe reserves the right to cease offering the Services and/or access to the Platform at any time.`,
      },
      
      {
        title: 'ACCOUNT SETUP AND DATA',
        content: `In order to use the Platform and Services, you will be required to sign up for an account (a personal or business account), 
        select a password and user name (“User ID”) and provide us with certain information or data, such as your registration and contact information ("Account Data"). 
        You may not select as your User ID a name that you do not have the right to use, or another person’s name with the intent to impersonate that person. 
        You may not transfer your personal account to anyone else without our prior written permission. 
        Business accounts may be transferred to another authorized user within your business for account management purposes, subject to BuzzVibe’s prior review. 
        You promise to provide us with accurate, complete and updated Account Data and to keep your Account Data accurate and current at all times. 
        You may edit your Account Data at any time by logging in to your account.
      
        You may be able to access certain parts or features of the Platform by using your account credentials from other services (each, a “Third Party Account”), 
        such as those offered by Facebook or Gmail. By using the Services through a Third Party Account, you permit us to access certain information from such account. 
        You are ultimately in control of how much information is accessible to us and may exercise such control by adjusting your privacy settings on your Third Party Account. 
      
        You consent to the collection and use of your Account Data and certain other information in accordance with our Privacy Policy. 
      
        You will not share your User ID, account or password with anyone, and you must protect the security of your User ID, account, password and any other access tools or credentials. 
        You are responsible for any activity associated with your User ID and account. You also agree that we will not be liable for any loss or damage arising from your failure to keep your User ID or password secure.
      
        In connection with certain features of the Platform or Services, we may permit users to use the Platform or Services or transact as guests, without the need to establish an account with us. 
        All guest users and related transactions shall be governed by these Terms and all other applicable BuzzVibe policies, including our Privacy Policy.`,
      },
      
      {
        title: 'USER PRIVACY',
        content: `BuzzVibe takes the privacy of its users very seriously. Please see our current Privacy Policy. 
        By using the Platform and/or Services, you are agreeing that we may handle your personal information and data in accordance with our Privacy Policy.`,
      },
      {
        title: 'MINIMUM AGE REQUIREMENT',
        content: `Anyone under the age of 18 is not permitted to use our Platform or Services. 
        We do not knowingly collect or solicit personally identifiable information from anyone under 18 years of age; 
        if you are under 18 years of age, please do not attempt to register for or otherwise use the Platform or Services or send us any personal information.  
        If we learn we have collected personal information from anyone under 18 years of age, we will delete that information as quickly as possible. 
        If you believe that anyone under 18 years of age may have provided us with personal information, please contact us at support@buzzvibe.com.`,
      },
      {
        title: 'CONNECTING BUYERS AND SELLERS',
        content: `You may use the Platform and Services in one or both of the following capacities: (i) as an individual, designer, artisan, creator, dealer, business, or brand that wishes to list certain merchandise (“Merchandise”) for sale through the Platform (collectively, "Sellers"), 
        or (b) as an individual or business that wishes to purchase Merchandise via the Platform (collectively, "Buyers"). All users of the Platform, including, without limitation, Buyers and Sellers are, collectively "Users".
      
        BuzzVibe relies upon a community of independent Sellers that supply the Merchandise advertised on and sold through the Platform. 
        Unless stated otherwise or to help facilitate shipments, BuzzVibe at no time holds possession of or title to the Merchandise advertised on and sold through the Platform. 
        BuzzVibe is not a broker or your agent. Except for certain new, wholesale, or retail Merchandise sold directly by BuzzVibe (the "BuzzVibe Merchandise"), BuzzVibe is not the Seller of any Merchandise listed on the Platform. 
        BuzzVibe merely provides a marketplace for sales between Sellers and Buyers, and, except for BuzzVibe Merchandise, is not involved in the transaction itself. 
        Sellers may sell Merchandise through their storefronts on the Platform and, except for BuzzVibe Merchandise, such Merchandise is not owned or controlled by BuzzVibe.
      
        Before purchasing Merchandise from any Seller, Buyers are responsible for making their own determinations that the Merchandise is suitable. 
        BuzzVibe is only responsible for connecting Sellers and Buyers, and is not responsible for making sure that Seller’s Merchandise is up to a certain standard of quality or authenticity. 
        BuzzVibe similarly is not responsible for ensuring that information (including credentials) a Buyer or Seller provides or about the Merchandise being offered is accurate or up-to-date. 
        We do not control the actions of any Buyer or Seller, and Sellers are not BuzzVibe employees or in any way authorized to act on behalf of BuzzVibe.
      
        EXCEPT FOR THE BUZZVIBE MERCHANDISE, BUZZVIBE DOES NOT DIRECTLY OFFER ANY MERCHANDISE. 
        YOU HEREBY ACKNOWLEDGE THAT BUZZVIBE DOES NOT SUPERVISE, DIRECT, CONTROL OR MONITOR THE SELLER’S MERCHANDISE AND EXPRESSLY DISCLAIMS ANY RESPONSIBILITY AND LIABILITY FOR THE SELLER’S MERCHANDISE 
        (INCLUDING SHIPPING OR RETURNS), INCLUDING BUT NOT LIMITED TO ANY WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR A PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW, REGULATION, OR CODE.
      
        Buyer and Seller must not enter into separate agreements outside of the Platform relating to Seller’s Merchandise (“Outside Agreements”). 
        If you do enter into any Outside Agreement, we may terminate your BuzzVibe account, and you acknowledge that BuzzVibe is not a party to and is not responsible for enforcing any Outside Agreement.`,
      },
      {
        title: 'FOR BUYERS',
        content: `If you purchase any Merchandise through the Platform:
        
        You agree to pay the amount listed and all other disclosed amounts, such as taxes, duties, costs, and expenses. 
        You agree that you are responsible for all payments and taxes associated with your use of the Platform and/or Services.
        
        You agree that we can automatically charge your stored payment card (credit and debit) or process authorized payments through other means, including ACH, during the purchase process. 
        All amounts are in US Dollars only.
      
        You agree that you are not on the U.S. Government’s Specially Designated Nationals And Blocked Persons List or any similar list issued by any other country.
        
        For certain transactions, Sellers may offer a return policy. Please see the applicable Seller’s page for more details on its specific return policy (if any). 
        Except for applicable Seller return policies, all fees and charges are nonrefundable.
      
        Certain transactions may display 'estimated value' on a listing but we are not evaluation experts and rely on data from Sellers and publicly available data. 
        Thus, we strongly recommend that you do your own research to determine the value of any Merchandise.
      
        While we do our best to give you accurate listing information, we cannot guarantee that the listing is 100% accurate and cannot guarantee the authenticity of any item. 
        Sellers are solely responsible for the accuracy in describing and pricing the Merchandise on the Platform. 
        BuzzVibe relies on the Sellers for such information and is not responsible in any way for the description or pricing of Merchandise on the Platform provided by the Seller. 
        
        If you have any claims or disputes regarding any Seller or any Merchandise on the Platform, please contact the Seller through the Platform and email us at support@buzzvibe.com. 
        We will use reasonable efforts to facilitate the communication of claims or disputes with the Seller. BuzzVibe may, but is not obligated to, facilitate the resolution of any such claim or dispute. 
      
        You agree and acknowledge that while BuzzVibe may assist with resolving your claims or disputes regarding any Merchandise on the Platform, 
        (i) you are responsible for contacting the Seller through the Platform and attempting to resolve the matter, 
        (ii) the ultimate resolution of any such claims or disputes is between you and the Seller, 
        and (iii) BuzzVibe is not responsible for successfully resolving any claims or disputes between you and the Seller.`,
      },
      {
        title: 'FOR SELLERS',
        content: `BuzzVibe is a marketplace facilitator that works with Sellers to promote and complete the sale of their Merchandise through our Platform. 
        Most U.S. states now have marketplace facilitator laws that require us to collect and remit state sales tax on behalf of Sellers. 
        If BuzzVibe is unable or not required to collect and remit state or local sales taxes, customs, or duties on behalf of a Seller, then the Seller is responsible for collecting and remitting state and local sales tax, customs, or duties, as applicable. 
        As a Seller, you are responsible for paying, withholding, filing, and reporting all taxes, customs, duties, and other governmental assessments associated with your activity in connection with the Services, 
        provided that BuzzVibe will do so on your behalf where required, and may, in its sole discretion, do any of the foregoing on your behalf where it is not required.
      
        Certain of the Services may allow you to list or otherwise offer Merchandise on the Platform for sale. By listing or otherwise offering Merchandise on the Platform for sale, you agree to the following:
      
        To ensure the integrity and quality of the Services, BuzzVibe maintains certain policies and rules for its Services, including, without limitation, policies, and rules to promote accurate representation and reliable delivery of Merchandise sold via the Platform. 
        You will comply at all times with all the policies and rules set forth in the applicable [Seller FAQs], which may be updated from time to time. 
        You represent and warrant that you own or have the right to sell the Merchandise, and that your Merchandise description, the Merchandise, and your conduct in listing the Merchandise comply with all applicable laws and regulations, as well as BuzzVibe's policies.
      
        You will provide accurate and lawful descriptions and prices for all Merchandise you list on the Platform.
        You will provide any legally required warnings or notices with respect to any Merchandise you list on the Platform.
        You will ensure that the Merchandise complies with safety standards and guidelines provided by the Consumer Product Safety Commission as applicable.
        You agree to complete the transaction promptly on the terms listed for the selected Merchandise.
        You may not list any Merchandise on the Platform that (i) violates any applicable law or regulation, or are illegal items, including counterfeit or stolen items; or (ii) infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party.
      
        If an issue or dispute arises between you and any Buyer regarding any Merchandise you offer on the Platform for sale, please contact the Buyer through the Platform and email us at support@buzzvibe.com. 
        We will use reasonable efforts to facilitate the communication of claims or disputes with the Buyer. BuzzVibe may, but is not obligated to, facilitate the resolution of any such claim or dispute.
      
        You agree and acknowledge that while BuzzVibe may assist with resolving your claims or disputes regarding any Merchandise on the Platform, 
        (i) you are responsible for contacting the Buyer through the Platform and attempting to resolve the matter, 
        (ii) the ultimate resolution of any such claims or disputes is between you and the Buyer, 
        and (iii) BuzzVibe is not responsible for successfully resolving any claims or disputes between you and the Buyer.
      
        In addition, BuzzVibe reserves the right, for any reason, in our sole discretion and without notice to you, to remove any Merchandise you list, to suspend your access to the Platform and Services, 
        or to otherwise impose consequences on you for actions that BuzzVibe deems, in its sole discretion, to be inconsistent with BuzzVibe’s policies or the spirit of a trustworthy and secure marketplace. 
        BuzzVibe will not be liable to you or to any third party for taking any such action.
      
        No Seller may collect any information from or relating to a Buyer (“Buyer Information”), whether via the Platform, in the course of offering Merchandise, or otherwise, beyond what is necessary to offer the Merchandise to and/or sell the Merchandise to that Buyer. 
        Sellers must not use any Buyer Information beyond what is necessary to offer the Merchandise to and/or sell the Merchandise to that Buyer on the Platform. 
        Upon the conclusion of offering the Merchandise to a Buyer (or otherwise upon the request of such Buyer or BuzzVibe), 
        Seller must properly destroy all Buyer Information from or relating to such Buyer and make no further use of it whatsoever. 
        Sellers must collect, use, maintain, and transmit all Buyer Information in compliance with all applicable laws.
      
        Certain Services may allow a User to communicate directly with you. When a User communicates with you to inquire about or purchase a product or otherwise, the User may provide you with certain personal information 
        (including the User's name and email address and, in the case of a purchase, payment and shipping information). 
        Without express consent, you shall not contact Users or use their personal information for any purpose other than BuzzVibe-related communications and for completing the transaction for which the information was provided. 
        You may not use this information to distribute unsolicited commercial messages.`,
      },
      {
        title: 'MARKETING MESSAGING',
        content: `As part of the Services, you may receive communications through the Platform, including messages that BuzzVibe sends you (for example, via email or SMS). 
        When signing up for the Platform or Services, you will receive a welcome message and instructions on how to opt-in to and stop receiving messages. 
        By signing up for the Platform or Services, providing us with your wireless number, and opting in to receive communications from us, 
        you confirm that you want BuzzVibe to send you information that we think may be of interest to you, which may include BuzzVibe using automated dialing technology to text you at the wireless number you provided, 
        and you agree to receive communications from BuzzVibe, and you represent and warrant that each person you register for the Services or for whom you provide a wireless phone number has consented to receive communications from BuzzVibe. 
        
        You agree to indemnify and hold BuzzVibe harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of the foregoing.`,
      },
      {
        title: 'YOUR CONDUCT',
        content: `You represent, warrant, and agree that you will not contribute any Content (defined below) or otherwise use the Platform or Services in a manner that:
        
        - infringes or violates the intellectual property rights or any other rights of anyone else (including BuzzVibe);
        - violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by BuzzVibe;
        - is dangerous, harmful, fraudulent, deceptive, threatening, harassing, abusive, defamatory, obscene, libelous, invasive of another’s privacy, hateful, or otherwise objectionable;
        - jeopardizes the security of your User ID, account, or anyone else’s (such as allowing someone else to log in to the Platform as you);
        - attempts, in any manner, to obtain the password, account, or other security information from any other User;
        - violates the security of any computer network, or cracks any passwords or security encryption codes;
        - runs Maillist, Listserv, any form of auto-responder or “spam” on the Platform, or any processes that run or are activated while you are not logged into the Platform, or that otherwise interfere with the proper function of the Platform or Services (including by placing an unreasonable load on the Platform’s infrastructure);
        - “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Platform, Services, or Content (through use of manual or automated means);
        - copies or stores any significant portion of the Content; or
        - decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Merchandise or Services.
      
        You further agree to not:
        - fail to deliver payment for Merchandise you purchased;
        - fail to deliver Merchandise purchased from you;
        - manipulate the price of any item of Merchandise or interfere with other Users' listings;
        - circumvent BuzzVibe’s policies;
        - circumvent or manipulate our fee structure, the billing process, or fees owed to BuzzVibe;
        - engage in any unsolicited or unauthorized advertising, "spamming" or "chain letters," or any other form of solicitation;
        - share BuzzVibe’s or other Users’ proprietary information or Content obtained through the Platform outside of the Platform without BuzzVibe’s or such User’s written consent;
        - abuse or harass anyone using the Platform;
        - access the Platform by any means other than through the interface that is provided, or violate any usage rules relating to the protection of materials or technology on the Platform.
      
        A violation of any of the foregoing is grounds for termination of your right to use or access the Platform and Services. 
        From time to time, BuzzVibe may modify the rules of its Platform to promote accurate representation and reliable delivery of Merchandise sold on its Platform. 
        We have the right, but not the obligation, in our sole discretion to impose consequences on you for actions that BuzzVibe deems, in its sole discretion, to be inconsistent with BuzzVibe’s policies or the spirit of a trustworthy and secure marketplace. 
        In addition, we have the right, but not the obligation, in our sole discretion to pre-screen, refuse, or remove any Buyer, Seller, Merchandise, listing, item, or Content from the Platform.`,
      },
      {
        title: 'YOUR CONTENT ON THE PLATFORM',
        content: `Our Platform and Services include information, data, text, articles, photographs, graphics, images, illustrations, video, messages, tags, or other materials ("Content") from many people and entities, including you, 
        and such Content is the sole responsibility of the person or entity that provided it. 
        You are entirely responsible for all Content that you email, transmit, upload or otherwise make available while using the Platform 
        (including through any third-party service integrations you enable through the Platform or Services) and for the consequences of your actions (including any loss or damage which BuzzVibe may suffer).  
        The Content is protected by copyright and/or other intellectual property laws. 
        You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Platform, 
        and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, 
        (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including BuzzVibe’s) rights.
      
        When you upload or post any Content to our Platform, you grant to BuzzVibe and its affiliates a license and right to such Content (and any intellectual property or other proprietary rights you have in such Content) 
        to distribute, syndicate, reproduce, edit, modify, adapt, store, aggregate, publish, publicly perform, publicly display, make derivative works of, and otherwise use, in the broadest sense permitted under applicable law, 
        such Content on or off the Platform: (a) for the purpose of providing the Services to you and other Users, such as promoting your listings on the Platform; and 
        (b) with respect to Content that is related to the Merchandise, such as information, photographs, videos, or reviews of your Merchandise, for the purpose of marketing and promoting the Platform and Services.  
        For example, for marketing and other purposes, BuzzVibe will occasionally display your Content or listings on other sites, like Facebook, X, and Pinterest.  
        You agree that the licenses you grant us with respect to such Content are royalty-free, perpetual, sublicensable, irrevocable, and worldwide. 
        In order to participate in certain additional BuzzVibe features, BuzzVibe may need additional rights with respect to your Content in order to provide such BuzzVibe features to you.  
        Any such additional rights, if necessary, will be specified in a separate agreement between you and BuzzVibe that you will have the right to review and approve.
      
        You represent and warrant that you own or otherwise control or have a license to all necessary rights to the Content that you post or submit to BuzzVibe for use on the Platform 
        and that you have sufficient rights to grant this license to such Content to BuzzVibe without infringement or violation of any third party rights, including without limitation, any privacy rights, 
        publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights.  
        In addition, you agree that BuzzVibe and its respective affiliates, successors, and assigns are not under any obligation of confidentiality with respect to any such Content 
        and that by posting Content, other Users may re-post your Content on third-party websites. Any Content that does not adhere to the foregoing will be removed by BuzzVibe.
      
        Certain features of the Platform allow you to share information with others, including through your social networks or other services where you have an account (“Third Party Accounts”). 
        When Content is authorized for sharing, we will clearly identify the Content you are authorized to redistribute and the ways you may redistribute it, usually by providing a “share” button on or near the Content. 
        If you share information from the Platform with others through your Third Party Accounts, such as your social networks, you authorize BuzzVibe to share that information with the applicable Third Party Account provider. 
        Please review the policies of any Third Party Account providers you share information with or through for additional information about how they may use your information. 
        If you redistribute Content, you must be able to edit or delete any Content you redistribute, and you must edit or delete it promptly upon our request.`,
      },
      {
        title: 'PROPRIETARY RIGHTS',
        content: `All right, title, and interest to the Platform and Services (which includes any BuzzVibe content or materials made available via the Platform, 
        the Platform’s and Services' look and feel, the designs, service marks, trademarks, and trade names displayed on the Platform) are the property of BuzzVibe, its affiliates, licensors, or users, 
        and are protected by copyrights, patents, trademarks, or other proprietary rights and laws.
      
        You may not use, copy, modify, create a derivative work from, reverse engineer, sell, distribute, assign, sublicense or otherwise transfer any right, of any part of the Platform without the prior written consent of the respective owner.
      
        Unless otherwise agreed to in a separate agreement between us, with respect to any software or applications that we make available to you (collectively, the "Software"), 
        BuzzVibe grants you a personal, worldwide, royalty-free, non-transferable, and non-exclusive license to use such Software for the sole purpose of enabling you to use the Platform and Services in the manner permitted by these Terms. 
        You may not (and you may not permit anyone else to) copy, modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to extract the source code of the Software or any part thereof. 
        You may not assign or grant a sublicense of your rights to use the Software, grant a security interest in or over your rights to use the Software, or otherwise transfer any part of your rights to use the Software.
      
        You agree to only use the Platform and Services for personal purposes, and not for any unintended use. 
        You agree not to reproduce, duplicate, copy, sell, trade, resell, frame, or exploit for any commercial purposes, any portion of the Platform or Services.`,
      },
      {
        title: 'THIRD PARTY INTERACTIONS',
        content: `Any information or Content publicly posted or privately transmitted through the Platform is the sole responsibility of the person from whom such information or Content originated, 
        and you access all such information and Content at your own risk, and we are not liable for any errors or omissions in that information or Content or for any damages or loss you might suffer in connection with it. 
        You agree that BuzzVibe is not responsible for any information, text, graphics, images, photos, videos, items of Merchandise, links, or other content posted by Sellers with respect to the Merchandise or for the quality of Merchandise purchased from such Sellers.  
        We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, 
        and you hereby release us from all liability for you having acquired or not acquired Content through the Platform.  
        We do not guarantee the identity of any Users with whom you interact in using the Platform and are not responsible for which Users gain access to the Platform and/or Services.
      
        You are responsible for all Content you contribute, in any manner, to the Platform, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it.
      
        Certain of our Services may contain links or connections to third-party websites or services that are not owned or controlled by BuzzVibe. 
        When you access third-party websites or use third-party services, you accept that there are risks in doing so and that BuzzVibe is not responsible for such risks.  
        BuzzVibe has no control over and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed on any third-party websites or by any third party that you interact with through the Platform or Services. 
        In addition, BuzzVibe will not and cannot monitor, verify, censor, or edit the content of any third-party site or service. 
        We encourage you to be aware when you leave the Platform and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. 
        By using the Platform or Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.
      
        Your interactions with organizations and/or individuals found on or through the Platform, including payment and delivery of Merchandise or services, 
        and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. 
        You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. 
        You agree that BuzzVibe shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.
      
        If there is a dispute between participants on the Platform, or between Users and any third party, you agree that BuzzVibe is under no obligation to become involved. 
        In the event that you have a dispute with one or more other Users, you release BuzzVibe, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, 
        suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. 
        In entering into this release, you expressly waive any protections (whether statutory or otherwise) that would otherwise limit the coverage of this release to include only those claims that you may know or suspect to exist in your favor at the time of agreeing to this release.`,
      },
      {
        title: 'FEES AND PAYMENTS',
        content: `The Services may be free or we may charge a fee for using the Services. 
        If you are using a free version of the Services, we will notify you before any Services you are then using begin carrying a fee, 
        and if you wish to continue using such Services, you must pay all applicable fees for such Services. 
        Note that if you elect to receive text messages through the Platform, data and message rates may apply. 
        Any and all such charges, fees, or costs are your sole responsibility. You should consult with your wireless carrier to determine what rates, charges, fees, or costs may apply to your use of the Platform or Services. 
      
        Certain of our Services may be subject to payments now or in the future (collectively, the “Paid Services”). 
        Please read on for further information and details regarding certain of our Paid Services. 
        Please note that any payment terms presented to you in the process of using or signing up for a Paid Service are deemed part of these Terms.`,
      },
      {
        title: 'BILLING',
        content: `We use third-party payment processors (the “Payment Processors”) to bill you through a payment account linked to your account on the Platform (your “Billing Account”) for use of the Paid Services (including the purchase of any Merchandise). 
        The processing of payments will be subject to the terms, conditions, and privacy policies of the applicable Payment Processor in addition to these Terms. 
        Please see below for additional information on specific Payment Processors that we use for different Paid Services. We are not responsible for any error by, or other acts or omissions of, any Payment Processor.
      
        By choosing to use Paid Services or purchase any Merchandise, you agree to pay through the applicable Payment Processor all charges for any use of such Paid Services (including the purchase of any Merchandise) in accordance with the applicable payment terms, 
        and you authorize us through the Payment Processors to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that selected Payment Method. 
        We reserve the right to correct any errors or mistakes that a Payment Processor makes even if it has already requested or received payment.
      
        The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer, or other providers of your chosen Payment Method. 
        If we do not receive payment from you, you agree to pay all amounts due on your account upon demand.`,
      },
      {
        title: 'REQUIRED INFORMATION',
        content: `YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT (INCLUDING CURRENT, COMPLETE AND ACCURATE CREDIT CARD AND BILLING INFORMATION). 
        YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), 
        AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, 
        SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER ID OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE IN ACCOUNT SETTINGS. 
        IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.`,
      },
      {
        title: 'CHANGE IN AMOUNT AUTHORIZED',
        content: `If the amount to be charged to your Billing Account varies from the amount you preauthorized (other than due to the imposition or change in the amount of state sales taxes or other taxes, customs charges or duties, 
        or a change in shipping method requested by you), you have the right to receive, and we shall provide a notice of the amount to be charged and the date of the charge before the scheduled date of the transaction. 
        Any agreement you have with your payment provider will govern your use of your Payment Method. 
        You agree that we may accumulate charges incurred and submit them as one or more aggregate charges during or at the end of each billing cycle.`,
      },
      {
        title: 'COMMISSIONS',
        content: `Certain of our Services may charge a commission. BuzzVibe will charge you a commission for each sale in accordance with its then-current commission rate and payment structure, which is available on the Platform. 
        BuzzVibe will deduct the amounts due for the commission from the amounts payable to Seller for each transaction facilitated through the Platform. 
        BuzzVibe reserves the right to change its commission rates at any time upon notice to you. Such changes shall be effective for all listings of Merchandise on the Platform as of the date provided in BuzzVibe’s notice.
        Currently, we use Stripe, Inc. as our Payment Processor if you purchase or sell Merchandise on our Platform. 
        You can access Stripe’s Terms of Service at Stripe Checkout User Terms and their Privacy Policy at Stripe Privacy Policy.`,
      },
      {
        title: 'THIRD PARTY CHARGES',
        content: `You are solely responsible for any fees or charges incurred to access the Platform through an internet access or mobile access provider, or other third-party.`,
      },
      {
        title: 'FEEDBACK, REVIEWS & RATINGS',
        content: `By submitting feedback, ideas, ratings, reviews, or suggestions ("Feedback") to BuzzVibe, publicly through web pages (e.g., forums), or privately through customer service, you acknowledge and agree that: 
        (a) your Feedback automatically becomes the property of BuzzVibe without any obligation of BuzzVibe to you; 
        (b) your Feedback does not contain confidential or proprietary information of you or any third party; 
        (c) BuzzVibe may use or disclose such Feedback for any purpose, in any way, in any media worldwide; 
        (d) BuzzVibe may have something similar to the Feedback already under consideration or in development, and 
        (e) you are not entitled to any compensation or reimbursement of any kind from BuzzVibe.`,
      },
      {
        title: 'TERMINATION',
        content: `You are free to stop using the Platform and Services at any time by contacting us at [support@buzzvibe.com]; 
        please refer to our Privacy Policy, as well as the licenses above, to understand how we treat the information you provide to us after you have stopped using our Platform or Services. 
      
        You agree that BuzzVibe may terminate or suspend your BuzzVibe account and access to the Platform and Services at any time, without notice, for any reason, including but not be limited to: 
        (a) breaches or violations of these Terms, including any policies or documents incorporated by reference, or any other agreement you have entered into with BuzzVibe, 
        (b) requests by law enforcement or other government agencies, 
        (c) a request by you to delete your account, 
        (d) discontinuance or material modification to the Platform or Services, 
        (e) unexpected technical or security issues or problems, 
        (f) extended periods of inactivity, 
        (g) engagement by you in fraudulent or illegal activities, or 
        (h) the nonpayment of any fees owed by you in connection with the Services. BuzzVibe has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms. 
      
        Account termination may result in the destruction of any Content associated with your account. BuzzVibe may delete your Content, your password, and all related information, and files and content associated with your account. 
        You agree that BuzzVibe shall not be liable to you or any third party for any termination of your account or access to the Platform or Services.
      
        If you have deleted your account by mistake, contact us immediately at [support@buzzvibe.com] – we will try to help, but unfortunately, we cannot promise that we can recover or restore anything. 
      
        Provisions that, by their nature, should survive termination of these Terms shall survive termination, including but not limited to any obligation you have to pay us or indemnify us, 
        any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.`,
      },
      {
        title: 'DISCLAIMER OF WARRANTIES',
        content: `Your use of the Platform and Services is at your sole risk. 
        BuzzVibe and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract employees, representatives, and agents, 
        and each of their respective successors and assigns (BuzzVibe and all such parties together, the “BuzzVibe Parties”) make no representations or warranties concerning the Platform or Services, 
        including without limitation regarding any Content contained in or accessed through the Platform, Services, or any Merchandise, 
        and the BuzzVibe Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Platform 
        or any claims, actions, suits, procedures, costs, expenses, damages, or liabilities arising out of use of, or in any way related to your participation in, the Platform or Services. 
      
        The BuzzVibe Parties make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through or in connection with the Platform, 
        including, without limitation, any Merchandise. Products and services purchased or offered through the Platform are provided “AS IS” and without any warranty of any kind from the BuzzVibe Parties or others 
        (unless, with respect to such others only, provided expressly and unambiguously in writing by a designated third party for a specific product).  
      
        THE PLATFORM, SERVICES, AND CONTENT ARE PROVIDED BY BUZZVIBE (AND ITS LICENSORS AND SUPPLIERS) ON AN "AS IS" AND "AS AVAILABLE" BASIS, 
        WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, 
        OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. 
      
        SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.`,
      },
      {
        title: 'LIMITATION OF LIABILITY',
        content: `TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) 
        SHALL ANY OF THE BUZZVIBE PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, 
        INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, 
        (B) ANY SUBSTITUTE MERCHANDISE, SERVICES, OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS, 
        (II) THE AMOUNT DUE UNDER OUR THEN-CURRENT REFUND POLICY, OR (III) THE FAIR MARKET VALUE OR LISTING PRICE OF THE MERCHANDISE ITEM SUBJECT TO THE APPLICABLE CLAIM, 
        OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, 
        SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
      
        You agree that you must evaluate, and bear all risks associated with, the use of any Content and items, including any reliance on the accuracy, completeness, or usefulness of such Content and items.`,
      },
      {
        title: 'INDEMNIFICATION',
        content: `You agree to indemnify and hold the BuzzVibe Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses, and expenses (including attorneys’ fees) 
        arising from or in any way related to any claims relating to: 
        (a) your use of the Platform or Services (including any actions taken by a third party using your account) and any Merchandise (including any Merchandise you buy, sell, or offer to sell using the Platform), 
        (b) your violation of these Terms or any other BuzzVibe policies, 
        (c) your violation of any applicable law, rule, or regulation or any rights of another including, without limitation, any violations of third-party intellectual property rights or the requirements under California’s Proposition 65, and/or 
        (d) any misrepresentation made by you.`,
      },
      {
        title: 'ASSIGNMENT',
        content: `You may not assign, delegate, or transfer these Terms or your rights or obligations hereunder, or your account, in any way (by operation of law or otherwise) without BuzzVibe’s prior written consent. 
        We may transfer, assign, or delegate these Terms and our rights and obligations without consent. Any assignment in violation of this provision is null and void.`,
      },
      {
        title: 'CHOICE OF LAW',
        content: `These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of the State of Nevada, without regard to the conflicts of laws provisions thereof. 
        The United Nations Convention on Contracts for the International Sale of Merchandise does not apply.`,
      },
      {
        title: 'ARBITRATION AGREEMENT',
        content: `Please read the following ARBITRATION AGREEMENT (“Arbitration Agreement”) carefully because it requires you to arbitrate certain disputes and claims with BuzzVibe and limits the manner in which you can seek relief from BuzzVibe. 
        Both you and BuzzVibe acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of these Terms, BuzzVibe’s officers, directors, employees and independent contractors (“Personnel”) 
        are third-party beneficiaries of these Terms and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.
      
        Arbitration Rules; Applicability of Arbitration Agreement: The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these Terms directly through good-faith negotiations, 
        which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in Los Angeles County, California. 
        The arbitration will proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes. 
        The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.
      
        Costs of Arbitration: The Rules will govern payment of all arbitration fees.
      
        Waiver of Jury Trial: YOU AND BUZZVIBE WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. 
        You and BuzzVibe are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. 
        In any litigation between you and BuzzVibe over whether to vacate or enforce an arbitration award, YOU AND BUZZVIBE WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.
      
        Waiver of Class or Consolidated Actions: ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. 
        CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. 
        If, however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor BuzzVibe is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in the “Exclusive Venue” section below.
      
        Opt-out: You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following address: [___________] postmarked within thirty (30) days of first accepting these Terms. 
        You must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of these Terms’ Arbitration Agreement.
      
        Exclusive Venue: If you send the opt-out notice above, and/or in any circumstances where the foregoing Arbitration Agreement permits either you or BuzzVibe to litigate any dispute arising out of or relating to the subject matter of these Terms in court, 
        then the foregoing Arbitration Agreement will not apply to either party, and both you and BuzzVibe agree that any judicial proceeding (other than small claims actions) will be brought in the state or federal courts located in Nevada.
      
        Severability: If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. 
        All other provisions of these Terms will remain in full force and effect. This Arbitration Agreement will survive the termination of your relationship with BuzzVibe.
      
        Exceptions for Small Claims and Intellectual Property Infringement: Notwithstanding this Arbitration Agreement, either you or BuzzVibe may assert claims, if they qualify, in small claims court in Nevada or any United States county where you live or work. 
        Furthermore, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights.`,
      },
      {
        title: 'MISCELLANEOUS',
        content: `BuzzVibe shall not be held liable or responsible to you nor be deemed to have defaulted under or breached these Terms for any failure or delay in fulfilling or performing any obligation of these Terms 
        where such failure or delay is caused by or results from causes beyond the reasonable control of BuzzVibe, including but not limited to, the elements, fire, floods, severe weather, earthquake, epidemics or pandemics, vandalism, accidents, 
        sabotage, power failure, denial of service attacks or similar attacks, Internet failure, embargoes, war, acts of war (whether war be declared or not), acts of terrorism, insurrections, riots, civil commotions, strikes, lockouts or other labor disturbances, 
        acts of God or acts, omissions or delays in acting by any governmental authority or by you or another User. 
      
        The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. 
        If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. 
      
        You and BuzzVibe agree that these Terms are the complete and exclusive statement of the mutual understanding between you and BuzzVibe and that these Terms supersede and cancel all previous written and oral agreements, communications, and other understandings relating to the subject matter of these Terms. 
        You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of BuzzVibe, and you do not have any authority of any kind to bind BuzzVibe in any respect whatsoever.
      
        Except as expressly set forth in the section above regarding the Arbitration Agreement, you and BuzzVibe agree there are no third-party beneficiaries intended under these Terms.`,
      },
      {
        title: 'COPYRIGHT DISPUTE POLICY AND PROCEDURE',
        content: `BuzzVibe respects the intellectual property of others and all Users are expected to do the same. 
        The Digital Millennium Copyright Act (the “DMCA”), contains certain provisions related to online service providers, like BuzzVibe, whereby such providers may be asked to remove material that allegedly violates a third party’s copyright. 
        In accordance with the DMCA, we have adopted the following policy governing copyright infringement. 
        We reserve the right to (1) block access to or remove material that we believe in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members, or Users 
        and (2) remove and discontinue service to repeat offenders.
      
        Procedure for Reporting Copyright Infringements: If you believe that material or content residing on or accessible through the Platform infringes your copyright 
        (or the copyright of someone whom you are authorized to act on behalf of), please send a notice of copyright infringement containing the following information to BuzzVibe’s Designated Agent to Receive Notification of Claimed Infringement:
        
        - A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;
        - Identification of works or materials being infringed;
        - Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that BuzzVibe is capable of finding and verifying its existence;
        - Contact information about the notifier including address, telephone number, and, if available, email address;
        - A statement that the notifier has a good faith belief that the material identified above is not authorized by the copyright owner, its agent, or the law; and
        - A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.
      
        Receipt of a Proper Bona Fide Infringement Notification by the Designated Agent: Upon receipt of a proper notice of copyright infringement, we reserve the right to:
        
        - remove or disable access to the infringing material;
        - notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and
        - terminate such content provider's access to the Platform and Services if they are a repeat offender.
      
        Procedure to Supply a Counter-Notice to the Designated Agent: If the content provider believes that the material that was removed (or to which access was disabled) is not infringing, or the content provider believes that it has the right to post and use such material 
        from the copyright owner, the copyright owner's agent, or, pursuant to the law, the content provider may send us a counter-notice containing the following information to the Designated Agent:
      
        - A physical or electronic signature of the content provider;
        - Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled;
        - A statement that the content provider has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material; and
        - Content provider's name, address, telephone number, and, if available, email address, and a statement that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in which the content provider’s address is located, or, if the content provider's address is located outside the United States, 
          for any judicial district in which BuzzVibe is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.
      
        If a counter-notice is received by the Designated Agent, BuzzVibe may, in its discretion, send a copy of the counter-notice to the original complaining party informing that person that BuzzVibe may replace the removed material or cease disabling it in 10 business days. 
        Unless the copyright owner files an action seeking a court order against the content provider accused of committing infringement, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at BuzzVibe’s discretion.
        
        Please contact BuzzVibe’s Designated Agent at the following address:
        By email to: support@buzzvibe.com`,
      },
      {
        title: 'TRADEMARKS',
        content: `BuzzVibe is a marketplace made up of individual third-party sellers who run their own storefronts, create their own policies, and are responsible for their inventory, postings, and compliance with the law. 
        While BuzzVibe provides a Platform for Buyers and Sellers to connect, BuzzVibe does not manufacture Merchandise, hold inventory, or take ownership of items except in connection with BuzzVibe Merchandise. 
        The content uploaded to the Platform is created by independent Sellers who are not employees, agents, or representatives of BuzzVibe. 
        Sellers are responsible for ensuring they have all necessary rights to the items they are selling and their content, and that they are not infringing or violating any third party’s rights by posting it on the Platform.
      
        BuzzVibe respects the rights of individual designers, artists, and creators whose Merchandise is sold on the Platform. 
        BuzzVibe reserves the right to disable any listing, storefront, or Seller account that we believe violates these Terms. 
        BuzzVibe also reserves the right to take action against violators or alleged violators of these Terms and/or the intellectual property rights of third parties.
      
        BuzzVibe is not in a position to offer legal advice or make legal determinations whether a Seller’s storefront contains content that infringes someone else’s intellectual property. 
        BuzzVibe will remove the material cited for alleged intellectual property infringement when provided with a report that provides the information set forth below.
      
        Reports of Trademark Infringement: If you believe that the storefront of a Seller on the Platform contains material that infringes on your trademark rights, 
        you may submit a report to BuzzVibe at: [support@buzzvibe.com] with the subject line: Trademark Dispute. 
        Please include the following information in your report:
      
        - Identification of the trademark or trademarks being infringed, including the Federal Trademark Registration numbers for each trademark;
        - Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the trademark holder seeks to have removed, with sufficient detail so that BuzzVibe is capable of finding and verifying its existence;
        - Contact information about the trademark owner including mailing address, telephone number, and email address;
        - A statement that the trademark owner has a good faith belief that the material identified in the report is not authorized by the trademark owner, its agent, or the law; and
        - A statement made under penalty of perjury that the information provided in the report is accurate and the party submitting the report is authorized to make the complaint on behalf of the trademark owner.
      
        BuzzVibe strives to respond quickly when we receive a report of trademark infringement that complies with our policies by removing or disabling access to the allegedly infringing material. 
        BuzzVibe will make a reasonable attempt to contact the accused Seller, provide information about the report and removal, and may also provide a copy of the report to the accused Seller. 
      
        Please note that BuzzVibe may request additional information before processing a report, such as identity verification of the reporting party or documentation regarding the claimed right. 
        BuzzVibe reserves the right to reject reports of infringement that contain information we believe is false, fraudulent, incomplete, or otherwise submitted in bad faith. 
        BuzzVibe also reserves the right to take action against abusers of this policy.
      
        Withdrawals of Reports: A report of alleged infringement may be withdrawn only by the trademark owner or authorized representative of the trademark owner who submitted the initial report. 
        The withdrawal must be submitted to [support@buzzvibe.com], must clearly state that it is a formal withdrawal, and sufficiently identify the Seller and/or material subject to the report by providing the Seller storefront name and/or listing URLs. 
      
        When BuzzVibe receives a formal withdrawal of an infringement report, BuzzVibe will make reasonable attempts to contact both parties involved to confirm receipt of the withdrawal and to inform the Seller affected by the withdrawal. 
        Please note that infringement matters are reviewed on a case-by-case basis, and withdrawals do not guarantee changes to a Seller’s storefront or status.`,
      },
                                                                  
                                                                        
  ];

  const toggleSection = (index) => {
    setOpenSections((prev) => {
      const newOpenSections = [...prev];
      newOpenSections[index] = !newOpenSections[index];
      return newOpenSections;
    });
  };

  return (
    <div className="terms-conditions-container">
      <h1 className="terms-conditions-header">TERMS OF SERVICE</h1>
      <p className="terms-conditions-intro">
      BuzzVibe LLC operates an online marketplace curated for buyers and sellers of unique merchandise.  These Terms of Service (as amended from time to time, the “Terms”) specify the rules and restrictions that govern your use of our platform (the “Platform”), including websites, applications, products, and services (including all data, contents, and computer code contained on or transmitted through the Platform) (collectively, the “Services”). If you have any questions, comments, or concerns regarding these Terms, please contact us at: support@buzzvibe.com.
      </p>
      <div className="terms-conditions-section-container">
        {termsData.map((term, index) => (
          <div key={index} className="terms-conditions-section">
            <button
              className={`terms-conditions-section-header ${
                openSections[index] ? 'terms-conditions-section-header-open' : ''
              }`}
              onClick={() => toggleSection(index)}
            >
              <span>{index + 1}. {term.title}</span>
              <span>{openSections[index] ? '▲' : '▼'}</span>
            </button>
            {openSections[index] && (
              <p className="terms-conditions-section-content">{term.content}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsAndConditions;

