import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../server";
import OnboardingSteps from "../components/Shop/OnboardingSteps";
import OnboardingStepsMobile from "../components/Shop/OnboardingStepsMobile";
import useWindowSize from "../hooks/useWindowSize";

const SellerActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSetupButton, setShowSetupButton] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    console.log("Activation token from URL:", activation_token);

    if (activation_token) {
      activateShop();
      setShowSetupButton(true);
    }
  }, [activation_token]);

  const activateShop = async () => {
    try {
      const response = await axios.post(`${server}/shop/activation`, {
        activation_token,
      });
      setShowSetupButton(true);
    } catch (err) {
      const message = err.response?.data?.message || "An error occurred during activation";
      console.error("Activation error:", message); 
      setErrorMessage(message);

      if (err.response?.data?.message === "Shop already exists") {
        setShowSetupButton(true);
      } else {
        setError(true);
      }
    }
  };

  const isMobile = windowSize.width <= 768; 

  return (
    <div>{isMobile ? (
      <OnboardingStepsMobile currentStep={2} />
    ) : (
      <OnboardingSteps currentStep={2} />
    )}
    <div className="seller-activation-container">
      <div className="seller-activation-content">
        

        {error ? (
          <p className="seller-activation-error">{errorMessage}</p>
        ) : (
          <div className="seller-activation-success">
            <p>Your account has been activated successfully! Please complete your payment setup to start receiving payments.</p>
            {showSetupButton && (
              <button
                onClick={() => window.location.href = "https://buzzvibe.com/shop-login"}
                className="seller-activation-setup-button"
              >
                Complete Setup
              </button>
            )}
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default SellerActivationPage;


