import React, { useEffect, useState, useRef } from 'react';
import { Stream } from "@cloudflare/stream-react";

const VideoPlayer = ({ videoUrl }) => {
  const [isMuted, setIsMuted] = useState(true);
  const streamRef = useRef(null); // Reference for the Stream player

  const handleUserInteraction = () => {
    setIsMuted(false); // Unmute when the user interacts
    if (streamRef.current) {
      streamRef.current.play(); // Play the video when clicked
    }
  };

  useEffect(() => {
    if (streamRef.current) {
      // Play video automatically when ready
      streamRef.current.play().catch((error) => {
        console.log('Autoplay prevented:', error);
      });
    }
  }, [streamRef]);

  return (
    <Stream
      controls
      src={videoUrl}
      muted={isMuted}
      autoplay={false} // Prevent autoplay initially, allow it to start on user interaction
      streamRef={streamRef} // Assign the ref to control the player
      onClick={handleUserInteraction} // Unmute and play on click
      preload="auto" // Preload video for faster load time
      responsive={true} // Responsive player
      loop={true}
    />
  );
};

export default VideoPlayer;





