import React from 'react';
import cn from 'classnames';

import Button from './Button';
import './Navigation.scss';
import CropIcon from './Icons/CropIcon';
import SaturationIcon from './Icons/SaturationIcon';
import BrightnessIcon from './Icons/BrightnessIcon';
import ContrastIcon from './Icons/ContrastIcon';
import HueIcon from './Icons/HueIcon';


/**
 * @typedef {Object} Props
 * @property {string} [className]
 * @property {string} [mode]
 * @property {(mode: string) => void} [onChange]
 * @property {() => void} [onDownload]
 * @property {(blob: string) => void} [onUpload]
 */

const Navigation = (/** @type {Props} */ props) => {
    const { className, onChange, mode } = props;

    const setMode = (mode) => () => {
        onChange?.(mode);
    };

    return (
        <div className={cn('image-editor-navigation', className)}>
            <div className="image-editor-navigation__buttons">
                <Button
                    className={'image-editor-navigation__button'}
                    active={mode === 'crop'}
                    onClick={setMode('crop')}
                >
                    <CropIcon />
                </Button>
                <Button
                    className={'image-editor-navigation__button'}
                    active={mode === 'saturation'}
                    onClick={setMode('saturation')}
                >
                    <SaturationIcon />
                </Button>
                <Button
                    className={'image-editor-navigation__button'}
                    active={mode === 'brightness'}
                    onClick={setMode('brightness')}
                >
                    <BrightnessIcon />
                </Button>
                <Button
                    className={'image-editor-navigation__button'}
                    active={mode === 'contrast'}
                    onClick={setMode('contrast')}
                >
                    <ContrastIcon />
                </Button>
                <Button className={'image-editor-navigation__button'} active={mode === 'hue'} onClick={setMode('hue')}>
                    <HueIcon />
                </Button>
            </div>
        </div>
    );
};

export default Navigation;
