import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CategoryScroller from '../Marketplace/CategoryScroller';
import TagScroller from './TagScroller';
import axios from 'axios'; 
import { ecommerceServer } from "../../server";
import { toggleTopLevelDropdown } from "../../state/uiSlice";
import { useDispatch } from "react-redux";

const MarketplaceDropdown = ({ categoriesData, setDropDown }) => {
  const navigate = useNavigate();
  const dropdownRef = useRef();
  const [selectedCategory, setSelectedCategory] = useState(categoriesData[0]);
  const [tags, setTags] = useState([]);
  const [featuredData, setFeaturedData] = useState({ products: [], shop: null });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(`${ecommerceServer}/tags/tags/approved`)
      .then((res) => setTags(res.data))
      .catch((err) => console.error(err));
  }, []);

  // Fetch featured products and shop when the selected category changes
  useEffect(() => {
    const fetchFeaturedData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${ecommerceServer}/product/featured/${encodeURIComponent(selectedCategory.title)}`,
          { withCredentials: true }
        );
        setFeaturedData(response.data);
      } catch (error) {
        console.error('Error fetching featured data:', error);
        setError('Failed to load featured data.');
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedData();
  }, [selectedCategory]);

  // Handle category selection from CategoryScroller
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  // Handle subcategory navigation
  const handleSubCategoryClick = (subCategory) => {
    navigate(`/products/${selectedCategory.title}/${subCategory.title}`);
    dispatch(toggleTopLevelDropdown());
    window.location.reload();
  };

  // Handle tag click
  const handleTagClick = (tag) => {
    navigate(`/products/tag/${encodeURIComponent(tag.name)}`);
    dispatch(toggleTopLevelDropdown());
  };

  return (
    <div ref={dropdownRef} className="marketplace-dropdown-container">
      <CategoryScroller categoriesData={categoriesData} onCategorySelect={handleCategorySelect} />

      {/* Subcategories Section */}
      <div className="marketplace-dropdown-subcategories">
        {selectedCategory.subCategories.map((subCategory) => (
          <div
            key={subCategory.id}
            className="marketplace-dropdown-subcategory"
            onClick={() => handleSubCategoryClick(subCategory)}
          >
            <span className="marketplace-dropdown-subcategory-text">{subCategory.title}</span>
          </div>
        ))}
      </div>

      

      <div className="marketplace-dropdown-inner">
        {/* Featured Products and Shop Section */}
        <div className="marketplace-dropdown-section">
          {loading && <div>Loading featured data...</div>}
          {error && <div>{error}</div>}
          {!loading && !error && (
            <>
            <div className="marketplace-dropdown-feature-products">
              {/* Featured Products */}
              {featuredData.products.map((product, index) => (
                <div
                  key={index}
                  className="marketplace-dropdown-product"
                  onClick={() => {
                    navigate(`/product/${product._id}`);
                    dispatch(toggleTopLevelDropdown());
                  }}
                >
                  <img
                    src={product.images[0]?.url || 'default-image-url'}
                    alt={product.name}
                    className="marketplace-dropdown-product-image"
                  />
                  <div>
                    <span className="marketplace-dropdown-product-title">{product.name}</span>
                    <div className="marketplace-dropdown-product-shop">{product.shop?.name}</div>
                  </div>
                </div>
              ))}
              </div>

              {/* Featured Shop */}
              {featuredData.shop && (
                <div
                  className="marketplace-dropdown-featured-shop"
                  onClick={() => {
                    navigate(`/shop/${featuredData.shop?.handle}`);
                    dispatch(toggleTopLevelDropdown());
                  }}
                >
                  <div className="marketplace-dropdown-featured-shop-inner">
                    <img
                      src={featuredData.shop.banner?.url || 'default-shop-image-url'}
                      alt={featuredData.shop.name}
                      className="marketplace-dropdown-featured-shop-image"
                    />
                    <div className="marketplace-dropdown-featured-shop-overlay">
                      <span className="marketplace-dropdown-featured-shop-text">{featuredData.shop.name}</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketplaceDropdown;
