import React from 'react';

const categories = [
    {
      name: "New Arrivals",
      href: "/new-arrivals", // Updated to link to new arrivals route
      imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393239/couture-category-buzzvibe_qimgdb.jpg",
    },
    {
      name: "Favorites",
      href: "/buzzvibe-favorites", // If you have a special route for favorites
      imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393233/art-category-buzzvibe_cfepbq.jpg",
    },
    {
      name: "Top Rated",
      href: "/top-rated", // Updated to link to top rated route
      imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1702599501/b5_lpnl4j.jpg",
    },
    {
      name: "Trending",
      href: "/trending", // Updated to link to trending products route
      imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393249/vintage-fashion-category-buzzvibe_l77p4f.jpg",
    },
    {
      name: "Sale",
      href: "/sale", // Updated if you have a special sale route
      imageSrc: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393583/collectibles-category-buzzvibe_xeeuv2.jpg",
    },
  ];

export default function CategorySection() {
    return (
        <div className="category-section-component-bg">
            <div className="category-section-component-container">
                <div className="category-section-component-header">
                    <h2 className="category-section-component-title">Shop by Category</h2>
                    <a href="#" className="category-section-component-browse-link">
                        Browse all Categories
                        <span aria-hidden="true"> &rarr;</span>
                    </a>
                </div>

                <div className="category-section-component-categories">
                    <div className="category-section-component-categories-inner">
                        <div className="category-section-component-categories-list">
                            {categories.map((category) => (
                                <a
                                    key={category.name}
                                    href={category.href}
                                    className="category-section-component-category"
                                >
                                    <span aria-hidden="true" className="category-section-component-category-image-wrapper">
                                        <img src={category.imageSrc} alt="" className="category-section-component-category-image" />
                                    </span>
                                    <span
                                        aria-hidden="true"
                                        className="category-section-component-category-gradient"
                                    />
                                    <span className="category-section-component-category-name">{category.name}</span>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="category-section-component-browse-mobile">
                    <a href="#" className="category-section-component-browse-link-mobile">
                        Browse all Categories
                        <span aria-hidden="true"> &rarr;</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

  
