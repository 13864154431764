import React, { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { AiOutlineCamera } from "react-icons/ai";
import { loadSeller } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ImageCropperModal from "../Image/ImageCropperModal";

const ShopBanner = ({ isOwner, shop, setShop }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shopBanner, setShopBanner] = useState(null);
  const [showBannerCropper, setShowBannerCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const currentProfile = sessionStorage.getItem("currentProfile");
  const dispatch = useDispatch();

  useEffect(() => {
    setShopBanner(shop.banner?.url || null);
  }, [shop]);

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowBannerCropper(true);
    }
  };

  const updateBanner = (croppedFile) => {
    setCroppedFile(croppedFile);
    const previewUrl = URL.createObjectURL(croppedFile);
    setShopBanner(previewUrl);
    setShowSaveButton(true);
  };

  const handleSaveBanner = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("banner", croppedFile);

      await axios.put(
        `${server}/shop/update-shop-banner`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "Current-Profile": currentProfile,
          },
        }
      );
      dispatch(loadSeller());
      toast.success("Banner updated successfully!");
      setShowSaveButton(false);
      setShop({ ...shop, banner: { url: shopBanner } });
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update banner");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="shop-banner-container">
      <img
        src={shopBanner || 'default-banner.png'}
        alt="Shop Banner"
        className="shop-banner-image"
      />
      <div className="banner-overlay"></div>
      {isOwner && (
        <div className="shop-banner-controls">
          <div className="shop-banner-edit-icon">
            <input
              type="file"
              id="banner"
              className="shop-banner-file-input"
              accept=".jpg, .jpeg, .png, .gif, .webp"
              onChange={handleBannerChange}
            />
            <label htmlFor="banner">
              <AiOutlineCamera className="shop-banner-camera-icon" />
            </label>
          </div>
          {showSaveButton && (
            <button
              className="shop-banner-save-button"
              onClick={handleSaveBanner}
              disabled={!croppedFile}
            >
              Save
            </button>
          )}
        </div>
      )}

      {showBannerCropper && (
        <ImageCropperModal
          updateAvatar={updateBanner}
          closeModal={() => setShowBannerCropper(false)}
          initialImage={selectedFile}
          aspectRatio={4}
          minWidth={500}
          isAvatar={false}
          isPost={false}
        />
      )}
    </div>
  );
};

export default ShopBanner;
