import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import ShopInfo from "../../components/Shop/ShopInfo";
import ShopProfileData from "../../components/Shop/ShopProfileData";
import ShopBanner from '../../components/Shop/ShopBanner';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import MixedSponsoredAds from '../../components/Sponsored/MixedSponsoredAds';
import FollowButton from '../../components/Connections/FollowButton';
import FollowersDisplay from '../../components/Connections/FollowersDisplay';
import MyPostWidgetRedux from '../../widgets/MyPostWidgetRedux';
import FollowingDisplay from '../../components/Connections/FollowingDisplay';
import ShopAvatar from '../../components/Shop/ShopAvatar';
import RoomsProfile from '../../components/Rooms/RoomsProfile';
import MyEvents from '../../components/Events/MyEvents';
import MyContentCarousel from '../../components/Profile/MyContentCarousel';
import MyShopImages from '../../components/Content/MyShopImages';
import EventsParentContainer from '../../components/Events/EventsParentContainer';

const ShopHomePage = () => {
  const [shop, setShop] = useState({});
  const { handle } = useParams();
  const dispatch = useDispatch();
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const isOwner = true;

  useEffect(() => {
    // Fetch shop info and products
    axios.get(`${server}/shop/handle-to-id/${handle}`)
      .then(res => {
        const id = res.data.id;
        dispatch(getAllProductsShop(id));
        return axios.get(`${server}/shop/get-shop-info/${id}`);
      })
      .then(res => setShop(res.data.shop))
      .catch(error => console.log(error));
  }, [handle, dispatch]);

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="shop-profile-container">
        {/* Shop Banner */}
        <ShopBanner isOwner={isOwner} shop={shop} setShop={setShop} />

        {/* Top Row Overlay */}
        <div className="shop-avatar-container-overlay">
          <div className="shop-avatar-profile-wrapper">
            <div className="shop-info-sidebar-avatar-container">
              <ShopAvatar isOwner={isOwner} shop={shop} setShop={setShop} />
            </div>
            <h3 className="shop-info-sidebar-name">{shop?.name}</h3>
          </div>
          {shop && shop._id && currentProfile && (
            <div className="shop-info-sidebar-follow-section">
              <div className="profile-follow-overview-container">
                <FollowersDisplay userId={shop?._id} profileType="Shop" />
                <FollowingDisplay userId={shop?._id} profileType="Shop" />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Main Content */}
      <div className="profile-main-content">
        <aside className="profile-sidebar-left">
        {shop && shop._id && (
          <>
          
          <MyShopImages shopId={shop?._id} />
          <EventsParentContainer ownerId={shop?._id}/>
         
          </>
        )}
          
         
          <ShopInfo isOwner={isOwner} />
        </aside>

        <main className="profile-main-area">
          <MyPostWidgetRedux />
          <ShopProfileData isOwner={isOwner} />
        </main>

        <aside className="profile-sidebar-right">
        
          <MixedSponsoredAds />
        </aside>
      </div>
    </div>
  );
};

export default ShopHomePage;

