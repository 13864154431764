import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const UserProtectedRoute = ({ children }) => {
  // Extract the userId from the URL parameters
  const { id } = useParams();
  const navigate = useNavigate();

  // Access the user state from Redux
  const { isLoading, user } = useSelector((state) => state.user);

  useEffect(() => {
    // Only perform the check once the user data has finished loading
    if (!isLoading) {
      // If there's no user or the userId doesn't match, redirect
      if (!user || user._id !== id) {
        navigate(`/profile/${id}`, { replace: true });
      }
    }
  }, [isLoading, user, id, navigate]);

  // While loading or if the user isn't authorized, don't render the children
  if (!user || user._id !== id) {
    return null; // You can replace this with a loading spinner or an error message if desired
  }

  // If the user is authorized, render the child components
  return children;
};

export default UserProtectedRoute;

