import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';

const Navbar = ({ active }) => {
  const userProfile = useSelector((state) => state.user.user);
  const sellerProfiles = useSelector((state) => state.seller.sellers);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const profile = currentProfile === 'User' ? userProfile : sellerProfiles.find((seller) => seller.name === currentProfile);
  const isUser = currentProfile === 'User';

  const logoutHandler = () => {
    axios
      .post(`${server}/user/logout`, {}, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        setTimeout(() => {
          window.location.href = 'https://buzzvibe.com';
        }, 1000);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <div className="mobile-navbar-container">
      {isAuthenticated ? (
        <div className="mobile-navbar-profile-section">
          <div className="mobile-navbar-profile-item">
            <Link
              to={isUser ? `/my-profile/${profile?._id}` : `/shop/preview/${profile?.handle}`}
              className={`mobile-navbar-link ${active === 1 ? 'mobile-navbar-active' : ''}`}
            >
              My Profile
            </Link>
          </div>

          <div className="mobile-navbar-profile-item">
            <Link
              to={isUser ? '/profile' : '/settings'}
              className={`mobile-navbar-link ${active === 2 ? 'mobile-navbar-active' : ''}`}
            >
              Account Settings
            </Link>
          </div>

          <div className="mobile-navbar-profile-item">
            <Link
              to="/shop-create"
              className={`mobile-navbar-link ${active === 3 ? 'mobile-navbar-active' : ''}`}
            >
              BuzzVibe Biz
            </Link>
          </div>

          <div className="mobile-navbar-profile-item">
            <div
              onClick={logoutHandler}
              className={`mobile-navbar-link mobile-navbar-link-cursor ${active === 4 ? 'mobile-navbar-active' : ''}`}
            >
              Log out
            </div>
          </div>
        </div>
      ) : (
        <div className="mobile-navbar-auth-links">
          <Link
            to="/login"
            className={`mobile-navbar-link ${active === 5 ? 'mobile-navbar-active' : ''}`}
          >
            Login
          </Link>
          <Link
            to="/signup"
            className={`mobile-navbar-link ${active === 6 ? 'mobile-navbar-active' : ''}`}
          >
            Sign up
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;

