import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
];

const shopTiers = [
  {
    name: 'No-G Shop',
    id: 'tier-basic-shop',
    description: 'Perfect for small shops starting out.',
    features: ['10 product listings', 'Email support', 'Basic analytics', 'Community access'],
    mostPopular: false,
    price: { monthly: 'Free', annually: 'Free' },
    priceId: { monthly: 'price_1Py9XKP4OgHr2xe6d0rugodI', annually: 'price_1Py9XKP4OgHr2xe6d0rugodI' },
  },
  {
    name: 'OG Shop',
    id: 'tier-advanced-shop',
    description: 'Advanced features for growing shops.',
    features: [
      'Up to 100 product listings',
      'Priority email support',
      'Advanced analytics',
      'Marketing tools integration',
      '1 promotional campaign per month',
    ],
    mostPopular: true,
    price: { monthly: '$50', annually: '$500' },
    priceId: { monthly: 'price_1POJS0P4OgHr2xe6is2bUBJA', annually: 'price_1POJSmP4OgHr2xe6EIE34Txp' },
  },
  {
    name: 'Super OG Shop',
    id: 'tier-enterprise-shop',
    description: 'Comprehensive solutions for large operations.',
    features: [
      'Unlimited product listings',
      'Dedicated support',
      'Full analytics suite',
      'Custom API integrations',
      'Unlimited promotional campaigns',
    ],
    mostPopular: false,
    price: { monthly: '$200', annually: '$2000' },
    priceId: { monthly: 'price_1POJUSP4OgHr2xe6WRgDQS7J', annually: 'price_1POJUmP4OgHr2xe68fpxHuAH' },
  },
];

export default function ShopSubscriptionPricing() {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const navigate = useNavigate();

  const handleSubscriptionSelection = (priceId, isFree) => {
    if (isFree) {
      // No subscription option selected, redirect to homepage
      navigate('/');
    } else {
      // Proceed with subscription checkout
      navigate(`/shop-subscription-checkout/${priceId}`);
    }
  };

  return (
    <div className="shop-subscription-pricing-container">
      <div className="shop-subscription-pricing-content">
        <div className="shop-subscription-pricing-header">
          <h2 className="shop-subscription-pricing-title">Shop Pricing</h2>
          <p className="shop-subscription-pricing-subtitle">
            Flexible pricing plans for your shop
          </p>
        </div>
        <p className="shop-subscription-pricing-description">
          Choose the plan that suits your shop size and goals.
        </p>
        <div className="shop-subscription-pricing-frequency">
          {frequencies.map((option) => (
            <div
              key={option.value}
              className={`shop-subscription-pricing-frequency-option ${frequency.value === option.value ? 'active' : ''}`}
              onClick={() => setFrequency(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
        <div className="shop-subscription-pricing-tiers">
          {shopTiers.map((tier) => (
            <div
              key={tier.id}
              className={`shop-subscription-pricing-tier ${tier.mostPopular ? 'most-popular' : ''}`}
            >
              <div className="shop-subscription-pricing-tier-header">
                <h3 className="shop-subscription-pricing-tier-name">{tier.name}</h3>
                {tier.mostPopular && <p className="shop-subscription-pricing-tier-badge">Most popular</p>}
              </div>
              <p className="shop-subscription-pricing-tier-description">{tier.description}</p>
              <p className="shop-subscription-pricing-tier-price">
                <span className="price">{tier.price[frequency.value]}</span>
                <span className="suffix">{frequency.priceSuffix}</span>
              </p>
              <button
                onClick={() => handleSubscriptionSelection(tier.priceId[frequency.value], tier.price[frequency.value] === 'Free')}
                className="shop-subscription-pricing-subscribe-btn"
              >
                {tier.price[frequency.value] === 'Free' ? 'Select Free Plan' : 'Subscribe Now'}
              </button>
              <ul className="shop-subscription-pricing-features">
                {tier.features.map((feature, index) => (
                  <li key={index} className="shop-subscription-pricing-feature">
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}


