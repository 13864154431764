import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Listbox, Disclosure, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronUpDownIcon, CheckIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid'
import MapComponent from '../components/Map/Map'
import Header from '../components/Layout/Header'
import Sidebar from '../components/Layout/Sidebar'
import { fetchBrands, fetchBrandCatalogItems } from "../redux/actions/brand";
import { loadAllSellers } from "../redux/actions/user";
import MapProductAdSlider from '../components/Sponsored/MapProductAdSlider';
import ComingSoon from '../components/Admin/ComingSoon';

const sortOptions = [
  { name: 'Most Popular', href: '#', current: true },
  { name: 'Best Rating', href: '#', current: false },
  { name: 'Newest', href: '#', current: false },
  { name: 'Price: Low to High', href: '#', current: false },
  { name: 'Price: High to Low', href: '#', current: false },
]
const subCategories = [
  { name: 'Favorites', href: '#' },
  { name: 'Most Liked', href: '#' },
  { name: 'Sales', href: '#' },
  { name: 'Events', href: '#' },
  { name: 'Trending', href: '#' },
]
const filters = [
  {
    id: 'color',
    name: 'Price',
    options: [
      { value: '$1-20', label: '$1-10', checked: false },
      { value: '$21-40', label: '$21-40', checked: false },
      { value: '$41-60', label: '$41-60', checked: true },
      { value: '$61-80', label: '$61-80', checked: false },
      { value: '$81-100', label: '$81-100', checked: false },
      { value: '$100+', label: '$100+', checked: false },
    ],
  },
  {
    id: 'category',
    name: 'Category',
    options: [
      { value: 'new-arrivals', label: 'New Arrivals', checked: false },
      { value: 'sale', label: 'Sale', checked: false },
      { value: 'THC', label: 'THC', checked: true },
      { value: 'CBD', label: 'CBD', checked: false },
      { value: 'Deltas', label: 'Deltas', checked: false },
    ],
  },
  {
    id: 'size',
    name: 'Distance',
    options: [
      { value: '2m', label: '2m', checked: false },
      { value: '6m', label: '6m', checked: false },
      { value: '12m', label: '12m', checked: false },
      { value: '18m', label: '18m', checked: false },
      { value: '20m', label: '20m', checked: false },
      { value: '40m', label: '40m', checked: true },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MapPage() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [selectedBrand, setSelectedBrand] = useState('');
  const  shops  = useSelector((state) => state.seller.allSellers);
  const dispatch = useDispatch();
  const [selectedShopList, setSelectedShopList] = useState(null);
  const brandsFromRedux = useSelector((state) => state.brand.brands);
  const [brandFilters, setBrandFilters] = useState({
    id: 'brands',
    name: 'Brands',
    options: [],
  });


  useEffect(() => {
    // Dispatch fetchBrands action at component mount or when relevant state changes
    dispatch(fetchBrands());
  
    // Determine if a brand has been selected and fetch sellers accordingly
    if (selectedBrand) {
      // If there's a selectedBrand, dispatch loadAllSellers with the brand
      dispatch(loadAllSellers(selectedBrand.value));
    } else {
      // If no brand is selected, dispatch loadAllSellers without a brand to fetch all sellers
      dispatch(loadAllSellers());
    }
  }, [dispatch, selectedBrand]);
  

  useEffect(() => {
    console.log("List of Shops:", shops);
  }, [shops]);

  useEffect(() => {
    if (brandsFromRedux) {
      const brandOptions = brandsFromRedux.map(brand => ({
        value: brand.name,
        label: brand.name,
        checked: false,
      }));
      setBrandFilters(prevFilters => ({ ...prevFilters, options: brandOptions }));
    }
  }, [brandsFromRedux]);
  

  // Function to handle brand selection change
  const handleBrandChange = (value) => {
    console.log("Selected Brand:", value); // Logging the selected brand immediately
    setSelectedBrand(value);
  
    // Reset checked state for brand filters
    const updatedOptions = brandFilters.options.map(option => ({
      ...option,
      checked: option.value === value, // Compare directly with value
    }));
    setBrandFilters(prevFilters => ({ ...prevFilters, options: updatedOptions }));
  };
  

  return (
    <div>
      <Header />
      <Sidebar />
      <ComingSoon />
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="map-page-mobile-filter-dialog" onClose={setMobileFiltersOpen}>
            <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
              <div className="map-page-mobile-filter-overlay" />
            </Transition.Child>

            <div className="map-page-mobile-filter-panel">
              <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="translate-x-full">
                <Dialog.Panel className="map-page-mobile-filter-content">
                  <div className="map-page-mobile-filter-header">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button type="button" className="map-page-mobile-filter-close-button" onClick={() => setMobileFiltersOpen(false)}>
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="map-page-filter-form">
                    <h3 className="sr-only">Categories</h3>
                    <ul role="list" className="map-page-category-list">
                      {subCategories.map((category) => (
                        <li key={category.name}>
                          <a href={category.href} className="block px-2 py-3">
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                    {filters.map((section) => (
                      <Disclosure as="div" key={section.id} className="map-page-filter-section">
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="map-page-filter-section-button">
                                <span className="map-page-filter-section-label">{section.name}</span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                  ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="map-page-filter-section-content">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div key={option.value} className="map-page-filter-option">
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.value}
                                      type="checkbox"
                                      defaultChecked={option.checked}
                                      className="map-page-filter-option-checkbox"
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                      className="map-page-filter-option-label"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="map-page-main">
          <div className="map-page-header">
            <h1 className="map-page-title">Map</h1>

            <div className="map-page-filters">
              {/* Horizontal filters */}
              <Listbox value={selectedBrand} onChange={handleBrandChange}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">Brands</Listbox.Label>
                    <div className="relative">
                      <Listbox.Button className="map-page-listbox-button">
                        <span>{selectedBrand ? selectedBrand.label : "Select a brand"}</span>
                        <ChevronUpDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                      </Listbox.Button>
                      <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Listbox.Options className="map-page-listbox-options">
                          {brandFilters.options.map((brand) => (
                            <Listbox.Option
                              key={brand.value}
                              className={({ active }) => `map-page-listbox-option ${active ? 'map-page-listbox-option-active' : ''}`}
                              value={brand}
                            >
                              {({ selected }) => (
                                <>
                                  <span className={`block truncate ${selected ? 'map-page-listbox-option-selected' : ''}`}>
                                    {brand.label}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              {subCategories.map((category) => (
                <a key={category.name} href={category.href} className="text-sm font-medium text-gray-700 hover:text-gray-900 whitespace-nowrap">
                  {category.name}
                </a>
              ))}
            </div>
            
            <Menu as="div" className="map-page-sort-menu">
              <div>
                <Menu.Button className="map-page-sort-menu-button">
                  Sort
                  <ChevronDownIcon className="map-page-sort-menu-chevron" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="map-page-sort-menu-options">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <a href={option.href} className={`map-page-sort-menu-option ${active ? 'map-page-sort-menu-option-active' : ''}`}>
                            {option.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button type="button" className="map-page-grid-button">
              <span className="sr-only">View grid</span>
              <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button type="button" className="map-page-filters-button" onClick={() => setMobileFiltersOpen(true)}>
              <span className="sr-only">Filters</span>
              <FunnelIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>

          <section aria-labelledby="products-heading" className="map-page-section">
            <h2 id="products-heading" className="sr-only">Products</h2>

            <div className="map-page-content">
              {/* Filters */}
              <div className="filters-container">
                <h2 className="text-lg font-semibold mb-4">Shop List</h2>
                <div className="map-page-shop-list">
                  {shops?.map((shop) => (
                    <div key={shop._id} className="map-page-shop-item" onClick={() => setSelectedShopList(shop)}>
                      <img src={shop.banner.url} alt="Shop Cover" />
                      <img src={shop.avatar.url} alt="Shop Logo" className="map-page-shop-item-avatar" />
                      <div className="map-page-shop-item-content">
                        <h3 className="map-page-shop-item-title">{shop.name}</h3>
                        <p className="map-page-shop-item-description">{shop.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Map Product Ad Slider */}
              <div>
                <MapProductAdSlider />
              </div>
              
              {/* Map */}
              <div className="map-page-map-container">
                <MapComponent selectedBrand={selectedBrand} selectedShopList={selectedShopList} />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}