import React, { useEffect, useState } from 'react';
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from 'react-icons/ai';
import { MdBorderClear } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from 'chart.js';
import { getAllOrdersOfShop } from '../../redux/actions/order';
import { getAllProductsShop } from '../../redux/actions/product';
import Loader from '../Layout/Loader';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

const DashboardHero = () => {
  const dispatch = useDispatch();
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const [filter, setFilter] = useState('monthly');

  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllOrdersOfShop(seller._id));
      dispatch(getAllProductsShop(seller._id));
    }
  }, [dispatch, seller?._id]);

  const availableBalance = seller?.availableBalance?.toFixed(2);

  // Prepare chart data for monthly orders
  const prepareMonthlyChartData = (orders) => {
    if (!orders) return null;
    const monthNames = [
      'January', 'February', 'March', 'April', 'May',
      'June', 'July', 'August', 'September', 'October',
      'November', 'December'
    ];

    const ordersPerMonth = Array(12).fill(0);
    orders.forEach(order => {
      const orderDate = new Date(order.createdAt);
      const monthIndex = orderDate.getMonth();
      ordersPerMonth[monthIndex]++;
    });

    return {
      labels: monthNames,
      datasets: [
        {
          label: 'Orders Per Month',
          data: ordersPerMonth,
          fill: false,
          borderColor: 'rgba(75, 192, 192, 1)',
          tension: 0.1,
        },
      ],
    };
  };

  // Prepare chart data for daily orders
  const prepareDailyChartData = (orders) => {
    if (!orders) return null;
    const ordersByDate = {};

    orders.forEach(order => {
      const orderDate = new Date(order.createdAt).toLocaleDateString('en-US');
      ordersByDate[orderDate] = (ordersByDate[orderDate] || 0) + 1;
    });

    const sortedDates = Object.keys(ordersByDate).sort((a, b) => new Date(a) - new Date(b));
    const ordersPerDay = sortedDates.map(date => ordersByDate[date]);

    return {
      labels: sortedDates,
      datasets: [
        {
          label: 'Orders Per Day',
          data: ordersPerDay,
          fill: false,
          borderColor: 'rgba(153, 102, ${orders ? new Set(orders.map(order => order.user)).size : 0}5, 1)',
          tension: 0.1,
        },
      ],
    };
  };

  const chartData = orders && orders.length > 0
  ? (filter === 'monthly' ? prepareMonthlyChartData(orders) : prepareDailyChartData(orders))
  : null;


  const columns = [
    { field: 'id', headerName: 'Order ID', minWidth: 150, flex: 0.7 },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) =>
        params.row.status === 'Delivered' ? 'greenColor' : 'redColor',
    },
    {
      field: 'itemsQty',
      headerName: 'Items Qty',
      type: 'number',
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      minWidth: 130,
      flex: 0.8,
      cellClassName: (params) =>
        params.row.paymentStatus === 'Pending'
          ? 'yellowColor'
          : 'greenColor',
    },
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 160,
      flex: 0.8,
      valueFormatter: ({ value }) =>
        new Date(value).toLocaleDateString('en-US'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Link to={`/order/${params.id}`}>
          <button className="dashboard-hero-button">
            <AiOutlineArrowRight size={20} />
          </button>
        </Link>
      ),
    },
  ];

  const rows = orders
    ? orders.map((item) => {
        const total = item.cart.reduce(
          (acc, curr) =>
            acc + (curr.unitAmount / 100) * curr.quantity,
          0
        );
        return {
          id: item._id,
          itemsQty: item.cart.length,
          total: `US$ ${total.toFixed(2)}`,
          status: item.status,
          paymentStatus: item.paymentInfo.status,
          date: item.createdAt,
        };
      })
    : [];

  const getRowClassName = (params) => {
    if (params.row.paymentStatus === 'Pending') {
      return 'shop-dashboard-hero-all-orders-pending-row';
    }
    return '';
  };
  
  const uniqueCustomerCount = orders ? new Set(orders.map(order => order.user)).size : 0;

  return (
    <div className="shop-dashboard-hero-header-container">
      <h1 className="bento-title">My Dashboard</h1>
      <div className="bento-container">
        <div className="bento-column bento-column-left">
        <h2 className="bento-title">Orders</h2>
          <div className="shop-dashboard-hero-header-chart-controls">
            <button onClick={() => setFilter('monthly')} className={filter === 'monthly' ? 'active-filter' : ''}>Monthly</button>
            <button onClick={() => setFilter('daily')} className={filter === 'daily' ? 'active-filter' : ''}>Daily</button>
          </div>
          <div className="shop-dashboard-hero-header-chart">
            {isLoading ? (
              <Loader />
            ) : (
              chartData && (
                <Line
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: 'top',
                      },
                    },
                  }}
                />
              )
            )}
          </div>
        </div>

        <div className="bento-middle">
          <div className="bento-box bento-box-top">
            <div className="shop-dashboard-hero-header-card">
              <div className="shop-dashboard-hero-header-card-content">
                <AiOutlineMoneyCollect
                  size={30}
                  className="mr-2"
                  fill="#00000085"
                />
                <h3 className="shop-dashboard-hero-header-card-title">
                  Account Balance{' '}
                  <span className="shop-dashboard-hero-header-card-subtitle">
                    (with 10% service charge)
                  </span>
                </h3>
              </div>
              <h5 className="shop-dashboard-hero-header-card-value">
                ${availableBalance}
              </h5>
              <Link to="/dashboard-withdraw-money">
                <h5 className="shop-dashboard-hero-header-card-link">
                  Withdraw Money
                </h5>
              </Link>
            </div>
          </div>
          <div className="bento-box bento-box-bottom">
            <div className="shop-dashboard-hero-header-card">
              <div className="shop-dashboard-hero-header-card-content">
                <MdBorderClear size={30} className="mr-2" fill="#00000085" />
                <h3 className="shop-dashboard-hero-header-card-title">All Orders</h3>
              </div>
              <h5 className="shop-dashboard-hero-header-card-value">
                {orders?.length}
              </h5>
              <Link to="/dashboard-orders">
                <h5 className="shop-dashboard-hero-header-card-link">
                  View Orders
                </h5>
              </Link>
            </div>
          </div>
          <div className="bento-box bento-box-bottom">
            <div className="shop-dashboard-hero-header-card">
              <div className="shop-dashboard-hero-header-card-content">
                <AiOutlineMoneyCollect
                  size={30}
                  className="mr-2"
                  fill="#00000085"
                />
                <h3 className="shop-dashboard-hero-header-card-title">
                  All Products
                </h3>
              </div>
              <h5 className="shop-dashboard-hero-header-card-value">
                {products?.length}
              </h5>
              <Link to="/dashboard-products">
                <h5 className="shop-dashboard-hero-header-card-link">
                  View Products
                </h5>
              </Link>
            </div>
          </div>
        </div>

        <div className="bento-column bento-column-right">
          <div className="bento-box bento-box-top">
            <div className="shop-dashboard-hero-header-card">
              <div className="shop-dashboard-hero-header-card-content">
                <MdBorderClear size={30} className="mr-2" fill="#00000085" />
                <h3 className="shop-dashboard-hero-header-card-title">Total Sales</h3>
              </div>
              <h5 className="shop-dashboard-hero-header-card-value">
                ${orders ? orders.reduce((acc, order) => acc + order.cart.reduce((sum, item) => sum + (item.unitAmount / 100) * item.quantity, 0), 0).toFixed(2) : 0}
              </h5>
            </div>
          </div>
          <div className="bento-box bento-box-bottom">
            <div className="shop-dashboard-hero-header-card">
            <div className="shop-dashboard-hero-header-card-content">
                <AiOutlineMoneyCollect
                  size={30}
                  className="mr-2"
                  fill="#00000085"
                />
                <h3 className="shop-dashboard-hero-header-card-title">
                  Total Customers
                </h3>
              </div>
              <h5 className="shop-dashboard-hero-header-card-value">
                {uniqueCustomerCount}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <br />
      <h3 className="shop-dashboard-hero-header-latest-orders">Latest Orders</h3>
      <div className="shop-dashboard-hero-header-orders-container shop-dashboard-hero-all-orders-container">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="shop-dashboard-hero-header-orders-table shop-dashboard-hero-all-orders-table">
            <table className="shop-dashboard-hero-header-data-grid shop-dashboard-hero-all-orders-data-grid">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.field}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr
                    key={row.id}
                    className={getRowClassName({ row })}
                  >
                    <td>{row.id}</td>
                    <td>{row.status}</td>
                    <td>{row.itemsQty}</td>
                    <td>{row.total}</td>
                    <td>{row.paymentStatus}</td>
                    <td>
                      {new Date(row.date).toLocaleDateString('en-US')}
                    </td>
                    <td>
                      <Link to={`/order/${row.id}`}>
                        <button className="dashboard-hero-button">
                          <AiOutlineArrowRight size={20} />
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardHero;
