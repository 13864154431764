import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'; // For React Portal
import socketIOClient from 'socket.io-client';
import {
  UserCircleIcon,
  ChatBubbleLeftEllipsisIcon,
  NewspaperIcon,
  UserPlusIcon,
  ShareIcon
} from '@heroicons/react/20/solid';
import { backend_url } from '../server';
import { Link } from 'react-router-dom';
import SinglePostWidget from '../widgets/SinglePostWidget';
import PropTypes from 'prop-types';

const ENDPOINT = backend_url;
const socket = socketIOClient(ENDPOINT, { withCredentials: true });

function NotificationFeed({ profileId, profileType }) {
  const [notifications, setNotifications] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

  const getIconForType = (type) => {
    switch (type) {
      case 'newComment':
        return <ChatBubbleLeftEllipsisIcon className="notification-feed-icon" aria-hidden="true" />;
      case 'newLike':
        return (
          <img
            src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845531/icon-BuzzBolt-02_ernlpk.png"
            alt="Like Notification"
            className="notification-feed-icon"
          />
        );
      case 'newPost':
        return <NewspaperIcon className="notification-feed-icon" aria-hidden="true" />;
      case 'follow':
        return <UserPlusIcon className="notification-feed-icon" aria-hidden="true" />;
      case 'reshare':
        return <ShareIcon className="notification-feed-icon" aria-hidden="true" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    // Fetch notifications
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`${ENDPOINT}/api/v2/notifications/get-notifications/${profileType}/${profileId}`);
        const data = await response.json();
        setNotifications(data.notifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();

    socket.on('notification', (newNotification) => {
      setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
    });

    return () => socket.off('notification');
  }, [profileId, profileType]);

  const getProfileLink = (senderProfileType, sender) => {
    if (senderProfileType === 'User') {
      return `/profile/${sender._id}`;
    } else if (senderProfileType === 'Shop') {
      return `/shop/${sender.handle}`;
    }
    return '#';
  };

  const handleOpenPostModal = (post) => {
    console.log("Selected Post:", post); // Debug log
    setSelectedPost(post);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
  };

  // Modal JSX using the same structure and class names from PostCard
  const modal = selectedPost
    ? ReactDOM.createPortal(
        <div className="post-modal" onClick={handleCloseModal}>
          <div
            className="post-modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent modal close on content click
            role="dialog"
            aria-modal="true"
            aria-labelledby="single-post-modal-title"
          >
            <button
              className="post-modal-close-button"
              onClick={handleCloseModal}
              aria-label="Close Modal"
            >
              &times;
            </button>
            <SinglePostWidget
              postId={selectedPost._id}
              owner={selectedPost.owner}
              postUserId={selectedPost.userId}
              profileType={selectedPost.profileType}
              name={selectedPost.name}
              description={selectedPost.description}
              location={selectedPost.location}
              images={selectedPost.images}
              videos={selectedPost.videos}
              avatar={selectedPost.avatar}
              likes={selectedPost.likes}
              comments={selectedPost.comments}
              createdAt={selectedPost.createdAt}
              resharedBy={selectedPost.resharedBy}
              reshareDescription={selectedPost.reshareDescription}
              reshareAvatar={selectedPost.reshareAvatar}
            />
          </div>
        </div>,
        document.body // Render modal into the body for consistent placement
      )
    : null;

  return (
    <div className="notification-feed-flow-root">
      {(!notifications || notifications.length === 0) ? (
        <div className="notification-feed-empty">
          <p className="notification-feed-empty-message">You don't have any recent notifications</p>
        </div>
      ) : (
        <ul role="list" className="notification-feed-list">
          {notifications.map((notification, idx) => (
            <li key={notification._id}>
              <div className="notification-feed-item">
                {idx !== notifications.length - 1 && (
                  <span className="notification-feed-connector" aria-hidden="true" />
                )}
                <div className="notification-feed-content-wrapper">
                  <div className="notification-feed-icon-wrapper">
                    {getIconForType(notification.type)}
                    {notification.sender && (
                      <img
                        className="notification-feed-avatar"
                        src={notification.sender.avatar.url}
                        alt="Sender avatar"
                      />
                    )}
                  </div>
                  <div className="notification-feed-text-wrapper">
                    <div className="notification-feed-text">
                      <p>
                        <Link to={getProfileLink(notification.senderProfileType, notification.sender)} className="notification-feed-link">
                          <strong>{notification.sender.name}</strong>
                        </Link>
                      </p>
                      <p>{notification.message}</p>
                      <p>{new Date(notification.createdAt).toLocaleString()}</p>
                    </div>

                    {notification.relatedPost && (
                      <button
                        className="notifcations-view-post-button"
                        onClick={() => handleOpenPostModal(notification.relatedPost)}
                      >
                        View Post
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Render modal using React Portal */}
      {modal}
    </div>
  );
}

// PropTypes for better type safety and documentation
NotificationFeed.propTypes = {
  profileId: PropTypes.string.isRequired,
  profileType: PropTypes.oneOf(['User', 'Shop']).isRequired,
};

export default NotificationFeed;











