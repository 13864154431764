import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ecommerceServer  } from '../../server'; 
import RegionForm from './RegionForm';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

const RegionsList = () => {
    const [regions, setRegions] = useState([]);
    const [currentRegion, setCurrentRegion] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchRegions();
    }, []);

    const fetchRegions = async () => {
        try {
            const { data } = await axios.get(`${ecommerceServer}/region/regions`);
            setRegions(data);
        } catch (error) {
            console.error('Error fetching regions:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${ecommerceServer}/region/regions/${id}`);
            fetchRegions();
        } catch (error) {
            console.error('Error deleting region:', error);
        }
    };

    const openModal = (region) => {
        setCurrentRegion(region);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setCurrentRegion(null);
        setIsModalOpen(false);
    };

    return (
        <div className="regions-container">
            <button className="regions-button-add" onClick={() => openModal({ name: '', states: [], boundaries: {} })}>
                <PlusIcon className="regions-button-add-icon" /> Add New Region
            </button>
            <ul className="regions-list">
                {regions.map(region => (
                    <li key={region._id} className="regions-item">
                        {region.name}
                        <div className="regions-actions">
                            <button className="regions-button regions-button-edit" onClick={() => openModal(region)}>Edit</button>
                            <button className="regions-button regions-button-delete" onClick={() => handleDelete(region._id)}>Delete</button>
                        </div>
                    </li>
                ))}
            </ul>

            {isModalOpen && (
                <div className="regions-modal-overlay">
                    <div className="regions-modal-content">
                        <RegionForm currentRegion={currentRegion} setCurrentRegion={setCurrentRegion} fetchRegions={fetchRegions} />
                        <button className="regions-modal-close-button" onClick={closeModal}>
                        <XMarkIcon className="xmark-icon" />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RegionsList;