import React, { useState } from 'react';

const MembershipAgreement = ({ nextStep, prevStep }) => {
    const [error, setError] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);

    const handleNext = () => {
        if (!isAgreed) {
            setError('You must agree to the terms before proceeding.');
        } else {
            setError('');
            nextStep();
        }
    };

    return (
        <div className="membership-agreement-container">
            <div className="membership-agreement-content">
                <h1 className="membership-agreement-title">BUZZVIBE STUDIOS MEMBERSHIP AGREEMENT</h1>
                <div className="membership-agreement-text">
                    <h2>BUZZVIBE STUDIOS MEMBERSHIP AGREEMENT</h2>
                    <p>
                        This Membership Agreement (“Agreement”) between you and BuzzVibe Studios LLC (“Studio”) outlines your rights and obligations as a member of the Studio, including your access to Studio facilities (“Facilities”). By signing below, you are accepting the terms of this Agreement and agreeing that you shall abide by, and be bound by this Agreement.
                    </p>
                    <h2>1. MEMBERSHIP</h2>
                    <p>Your membership includes:</p>
                    <ul>
                        <li>(a) Ability to reserve time in the recording and/or podcasting studio, and use of the equipment located at the Facilities, subject to availability;</li>
                        <li>(b) Ability to book a Studio engineer/producer, subject to availability;</li>
                        <li>(c) Access to shared common spaces, including lounge area, located at the Facilities;</li>
                        <li>(d) Access to and use of a shared internet connection while at the Facilities; and</li>
                        <li>(e) Certain optional add-on services and/or member benefits.</li>
                    </ul>

                    <h2>STUDIO RENTAL COSTS</h2>
                    <table className="membership-agreement-table">
                        <thead>
                            <tr>
                                <th>Fee</th>
                                <th>Amount</th>
                                <th>Payment Terms</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Fee">Recording Studio Fees</td>
                                <td data-label="Amount">$100/hour; 3 hour session block.</td>
                                <td data-label="Payment Terms">Due no later than forty-eight (48) hours prior to the reservation time</td>
                            </tr>
                            <tr>
                                <td data-label="Fee">Podcasting Studio Fees</td>
                                <td data-label="Amount">$100/hour; 3 hour session block.</td>
                                <td data-label="Payment Terms">Due no later than forty-eight (48) hours prior to the reservation time</td>
                            </tr>
                            <tr>
                                <td data-label="Fee">Optional Service Add-ons</td>
                                <td data-label="Amount">Refreshment/Beverages $25/month</td>
                                <td data-label="Payment Terms">Due no later than the 1st of each month</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>2. RESERVATIONS; SCHEDULING</h2>
                    <p>
                        (a) Studio grants you a revocable and limited license to use the Facilities (recording studio and/or podcasting studio, as applicable) during the reservation dates and times you select only for the purposes set forth in this Agreement. The Facilities will only be available to you (and permitted guests) during the hours which you selected, which may be limited. Reservations are not transferable.
                    </p>
                    <p>
                        (b) Reservations are made on a first come, first served basis and are subject to availability. Reservations must be made via e-mail at least twenty-four (24) hours prior to your desired reservation time. Your reservation is not complete until you (i) pay a deposit covering the entire amount of fees due for your reservation and (ii) receive a confirmation e-mail from the Studio.
                    </p>
                    <p>
                        (c) You have no license to use the Facilities at any time other than during your reservation period. If the Facilities are used in any unauthorized way, (i) all persons may be required to immediately leave or may be removed from the Facilities, (ii) you will be in breach of this Agreement, and (iii) your membership may be terminated at the Studio’s sole discretion.
                    </p>
                    <p>
                        (d) Any request to reschedule must be made and confirmed by the Studio via email at least twenty-four (24) hours prior to the scheduled reservation time. Rescheduling is not guaranteed and is based on availability. Studio makes no guarantee that your reservation time can be rescheduled at your request.
                    </p>
                    <p>
                        (e) Studio may need to reschedule your reservation time due to unforeseen circumstances, including but not limited to cleaning and equipment repairs. If this should occur, Studio will notify you of the need to reschedule and will use reasonable efforts to reschedule to a future date and time of your choosing, subject to availability.
                    </p>
                    <p>
                        (f) In the event any equipment at the Facilities malfunctions or is unusable during your reservation period, you must promptly notify the Studio.
                    </p>
                    <p>
                        (g) Any request to cancel your reservation must be made via email at least twenty-four (24) hours prior to your scheduled reservation time. If you cancel with less than twenty-four (24) hours advance notice, you will still be responsible for payment of all applicable fees. No refund or rescheduling will be provided for cancellations made with less than twenty-four (24) hours advance notice.
                    </p>
                    <p>
                        (h) If you do not reschedule or cancel your reservation as provided in this Section, and do not show up for your reservation period, you will forfeit that reservation time. If you no-show three (3) times during your membership, Studio may terminate your membership.
                    </p>
                    <p>
                        (i) If you do not show up for your reservation period and had reserved a Studio engineer and/or producer, in addition to the applicable hourly fees for studio time, you will still be responsible for and charged applicable hourly fees for such engineering and/or production services.
                    </p>
                    <p>
                        (j) You will not be refunded for any hourly fees (or portion of such fees) if you choose not to use the Studio for your entire reservation period.
                    </p>
                    <h2>3. STUDIO ENGINEER/PRODUCER</h2>
                    <p>
                        (a) You are generally responsible for the management, production, creation, and development of your recordings. If you reserve a Studio engineer or producer, these individuals will be available to you during your reservation period only. Studio engineers and producers can only be reserved with forty-eight (48) hours advance notice, subject to availability.
                    </p>
                    <p>
                        (b) Cancellation and re-scheduling requests for engineers and producers may be accommodated at the sole discretion of Studio and/or the engineer or producer. Any such requests must be made at least forty-eight (48) hours prior to the scheduled reservation time. You are responsible for all engineer or producer fees for any cancellation or re-scheduling request made less than forty-eight (48) hours of the scheduled reservation time.
                    </p>
                    <p>
                        (c) Reserving a Studio engineer or producer does not create any employment relationship between you and the Studio or Studio engineer or producer.
                    </p>
                    <h2>4. GUESTS/STUDIO CAPACITY</h2>
                    <p>
                        (a) Your membership is intended for your personal use only. You may not allow any other individuals to use your membership or access the Facilities without Studio’s prior written consent. If Studio provides written consent for you invite one or more guests to the Facilities, you agree to accompany your guests at all times. You and your guests agree to adhere to any check-in or security procedures that may be required by Studio. Your guests will be permitted to access the areas of the Facilities that you have reserved during your reservation period only. You are responsible for any damages that your guests cause and/or incur and you agree to be charged for any repairs or replacement costs that result from any damages caused by you or your guests.
                    </p>
                    <p>
                        (b) The recording studio is limited to five (5) individuals per session. The podcasting studio is limited to five (5) individuals per session.
                    </p>
                    <p>
                        (c) Studio reserves the right, in its sole discretion, to restrict the access of guests or to terminate your membership in the event that you or your guests violate the terms of this Agreement or any other applicable rules or policies.
                    </p>
                    <h2>5. FEES</h2>
                    <p>
                        Monthly membership dues and other fees for use of the Facilities and other services are specified on Exhibit A attached hereto (the “Fee Schedule”). Payment terms are set forth on the Fee Schedule. The Fee Schedule may change from time to time at Studio’s discretion, provided that the monthly membership dues shall be fixed during the first twelve (12) months of your membership. The Studio may cancel your membership for failure to timely pay dues and/or fees.
                    </p>
                    <h2>6. MEMBER CONDUCT</h2>
                    <p>
                        (a) As a condition of your membership, you agree to uphold the Membership Code of Conduct & House Rules attached hereto as Exhibit B (“House Rules”). Studio reserves the right to modify the House Rules from time to time at its discretion. Any updates to the House Rules will be binding and will be communicated to you via email and/or posted at the Facilities.
                    </p>
                    <p>
                        (b) Studio reserves the right to suspend or terminate your membership without refund if Studio, in its sole discretion, determines that you have engaged in conduct that would reasonably be considered (i) a violation of applicable law; or (ii) offensive, incendiary, racist, violent, lewd, or profane.
                    </p>
                    <p>
                        (c) If, in Studio’s sole discretion, it determines that your conduct violates this Agreement or the House Rules in any way, you agree that Studio may immediately and permanently terminate your membership without refund of any dues and/or fees previously paid or payable.
                    </p>
                    <h2>7. CONDITION OF THE FACILITIES</h2>
                    <p>
                        (a) The Facilities (including any equipment or similar property) are provided as-is, and Studio makes no warranty to you regarding the suitability of the Facilities for your intended use. You should immediately notify Studio of any problem, malfunction, or damage to the Facilities (including equipment). You are not permitted to remove, add, or change any equipment, fixtures, furnishings, or property.
                    </p>
                    <p>
                        (b) You shall leave the Facilities in the same condition as when you entered. All furniture and property should be returned to its original location, lights should be turned off and all trash disposed of in the nearest containers outside of the Facilities when you depart. You shall be responsible for any damage caused to the Facilities during your reservation period and/or while you are at the Facilities, as well as any costs of cleanup. Studio will arrange for any necessary repairs, including replacement of equipment, at your expense. Studio is not responsible for any personal item or property that is left, lost, damaged, or stolen at or during your use of the Facilities.
                    </p>
                    <h2>8. RIGHT OF ENTRY</h2>
                    <p>
                        Studio will not use or allow others to access the areas of the Facilities you have reserved during your reservation period (i.e., recording studio or podcasting studio, as applicable). However, Studio, including its management and staff, reserves the right to enter all areas of the Facilities at any time for any reasonable purpose, including but not limited to repairs to the Facilities (including fixtures, furnishings, and property), any emergency that may threaten damage to the Facilities or injury to any person in or near the Facilities. Studio will provide reasonable notice of entry, when possible.
                    </p>
                    <h2>9. HOLDING OVER</h2>
                    <p>
                        You agree there will be no holding over or late departures from the Facilities without Studio’s prior approval. In the event there is availability following your reservation period, you may request to reserve additional time for additional fees. Any unauthorized holding over or unauthorized late departure will be subject to a charge of fifty dollars ($50) per hour, plus the standard hourly reservation fees, and any additional fees Studio incurs including, but not limited to, the cost of refunding fees to members or others displaced by your holding over.
                    </p>
                    <h2>10. PERMITTED USE</h2>
                    <p>
                        (a) You are authorized to use the Facilities solely for musical and/or audio recording, editing, and production, and for no other purpose unless Studio gives you prior written permission for additional permitted uses. You may not use the Facilities in any manner that may lead to liability for Studio, violate any occupancy rules, render the Studio’s insurance void, or which may result in increased insurance premiums. You shall not use the Facilities in any manner that would violate any applicable law or regulation.
                    </p>
                    <p>
                        (b) You are not permitted to use the Facilities for mail or package delivery. Studio shall not be liable in connection with any mail or packages delivered to the Facilities.
                    </p>
                    <p>
                        (c) You further agree to observe and obey all rules and warnings and to follow any instructions or directions provided by Studio or the property manager/owner of the Facilities.
                    </p>
                    <h2>11. OTHER MEMBERS</h2>
                    <p>
                        Studio does not control and is not responsible for the actions of other individuals you or your guests encounter at the Facilities. This includes, but is not limited to, other Studio members and their guests, tenants, and visitors of the Facilities, employees, staff, security, and/or contractors. If a dispute arises between Studio members (or their permitted guests), Studio has no responsibility or obligation to participate, mediate, or indemnify any party, except to the extent that such dispute is the result of Studio’s gross negligence or willful misconduct.
                    </p>
                    <h2>12. LIVE OR IN-PERSON EVENTS</h2>
                    <p>
                        (a) Studio may host live or in-person events, including listening sessions (for business purposes) or listening parties.
                    </p>
                    <p>
                        (b) If you participate in any live or in-person event, you agree to observe and obey all posted rules and warnings, to follow any instructions or directions given by Studio through its employees, representatives, staff, or agents, and to abide by any decision of any such personnel, regarding your ability to safely participate. You agree to exhibit appropriate behavior at all times and to obey all applicable laws while participating in any such event. This includes, generally, respect for other people, equipment, facilities, or property and adhering to the House Rules. Studio (and/or security personnel) may dismiss you, without refund, if your behavior endangers the safety of or negatively affects Studio, or any person, facility, or property.
                    </p>
                    <p>
                        (c) If you choose to consume alcoholic beverages during any event, you must do so responsibly and only if you are age twenty-one (21) or older. Studio is not responsible for any personal item or property that is lost, damaged, or stolen at or during any event.
                    </p>
                    <h2>13. THIRD PARTY PRODUCTS OR SERVICES</h2>
                    <p>
                        Your membership does not include, and Studio is not liable for, the products or services provided by third parties. Third party services are provided solely by the applicable third-party service providers and pursuant to separate arrangements between you and the third party service provider. To the extent any third party platforms or apps have their own terms of use, such terms shall govern the use of the applicable system. For those without terms of use, such platforms or apps shall be provided to you “as-is,” and without any representations or warranties.
                    </p>
                    <h2>14. FORCE MAJEURE</h2>
                    <p>
                        Studio and its affiliates, owners, members, managers, directors, employees, contractors, agents, representatives, successors, or assigns (collectively, “Releasees”) shall not be deemed in breach of this Agreement if Releasees are unable to provide any of the membership services, access to the Facilities, or any portion thereof by reason of fire, earthquake, labor dispute, act of God or public enemy, epidemic, pandemic, death, illness, or incapacity of Releasees or any local, state, federal, national or international law, governmental order or regulation, or any other event beyond Releasees’ reasonable control (collectively, “Force Majeure Event”). Upon occurrence of any Force Majeure Event, Releasees shall give notice to you of their inability to provide the membership services, access to the Facilities, or of delay in providing the aforementioned and shall use reasonable efforts to (a) reschedule all missed reservation times at no additional charge, or (b) terminate this Agreement and refund you the cost for any affected dues and/or fees.
                    </p>
                    <h2>15. TERMINATION AND CANCELLATION</h2>
                    <p>
                        Studio reserves the right in its sole discretion to refuse or terminate your membership, at any time without notice. Studio may terminate your membership at any time, without refund, if you breach any part of this Agreement. In the event of termination, you are no longer authorized to access the Facilities or otherwise retain any of membership rights or services. The restrictions imposed on you in this Agreement will continue to apply, even after termination by you or Studio.
                    </p>
                    <h2>16. INTELLECTUAL PROPERTY</h2>
                    <p>
                        (a) The words, videos, voice, and sound recordings, design, layout, graphics, photos, images, information, materials, documents, data, domain names, social media handles, databases, and all other information and intellectual property owned or created by Studio, its website, any third-party website or mobile app Studio may use in connection with its business, and contained in communications sent to you by Studio, as well as the look and feel of all of the foregoing (the “Content”) is property of Studio and/or its affiliates or licensors, and is protected by copyright, trademark, and other intellectual property law. The Facilities may also have cameras regularly in use for live-streaming and related content (all of which is considered “Content” under this Agreement), and you expressly consent to being observed, photographed, or recorded for this purpose while at the Facilities.
                    </p>
                    <p>
                        (b) Studio owns all rights, title and interest, including all intellectual property and other proprietary rights in and to Studio’s website and/or apps, including all related software and technology used to provide the certain features and functionality.
                    </p>
                    <p>
                        (c) As a member of Studio, you will be considered a licensee only of the foregoing rights. For the avoidance of doubt, you are granted a revocable, non-transferable license for personal, non-commercial use of the Content only, limited to you only.
                    </p>
                    <p>
                        (d) You may not republish, reproduce, duplicate, copy, sell, display, disclose, distribute to any third party, or otherwise use any Content for commercial purposes or in any way that earns you or any third-party money (other than by applying them generally in your own business). You may not take, copy, or use for any purpose any photographs or illustrations of any portion of the Facilities without Studio’s prior consent. By downloading, viewing, or otherwise using the Content for personal use, you are in no way entitled to any ownership rights of the foregoing. Any unauthorized use of any Studio materials or Content shall constitute appropriation and/or infringement. You must receive Studio’s written permission before using any Studio materials, or Content for your own commercial use or before sharing with third parties. The trademarks and logos displayed are trademarks belonging to Studio (and/or its affiliates and licensors), unless otherwise indicated. Any use including framing, metatags or other text utilizing these trademarks, or other trademarks displayed, is strictly prohibited without Studio’s written permission.
                    </p>
                    <p>
                        (e) All rights not expressly granted in this Agreement or any express written license, are reserved by Studio.
                    </p>
                    <h2>17. ASSUMPTION OF RISK</h2>
                    <p>
                        You knowingly and freely assume all risk and responsibility for injuries to any persons or damages to, or destruction, theft, or loss of, any property, and release, covenant not to sue, and hold Releasees harmless for any and all liability to you, your personal representatives, assigns, heirs and next of kin, for any and all claims, liabilities, causes of action, obligations, lawsuits, charges, complaints, controversies, damages, costs or expenses of whatsoever kind, nature, or description, whether direct or indirect, in law or in equity, in contract or in tort, or otherwise, whether known or unknown, arising out of or connected with your membership, use, or access of the Facilities, whether or not caused by the active or passive negligence of the Releasees. You (and any guests) agree to sign any liability waivers reasonably requested by Studio from time to time. You consent to medical care and transportation in order to obtain treatment in the event of injury to you as Studio, other personnel, or medical professionals may deem appropriate. This Agreement extends to any liability arising out of or in any way connected with the medical treatment and transportation provided in the event of an emergency and/or injury.
                    </p>
                    <h2>18. LIMITATION OF LIABILITY</h2>
                    <p>
                        In the event that the indemnity provision is held unenforceable for any reason, you agree that any damages claimed shall be limited to the total amounts paid to Studio pursuant to this Agreement. Releasees shall not be liable for any indirect, special, incidental, consequential, reliance, or punitive damages, including loss of profits or business interruption, or for the cost of any substitute goods, services, or technology.
                    </p>
                    <h2>19. INDEMNITY</h2>
                    <p>
                        To the extent permitted by law, you shall indemnify Releasees from and against any and all claims, including third party claims, losses, liabilities, damages, costs and expenses, including reasonable attorneys’ fees, resulting from any breach of this Agreement, your use of the Facilities, whether or not caused by the active or passive negligence of the Releasees.
                    </p>
                    <h2>20. SECURITY</h2>
                    <p>
                        You acknowledge that the Facilities will have recording and security cameras regularly in use and consent to being observed, photographed, or recorded while at the Facilities. You agree that Studio may use your image or recordings for content to be used in online and printed marketing and ad promotions, content creation and for security purposes, crime prevention, or crime detection, including damage to property.
                    </p>
                    <h2>21. NOTICES</h2>
                    <p>
                        All notices under this Agreement shall be made via email and deemed to be made one (1) business day after transmission. The email addresses for notices to you and Studio, respectively, are as specified on the signature page to this Agreement, and may be updated from time to time.
                    </p>
                    <h2>22. GOVERNING LAW; DISPUTES</h2>
                    <p>
                        This Agreement shall be governed in all respects by the laws of the State of California without regard to its choice of law principles. You agree to submit any and all claims, or any dispute related to this Agreement or your membership, to non-binding arbitration before JAMS. The arbitration shall be held in accordance with the JAMS then-current Streamlined Arbitration Rules & Procedures, which currently are available at: https://www.jamsadr.com/rules-streamlined-arbitration. You and Studio shall work in good faith to choose a mutually agreeable arbitrator, who shall be either a retired judge, or an attorney who is experienced in commercial contracts and licensed to practice law in California, selected pursuant to the JAMS rules. You expressly agree that any arbitration shall be conducted in Los Angeles County. You understand and agree that by signing this Agreement, you are waiving the right to a jury, unless there is a dispute as to the outcome of arbitration.
                    </p>
                    <h2>23. ASSIGNMENT</h2>
                    <p>
                        This Agreement, or the rights granted hereunder, may not be assigned or otherwise transferred in whole or part by you without Studio’s prior written consent.
                    </p>
                    <h2>24. AMENDMENTS</h2>
                    <p>
                        This Agreement may be amended from time to time at Studio’s discretion. Any amendments will be provided to you in writing via email.
                    </p>
                    <h2>25. NO WAIVER</h2>
                    <p>
                        None of the terms of this Agreement shall be deemed to have been waived by any act or acquiescence of either party. Only a written agreement can constitute waiver of any of the terms of this Agreement between the parties. No waiver of any term or provision of this Agreement shall constitute a waiver of any other term or provision or of the same provision on a future date. Failure of either party to enforce any term of this Agreement shall not constitute waiver of such term or any other term.
                    </p>
                    <h2>26. SEVERABILITY</h2>
                    <p>
                        If any provision or term of this agreement is held to be unenforceable, this Agreement will be deemed amended to the extent necessary to render the otherwise unenforceable provision, and the remainder of the Agreement, valid and enforceable.
                    </p>
                    <h2>27. ENTIRE AGREEMENT</h2>
                    <p>
                        This Agreement constitutes the entire agreement between you and Studio and supersedes any prior or contemporaneous understandings, whether written or oral.
                    </p>
                    <h2>EXHIBIT A</h2>
                    <h2>MEMBERSHIP FEE</h2>
                    <table className="membership-agreement-table">
                        <thead>
                            <tr>
                                <th>Fee</th>
                                <th>Amount</th>
                                <th>Payment Terms</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Fee">Monthly Membership Fee</td>
                                <td data-label="Amount">$50/month</td>
                                <td data-label="Payment Terms">Due no later than the 1st of each month</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div className="membership-agreement-checkbox">
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isAgreed} 
                            onChange={(e) => setIsAgreed(e.target.checked)} 
                        />
                        I agree to the terms and conditions
                    </label>
                </div>
                
                <div className="membership-agreement-buttons">
                    <button onClick={prevStep} className="membership-agreement-button membership-agreement-button-back">Back</button>
                    <button onClick={handleNext} className="membership-agreement-button membership-agreement-button-next">Next</button>
                </div>
            </div>
        </div>
    );
};

export default MembershipAgreement;


