import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { AiOutlineArrowRight } from "react-icons/ai";

const AllRefundOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  const eligibleOrders = orders?.filter((order) => order.status === "Processing refund");

  return (
    <div className="profile-content-component-orders">
      <table className="profile-content-component-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Items Qty</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {eligibleOrders &&
            eligibleOrders.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td className={order.status === "Delivered" ? "greenColor" : "redColor"}>
                  {order.status}
                </td>
                <td>{order.cart.length}</td>
                <td>{`US$ ${order.totalPrice}`}</td>
                <td>
                  <Link to={`/user/order/${order._id}`}>
                    <button className="profile-content-component-view-btn">
                      <AiOutlineArrowRight />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllRefundOrders;
